<template>
  <div class="dashboard-container">
    <div id="eventBar" :class="{cms: eventBar_link}" :style="{backgroundColor: eventBar_bg_color}"><a :href="eventBar_link" target="_blank" :style="{color: eventBar_color}">{{eventBar_content}}</a></div>
    <Header :class="{barHeader: eventBar_link, eventHeader: barShadow}" :shadow="shadow" :currUser.sync="currUser" :lang="lang" :langs="langs" :screenWidth="screenWidth" :server_env="server_env" />
    <div class="app-container">
      <div class="banner-container">
        <div class="banner">
          <div class="text">
            <h1>{{ langs.landing.banner.title }}</h1>
            <p>{{ langs.landing.banner.content }}</p>
            <a :href="utmSignhref" id="Btn_SignUp_Banner">{{ langs.landing.banner.sign }}</a>
            <a v-if="(screenWidth < 1024)" href="https://apps.apple.com/app/apple-store/id936601959?mt=8" target="_blank" class="Btn_download"><img src="../../../assets/images/home/ic-download-app-ios.svg" />{{ langs.landing.banner.downloadApp }}</a>
            <a v-else :href="browser ? 'https://chrome.google.com/webstore/detail/pdf-markup-my-web-markups/cbjngbfjehiofmihfpodinolkcokdojp' : 'https://microsoftedge.microsoft.com/addons/detail/markup-my-web-markups-/bkaddcdohjngdgldhhdmghjdohcnncjj'" target="_blank" class="Btn_download">{{ langs.landing.banner.downloadExtension }}<img src="../../../assets/images/home/arrow.svg" alt="icon"></a>
          </div>
          <img src="../../../assets/images/home/hero_image.png" srcset="../../../assets/images/home/hero_image@2x.png 2x, ../../../assets/images/home/hero_image@3x.png 3x" class="hero-image">
        </div>
      </div>
      <div class="out" ref="out">
        <div class="slide" :class="{'slideFixed' : fixed}">
          <div class="imgNone" :class="{'imgFixed' : fixed}" ref="imgFixed" :style="{backgroundImage: 'url(' + backgroundImage + ')'}"></div>
          <div class="swiper-container swiper1" v-show="ifSwiper" ref="swiper1">
            <div class="swiper-wrapper">
              <div class="swiper-slide" ref="slide1" id="slide1">
                <div class="img-container" :class="{'imageNone' : fixed}">
                  <img src="../../../assets/images/home/img-feature-capture.svg" alt="Capture Your Key Findings">
                </div>
                <div class="text-container" :class="{'textBlock' : activeNumber == 0}">
                  <h3>{{ langs.landing.out.capture.title }}</h3>
                  <p>{{ langs.landing.out.capture.content }}</p>
                </div>
              </div>
              <div class="swiper-slide" ref="slide2" id="slide2">
                <div class="img-container" :class="{'imageNone' : fixed}">
                  <img src="../../../assets/images/home/img-feature-thought.svg" alt="Add Your Thoughts">
                </div>
                <div class="text-container" :class="{'textBlock' : activeNumber == 1}">
                  <h3>{{ langs.landing.out.thought.title }}</h3>
                  <p>{{ langs.landing.out.thought.content }}</p>
                </div>
              </div>
              <div class="swiper-slide" ref="slide3" id="slide3">
                <div class="img-container" :class="{'imageNone' : fixed}">
                  <img src="../../../assets/images/home/img-feature-tag.svg" alt="Tag Your Search History">
                </div>
                <div class="text-container" :class="{'textBlock' : activeNumber == 2}">
                  <h3>{{ langs.landing.out.tag.title }}</h3>
                  <p>{{ langs.landing.out.tag.content }}</p>
                </div>
              </div>
              <div class="swiper-slide" ref="slide4" id="slide4">
                <div class="img-container" :class="{'imageNone' : fixed,'img-container-block': beforeFour}">
                  <img src="../../../assets/images/home/img-feature-summarize.svg" alt="Summarize Your Study Material">
                </div>
                <div class="text-container" :class="{'textBlock' : activeNumber == 3,'text-container-block' : beforeFour}">
                  <h3>{{ langs.landing.out.summarize.title }}</h3>
                  <p>{{ langs.landing.out.summarize.content }}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- Add Pagination -->
          <div class="swiper-pagination swiper-pagination1" :class="[scrollUp ? 'slide-up' : 'slide-down']">
            <span class="swiper-pagination-bullet" :class="{'swiper-pagination-bullet-active': activeNumber == 0}" @click="change(0)"></span>
            <span class="swiper-pagination-bullet" :class="{'swiper-pagination-bullet-active': activeNumber == 1}" @click="change(1)"></span>
            <span class="swiper-pagination-bullet" :class="{'swiper-pagination-bullet-active': activeNumber == 2}" @click="change(2)"></span>
            <span class="swiper-pagination-bullet" :class="{'swiper-pagination-bullet-active': activeNumber == 3}" @click="change(3)"></span>
          </div>
          <div class="swiper11" v-show="!ifSwiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="img-container">
                  <img src="../../../assets/images/home/img-feature-capture.svg" alt="Capture Your Key Findings">
                </div>
                <div class="text-container">
                  <h3>{{ langs.landing.out.capture.title }}</h3>
                  <p>{{ langs.landing.out.capture.content }}</p>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="img-container">
                  <img src="../../../assets/images/home/img-feature-thought.svg" alt="Add Your Thoughts">
                </div>
                <div class="text-container">
                  <h3>{{ langs.landing.out.thought.title }}</h3>
                  <p>{{ langs.landing.out.thought.content }}</p>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="img-container">
                  <img src="../../../assets/images/home/img-feature-tag.svg" alt="Tag Your Search History">
                </div>
                <div class="text-container">
                  <h3>{{ langs.landing.out.tag.title }}</h3>
                  <p>{{ langs.landing.out.tag.content }}</p>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="img-container last-img-container">
                  <img src="../../../assets/images/home/img-feature-summarize.svg" alt="Summarize Your Study Material">
                </div>
                <div class="text-container">
                  <h3>{{ langs.landing.out.summarize.title }}</h3>
                  <p>{{ langs.landing.out.summarize.content }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="help-wrapper">
        <h3>{{ langs.landing.help.title }}</h3>
        <div class="help-container">
          <div class="help-content">
            <div class="help-item" :class="{ active: activeHelp === 1 }" @click="(activeHelp = 1)">
              <div class="item-header">
                <img class="icon" src="../../../assets/images/home/icon-learn.svg" alt="icon-annotation" />
                <h4>{{ langs.landing.help.learnLanguage.title }}<img class="arrow" src="../../../assets/images/home/ic-btn-down.svg" alt="icon"></h4>
              </div>
              <div class="item-footer">
                <div class="description">{{ langs.landing.help.learnLanguage.description }}</div>
                <a @click="openVerified()">{{ langs.landing.help.learnLanguage.cta }}<img src="../../../assets/images/home/icon-arrow.svg" alt="icon"></a>
              </div>
            </div>
            <div class="help-item" :class="{ active: activeHelp === 2 }" @click="activeHelp = 2">
              <div class="item-header">
                <img class="icon" src="../../../assets/images/home/icon-interest.svg" alt="icon-interest">
                <h4>{{ langs.landing.help.startInterset.title }}<img class="arrow" src="../../../assets/images/home/ic-btn-down.svg" alt="icon"></h4>
              </div>
              <div class="item-footer">
                <div class="description">{{ langs.landing.help.startInterset.description }}</div>
              </div>
            </div>
            <div class="help-item" :class="{ active: activeHelp === 3 }" @click="activeHelp = 3">
              <div class="item-header">
                <img class="icon" src="../../../assets/images/home/icon-hobby.svg" alt="icon-reading">
                <h4>{{ langs.landing.help.developHobby.title }}<img class="arrow" src="../../../assets/images/home/ic-btn-down.svg" alt="icon"></h4>
              </div>
              <div class="item-footer">
                <div class="description">{{ langs.landing.help.developHobby.description }}</div>
                <a target="_blank" href="https://apps.apple.com/app/id936601959?pt=264933&ct=Markup_Website_DownloadApp&mt=8">{{ langs.landing.help.developHobby.cta }}<img src="../../../assets/images/home/icon-arrow.svg" alt="icon"></a>
              </div>
            </div>
            <div class="help-item" :class="{ active: activeHelp === 4 }" @click="activeHelp = 4">
              <div class="item-header">
                <img class="icon" src="../../../assets/images/home/icon-online.svg" alt="icon-learning">
                <h4>{{ langs.landing.help.makeOnline.title }}<img class="arrow" src="../../../assets/images/home/ic-btn-down.svg" alt="icon"></h4>
              </div>
              <div class="item-footer">
                <div class="description">{{ langs.landing.help.makeOnline.description }}</div>
              </div>
            </div>
          </div>
          <div class="help-image">
            <img v-show="activeHelp === 1" src="../../../assets/images/home/img-learn-language.png" srcset="../../../assets/images/home/img-learn-language@2x.png 2x, ../../../assets/images/home/img-learn-language@3x.png 3x" alt="Learn A New Language">
            <img v-show="activeHelp === 2" src="../../../assets/images/home/img-start-interset.png" srcset="../../../assets/images/home/img-start-interset@2x.png 2x, ../../../assets/images/home/img-start-interset@3x.png 3x" alt="Start A New Interest">
            <img v-show="activeHelp === 3" src="../../../assets/images/home/img-develop-hobby.png" srcset="../../../assets/images/home/img-develop-hobby@2x.png 2x, ../../../assets/images/home/img-develop-hobby@3x.png 3x" alt="Develop A Reading Hobby">
            <img v-show="activeHelp === 4" src="../../../assets/images/home/img-make-online.png" srcset="../../../assets/images/home/img-make-online@2x.png 2x, ../../../assets/images/home/img-make-online@3x.png 3x" alt="Make Online Learning Fun and Engaging">
          </div>
        </div>
      </div>
      <div class="devices-container">
        <h2>{{ langs.landing.devices.title }}</h2>
        <div class="devices">
          <div class="device ios">
            <img class="thumnail" src="../../../assets/images/home/img-mk-device-ios.svg" alt="device-ios">
            <div class="device-name">{{ langs.landing.devices.app }}</div>
            <a @click="app" href="https://apps.apple.com/app/id936601959?pt=264933&ct=Markup_Website_DownloadApp&mt=8" target="_blank" id="Btn_iOS_content">
              <img class="ic" src="../../../assets/images/home/ic-download-ios.svg" alt="apple-icon"><span>{{ langs.landing.devices.appLink }}</span>
            </a>
          </div>
          <div class="device web">
            <img class="thumnail" src="../../../assets/images/home/img-mk-device-web.svg" alt="device-web">
            <div class="device-name">{{ langs.landing.devices.web }}</div>
            <a :href="langs.landing.out.explore" id="Btn_MyMarkup_content"><img class="ic" src="../../../assets/images/app_smart_bar/ic_download_web.svg" alt="web-icon"><span>{{ langs.landing.devices.webLink }}</span></a>
          </div>
          <div class="device chrome">
            <img class="thumnail" src="../../../assets/images/home/img-mk-device-chrome.svg" alt="device-extension">
            <div class="device-name">{{ langs.landing.devices.extension }}</div>
            <a id="Btn_Extension_content" target="_blank" :href="browser ? 'https://chrome.google.com/webstore/detail/markup-web-markups-highli/cbjngbfjehiofmihfpodinolkcokdojp' : 'https://microsoftedge.microsoft.com/addons/detail/markup-my-web-markups-/bkaddcdohjngdgldhhdmghjdohcnncjj'"><img class="ic" src="../../../assets/images/home/ic-download-web.svg" alt="extension-icon"><span>{{ langs.landing.devices.extensionLink }}</span></a>
          </div>
        </div>
      </div>
      <div class="icones" :class="{'iconsFixed' : fixed}">
        <h2 class="icon_title">{{ langs.landing.media }}</h2>
        <div class="icons">
          <a class="icon icon1" href="https://mashable.com/shopping/jan-19-markup-ios-pro-lite-lifetime-subscription/" target="_blank"><img src="../../../assets/images/home/ic-mk-nomination-mashable.svg" alt="media-img"></a>
          <a class="icon icon2" href="https://www.pcmag.com/deals/this-50-annotation-tool-makes-collaboration-much-easier" target="_blank"><img src="../../../assets/images/home/ic-mk-nomination-pcmagazine.svg" alt="media-img"></a>
          <a class="icon icon3" href="https://www.cultofmac.com/733602/markup-ios-pro-lite-lifetime-subscription/" target="_blank"><img src="../../../assets/images/home/ic-mk-nomination-cult.svg" alt="media-img"></a>
          <a class="icon icon4" href="https://boingboing.net/2021/01/22/markup-pro-makes-it-simple-to-find-and-share-what-you-want-off-the-web.html" target="_blank"><img src="../../../assets/images/home/ic-mk-nomination-boing.svg" alt="media-img"></a>
          <a class="icon icon5" href="https://alldigitalschool.com/resource-listing/my-markups" target="_blank"><img src="../../../assets/images/home/ic-mk-nomination-ads.png" alt="media-img"></a>
          <span class="icon icon6"><img src="../../../assets/images/home/ic-mk-nomination-tabletpc.svg" alt="media-img"></span>
        </div>
      </div>
      <div class="comments">
        <div class="swiper-container swiper-no-swiping swiper2" v-show="ifSwiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide swiper-slide1">
              <div class="card">
                <div>
                  <img class="down" src="../../../assets/images/app_smart_bar/ic-wo-section-3-quotedown.svg" alt="">
                  <p>{{ langs.landing.comments.yale.content }}</p>
                </div>
                <div>
                  <h4>{{ langs.landing.comments.yale.title }}</h4>
              </div>
            </div>
            </div>
            <div class="swiper-slide" :class="{'swiper-slide2': leftRight,'swiper-slide22': !leftRight}">
              <div class="card">
                <div>
                  <img class="down" src="../../../assets/images/app_smart_bar/ic-wo-section-3-quotedown.svg" alt="">
                  <p>{{ langs.landing.comments.raymond.content }}</p>
                </div>
                <div>
                  <h4>{{ langs.landing.comments.raymond.title }}</h4>
                  <span>{{ langs.landing.comments.raymond.subtitle }}</span>
                </div>
              </div>
            </div>
            <div class="swiper-slide swiper-slide3">
              <div class="card">
                <div>
                  <img class="down" src="../../../assets/images/app_smart_bar/ic-wo-section-3-quotedown.svg" alt="">
                  <p>{{ langs.landing.comments.krista.content }}</p>
                </div>
                <div>
                  <h4>{{ langs.landing.comments.krista.title }}</h4>
                  <span>{{ langs.landing.comments.krista.subtitle }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-button-prev" slot="button-prev" @click="swiperLeft()"></div>
          <div class="swiper-button-next" slot="button-next" @click="swiperRight()"></div>
        </div>
        <div class="swiper22" v-show="!ifSwiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="card">
                <div>
                  <img class="down" src="../../../assets/images/app_smart_bar/ic-wo-section-3-quotedown.svg" alt="">
                  <p>{{ langs.landing.comments.yale.content }}</p>
                </div>
                <div>
                  <h4>{{ langs.landing.comments.yale.title }}</h4>
              </div>
            </div>
            </div>
            <div class="swiper-slide">
              <div class="card">
                <div>
                  <img class="down" src="../../../assets/images/app_smart_bar/ic-wo-section-3-quotedown.svg" alt="">
                  <p>{{ langs.landing.comments.raymond.content }}</p>
                </div>
                <div>
                  <h4>{{ langs.landing.comments.raymond.title }}</h4>
                  <span>{{ langs.landing.comments.raymond.subtitle }}</span>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="card">
                <div>
                  <img class="down" src="../../../assets/images/app_smart_bar/ic-wo-section-3-quotedown.svg" alt="">
                  <p>{{ langs.landing.comments.krista.content }}</p>
                </div>
                <div>
                  <h4>{{ langs.landing.comments.krista.title }}</h4>
                  <span>{{ langs.landing.comments.krista.subtitle }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="matters">
        <p>{{ langs.landing.matters }}</p>
        <a :href="utmSignhref" id="Btn_SignUp_Bottom">{{ langs.landing.banner.sign }}</a>
      </div>
      <Edu :outside="outside" :langs="langs" :dialogEdu.sync="dialogEdu" :drawerEdu.sync="drawerEdu"/>
      <Footer :server_env="server_env" :currUser.sync="currUser" :lang.sync="lang" :langs="langs" @changeCurrUser="changeCurrUser" />
      <div class="shade" v-show="show">
        <div class="verification">
          <p>Thanks for signing up! Please check your email inbox and click the verification link in the email.</p>
          <div>
            <button type="button" @click="close()">
              <span>OK</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import axios from 'axios';
import Header from '../common/landingHeader';
import Footer from '../common/landingFooter';
import Swiper from "swiper";
import Edu from '../common/Education';
import "../../packs/swiper.min.css";

export default {
  name: 'landing',
  components: {
    Header,
    Footer,
    Edu
  },
  props: ['outside'],
  data() {
    return {
      langues: ['en', 'zh-tw', 'zh-cn', 'ja', 'es', 'ko'],
      num: 0,
      scrollUp: false,
      currUser: this.outside.currUser,
      server_env: this.outside.server_env,
      screenWidth: '',
      screenHeight: '',
      shadow: false,
      barShadow: false,
      dialogEdu: false,
      drawerEdu: false,
      utmSignhref: '/auth/kdan_mobile?default_form=sign_up_form',
      browser: true,
      show: false,
      leftRight: true,
      activeNumber: 0,
      fixed: false,
      beforeFour: false,
      backgroundImage: require('../../../assets/images/home/img-feature-capture.svg'),
      animationSpeed: 1,
      ifSwiper: true,
      activeHelp: 1,
      swiperOption: {
        direction: 'vertical',
        slidesPerView: 1,
        spaceBetween: 30,
        mousewheel: true,
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      },
      eventBar_color: '',
      eventBar_bg_color: '',
      eventBar_content: '',
      eventBar_link: '',
      lang: 'en',
      topOrEnd: true 
    }
  },
  watch: {
    screenWidth(val) {
      this.screenWidth = val;
      if(this.screenWidth > 1024){
        this.ifSwiper = true;
      }else if(this.screenWidth <= 1024){
        this.ifSwiper = false;
      }
      if (this.screenWidth < 930) {
        this.dialogEdu = false;
      } else {
        this.drawerEdu = false;
      }
    }
  },
  computed:{
    langs: function(val){
      return JSON.parse(JSON.stringify(this.outside.langs))
    }
  },
  beforeMount() {
    if (this.currUser) {
      if (this.langues.includes(this.currUser.lang.toLowerCase())) {
        this.lang = this.currUser.lang.toLowerCase();
      } else {
        this.lang = 'en';
      }
    } else {
      // const lang = Cookies.get('lang');
      // if (lang) {
      //   this.lang = lang;
      // } else {
      //   this.lang = 'en';
      // }
      if (window.location.pathname.indexOf('/es') != -1 || window.location.pathname.indexOf('/zh-tw') != -1 || window.location.pathname.indexOf('/zh-cn') != -1 || window.location.pathname.indexOf('/ja') != -1 || window.location.pathname.indexOf('/ko') != -1) {
        this.lang = window.location.pathname.substr(1);
      } else {
        this.lang = 'en';
      }
      Cookies.set('lang', this.lang, {expires: 7});
    }
    if (this.lang == 'en') {
      if (window.location.pathname.indexOf('/es') != -1 || window.location.pathname.indexOf('/zh-tw') != -1 || window.location.pathname.indexOf('/zh-cn') != -1 || window.location.pathname.indexOf('/ja') != -1 || window.location.pathname.indexOf('/ko') != -1) {
        window.location.pathname = '/'
      }
    } else {
      if (window.location.pathname.indexOf('/' + this.lang) == -1) {
        window.location.pathname = '/' + this.lang;
      }
    }
    if(this.lang == 'ja' || this.lang == 'ko'){
      this.topOrEnd = false;
    } else {
      this.topOrEnd = true;
    }
  },
  mounted () {
    this.eventBar();
    this.syncPurchaseStat();
    let that = this;
    let explorer = window.navigator.userAgent;
    //判断是否为edge浏览器
    if (explorer.indexOf("Edg") >= 0) {
      this.browser = false;
    } else {
      this.browser = true;
    }
    this.num = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    //utm携带到会员系统
    if (window.location.search.indexOf('utm_source') != '-1' || window.location.search.indexOf('utm_medium') != '-1' || window.location.search.indexOf('utm_campaign') != '-1') {
      this.utmSignhref = '/auth/kdan_mobile?default_form=sign_up_form&' + window.location.search.substr(1) + '&lang=' + this.lang;
      document.cookie = "utmutm=" + window.location.search + ";"
    } else {
      this.utmSignhref = '/auth/kdan_mobile?default_form=sign_up_form&lang=' + this.lang;
    }
    //utm携带到会员系统
    var arrStr = document.cookie.split("; ");
    for (var i = 0; i < arrStr.length; i++) {
      var temp = arrStr[i].split("=");
      if (temp[0] == 'utmutm') {
        this.utmSignhref = '/auth/kdan_mobile?default_form=sign_up_form&' + arrStr[i].substr(8) + '&lang=' + this.lang;
      }
    }
    document.addEventListener('scroll', this.handleScroll);
    this.screenWidth = window.innerWidth;
    window.onresize= () => {
      that.screenWidth = window.innerWidth;
    }
    if (window.innerWidth <= 1024) {
      that.ifSwiper = false;
    } else {
      that.ifSwiper = true;
    }
    var swiper2 = new Swiper(".swiper2", {
      slidesPerView: 'auto',
      spaceBetween: 0,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    });
  },
  methods: {
    syncPurchaseStat () {
      const refresh = Cookies.get('refreshState');
      if (this.currUser && !refresh) {
        axios.get("/api/members/me?refresh=true")
        .then((res)=>{
          this.currUser = res.data.results;
          Cookies.set('refreshState', true);
        })
        .catch(function(err){
          console.log(err);
          Cookies.set('refreshState', true);
        });
      }
    },
    async eventBar() {
      let that = this;
      await axios.get("https://cms.kdanmobile.com/items/kdan_event_bar?filter[product_page][_eq]=markups&deep[translations][_filter][status][_eq]=published&deep[translations][_filter][languages_code][_eq]=" + that.lang + "&fields=*,translations.*",{transformRequest: [
          function(data,headers){
            if(headers.Accept){
              delete headers.Accept;
            }
            if(headers.AppID){
              delete headers.AppID;
            }
          }
          ]})
      .then((res)=>{
        if(res.data.data[0]){
          // let search = window.location.search;
          // if (search.indexOf('utm_source=PR') == -1 || search.indexOf('utm_campaign=PR_UnJaded_Jade_202202') == -1 || search.indexOf('utm_medium=UnJaded_Jade') == -1) {
          //   that.eventBar_link = '';
          //   return
          // }
          that.eventBar_bg_color = res.data.data[0].bg_color;
          that.eventBar_color = res.data.data[0].color;
          that.eventBar_content = res.data.data[0].translations[0].content;
          that.eventBar_link = res.data.data[0].translations[0].link;
        }
      })
      .catch(function(err){
        console.log(err);
      });
    },
    openVerified () {
      this.$children[2].eduEmail = null;
      this.$children[2].eduEmail_error = false;
      this.$children[2].eduFormat_error = false;
      this.$children[2].eduEmail_onblur = false;
      if (window.innerWidth < 930) {
        this.drawerEdu = true;
      } else {
        this.dialogEdu = true;
      }
    },
    handleScroll () {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      this.shadow = scrollTop > 0;
      this.barShadow = scrollTop > 64;
      var outTop = this.$refs.out.getBoundingClientRect().top;
      var slideTop1 = this.$refs.slide1.getBoundingClientRect().top;
      var slideTop2 = this.$refs.slide2.getBoundingClientRect().top;
      var slideTop3 = this.$refs.slide3.getBoundingClientRect().top;
      var slideTop4 = this.$refs.slide4.getBoundingClientRect().top;
      const innerHeight = window.innerHeight * 3
      if (window.innerWidth <= 1024) {
        this.fixed = false;
      } else if (outTop <= 0 && outTop > -innerHeight) {
        this.fixed = true;
        this.beforeFour = false;
      } else {
        this.fixed = false;
        this.beforeFour = true;
      }
      if (outTop > 0) {
        this.scrollUp = true
      } else if (outTop < innerHeight) {
        this.scrollUp = false
      }
      this.num = scrollTop

      if (slideTop4 <= 350) {
        this.activeNumber = 3;
        this.backgroundImage = require('../../../assets/images/home/img-feature-summarize.svg');
      } else if (slideTop3 <= 400) {
        this.activeNumber = 2;
        this.backgroundImage = require('../../../assets/images/home/img-feature-tag.svg');
      } else if (slideTop2 <= 400) {
        this.activeNumber = 1;
        this.backgroundImage = require('../../../assets/images/home/img-feature-thought.svg');
      } else if (slideTop1 <= 300) {
        this.activeNumber = 0;
        this.backgroundImage = require('../../../assets/images/home/img-feature-capture.svg');
      }
    },
    open () {
      this.show = true;
    },
    close () {
      this.show = false;
    },
    swiperLeft () {
      this.leftRight = true;
    },
    swiperRight () {
      this.leftRight = false;
    },
    change (i) {
      if(i == 0) {
        document.getElementById('slide1').scrollIntoView({
          behavior: "smooth",
          block:    "center"
        });
      }else if(i == 1){
        document.getElementById('slide2').scrollIntoView({
          behavior: "smooth",
          block:    "center"
        });
      }else if(i == 2){
        document.getElementById('slide3').scrollIntoView({
          behavior: "smooth",
          block:    "center"
        });
      }else if(i == 3){
        document.getElementById('slide4').scrollIntoView({
          behavior: "smooth",
          block:    "center"
        });
      }
    },
    app (e) {
      var lang = navigator.language || navigator.userLanguage;
      if (lang == 'zh-CN') {
        e.preventDefault();
        window.open('https://apps.apple.com/cn/app/apple-store/id936601959?mt=8');
      }
    },
    changeCurrUser (curr,lang) {
      this.currUser = curr;
      this.lang = lang;
      if (window.location.pathname.indexOf('/') != -1 || window.location.pathname.indexOf('/es') != -1 || window.location.pathname.indexOf('/zh-tw') != -1 || window.location.pathname.indexOf('/zh-cn') != -1 || window.location.pathname.indexOf('/ja') != -1 || window.location.pathname.indexOf('/ko') != -1) {
        if (lang == 'en') {
          window.location.pathname = '/'
        } else {
          window.location.pathname = '/' + lang;
        }
      }
    }
  },
  destroyed () {
    //离开该页面需要移除这个监听的事件
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>


<style lang="scss" scoped>
@import '../../vue_styles/global.scss';
.dashboard-container {
  #eventBar {
    display: none;
  }
  .cms{
    display: block !important;
    width: 100%;
    padding: 22px;
    text-align: center;
    a {
      font-size: 16px;
    }
    a:hover {
      text-decoration: underline;
    }
  }
  .barHeader {
    position: static;
  }
  .eventHeader {
    position: fixed;
  }
  .app-container {
    margin-top: 64px;
    position: relative;
    font-family: Clear Sans,sans-serif;
    .banner-container {
      background-position: center top;
      background-repeat: no-repeat;
    }
    .banner {
      display: flex;
      justify-content: center;
      color: #000;
      .text {
        p {
          line-height: 1.5;
        }
        #Btn_SignUp_Banner {
          display: block;
          width: 252px;
          padding: 10px 24px;
          font-size: 22px;
          font-weight: 500;
          line-height: 28px;
          color: #ffffff;
          text-align: center;
          border-radius: 4px;
          background-color: #ff5f4b;
        }
        #Btn_SignUp_Banner:hover {
          opacity: 0.8;
        }
        #Btn_SignUp_Banner:active {
          background-color: #e65644;
        }
        .Btn_download {
          align-items: center;
          color: #000;
          font-size: 20px;
          font-weight: 500;
          line-height: 1.5;
          img {
            width: 20px;
            margin-left: 8px;
          }
        }
        .Btn_download:hover {
          opacity: 0.8;
        }
      }
    }
    .out {
      transition: all 1.2s ease 0s;
      padding-top: 1px;
      .slide {
        position: relative;
        .imgNone {
          background-size: 100% auto;
          background-repeat: no-repeat;
          img {
            width: 100%;
          }
        }
        .swiper1, .swiper11 {
          width: 100%;
          z-index: 99;
          .swiper-slide {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .text-container {
              color: #000;
              h3 {
                font-size: 28px;
                line-height: 1.5;
              }
              p {
                margin-top: 16px;
                font-size: 20px;
                line-height: 1.5;
              }
            }
            .img-container {
              width: 100%;
              text-align: center;
              img {
                width: 100%;
              }
            }
          }
        }
        .swiper11 {
          position: relative;
          height: auto;
          .swiper-wrapper {
            flex-direction: column;
            max-width: 688px;
            margin: 0 auto;
            .swiper-slide {
              display: flex;
              flex-direction: column-reverse;
              margin: 0 auto;
              width: 100%;
              .text-container {
                width: 100%;
              }
              .last-img-container {
                margin-bottom: 60px;
              }
            }
          }
        }
        .swiper-pagination {
          .swiper-pagination-bullet {
            width: 8px;
            height: 42px;
            background-color: transparent;
            border-radius: initial;
            cursor: pointer;
          }
          .swiper-pagination-bullet-active {
            background-color: #ff5f4b;
            border-radius: 20px;
          }
        }
      }
    }
    .icones {
      background-color: #f4f7fa;
      padding-top: 45px;
      .icon_title {
        font-size: 18px;
        line-height: 2;
        text-align: center;
        color: #000;
        margin-bottom: 40px;
      }
      .icons {
        display: flex;
        justify-content: center;
        align-items: center;
        .icon {
          width: 180px;
          cursor: pointer;
          img {
            width: 100%;
          }
        }
      }
    }
    .iconsFixed {
      margin-top: 500px;
    }
    .comments {
      background-color: #f4f7fa;
      .swiper2, .swiper22 {
        position: relative;
        width: 1200px;
        margin: 0 auto;
        .swiper-slide{
          width: 50%;
          text-align: center;
          .card {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 480px;
            height: 354px;
            margin: 120px auto;
            padding: 40px;
            border-radius: 16px;
            box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.06);
            text-align: left;
            color: #000;
            background-color: #fff;
            .down {
              width: 40px;
            }
            p {
              margin-top: 40px;
              font-size: 14px;
              line-height: 1.5;
            }
            h4 {
              font-size: 18px;
              line-height: 1.78;
            }
            span {
              width: 100%;
              font-size: 14px;
              line-height: 1.43;
              letter-spacing: 0.39px;
              color: rgba(0, 0, 0, 0.6);
            }
          }
        }
        .swiper-slide1 {
          .card {
            margin-right: 20px;
          }
        }
        .swiper-slide2 {
          .card {
            margin-left: 20px;
          }
        }
        .swiper-slide22 {
          .card {
            margin-right: 20px;
          }
        }
        .swiper-slide3 {
          .card {
            margin-left: 20px;
          }
        }
        /deep/ .swiper-button-prev {
          position: absolute;
          left: 0px;
          width: 60px;
          height: 60px;
          background-image: url('../../../assets/images/home/icon-carousel.svg');
          background-size: 60px 60px;
          background-color: #000;
          border-radius: 50%;
          &.swiper-button-disabled {
            opacity: 0.2;
          }
        }
        /deep/ .swiper-button-prev:focus {
          outline: none;
        }
        /deep/ .swiper-button-next {
          right: 0px;
          width: 60px;
          height: 60px;
          background-image: url('../../../assets/images/home/icon-carousel.svg');
          background-size: 60px 60px;
          background-color: #000;
          border-radius: 50%;
          transform: rotate(180deg);
          &.swiper-button-disabled {
            opacity: 0.2;
          }
        }
        /deep/ .swiper-button-next:focus {
          outline: none;
        }
      }
      .swiper22 {
        width: 100%;
        height: auto;
        padding: 0 24px;
        .swiper-wrapper {
          display: flex;
          flex-wrap: wrap;
          padding: 120px 0;
          .swiper-slide {
            margin: 0 auto;
            width: 100%;
            max-width: 480px;
          }
        }
      }
    }
    .help-wrapper {
      h3 {
        text-align: center;
        color: #000;
        line-height: 1.5;
      }
      .help-container {
        .help-item {
          border-radius: 8px;
          background-color: #fff;
          &:not(:first-child) {
            margin-top: 26px;
          }
          .item-header {
            display: flex;
            cursor: pointer;
          }
          .icon {
            flex: none;
          }
          h4 {
            display: flex;
            align-items: center;
            flex: 1;
            justify-content: space-between;
            line-height: 1.5;
            color: #000;
          }
          .arrow {
            flex: none;
            margin-left: 20px;
          }
          .description {
            display: none;
            font-size: 16px;
            line-height: 1.5;
            color: #232323;
          }
          a {
            display: none;
            color: #ff5f4b;
            font-size: 16px;
            font-weight: bold;
            line-height: 22px;
            img {
              margin-left: 9px;
            }
          }
          &.active {
            box-shadow: 2px 8px 24px 0 rgba(175, 175, 175, 0.3);
            &:not(:first-child) {
              margin-top: 16px;
            }
            .item-header {
              cursor: auto;
            }
            .description{
              display: block;
            }
            a {
              display: inline-block;
            }
            .arrow {
              display: none;
            }
          }
        }
      }
    }
    .devices-container {
      h2 {
        color: #000000;
        text-align: center;
      }
      .devices {
        display: flex;
        .device {
          display: flex;
          flex-direction: column;
          align-items: center;
          max-width: 300px;
          width: 100%;
          .thumnail {
            width: 200px;
          }
          .device-name {
            text-align: center;
            color: #000;
            margin-bottom: 16px;
            font-weight: 500;
          }
          a {
            display: flex;
            align-items: center;
            border: solid 1.5px #ff5f4b;
            white-space: nowrap;
          }
          a:hover {
            background-color: #fff1ef;
          }
          a:active {
            background-color: #fff;
          }
          span {
            color: #ff5f4b;
            font-weight: 500;
          }
        }
      }
    }
    .matters {
      text-align: center;
      background: linear-gradient(to bottom, #ff5f4b 0%, #ff3920 100%);
      p {
        font-weight: bold;
        text-align: center;
        color: #ffffff;
      }
      a {
        display: inline-block;
        font-weight: 500;
        color: #ffffff;
        border-radius: 4px;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
        background-color: #071d4c;
      }
    }
    .shade {
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 50px;
      display: flex;
      flex-direction: row;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      z-index: 10000;
      .verification {
        width: 50%;
        padding: 30px;
        display: inline-flex;
        flex-direction: column;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        background-color: white;
        border-radius: 8px;
        div {
          margin-top: 16px;
          button {
            border-radius: 4px;
            outline: 0px;
            border: 0px;
            font-weight: 500;
            line-height: 1.5;
            transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            display: inline-block;
            width: auto;
            height: fit-content;
            font-size: 1rem;
            padding: 8px 18px;
            color: rgb(255, 255, 255);
            background-color: rgb(0, 0, 0);
          }
          button:hover {
            color: rgb(255, 255, 255);
            background-color: rgb(65, 65, 65);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .dashboard-container {
    .app-container {
      .icones {
        .icons {
          margin: 0 auto;
          flex-wrap: wrap;
        }
      }
    }
  }
}
@media screen and (max-width: 1023.9px) {
  .dashboard-container {
    .app-container {
      .banner .text {
        max-width: 688px;
        margin: 0 auto;
        .Btn_download {
          display: inline-flex;
          color: #071d4c;
          img {
            margin-right: 8px;
          }
        }
      }
      .help-wrapper {
        .help-container {
          .help-image {
            display: none;
          }
        }
      }
      .comments {
        .swiper22 {
          .swiper-slide {
            .card {
              margin: 0;
            }
            & + .swiper-slide {
              .card {
                margin-top: 40px;
              }
            }
          }
        }
      }
      .out {
        .slide {
          .swiper11 .swiper-wrapper .swiper-slide .text-container {
            text-align: center;
          }
          .swiper-pagination {
            display: none;
          }
        }
      }
      .devices-container {
        .devices {
          flex-direction: column;
          align-items: center;
          margin-top: 48px;
        }
      }
    }
  }
}
@media screen and (min-width: 673px) and (max-width: 768px) {
  .dashboard-container {
    .app-container {
      .icones {
        .icons {
          .icon3 {
            margin-top: 0;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .container {
    max-width: 768px;
  }
  .dashboard-container {
    .app-container {
      .banner-container {
        background-image: url('../../../assets/images/home/hero_bg@2x.png');
        background-size: 100% auto;
        background-position: center bottom;
      }
      .banner {
        padding: 63px 0;
        flex-direction: column;
        .text {
          margin-bottom: 40px;
          text-align: center;
        }
        h1 {
          margin-bottom: 20px;
          font-size: 48px;
          line-height: 1.5;
        }
        p {
          margin-bottom: 28px;
          font-size: 24px;
        }
        #Btn_SignUp_Banner {
          margin: 0 auto;
        }
        .Btn_download {
          margin-top: 12px;
        }
      }
      .out {
        .slide {
          .swiper11 {
            .swiper-wrapper {
              .swiper-slide {
                padding: 40px 0;
                .text-container {
                  p {
                    margin-top: 12px;
                  }
                }
              }
            }
          }
        }
      }
      .icones {
        .icons {
          width: 392px;
          .icon:nth-child(2n) {
            margin-left: 32px;
          }
        }
      }
      .help-wrapper {
        margin: 0 auto;
        max-width: 768px;
        padding: 60px 40px;
      }
      .devices-container {
        margin-top: 120px;
        margin-bottom: 110px;
        h2 {
          max-width: 562px;
          margin: 0 auto;
        }
        .devices {
          .device + .device {
            margin-top: 40px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279.9px) {
  .container {
    max-width: 912px;
  }
  .dashboard-container {
    .app-container {
      .out {
        margin-top: 100px;
        .slide .swiper1 .swiper-wrapper .swiper-slide {
          max-width: 1024px;
          width: 100%;
          justify-content: flex-start;
        }
      }
      .comments {
        .swiper2, .swiper22 {
          width: 928px;
          .swiper-slide{
            .card {
              width: 362px;
              height: 376px;
              p {
                margin-top: 25px;
              }
            }
          }
        }
      }
      .icones {
        .icons {
          width: 540px;
          .icon:nth-child(n+4) {
            margin-top: 16px;
          }
        }
      }
      .devices-container {
        .devices {
          .device + .device {
            margin-left: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767.9px) {
  .dashboard-container .app-container {
    .banner-container {
      margin-bottom: 24px;
      background-image: url('../../../assets/images/home/hero_bg.png');
      background-size: 100% auto;
      background-position: center bottom;
    }
    .banner {
      padding: 40px 0;
      flex-direction: column;
      text-align: center;
      .text {
        margin: 0 auto 40px;
        padding: 0 16px;
        h1 {
          margin-bottom: 16px;
          font-size: 28px;
          line-height: 1.5;
        }
        p {
          font-size: 16px;
          line-height: 1.5;
          margin-bottom: 24px;
        }
        #Btn_SignUp_Banner {
          margin: auto;
        }
        .Btn_download {
          display: inline-flex;
          margin-top: 8px;
          font-size: 16px;
          color: #071d4c;
          img {
            margin-right: 8px;
          }
        }
      }
      .hero-image {
        width: 100%;
        height: auto;
      }
    }
    .help-wrapper {
      padding: 40px 16px;
      h3 {
        margin-bottom: 40px;
        font-size: 28px;
      }
      .help-container {
        .help-item {
          padding: 20px 20px 20px 32px;
          &:not(:first-child) {
            padding-top: 10px;
            &.active {
              padding-top: 20px;
            }
          }
          .item-header {
            align-items: center;
          }
          h4 {
            font-size: 22px;
          }
          .item-footer {
            padding-left: 44px;
          }
          .description {
            margin-top: 16px;
          }
          a {
            margin-top: 14px;
          }
          &.active {
            padding: 20px 20px 40px 32px;
          }
          .icon {
            width: 24px;
            margin-right: 20px;
          }
        }
      }
    }
    .comments {
      .swiper22 {
        padding: 0 16px;
        .swiper-wrapper {
          padding: 80px 0;
          .swiper-slide {
            width: 100%;
            & + .swiper-slide .card {
              margin-top: 20px;
            }
            .card {
              width: 100%;
              max-width: 480px;
              min-height: 296px;
              padding: 20px;
              margin-left: auto;
              margin-right: auto;
              .down {
                width: 30px;
              }
              h4 {
                font-size: 16px;
                line-height: 2;
              }
              p {
                margin-top: 20px;
              }
            }
          }
        }
      }
    }
    .devices-container {
      margin-top: 60px;
      margin-bottom: 60px;
      padding: 0 16px;
      h2 {
        font-size: 28px;
        line-height: 38px;
      }
      .devices .device {
        padding: 16px 0 38px;
        .device-name {
          font-size: 22px;
          line-height: 30px;
        }
        a {
          height: 40px;
          padding: 8px 20px;
          border-radius: 4px;
        }
        .ic {
          margin-right: 12px;
        }
        span {
          font-size: 16px;
        }
      }
    }
    .out {
      .slide {
        .swiper11 {
          .swiper-wrapper {
            .swiper-slide {
              padding: 32px 0;
              & + .swiper-slide {
                margin-top: 32px;
              }
              .text-container {
                padding: 0 16px;
                text-align: center;
                h3 {
                  font-size: 22px;
                }
                p {
                  font-size: 16px;
                  margin-top: 12px;
                }
              }
              .img-container {
                width: 100%;
                img {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
    .matters {
      padding: 66px 8px 98px;
      p {
        margin-bottom: 32px;
        font-size: 28px;
        line-height: 42px;
      }
      a {
        padding: 8px 20px;
        font-size: 16px;
        line-height: 24px;
      }
    }
    .icones {
      .icon_title {
        margin-bottom: 68px;
        font-size: 14px;
      }
      .icons {
        max-width: 288px;
        .icon {
          width: 144px;
        }
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .dashboard-container .app-container {
    .help-wrapper {
      padding: 60px 0;
      h3 {
        margin-bottom: 60px;
        font-size: 36px;
      }
      .help-container {
        .help-item {
          padding: 24px 24px 40px 36px;
          &:not(:first-child) {
            padding-top: 14px;
            &.active {
              padding-top: 24px;
            }
          }
          .item-header {
            align-items: flex-start;
          }
          h4 {
            font-size: 28px;
          }
          .item-footer {
            padding-left: 64px;
          }
          .description {
            margin-top: 20px;
          }
          a {
            margin-top: 16px;
          }
          &.active {
            padding: 40px 24px 40px 36px;
          }
          .icon {
            width: 40px;
            margin-right: 24px;
          }
        }
      }
    }
    .devices-container {
      h2 {
        font-size: 36px;
        line-height: 49px;
      }
      .devices .device {
        padding: 16px 23px 22px;
        .device-name {
          font-size: 24px;
          line-height: 38px;
        }
        a {
          height: 48px;
          padding: 10px 24px;
          border-radius: 6px;
        }
        .ic {
          margin-right: 16px;
        }
        span {
          font-size: 22px;
        }
      }
    }
    .matters {
      padding: 90px 0;
      p {
        margin-bottom: 40px;
        font-size: 36px;
        line-height: 51px;
      }
      a {
        padding: 10px 24px;
        font-size: 22px;
        line-height: 28px;
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .dashboard-container {
    .app-container {
      overflow: hidden;
      .banner-container {
        background-image: url('../../../assets/images/home/hero_bg@3x.png');
        background-size: 1920px auto;
      }
      .banner {
        align-items: center;
        width: 100%;
        max-width: 944px;
        margin: 0 auto;
        padding: 96px 0;
        .text {
          width: 440px;
          margin-right: 29px;
          flex: none;
        }
        h1 {
          margin-bottom: 24px;
          font-size: 48px;
          line-height: 1.3;
        }
        p {
          margin-bottom: 32px;
          font-size: 24px;
        }
        .Btn_download {
          display: flex;
          width: 180px;
          margin-top: 12px;
        }
        img {
          width: 840px;
          margin-right: -365px;
        }
      }
      .out {
        .slide {
          .imgNone {
            display: none;
            width: 480px;
            height: 480px;
            transition: all 1.2s;
          }
          .swiper-container {
            .swiper-wrapper {
              .swiper-slide {
                .img-container {
                  width: 480px;
                }
                .text-container {
                  width: 420px;
                  margin-left: 40px;
                  margin-right: 40px;
                }
              }
              #slide2, #slide3 {
                  opacity: 0;
              }
              #slide4 {
                z-index: -999;
              }
            }
          }
          .imgFixed {
            display: block;
            position: fixed;
            top: 50%;
            transform: translateY(-50%);
            left: calc((100% - 1024px) / 2);
            background-image: url('../../../assets/images/home/img-feature-capture.svg');
          }
          .swiper1 {
            .swiper-wrapper {
              display: flex;
              flex-direction: column;
              align-items: center;
              .swiper-slide {
                height: 100vh;
                .imageNone {
                  opacity: 0;
                }
              }
            }
          }
          .swiper-pagination {
            display: flex;
            flex-direction: column;
            border-radius: 20px;
            background-color: #fff0f0;
          }
          .swiper-pagination1 {
            position: absolute;
            right: calc((100% - 1024px) / 2 + 36px);
          }
          .slide-up {
            top: calc(100vh / 2);
            transform: translateY(-50%);
          }
          .slide-down {
            bottom: calc(100vh / 2);
            transform: translateY(50%);
          }
        }
        .slideFixed {
          z-index: 99;
          h2 {
            position: fixed;
            top: 129px;
            left: 0;
            background-color: white;
            z-index: 999;
          }
          .swiper-container {
            .swiper-wrapper {
              transform: none;
              .swiper-slide {
                .text-container {
                  position: fixed;
                  top: 50%;
                  transform: translateY(-50%);
                  right: calc((100% - 1024px) / 2 + 44px);
                  opacity: 0;
                }
                .textBlock {
                  opacity: 1;
                  transition: opacity 1.2s;
                }
              }
              #slide2, #slide3 {
                opacity: 1;
              }
            }
          }
          .swiper-pagination1 {
            position: fixed;
            bottom: calc(100vh / 2);
            top: initial;
            transform: translateY(50%);
          }
        }
      }
      .help-wrapper {
        .help-container {
          display: flex;
          justify-content: center;
          .help-item {
            width: 520px;
            padding: 32px 40px;
            &:not(:first-child) {
              padding-top: 22px;
              &.active {
                padding-top: 32px;
              }
            }
            .description {
              margin-top: 16px;
            }
            &.active {
              padding: 40px 60px 40px 40px;
            }
          }
          .help-image {
            margin-left: 75PX;
            img {
              width: 364px;
            }
          }
        }
      }
      .devices-container {
        margin-top: 160px;
        margin-bottom: 160px;
        .devices {
          justify-content: center;
          margin-top: 64px;
          .device + .device {
            margin-left: 60px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1280px) {
  .container {
    max-width: 1152px;
  }
  .dashboard-container {
    .app-container {
      .banner {
        max-width: 1080px;
        img {
          margin-right: -262px;
        }
      }
      .help-wrapper {
        .help-container {
          .help-image {
            img {
              width: 520px;
            }
          }
        }
      }
      .out .slide {
        .imgFixed {
          left: calc((100% - 1152px) / 2);
          width: 600px;
          height: 600px;
        }
        .swiper-container .swiper-wrapper .swiper-slide {
          .img-container {
            width: 600px;
            img {
              width: 100%;
            }
          }
          .text-container {
            width: 432px;
            margin-left: 60px;
            margin-right: 60px;
            right: calc((100% - 1152px) / 2);
          }
        }
        .swiper-pagination1 {
          right: calc((100% - 1152px) / 2);
        }
      }
    }
  }
}
</style>
