<template>
  <div class="dashboard-container">
    <Header class="header" :isActive="false" :currUser.sync="currUser" :lang.sync="lang" :langs="langs" :screenWidth="screenWidth" :server_env="outside.server_env" @changeCurrUser="changeCurrUser" />
    <div class="navBox" ref="navBox" v-show="!skeleton">
      <div class="arrow arrow_left" v-show="showLeft"><img src="../../../assets/images/app_smart_bar/ic-category-arrow-left.png" @click="slide_left()"></div>
      <ul class="nav" :style="{left: '-' + navLeft + 'px'}" ref="navChild">
        <li v-for="(item, index) in navs" :key="index" @click="goto(item)" :class="{'bold':item.id == category}" :ref="index"><a>{{item.name}}</a></li>
      </ul>
      <div class="arrow arrow_right" v-show="showRight"><img src="../../../assets/images/app_smart_bar/ic-category-arrow-right.svg" @click="slide_right()"></div>
    </div>
    <div class="app-container container">
      <div class="zero" v-show="!skeleton">{{categoryName}}</div>
      <div class="skeleton_eight" v-show="skeleton">
        <div class="skeleton_content" v-for="(item,index) in skeletonEight">
          <div class="skeleton_top"></div>
          <div class="skeleton_right">
            <div class="skeleton_middle">
              <div class="skeleton_icon"></div>
              <div class="skeleton_url"></div>
            </div>
            <div class="skeleton_title"></div>
            <div class="skeleton_title"></div>
            <div class="skeleton_bottom"></div>
          </div>
        </div>
      </div>

      <div class="eight" v-show="!skeleton">
        <a class="content" v-for="(item,index) in content_eight" :key="index" :href="'/sharings/'+item.shareId+'?enter=explore&lang='+lang">
          <div class="content_top">
            <div class="img" v-lazy:background-image="item.thumbnailLink"></div>
          </div>
          <div class="content_bottom">
            <div class="img_a">
              <img src="../../../assets/images/app_smart_bar/ic-web-globe.svg">
              <a style="color: #8d8d8d;" :href="item.originLink">{{item.host}}</a>
            </div>
            <div class="title">{{item.title}}</div>
            <!-- <div class="slide">
              <p v-for="(item2,index2) in item.highlights" :key="index2" style="color: #979797">{{item2.core}}</p>
            </div> -->
            <div class="viewicon">
              <i>{{item.totalHits}} {{item.totalHits > 1 ? langs.explores.views : langs.explores.view}}</i>
              <div class="icons">
                <div class="icon_save">
                  <span v-if="!item.my_web" class="collect" @click.prevent="save(item)"></span>
                  <span v-else class="collected" @click.prevent="remove_article(item)"></span>
                </div>
                <div class="bubble save_bubble">
                  {{langs.explores.save}}
                  <div class="triangle"></div>
                </div>
                <div class="icon_share" @click.prevent="share(item)">
                  <span class="share"></span>
                </div>
                <div class="bubble share_bubble">
                  {{langs.common.share}}
                  <div class="triangle"></div>
                </div>
                <!-- <div class="icon_summary">
                  <img v-if="currUser" class="icon_normal" src="../../../assets/images/app_smart_bar/ic-autosummary-normal.svg" alt="" @click.prevent="summary()">
                  <img v-if="currUser == null" class="icon_normal" src="../../../assets/images/app_smart_bar/ic-autosummary-normal.svg" alt="" @click.prevent="login()">
                  <img v-if="currUser" class="icon_hover" src="../../../assets/images/app_smart_bar/ic-autosummary-hover.svg" alt="" @click.prevent="summary()">
                  <img v-if="currUser == null" class="icon_hover" src="../../../assets/images/app_smart_bar/ic-autosummary-hover.svg" alt="" @click.prevent="login()">
                </div> -->
              </div>
            </div>
          </div>
        </a>
      </div>
      <el-divider></el-divider>
      <div class="leftRight" v-show="!skeleton">
        <div class="others">
          <a class="content" v-for="(item,index) in content_others" :key="index" :href="'/sharings/'+item.shareId+'?enter=explore&lang='+lang">
            <div class="content_left">
              <div class="img" v-lazy:background-image="item.thumbnailLink"></div>
            </div>
            <div class="content_middle">
              <div class="img_a">
                <img src="../../../assets/images/app_smart_bar/ic-web-globe.svg">
                <a style="color: #8d8d8d;" :href="item.originLink">{{item.host}}</a>
              </div> 
              <div class="title">{{item.title}}</div>
              <!-- <div class="slide">
                <div v-for="(item2,index2) in item.highlights" :key="index2" class="article" :style="{'border-color': item2.styleOptions.backgroundColor}">{{item2.core}}</div>
              </div> -->
              <div class="viewicon">
                <i>{{item.totalHits}} {{item.totalHits > 1 ? langs.explores.views : langs.explores.view}}</i>
                <div class="icons">
                  <div class="icon_save">
                    <span v-if="!item.my_web" class="collect" @click.prevent="save(item)"></span>
                    <span v-else class="collected" @click.prevent="remove_article(item)"></span>
                  </div>
                  <div class="bubble save_bubble">
                    {{langs.explores.save}}
                    <div class="triangle"></div>
                  </div>
                  <div class="icon_share" @click.prevent="share(item)">
                    <span class="share"></span>
                  </div>
                  <div class="bubble share_bubble">
                    {{langs.common.share}}
                    <div class="triangle"></div>
                  </div>
                  <!-- <div class="icon_summary">
                    <img v-if="currUser" class="icon_normal" src="../../../assets/images/app_smart_bar/ic-autosummary-normal.svg" alt="" @click.prevent="summary()">
                    <img v-if="currUser == null" class="icon_normal" src="../../../assets/images/app_smart_bar/ic-autosummary-normal.svg" alt="" @click.prevent="login()">
                    <img v-if="currUser" class="icon_hover" src="../../../assets/images/app_smart_bar/ic-autosummary-hover.svg" alt="" @click.prevent="summary()">
                    <img v-if="currUser == null" class="icon_hover" src="../../../assets/images/app_smart_bar/ic-autosummary-hover.svg" alt="" @click.prevent="login()">
                  </div> -->
                </div>
              </div>
                
            </div>
          </a>
          <div class="loading" v-show="!isBottom">
            <div class="skeleton_left"></div>
            <div class="skeleton_right">
              <div class="skeleton_middle">
                <div class="skeleton_icon"></div>
                <div class="skeleton_url"></div>
              </div>
              <div class="skeleton_title"></div>
              <div class="skeleton_title"></div>
              <div class="skeleton_bottom"></div>
            </div>
          </div>
        </div>
        <div class="whatever">
          <div class="whatever_top">
            <p>{{ langs.explores.markup }}</p>
            <el-divider></el-divider>
            <div class="mw_two mw_two1">
              <div class="mw_left"><img src="../../../assets/images/app_smart_bar/img-download-platform-app.svg" alt=""></div>
              <div class="mw_right">
                <p>{{ langs.explores.download }}</p>
                <a target="_blank" @click="app" href="https://apps.apple.com/app/apple-store/id936601959?mt=8" id="Btn_iOS_ExplorePage"><img src="../../../assets/images/app_smart_bar/ic_download_ios.svg" alt=""><span>iOS</span></a>
              </div>
            </div>
            <div class="mw_two mw_two2">
              <div class="mw_left"><img src="../../../assets/images/app_smart_bar/img-download-platform-chrome.svg" alt=""></div>
              <div class="mw_right" >
                <p>{{ langs.explores.extension }}</p>
                <a id="Btn_Extension_ExplorePage" target="_blank" :href="browser ? 'https://chrome.google.com/webstore/detail/pdf-markup-my-web-markups/cbjngbfjehiofmihfpodinolkcokdojp' : 'https://microsoftedge.microsoft.com/addons/detail/markup-my-web-markups-/bkaddcdohjngdgldhhdmghjdohcnncjj'"><img src="../../../assets/images/app_smart_bar/ic_download_extension.svg" alt=""><span>{{ langs.explores.browser }}</span></a>
              </div>
            </div>
          </div>
          <div class="whatever_middle">
            <a :href="langs.common.footer.a1" target="_blank">{{ langs.common.footer.contact }}</a>
            <a :href="langs.common.footer.a2" target="_blank">{{ langs.common.footer.terms }}</a>
            <a :href="langs.common.footer.a3" target="_blank">{{ langs.common.footer.privacy }}</a>
            <a :href="langs.common.footer.a4" target="_blank">FAQ</a>
          </div>
          <div class="whatever_bottom">
            <p id="test">{{'© 2009-' + fullYear + '&nbsp;' }} <a :href="langs.common.footer.a5" target="_blank">Kdan Mobile Software Ltd.</a></p>
          </div>
        </div>
      </div>
      <div class="leftRight" v-show="skeleton">
        <div class="skeleton_eight">
          <div class="skeleton_content" v-for="(item,index) in skeletonEight">
            <div class="skeleton_left"></div>
            <div class="skeleton_right">
              <div class="skeleton_middle">
                <div class="skeleton_icon"></div>
                <div class="skeleton_url"></div>
              </div>
              <div class="skeleton_title"><div></div></div>
              <div class="skeleton_title"><div></div></div>
              <div class="skeleton_bottom"><div></div></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Share :dialogFormVisible1.sync="ifShare" :drawer1.sync="drawer1" :shareUrl="thumbnailLink" :title="title" :host="host" :shareLink="url" :lang="lang" :langs="langs" />

    <el-dialog class="login" :title="langs.common.header.log" :visible.sync="dialogFormVisible3" :show-close="false" width top="25vh">
      <div class="top">{{ langs.common.log }}</div>
      <div class="bottom">
        <button class="button button_login" @click="login_login()" id="Btn_LogIn_Article_SavetoMarkup">{{ langs.common.continue }}</button>
      </div>
    </el-dialog>

    <div id="savesuc" v-show='saveSuccess'><img src="../../../assets/images/app_smart_bar/ic-toast-sucess.svg" alt=""><span>{{ langs.common.success }}</span></div>
    <div id="removesuc" v-show='removeSuccess'><img src="../../../assets/images/app_smart_bar/ic-toast-sucess.svg" alt=""><span>{{ langs.common.removed }}</span></div>
    <div id="fail" v-show='failed'><img src="../../../assets/images/app_smart_bar/ic-toast-fail.svg" alt=""><span>{{ langs.common.failed }}</span></div>
  </div>
</template>

<script>
import Header from '../common/Header';
import Cookies from 'js-cookie';
import axios from 'axios';
import Share from '../common/Share';
import Clipboard from 'clipboard';
export default {
  name: 'explore',
  components: {
    Header,
    Share
  },
  props: ['outside'],
  data() {
    return {
      langues: ['en', 'zh-tw', 'zh-cn', 'ja', 'es', 'ko'],
      currUser: this.outside.currUser,
      api_host: this.outside.api_host,
      skeletonEight: [0,1,2,3,4,5,6,7],
      navs: [],
      navList: [],
      category: 2,
      categoryName: '',
      contentAll: [],
      content_eight: [],
      content_others: [],
      showLeft: false,
      showRight: true,
      navLeft: 0,
      page: 1,
      pageSize: 0,
      isBottom: false,
      ifShare: false,
      drawer1: false,
      screenWidth: '',
      url: null,
      thumbnailLink: '',
      title: '',
      host: '',
      loading: false,
      loadMore: true,
      dialogFormVisible3: false,
      skeleton: true,
      antiShake: false,
      copied: false,
      saveSuccess: false,
      removeSuccess: false,
      failed: false,
      browser: true,
      lang: 'en',
      fullYear: ''
    }
  },
  computed:{
    langs: function(val){
      return JSON.parse(JSON.stringify(this.outside.langs))
    }
  },
  watch: {
    screenWidth(val) {
      this.screenWidth = val;
      if(this.screenWidth <= 768){
        this.ifShare = false;
      }else {
        this.drawer1 = false;
      }
    }
  },
  beforeMount() {
    if (this.currUser) {
      if (this.langues.includes(this.currUser.lang.toLowerCase())) {
        this.lang = this.currUser.lang.toLowerCase();
      } else {
        this.lang = 'en';
      }
    } else {
      // const lang = Cookies.get('lang');
      // if (lang) {
      //   this.lang = lang;
      // } else {
      //   this.lang = 'en';
      // }
      if (window.location.pathname.indexOf('/es') != -1 || window.location.pathname.indexOf('/zh-tw') != -1 || window.location.pathname.indexOf('/zh-cn') != -1 || window.location.pathname.indexOf('/ja') != -1 || window.location.pathname.indexOf('/ko') != -1) {
        const lang = window.location.pathname.substr(1);
        this.lang =  lang.substring(0,lang.length-8);
      } else {
        this.lang = 'en';
      }
      Cookies.set('lang', this.lang, {expires: 7});
    }
    if (this.lang == 'en') {
      if (window.location.pathname.indexOf('/es/explore') != -1 || window.location.pathname.indexOf('/zh-tw/explore') != -1 || window.location.pathname.indexOf('/zh-cn/explore') != -1 || window.location.pathname.indexOf('/ja/explore') != -1 || window.location.pathname.indexOf('/ko/explore') != -1) {
        window.location.pathname = '/explore'
      }
    } else {
      if (window.location.pathname.indexOf('/' + this.lang + '/explore') == -1) {
        window.location.pathname = '/' + this.lang + '/explore';
      }
    }
  },
  mounted () {
    this.fullYear = new Date().getFullYear();
    this.syncPurchaseStat();
    let explorer = window.navigator.userAgent;
    //判断是否为edge浏览器
    if (explorer.indexOf("Edg") >= 0) {
      this.browser = false;
    }else {
      this.browser = true;
    }
    this.category = location.search.replace('?category_id=','') || 2;
    this.navList = [{name:"Recents", key:this.langs.explores.recents}, {name:"art and entertainment", key:this.langs.explores.art}, {name:"finance", key:this.langs.explores.finance}, {name:"business and industrial", key:this.langs.explores.business}, {name:"travel", key:this.langs.explores.travel}, {name:"technology and computing", key:this.langs.explores.technology}, {name:"sports", key:this.langs.explores.sports}, {name:"automotive and vehicles", key:this.langs.explores.automotive}, {name:"home and garden", key:this.langs.explores.home}, {name:"style and fashion", key:this.langs.explores.style}, {name:"food and drink", key:this.langs.explores.food}, {name:"science", key:this.langs.explores.science}, {name:"society", key:this.langs.explores.society}, {name:"health and fitness", key:this.langs.explores.health}, {name:"law, govt and politics", key:this.langs.explores.law}, {name:"family and parenting", key:this.langs.explores.family}, {name:"hobbies and interests", key:this.langs.explores.hobbies}, {name:"education", key:this.langs.explores.education}, {name:"shopping", key:this.langs.explores.shopping}, {name:"careers", key:this.langs.explores.careers}];
    this.getContentsFirst();
    this.getNavs();
    let that = this;
    window.onscroll = function(){
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if(scrollTop + windowHeight >= scrollHeight - 200 && that.loadMore){
      //到了这个就可以进行业务逻辑加载后台数据了
        that.load();
      }
    }
    window.onresize= () => {
      that.screenWidth = window.innerWidth;
    }
  },
  methods: {
    syncPurchaseStat() {
      const refresh = Cookies.get('refreshState');
      const upgrade = Cookies.get('setUpgrade');
      if (this.currUser && (upgrade || !refresh)) {
        axios.get("/api/members/me?refresh=true")
        .then((res)=>{
          this.currUser = res.data.results;
          Cookies.set('refreshState', true);
          Cookies.remove('setUpgrade');
        })
        .catch(function(err){
          console.log(err);
          Cookies.set('refreshState', true);
        });
      }
    },
    // 获取一级分类
    async getNavs () {
      let that = this;
      await axios.get('/api/categories?level=level1')
      .then(function(response){
        that.navs = response.data.results;
        that.navs.forEach(function (item, index) {
          // that.navs[index].name = item.name.replace(/^\S/, function(str){return str.toUpperCase();})
          for (let i = 0; i < that.navList.length; i++) {
            if (that.navs[index].name == that.navList[i].name) {
              that.navs[index].name = that.navList[i].key;
              if (that.navs[index].id == that.category) {
                that.categoryName = that.navList[i].key;
              }
            }
          }
        });
      })
      .catch(function(err){
      });
    },
    // 获取文章列表
    async getContents () {
      let that = this;
      await axios.get('/api/projects/recent_weblist' + '?category_id=' + this.category + '&index=' + this.pageSize)
      .then(function(response){
        that.contentAll = response.data.results;
        that.pageSize += response.data.order.per_page_count;
        that.loadMore = response.data.paging.total_pages > 1;
      })
      .catch(function(err){
      });
      this.loading = false;
      let arr = '';
      this.contentAll.forEach(function(e, index){
        arr = JSON.parse(decodeURIComponent(escape(window.atob(e.highlights))));
        that.contentAll[index].highlights = arr;
      });
      that.content_others = that.content_others.concat(that.contentAll);
      if(!this.loadMore){
        this.isBottom = true;
      }
    },
    async getContentsFirst () {
      this.skeleton = true;
      let that = this;
      await axios.get('/api/projects/recent_weblist' + '?category_id=' + this.category + '&index=' + this.pageSize)
      .then(function(response){
        that.contentAll = response.data.results;
        that.pageSize += response.data.order.per_page_count;
        that.loadMore = response.data.paging.total_pages > 1;
      })
      .catch(function(err){
      });
      let arr = '';
      this.contentAll.forEach(function(e, index){
        arr = JSON.parse(decodeURIComponent(escape(window.atob(e.highlights))));
        that.contentAll[index].highlights = arr;
      });
      if(that.contentAll.length > 8){
        that.content_eight = that.contentAll.splice(0,8);
        that.content_others = that.content_others.concat(that.contentAll);
      }else {
        that.content_eight = that.contentAll;
        that.content_others = [];
      };
      if(!this.loadMore){
        this.isBottom = true;
      }
      this.skeleton = false;
    },
    //导航栏切换
    goto (item) {
      location.search = '?category_id=' + item.id;
    },
    load () {
      if (this.loading) return;
      this.loading = true;
      this.getContents();
    },
    slide_left () {
      let navWidth = this.$refs.navBox.offsetWidth;
      const moveLen = Math.round(navWidth * 0.4 * 100) / 100;
      this.showRight = true;
      if(this.navLeft <= moveLen) {
        this.showLeft = false;
        this.navLeft = 0;
      } else {
        this.navLeft = Math.round((this.navLeft - moveLen) * 100) / 100;
      }
    },
    slide_right () {
      let navWidth = this.$refs.navBox.offsetWidth;
      const moveLen = Math.round(navWidth * 0.4 * 100) / 100;
      this.showLeft = true;
      const remainLen = this.$refs.navChild.scrollWidth - (this.navLeft + navWidth);
      if(remainLen <= moveLen) {
        this.showRight = false;
        this.navLeft = this.$refs.navChild.scrollWidth - navWidth + 20;
      } else {
        this.navLeft = Math.round((this.navLeft + moveLen) * 100) / 100;
      }
    },
    save(item){
      if (!this.currUser) {
        this.dialogFormVisible3 = true;
        return;
      }
      if (this.antiShake) {
        return
      }
      this.antiShake = true;
      let projects = {
        projects: [{
          originLink: item.originLink,
          thumbnailLink: item.thumbnailLink,
          title: item.title,
          web_summarys: item.web_summarys
        }]
      };
      let that = this;
      axios.post("/api/projects/save_url",projects)
      .then((res)=>{
        item.my_web = {projectId: res.data.results[0].projectId};
        this.antiShake = false;
        this.saveSuccess = true;
        setTimeout(function(){that.saveSuccess = false;},3000);
      })
      .catch(function(err){
        that.failed = true;
        setTimeout(function(){that.failed = false;},3000);
      });
    },
    async remove_article (item) {
      if (!this.currUser || !item.my_web) return;
      let that = this;
      await axios.post("/api/projects/delete_by_id",{projectId: item.my_web.projectId})
      .then((res)=>{
        item.my_web = null;
        this.removeSuccess = true;
        setTimeout(function(){that.removeSuccess = false;},3000);
      })
      .catch(function(err){
        that.failed = true;
        setTimeout(function(){that.failed = false;},3000);
      });
    },
    share(item) {
      if(window.innerWidth < 768){
        this.drawer1 = true;
      }else{
        this.ifShare = true;
      }
      this.url = this.api_host + item.sharing_path;
      this.thumbnailLink = item.thumbnailLink;
      this.title = item.title;
      this.host = item.host;
    },
    login() {
      this.dialogFormVisible3 = true;
    },
    login_login() {
      //utm携带到会员系统
      var arrStr = document.cookie.split("; ");
      for (var i = 0; i < arrStr.length; i++) {
        var temp = arrStr[i].split("=");
        if (temp[0] == 'utmutm') {
          window.location.href = "/auth/kdan_mobile?" + arrStr[i].substr(8) + '&lang=' + this.lang;
          return
        }
      }
      window.location.href = "/auth/kdan_mobile" + '?lang=' + this.lang;
    },
    app (e) {
      var lang = navigator.language || navigator.userLanguage;
      if (lang == 'zh-CN') {
        e.preventDefault();
        window.open('https://apps.apple.com/cn/app/apple-store/id936601959?mt=8');
      }
    },
    changeCurrUser (curr,lang) {
      this.$emit('update:currUser', curr, lang);
      if (window.location.pathname.indexOf('/explore') != -1 || window.location.pathname.indexOf('/es/explore') != -1  || window.location.pathname.indexOf('/zh-tw/explore') != -1 || window.location.pathname.indexOf('/zh-cn/explore') != -1 || window.location.pathname.indexOf('/ja/explore') != -1 || window.location.pathname.indexOf('/ko/explore') != -1) {
        if (lang == 'en') {
          window.location.pathname = '/explore'
        } else {
          window.location.pathname = '/' + lang + '/explore';
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../vue_styles/global.scss';
.dashboard-container{
  #removesuc, #savesuc {
    position: fixed;
    left: 36px;
    bottom: 36px;
    display: flex;
    align-items: center;
    padding: 14px 16px;
    border-radius: 5px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.05);
    background-color: #dff4d2;
    z-index: 999;
    text-align: center;
    white-space: nowrap;
    img {
      width: 20px;
      height: 20px;
      margin-right: 12px;
    }
    span {
      font-size: 14px;
      font-weight: 500;
      color: #28a60e;
    }
  }
  #fail {
    position: fixed;
    left: 36px;
    bottom: 36px;
    display: flex;
    align-items: center;
    padding: 14px 16px;
    border-radius: 5px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.05);
    background-color: #ffe1dd;
    z-index: 999;
    text-align: center;
    img {
      width: 20px;
      height: 20px;
      margin-right: 12px;
    }
    span {
      font-size: 14px;
      font-weight: 500;
      color: #ff4d36;
    }
  }
  .header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1999;
    background-color: #fff;
  }
  .navBox {
    position: fixed;
    width: 100%;
    height: 64px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);
    overflow:hidden;
    top: 64px;
    left: 0;
    background-color: #fff;
    z-index: 999;
    .nav {
      display: flex;
      height: 64px;
      padding-left: 2%;
      padding-right: 2%;
      position: absolute;
      top: 0;
      transition: all 1.5s;
      li {
        flex: none;
        margin: auto 20px;
        line-height: 64px;
        a {
          font-size: 18px;
        }
      }
      .bold {
        a {
          font-weight: bold;
          color: #5a5a5a;
        }
      }
    }
    .arrow {
      width: 10%;
      position: absolute;
      top: 0;
      z-index: 999;
      text-align: center;
      line-height: 64px;
      img {
        width: 20px;
        height: 36px;
        cursor: pointer;
      }
    }
    .arrow_left {
      left: 0;
      background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 60%, rgba(255,255,255,0.5) 80%, rgba(255,255,255,0) 100%);/* Safari 5.1 - 6 */
      background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 60%, rgba(255,255,255,0.5) 80%, rgba(255,255,255,0) 100%);/* Firefox 3.6 - 15*/
      background: -o-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 60%, rgba(255,255,255,0.5) 80%, rgba(255,255,255,0) 100%);/* Opera 11.1 - 12*/
      background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 60%, rgba(255,255,255,0.5) 80%, rgba(255,255,255,0) 100%);/* 标准的语法 */
    }
    .arrow_right {
      right: 0;
      background: -webkit-linear-gradient(right, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 60%, rgba(255,255,255,0.5) 80%, rgba(255,255,255,0) 100%);/* Safari 5.1 - 6 */
      background: -moz-linear-gradient(right, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 60%, rgba(255,255,255,0.5) 80%, rgba(255,255,255,0) 100%);/* Firefox 3.6 - 15*/
      background: -o-linear-gradient(right, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 60%, rgba(255,255,255,0.5) 80%, rgba(255,255,255,0) 100%);/* Opera 11.1 - 12*/
      background: linear-gradient(to left, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 60%, rgba(255,255,255,0.5) 80%, rgba(255,255,255,0) 100%);/* 标准的语法 */
    }
  }
  .app-container {
    .zero {
      height: 53px;
      margin: 0 1.5%;
      margin-top: 181px;
      font-size: 40px;
      font-weight: bold;
      letter-spacing: -0.2px;
    }
    .eight{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .content {
        width: 21.9595%;
        margin: 0 1.5%;
        margin-top: 48px;
        .content_top {
          font-size: 0;
          .img {
            width: 100%;
            display: inline-block;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            height: 180px;
          }
        }
        .content_bottom {
          .img_a {
            display: flex;
            flex-direction: row;
            margin-top: 10px;
            height: 20px;
            overflow: hidden;
            img {
              width: 20px;
              margin-right: 6px;
            }
            a {
              overflow: hidden;
              line-height: 20px;
              font-size: 12px;
              color: #8d8d8d;
              text-overflow: ellipsis;
            }
          }
          .title {
            height: 90px;
            line-height: 30px;
            margin: 6px 0 0;
            font-size: 22px;
            font-weight: bold;
            color: #343434;
            overflow: hidden;
            text-overflow:ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          .viewicon {
            display: flex;
            position: relative;
            margin-top: 8px;
            justify-content: space-between;
            align-items: flex-end;;
            height: 20px;
            i {
              display: block;
              font-size: 11px;
              line-height: 1;
              color: #979797;
            }
            .icons {
              display: none;
              position: relative;
              .bubble {
                position: absolute;
                padding: 4px 8px;
                border-radius: 4px;
                box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.28);
                background-color: #2c2c2c;
                font-size: 11px;
                text-align: center;
                color: #ffffff;
                .triangle {
                  position: absolute;
                  width: 5px;
                  height: 5px;
                  border: 5px;
                  border-style: solid;
                  border-color: transparent transparent #2c2c2c transparent;
                  top: -40%;
                  left: 45%;
                }
              }
              .save_bubble {
                display: none;
                top: 150%;
                right: -36%;
                white-space: nowrap;
              }
              .share_bubble {
                display: none;
                top: 150%;
                right: -15%;
              }
              .icon_save {
                .collect, .collected {
                  display: block;
                  width: 20px;
                  height: 20px;
                }
                .collect {
                  background: url('../../../assets/images/app_smart_bar/ic-savearticles-normal.svg') center no-repeat;
                  &:hover {
                    background-image: url('../../../assets/images/app_smart_bar/ic-savearticles-hover.svg');
                  }
                }
                .collected {
                  background: url('../../../assets/images/app_smart_bar/ic-savearticles-active.svg') center no-repeat;
                }
              }
              .icon_share {
                .share {
                  display: block;
                  width: 20px;
                  height: 20px;
                  background: url('../../../assets/images/app_smart_bar/ic-share-normal.svg') center no-repeat;
                  &:hover {
                    background-image: url('../../../assets/images/app_smart_bar/ic-share-hover.svg');
                  }
                }
              }
              .icon_save, .icon_share, .icon_summary {
                display: inline-block;
                margin: 0 3px;
                vertical-align: middle;
              }
              .icon_save:hover + .save_bubble {
                display: block;
              }
              .icon_share:hover + .share_bubble {
                display: block;
              }
            }
          }
        }
      }
      .content:focus {
        outline: none;
      }
      .content:hover {
        .content_bottom {
          .viewicon{
            .icons {
              display: block;
            }
          }
        }
      }
    }
    .skeleton_eight {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 170px;
      .skeleton_content {
        width: 21.9595%;
        margin: 0 1.5%;
        .skeleton_top { 
          width: 100%;
          height: 180px;
          margin-top: 48px;
          background-color: #e8e8e8;
        }
        .skeleton_right {
          .skeleton_middle {
            display: flex;
            justify-content: flex-start;
            margin-top: 10px;
            .skeleton_icon{ 
              width: 20px;
              height: 20px;
              margin-right: 6px;
              border-radius: 100%;
              background-color: #e8e8e8;
            }
            .skeleton_url{ 
              width: 115px;
              height: 12px;
              margin: auto 0;
              background-color: #e8e8e8;
            }
          }
          .skeleton_title { 
            margin-top: 12px;
            width: 100%;
            height: 16px;
            background-color: #e8e8e8;
          }
          .skeleton_bottom { 
            margin-top: 12px;
            width: 188px;
            height: 10px;
            background-color: #e8e8e8;
          }
        }
      }
    }
    .el-divider {
      flex-basis: 100%;
      margin: 70px 0 49px 0;
    }
    .leftRight{
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: flex-start;
      .others{
        flex: 3;
        .content {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 48px;
          .content_left {
            width: 25.3906%;
            font-size: 0;
            .img {
              width: 100% !important;
              display: inline-block !important;
              background-size: cover !important;
              background-position: center !important;
              background-repeat: no-repeat !important;
              height: 153px !important;
            }
          }
          .content_middle {
            width: 61.0352%;
            display: flex;
            flex-direction: column;
            margin-left: 2.965%;
            .img_a{
              display: flex;
              flex-direction: row;
              height: 20px;
              overflow: hidden;
              img {
                width: 20px;
                margin-right: 6px;
              }
              a {
                overflow: hidden;
                line-height: 20px;
                font-size: 12px;
                color: #8d8d8d;
                text-overflow: ellipsis;
              }
            }
            .title {
              margin-top: 8px;
              height: 90px;
              color: #343434;
              font-size: 22px;
              font-weight: bold;
              line-height: 30px;
              width: 100%;
              overflow: hidden;
              text-overflow:ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
            .viewicon{
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: flex-end;
              height: 20px;
              margin-top: 15px;
              i {
                display: block;
                font-size: 11px;
                line-height: 1;
                color: #979797;
              }
              .icons {
                display: none;
                position: relative;
                .bubble {
                  position: absolute;
                  padding: 4px 8px;
                  border-radius: 4px;
                  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.28);
                  background-color: #2c2c2c;
                  font-size: 11px;
                  text-align: center;
                  color: #ffffff;
                  .triangle {
                    position: absolute;
                    width: 5px;
                    height: 5px;
                    border: 5px;
                    border-style: solid;
                    border-color: transparent transparent #2c2c2c transparent;
                    top: -40%;
                    left: 45%;
                  }
                }
                .save_bubble {
                  display: none;
                  top: 150%;
                  right: -36%;
                  white-space: nowrap;     
                }
                .share_bubble {
                  display: none;
                  top: 150%;
                  right: -15%;
                }
                .icon_save {
                  .collect, .collected {
                    display: block;
                    width: 20px;
                    height: 20px;
                  }
                  .collect {
                    background: url('../../../assets/images/app_smart_bar/ic-savearticles-normal.svg') center no-repeat;
                    &:hover {
                      background-image: url('../../../assets/images/app_smart_bar/ic-savearticles-hover.svg');
                    }
                  }
                  .collected {
                    background: url('../../../assets/images/app_smart_bar/ic-savearticles-active.svg') center no-repeat;
                  }
                }
                .icon_share {
                  .share {
                    display: block;
                    width: 20px;
                    height: 20px;
                    background: url('../../../assets/images/app_smart_bar/ic-share-normal.svg') center no-repeat;
                    &:hover {
                      background-image: url('../../../assets/images/app_smart_bar/ic-share-hover.svg');
                    }
                  }
                }
                .icon_save, .icon_share,.icon_summary {
                  display: inline-block;
                  margin: 0 3px;
                  vertical-align: middle;
                }
                .icon_save:hover + .save_bubble {
                  display: block;
                }
                .icon_share:hover + .share_bubble {
                  display: block;
                }
              }
            }
          }
          .content_right {
            width: 9.1797%;
            img {
              width: 20px;
              float: right;
            }
          }
          .el-divider {
            flex-basis: 100%;
            margin: 40px 0;
          }
        }
        .content:focus {
          outline: none;
        }
        .content:hover {
          .content_middle {
            .viewicon{
              .icons {
                display: block;
              }
            }
          }
        }
        .loading {
          width: 76%;
          height: 153px;
          display: flex;
          margin-bottom: 5%;
          .skeleton_left { 
            width: 33.33%;
            background-color: #e8e8e8;
          }
          .skeleton_right {
            margin-left: 3.965%;
            width: 50%;
            .skeleton_middle {
              display: flex;
              justify-content: flex-start;
              margin-top: 10px;
              .skeleton_icon{ 
                width: 20px;
                height: 20px;
                margin-right: 6px;
                border-radius: 100%;
                background-color: #e8e8e8;
              }
              .skeleton_url{ 
                width: 115px;
                height: 12px;
                margin: auto 0;
                background-color: #e8e8e8;
              }
            }
            .skeleton_title { 
              margin-top: 12px;
              width: 100%;
              height: 16px;
              background-color: #e8e8e8;
            }
            .skeleton_bottom { 
              margin-top: 56px;
              width: 188px;
              height: 10px;
              background-color: #e8e8e8;
            }
          }
        }
      }
      .skeleton_eight {
        display: flex;
        flex-direction: column;
        width: 60%;
        .skeleton_content {
          width: 100%;
          margin-top: 48px;
          display: flex;
          justify-content: flex-start;
          .skeleton_left { 
            width: 260px;
            height: 180px;
            background-color: #e8e8e8;
            margin-right: 20px;
          }
          .skeleton_right {
            flex: 1;
            display: flex;
            flex-direction: column;
            .skeleton_middle {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              margin-top: 10px;
              .skeleton_icon{ 
                width: 20px;
                height: 20px;
                margin-right: 6px;
                border-radius: 100%;
                background-color: #e8e8e8;
              }
              .skeleton_url{ 
                width: 115px;
                height: 12px;
                margin: auto 0;
                background-color: #e8e8e8;
              }
            }
            .skeleton_title { 
              margin-top: 12px;
              width: 100%;
              height: 16px;
              background-color: #e8e8e8;
            }
            .skeleton_bottom {
              margin-top: 80px;
              width: 188px;
              height: 10px;
              background-color: #e8e8e8;
            }
          }
        }
      }
      .whatever {
        position: sticky;
        top: 177px;
        width: 55.325%;
        display: flex;
        flex-direction: column;
        flex: 1;
        .whatever_top {
          border-radius: 6px;
          background-color: #fbfbfb;
          p{
            font-size: 16px;
            font-weight: bold;
            color: #3a3a3a;
            margin: 16px 0 4px 24px;
          }
          .mw_two {
            width: 100%;
            display: flex;
            align-items: center;
            padding-left: 23px;
            .mw_left {
              margin-right: 25px;
              img {
                width: 88px;
              }
            }
            .mw_right {
              p {
                margin: 0;
                margin-bottom: 4px;
                font-size: 14px;
                font-weight: 500;
                line-height: 25px;
                color: #676767;
              }
              a {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 6px;
                height: 30px;
                border: 1px solid #f85151;
                background-color: #ffffff;
                cursor: pointer;
                img {
                  width: 20px;
                  margin-right: 4px;
                }
                span {
                  padding-top: 2px;
                  line-height: 14px;
                  font-size: 12px;
                  font-weight: 500;
                  color: #f85151;
                }
              }
            }
          }
          .mw_two1 {
            .mw_right a {
              width: 70px;
            }
          }
          .mw_two2 {
            margin-top: 42px;
           .mw_right a {
              white-space: nowrap;
              height: 30px;
           }
          }
        }
        .el-divider {
          width: 87%;
          height: 1px;
          margin: 0px 19px 22px;
          background-color: #e3e3e3;
        }
        .whatever_middle {
          margin-top: 20px;
          padding-left: 24px;
          border-radius: 6px;
          background-color: #fbfbfb;
          a {
            display: block;
            margin: 20px 0;
            line-height: 16px;
            font-size: 12px;
            color: #868686;
          }
        }
        .whatever_bottom {
          margin: 14px 0;
          p {
            text-align: center;
            font-size: 12px;
            line-height: 16px;
            color: #727272;
            a{
               text-decoration: underline;
            }
          }
        }
      }
    }
  }
  /deep/ .login {
    .el-dialog {
      width: 420px;
      border-radius: 5px;
      .el-dialog__header{
        .el-dialog__title{
          font-size: 18px;
          font-weight: bold;
          color: #343434;
        }
      }
      .el-dialog__body{
        padding: 0 20px 20px 20px;
        .top{
          font-size: 14px;
          line-height: 1.43;
          color: #4b4b4b;
        }
        .bottom{
          margin-top: 30px;
          display: flex;
          justify-content: flex-end;
          .button {
            height: 40px;
            border-radius: 5px;
            font-weight: bold;
            text-align: center;
            font-size: 16px;
          }
          .button_login {
            background-color: #ff5f4b;
            color: #fff;
            padding: 10px 19px 9px 21px;
          }
          .button_login:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}
  @media screen and (max-width: 768px) {
    .dashboard-container {
      .navBox {
        height: 44px;
        .nav {
          height: 44px;
          li {
            line-height: 44px;
          }
        }
        .arrow {
          height: 44px; 
          img {
            vertical-align: sub;
          }
        }
      }
      .container {
        padding-top: 46px;
        padding-bottom: 80px;
        .zero {
          display: none;
        }
        .eight {
          margin-top: 64px;
          flex-direction: column;
          .content {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            margin-top: 30px;
            justify-content: center;
            .content_top {
              .img {
                width: 100px;
                height: 100px;
              }
            }
            .content_bottom {
              width: 61.0352%;
              height: 100px;
              display: flex;
              flex-direction: column;
              margin-left: 2.965%;
              .img_a{
                align-items: center;
                margin-top: 0;
                img {
                  width: 16px;
                }
              }
              .title {
                margin-top: 8px;
                height: 36px;
                line-height: 1.2;
                color: #343434;
                font-size: 15px;
                width: 100%;
                -webkit-line-clamp: 2;
              }
              .viewicon{
                flex-direction: row;
                justify-content: space-between;
                margin: 0;
                margin-top: 16px;
                .icons {
                  display: block;
                  .icon_save:hover + .save_bubble {
                    display: none;
                  }
                  .icon_share:hover + .share_bubble {
                    display: none;
                  }
                }
              }
            }
            .content_right {
              width: 9.1797%;
              img {
                width: 20px;
                float: right;
              }
            }
            .el-divider {
              flex-basis: 100%;
              margin: 40px 0;
            }
          }
        }
        .skeleton_eight {
          flex-direction: column;
          margin-top: 56px;
          .skeleton_content {
            margin-top: 30px;
            width: 100%;
            display: flex;
            padding: 0 10%;
            margin-left: 0;
            margin-right: 0;
            .skeleton_top {
              margin: 0;
              margin-right: 11px;
              width: 100px;
              height: 100px;
            }
            .skeleton_right {
              flex: 1;
              .skeleton_middle {
                margin: 0;
              }
            }
          }
        }
        .el-divider {
          display: none;
        }
        .leftRight {
          display: flex;
          .others {
            width: 100%;
            .content {
              margin: 0;
              margin-top: 30px;
              display: flex;
              justify-content: center;
              .content_left {
                width: 100px;
                .img {
                  width: 100px !important;
                  height: 100px !important;
                }
              }
              .content_middle {
                height: 100px;
                .img_a{
                  align-items: center;
                  img {
                    width: 16px;
                  }
                }
                .title {
                  height: 36px;
                  line-height: 1.2;
                  font-size: 15px;
                  -webkit-line-clamp: 2;
                }
                .viewicon {
                  margin: 0;
                  margin-top: 16px;
                  .icons {
                    display: block;
                    .icon_save:hover + .save_bubble {
                      display: none;
                    }
                    .icon_share:hover + .share_bubble {
                      display: none;
                    }
                  }
                }
              }
            }
            .loading {
              width: 100%;
              height: 100px;
              justify-content: center;
              margin-top: 30px;
              .skeleton_left { 
                width: 100px;
                height: 100px;
              }
              .skeleton_right {
                margin-left: 3.965%;
                width: 61.0352%;
                .skeleton_middle {
                  margin-top: 0;
                }
                .skeleton_title {
                  margin-top: 8px;
                }
                .skeleton_bottom {
                  margin-top: 22px;
                }
              }
            }
          }
          .skeleton_eight {
            width: 100%;
            margin-top: 0;
            .skeleton_content { 
              margin-top: 30px;
              .skeleton_left {
                width: 100px;
                height: 100px;
                margin-right: 11px;
              }
              .skeleton_right {
                height: 100px;
                .skeleton_middle {
                  margin: 0;
                  .skeleton_icon {}
                  .skeleton_url {
                    margin: 4px 0;
                  }
                }
                .skeleton_title {
                  div {
                    height: 16px;
                  }
                }
                .skeleton_bottom {
                  margin-top: 14px;
                  div {
                    height: 10px;
                  }
                }
              }
            }
          }
          .whatever {
            display: none;
          }
        }
      }
      /deep/ .login {
        .el-dialog {
          width: 300px;
          .el-dialog__body {
            .bottom {
              .button {
                font-size: 12px;
              }
            }
          }
        }
      }
      #removesuc, #savesuc, #fail {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  @media screen and (max-width: 420px) {
    .dashboard-container {
      .container {
        .skeleton_eight {
          .skeleton_content {
            padding: 0;
          }
        }
      }
    }
    .el-message-box__wrapper {
      .winClass {
        width: 300px;
      }
    }
  }
  @media screen and (min-width: 769px) and (max-width:1024px) {
    .dashboard-container {
      .app-container {
        padding: 0 80px 90px;
        .zero {
          margin: 181px 0 0 0;
        }
        .eight {
          justify-content: space-between;
          .content {
            width: 42.765%;
            margin-left: 0;
            margin-right: 0;
          }
        }
        .skeleton_eight {
          justify-content: space-between;
          .skeleton_content {
            width: 42.765%;
            margin-left: 0;
            margin-right: 0;
          }
        }
        .leftRight {
          .whatever {
            display: none;
          }
          .skeleton_eight {
            margin-top: 0;
            width: 100%;
          }
        }
      }
    }
  }
  @media screen and (min-width: 1025px) and (max-width: 1280px) {
    .dashboard-container {
      .app-container {
        .zero {
          margin-left: 2.6%;
          margin-right: 2.6%;
        }
        .eight {
          .content {
            width: 28.018%;
            margin: 0 2.6%;
            margin-top: 48px;
          }
        }
        .skeleton_eight {
          .skeleton_content {
            width: 28.018%;
            margin: 0 2.6%;
          }
        }
        .leftRight {
          .skeleton_eight {
            margin-top: 0;
            width: 80%;
          }
          .whatever {
            .whatever_top {
              padding-bottom: 37px;
              .mw_two {
                flex-direction: column;
                padding: 0;
                .mw_left {
                  margin: 0;
                  text-align: center;
                  img {
                    width: 72px;
                  }
                }
                .mw_right {
                  p {
                    margin-top: 10px;
                    text-align: center;
                  }
                  a {
                    margin: 0 auto;
                  }
                }
              }
              .mw_two2 {
                margin-top: 48px;
              }
            }
            .el-divider {
              width: 82%;
              height: 1px;
              margin: 0px 0 28px 20px;
              background-color: #e3e3e3;
            }
            .whatever_middle {
              margin-top: 20px;
              background-color: #fbfbfb;
              a {
                display: block;
                margin: 20px 0;
                font-size: 12px;
                color: #868686;
              }
            }
            .whatever_bottom {
              margin: 16px 0 16px 0;
              p {
                text-align: center;
                font-size: 11px;
                color: #727272;
                a{
                   text-decoration:underline;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
