<template>
  <div></div>
</template>

<script>
export default {
  name: 'mkpro',
  mounted () {
    var u = navigator.userAgent;
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //iOS终端
    var isiPad = u.indexOf('iPad') > -1 || this.isIPad(); //iPad终端
    if (isiOS || isiPad) {
      window.location.href = 'https://apps.apple.com/app/apple-store/id936601959?mt=8';
    } else {
      if (window.location.href.indexOf('mkpro') > -1) {
        const host = window.location.host
        let url = '/pricing'
        // universal测试环境和正式互换，测试环境pricing跳正式机
        if (host === 'markups.preparing.kdanmobile.com') {
          url = 'https://markups.kdanmobile.com' + url
        } else {
          url = 'https://markups.preparing.kdanmobile.com' + url
        }
        window.location.href = url
      } else if (window.location.href.indexOf('explore') > -1) {
        window.location.href = '/explore';
      }
    }
  },
  methods: {
    isIPad() {
      // 兼容 ipad 13.2.x版本
      return /iPad/.test(navigator.userAgent) || (/Mac/.test(navigator.userAgent) && navigator.maxTouchPoints > 0)
    }
  }
}
</script>
