<template>
  <div>
    <el-dialog class="dialogFormVisible" title="" :visible.sync="dialogEdu" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" width top="5vh">
      <template slot="title"><inline-svg @click="closeDialogFormVisible()" :src="require('../../../assets/images/app_smart_bar/close.svg')" />
        </template>
      <div class="top">
        <img src="../../../assets/images/app_smart_bar/markuppro.svg" alt="">
        <h2>{{ langs.common.education.dialogFormVisible.title }}</h2>
        <h3>{{ langs.common.education.dialogFormVisible.desc }}</h3>
        <div class="plan">
          <span class="title">{{ langs.common.education.dialogFormVisible.top.span1 }}</span>
          <span class="desc">{{ langs.common.education.dialogFormVisible.top.span2 }}</span>
          <span class="li"><div class="dot"></div>{{ langs.common.education.dialogFormVisible.top.span3 }}</span>
          <span class="li"><div class="dot"></div>{{ langs.common.education.dialogFormVisible.top.span4 }}</span>
          <span class="li"><div class="dot"></div>{{ langs.common.education.dialogFormVisible.top.span5 }}</span>
        </div>
      </div>
      <div class="bottom">
        <span class="title">{{ langs.common.education.dialogFormVisible.bottom.span1 }}</span>
        <span class="desc">{{ langs.common.education.dialogFormVisible.bottom.span2 }}</span>
        <input :class="{blue: !eduEmail_error && !eduFormat_error && eduEmail_onblur}" type="text" placeholder="example@school.edu" v-model="eduEmail" @blur="handleEduEmail()" @input="handleEduEmail('input')" @keyup.enter="checkEmail()">
        <p v-show="eduEmail_error && eduEmail_onblur">{{ langs.common.education.dialogFormVisible.bottom.span3 }}</p>
        <p v-show="eduFormat_error && eduEmail_onblur">{{ langs.common.education.dialogFormVisible.bottom.span4 }}</p>
        <div id="btn" :class="{btnon: !eduEmail_error && !eduFormat_error && eduEmail_onblur}" @click="checkEmail()">{{ langs.common.education.dialogFormVisible.bottom.span5 }}</div>
        <div class="terms">{{ langs.common.education.dialogFormVisible.bottom.span6 }}
          <a href="https://www.kdanmobile.com/terms_of_service" target="_blank">{{ langs.common.education.dialogFormVisible.bottom.span7 }}</a>
          {{ langs.common.education.dialogFormVisible.bottom.span8 }}
          <a href="https://www.kdanmobile.com/privacy_policy" target="_blank">{{ langs.common.education.dialogFormVisible.bottom.span9 }}</a></div>
      </div>
    </el-dialog>
    <el-drawer
      custom-class="el-drawer"
      :visible.sync="drawerEdu"
      size=""
      direction="btt"
      :show-close="false"
      >
      <template slot="title"><inline-svg @click="closeDrawer()" :src="require('../../../assets/images/app_smart_bar/close.svg')" />
        </template>
      <div class="top">
        <img src="../../../assets/images/app_smart_bar/markuppro.svg" alt="">
        <h2>{{ langs.common.education.dialogFormVisible.title }}</h2>
        <h3>{{ langs.common.education.dialogFormVisible.desc }}</h3>
        <div class="plan">
          <span class="title">{{ langs.common.education.dialogFormVisible.top.span1 }}</span>
          <span class="desc">{{ langs.common.education.dialogFormVisible.top.span2 }}</span>
          <span class="li"><div class="dot"></div>{{ langs.common.education.dialogFormVisible.top.span3 }}</span>
          <span class="li"><div class="dot"></div>{{ langs.common.education.dialogFormVisible.top.span4 }}</span>
          <span class="li"><div class="dot"></div>{{ langs.common.education.dialogFormVisible.top.span5 }}</span>
        </div>
      </div>
      <div class="bottom">
        <span class="title">{{ langs.common.education.dialogFormVisible.bottom.span1 }}</span>
        <span class="desc">{{ langs.common.education.dialogFormVisible.bottom.span2 }}</span>
        <input :class="{blue: !eduEmail_error && !eduFormat_error && eduEmail_onblur}" type="text" placeholder="example@school.edu" v-model="eduEmail" @blur="handleEduEmail()" @input="handleEduEmail('input')" @keyup.enter="checkEmail()">
        <p v-show="eduEmail_error && eduEmail_onblur">{{ langs.common.education.dialogFormVisible.bottom.span3 }}</p>
        <p v-show="eduFormat_error && eduEmail_onblur">{{ langs.common.education.dialogFormVisible.bottom.span4 }}</p>
        <div id="btn" :class="{btnon: !eduEmail_error && !eduFormat_error && eduEmail_onblur}" @click="checkEmail()">{{ langs.common.education.dialogFormVisible.bottom.span5 }}</div>
        <div class="terms">{{ langs.common.education.dialogFormVisible.bottom.span6 }}
          <a href="https://www.kdanmobile.com/terms_of_service" target="_blank">{{ langs.common.education.dialogFormVisible.bottom.span7 }}</a>
           {{ langs.common.education.dialogFormVisible.bottom.span8 }} 
          <a href="https://www.kdanmobile.com/privacy_policy" target="_blank">{{ langs.common.education.dialogFormVisible.bottom.span9 }}</a>
        </div>
      </div>
    </el-drawer>
    <el-dialog class="dialogSuccess" title="" :visible.sync="dialogSuccess" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" width top="25vh">
      <template slot="title"><inline-svg @click="shut()" :src="require('../../../assets/images/app_smart_bar/close.svg')" />
        </template>
      <div class="top">
        <img src="../../../assets/images/app_smart_bar/ic-edu-successful.svg" alt="">
        <h2>{{ langs.common.education.dialogFormVisible1.span1 }}</h2>
        <span>{{ langs.common.education.dialogFormVisible1.span2 }}</span>
      </div>
      <div class="bottom">
        <span class="title">{{ langs.common.education.dialogFormVisible1.span3 }}</span>
        <div class="btn" @click="skipPayPage()">{{ langs.common.education.dialogFormVisible1.span4 }}</div>
      </div>
    </el-dialog>
    <el-drawer
      custom-class="el-drawerSuccess"
      :visible.sync="drawerSuccess"
      size=""
      direction="btt"
      :show-close="false"
      >
      <template slot="title"><inline-svg @click="shut()" :src="require('../../../assets/images/app_smart_bar/close.svg')" />
        </template>
      <div class="top">
        <img src="../../../assets/images/app_smart_bar/ic-edu-successful.svg" alt="">
        <h2>{{ langs.common.education.dialogFormVisible1.span1 }}</h2>
        <span>{{ langs.common.education.dialogFormVisible1.span2 }}</span>
      </div>
      <div class="bottom">
        <span class="title">{{ langs.common.education.dialogFormVisible1.span3 }}</span>
        <div class="down">
          <div class="btn" @click="skipPayPage()">{{ langs.common.education.dialogFormVisible1.span4 }}</div>
        </div>
      </div>
    </el-drawer>
    <el-dialog class="dialogFail" title="" :visible.sync="dialogFail" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" width top="25vh">
      <template slot="title"><inline-svg @click="shut()" :src="require('../../../assets/images/app_smart_bar/close.svg')" />
        </template>
      <div class="top">
        <img src="../../../assets/images/app_smart_bar/ic-edu-failed.svg" alt="">
        <h2>{{ langs.common.education.dialogFormVisible2.span1 }}</h2>
      </div>
      <div class="bottom">
        <span class="title">{{ langs.common.education.dialogFormVisible2.span2 }}</span>
        <div class="btn" @click="shut()">{{ langs.common.education.dialogFormVisible2.span3 }}</div>
        <span class="terms">{{ langs.common.education.dialogFormVisible2.span4 }}<a @click="contactUs()">{{ langs.common.education.dialogFormVisible2.span5 }}</a></span>
      </div>
    </el-dialog>
    <el-drawer
      custom-class="el-drawerFail"
      :visible.sync="drawerFail"
      size=""
      direction="btt"
      :show-close="false"
      >
      <template slot="title"><inline-svg @click="shut()" :src="require('../../../assets/images/app_smart_bar/close.svg')" />
        </template>
      <div class="top">
        <img src="../../../assets/images/app_smart_bar/ic-edu-failed.svg" alt="">
        <h2>{{ langs.common.education.dialogFormVisible2.span1 }}</h2>
      </div>
      <div class="bottom">
        <span class="title">{{ langs.common.education.dialogFormVisible2.span2 }}</span>
        <div class="down">
          <div class="btn" @click="shut()">{{ langs.common.education.dialogFormVisible2.span3 }}</div>
          <span class="terms">{{ langs.common.education.dialogFormVisible2.span4 }}<a @click="contactUs()">{{ langs.common.education.dialogFormVisible2.span5 }}</a></span>
        </div>
      </div>
    </el-drawer>

    <el-dialog class="dialogEduForm" title="" :visible.sync="dialogEduForm" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" width top="5vh">
      <template slot="title"><inline-svg @click="shutForm()" :src="require('../../../assets/images/app_smart_bar/close.svg')" />
        </template>
      <div class="top">
        <div class="top-left"><img src="../../../assets/images/app_smart_bar/bgl.svg" alt=""></div>
        <div class="top-center">
          <img src="../../../assets/images/app_smart_bar/main.svg" alt="">
          <span>{{ langs.common.education.dialogFormVisible3.span1 }}</span>
        </div>
        <div class="top-right"><img src="../../../assets/images/app_smart_bar/bgr.svg" alt=""></div>
      </div>
      <div class="bottom">
        <div class="check-name">
          <input type="text" :placeholder="langs.common.education.dialogFormVisible3.span2" v-model.trim="name" @blur="handleInfo('name',name)" @input="handleInfo('name',name)" @keyup.enter="checkInfo()">
          <p v-show="name_error">{{ langs.common.education.dialogFormVisible3.span3 }}</p>
        </div>
        <div class="check-email">
          <input :class="{blue: !email_error && !format_error && email_onblur}" type="text" :placeholder="langs.common.education.dialogFormVisible3.span4" v-model.trim="email" @blur="handleInfo('email',email)" @input="handleInfo('email',email, 'input')" @keyup.enter="checkInfo()">
          <p v-show="email_error && email_onblur">{{ langs.common.education.dialogFormVisible3.span5 }}</p>
          <p v-show="format_error && email_onblur">{{ langs.common.education.dialogFormVisible3.span6 }}</p>
        </div>
        <div class="check-select">Markup</div>
        <div class="check-organization">
          <input type="text" :placeholder="langs.common.education.dialogFormVisible3.span7" v-model.trim="organization" @blur="handleInfo('organization',organization)" @input="handleInfo('organization',organization)" @keyup.enter="checkInfo()">
          <p v-show="organization_error">{{ langs.common.education.dialogFormVisible3.span8 }}</p>
        </div>
        <div class="check-subject">
          <input type="text" :placeholder="langs.common.education.dialogFormVisible3.span9" v-model.trim="subject" @blur="handleInfo('subject',subject)" @input="handleInfo('subject',subject)" @keyup.enter="checkInfo()">
          <p v-show="subject_error">{{ langs.common.education.dialogFormVisible3.span10 }}</p>
        </div>
        <div class="check-textarea">
          <textarea :placeholder="langs.common.education.dialogFormVisible3.span11" name="" id="" cols="30" rows="9" v-model.trim="textarea" @blur="handleInfo('textarea',textarea)" @input="handleInfo('textarea',textarea)"></textarea>
          <p v-show="textarea_error">{{ langs.common.education.dialogFormVisible3.span12 }}</p>
        </div>
        <div v-show="!loading" class="check-btn" @click="checkInfo()">{{ langs.common.education.dialogFormVisible3.span13 }}</div>
        <div v-show="loading" class="check-btn checkLoad-btn">{{ langs.common.education.dialogFormVisible3.span14 }}</div>
      </div>
    </el-dialog>
    <el-drawer
      custom-class="el-drawerEduForm"
      :visible.sync="drawerEduForm"
      size=""
      direction="btt"
      :show-close="false"
      >
      <template slot="title"><inline-svg @click="shutForm()" :src="require('../../../assets/images/app_smart_bar/close.svg')" />
        </template>
      <div class="top">
        <img src="../../../assets/images/app_smart_bar/main.svg" alt="">
        <span>{{ langs.common.education.dialogFormVisible3.span1 }}</span>
      </div>
      <div class="bottom">
        <div class="check-name">
          <input type="text" :placeholder="langs.common.education.dialogFormVisible3.span2" v-model.trim="name" @blur="handleInfo('name',name)" @input="handleInfo('name',name)">
          <p v-show="name_error">{{ langs.common.education.dialogFormVisible3.span3 }}</p>
        </div>
        <div class="check-email">
          <input :class="{blue: !email_error && !format_error && email_onblur}" type="text" :placeholder="langs.common.education.dialogFormVisible3.span4" v-model.trim="email" @blur="handleInfo('email',email)" @input="handleInfo('email',email, 'input')">
          <p v-show="email_error && email_onblur">{{ langs.common.education.dialogFormVisible3.span5 }}</p>
          <p v-show="format_error && email_onblur">{{ langs.common.education.dialogFormVisible3.span6 }}</p>
        </div>
        <div class="check-select">Markup</div>
        <div class="check-organization">
          <input type="text" :placeholder="langs.common.education.dialogFormVisible3.span7" v-model.trim="organization" @blur="handleInfo('organization',organization)" @input="handleInfo('organization',organization)">
          <p v-show="organization_error">{{ langs.common.education.dialogFormVisible3.span8 }}</p>
        </div>
        <div class="check-subject">
          <input type="text" :placeholder="langs.common.education.dialogFormVisible3.span9" v-model.trim="subject" @blur="handleInfo('subject',subject)" @input="handleInfo('subject',subject)">
          <p v-show="subject_error">{{ langs.common.education.dialogFormVisible3.span10 }}</p>
        </div>
        <div class="check-textarea">
          <textarea :placeholder="langs.common.education.dialogFormVisible3.span11" name="" id="" cols="30" rows="9" v-model.trim="textarea" @blur="handleInfo('textarea',textarea)" @input="handleInfo('textarea',textarea)"></textarea>
          <p v-show="textarea_error">{{ langs.common.education.dialogFormVisible3.span12 }}</p>
        </div>
        <div v-show="!loading" class="check-btn" @click="checkInfo()">{{ langs.common.education.dialogFormVisible3.span13 }}</div>
        <div v-show="loading" class="check-btn checkLoad-btn">{{ langs.common.education.dialogFormVisible3.span14 }}</div>
      </div>
    </el-drawer>

    <el-dialog class="dialogPrompt" title="" :visible.sync="dialogPrompt" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" width top="25vh">
      <template slot="title"><inline-svg @click="shutPrompt()" :src="require('../../../assets/images/app_smart_bar/close.svg')" />
        </template>
      <div class="top">
        <img v-show="sendSuccess" src="../../../assets/images/app_smart_bar/alreadysubscribe-icon.svg" alt="">
        <img v-show="sendFail" src="../../../assets/images/app_smart_bar/error.svg" alt="">
      </div>
      <div class="bottom">
        <span v-show="sendSuccess" class="title">{{ langs.common.education.dialogFormVisible4.span1 }}</span>
        <span v-show="sendFail" class="title">{{ langs.common.education.dialogFormVisible4.span2 }}</span>
        <div class="btn" @click="shutPrompt()">{{ langs.common.education.dialogFormVisible4.span3 }}</div>
      </div>
    </el-dialog>
    <el-drawer
      custom-class="el-drawerPrompt"
      :visible.sync="drawerPrompt"
      size=""
      direction="btt"
      :show-close="false"
      >
      <template slot="title"><inline-svg @click="shutPrompt()" :src="require('../../../assets/images/app_smart_bar/close.svg')" />
        </template>
      <div class="top">
        <img v-show="sendSuccess" src="../../../assets/images/app_smart_bar/alreadysubscribe-icon.svg" alt="">
        <img v-show="sendFail" src="../../../assets/images/app_smart_bar/error.svg" alt="">
      </div>
      <div class="bottom">
        <span v-show="sendSuccess" class="title">{{ langs.common.education.dialogFormVisible4.span1 }}</span>
        <span v-show="sendFail" class="title">{{ langs.common.education.dialogFormVisible4.span2 }}</span>
        <div class="down">
          <div class="btn" @click="shutPrompt()">{{ langs.common.education.dialogFormVisible4.span3 }}</div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import axios from 'axios';
import InlineSvg from 'vue-inline-svg';
export default {
  props: ['outside', 'langs', 'screenWidth', 'dialogEdu', 'drawerEdu'],
  components: {
    InlineSvg
  },
  data () {
    return {
      dialogSuccess: false,
      dialogFail: false,
      dialogEduForm: false,
      dialogPrompt: false,
      drawerSuccess: false,
      drawerFail: false,
      drawerEduForm: false,
      drawerPrompt: false,
      name: null,
      email: null,
      eduEmail: null,
      organization: null,
      subject: null,
      textarea: null,
      name_error: false,
      email_error: false,
      email_onblur: false,
      eduEmail_error: false,
      eduEmail_onblur: false,
      format_error: false,
      eduFormat_error: false,
      organization_error: false,
      subject_error: false,
      textarea_error: false,
      sendSuccess: false,
      sendFail: false,
      loading: false
    }
  },
  watch: {
    screenWidth (val) {
      this.screenWidth = val;
      if (this.screenWidth < 930) {
        this.dialogSuccess = false;
        this.dialogFail = false;
        this.dialogEduForm = false;
      } else {
        this.drawerSuccess = false;
        this.drawerFail = false;
        this.drawerEduForm = false;
      }
    }
  },
  methods: {
    closeDialogFormVisible () {
      this.$emit('update:dialogEdu', false);
    },
    closeDrawer () {
      this.$emit('update:drawerEdu', false);
    },
    shut () {
      setTimeout(function(){
        document.querySelector('body').classList.value = '';
      },300);
      this.dialogSuccess = false;
      this.drawerSuccess = false;
      this.dialogFail = false;
      this.drawerFail = false;
    },
    shutForm () {
      setTimeout(function(){
        document.querySelector('body').classList.value = '';
      },300);
      this.dialogEduForm = false;
      this.drawerEduForm = false;
      this.loading = false;
    },
    shutPrompt () {
      setTimeout(function(){
        document.querySelector('body').classList.value = '';
      },300);
      this.dialogPrompt = false;
      this.drawerPrompt = false;
    },
    checkEmail () {
      if (document.getElementsByClassName('btnon').length != 0) {
        this.$emit('update:drawerEdu', false);
        this.$emit('update:dialogEdu', false);
        if (this.eduEmail.indexOf('.edu') > -1 || this.eduEmail.indexOf('.ac') > -1) {
          if (window.innerWidth < 930) {
            this.drawerSuccess = true;
          } else {
            this.dialogSuccess = true;
          }
        } else {
          if (window.innerWidth < 930) {
            this.drawerFail = true;
          } else {
            this.dialogFail = true;
          }
        }
        setTimeout(function(){
          document.querySelector('body').classList.value = 'el-popup-parent--hidden';
        },300);
      }
    },
    handleInfo (arg, content, type) {
      if (content) {
        this[arg + '_error'] = false;
      } else {
        this[arg + '_error'] = true;
      }
      if (arg == "email") {
        if (type != 'input') {
          this.email_onblur = true;
        }
        if (content) {
          var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
          if (reg.test(content)) {
            this.format_error = false;
            this.email_error = false;
            this.email_onblur = true;
          } else {
            this.format_error = true;
            this.email_error = false;
          }
        } else {
          this.format_error = false;
          this.email_error = true;
        }
      }
    },
    handleEduEmail (type) {
      if (type != 'input') {
        this.eduEmail_onblur = true;
      }
      if (this.eduEmail) {
        var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
        if (reg.test(this.eduEmail)) {
          this.eduFormat_error = false;
          this.eduEmail_error = false;
          this.eduEmail_onblur = true;
        } else {
          this.eduFormat_error = true;
          this.eduEmail_error = false;
        }
      } else {
        this.eduFormat_error = false;
        this.eduEmail_error = true;
      }
    },
    async sendEmail () {
      let info = {
        "name": this.name,
        "email": this.email,
        "product": "Markup Pro",
        "organization": this.organization,
        "subject": this.subject,
        "school": this.subject,
        "comment": this.textarea,
        "form_type": "creative_store_help_center"
      };
      let that = this;
      await axios.post(that.outside.server_env == 'production' ? "https://mail-center.kdanmobile.com/api/v1/contact_and_support" : "https://mail-center.preparing.kdanmobile.com/api/v1/contact_and_support", info,{headers:{'Content-Type':'application/json'}})
      .then((res)=>{
        that.dialogEduForm = false;
        that.drawerEduForm = false;
        that.sendSuccess = true;
        that.sendFail = false;
        that.loading = false;
        if (window.innerWidth < 930) {
          that.drawerPrompt = true;
        } else {
          that.dialogPrompt = true;
        }
      })
      .catch(function(err){
        that.dialogEduForm = false;
        that.drawerEduForm = false;
        that.sendFail = true;
        that.sendSuccess = false;
        that.loading = false;
        if (window.innerWidth < 930) {
          that.drawerPrompt = true;
        } else {
          that.dialogPrompt = true;
        }
      });
    },
    checkInfo () {
      this.handleInfo('name', this.name);
      this.handleInfo('email', this.email);
      this.handleInfo('organization', this.organization);
      this.handleInfo('subject', this.subject);
      this.handleInfo('textarea', this.textarea);
      if (!this.name_error && !this.email_error && !this.format_error && !this.organization_error && !this.subject_error && !this.textarea_error) {
        //check-btn
        this.loading = true;
        //Contact us寄信
        this.sendEmail();
      }
    },
    contactUs () {
      this.name = null;
      this.email = null;
      this.organization = null;
      this.subject = null;
      this.textarea = null;
      this.name_error = false;
      this.email_error = false;
      this.email_onblur = false;
      this.format_error = false;
      this.organization_error = false;
      this.subject_error = false;
      this.textarea_error = false;
      this.drawerFail = false;
      this.dialogFail = false;
      if (window.innerWidth < 930) {
        this.drawerEduForm = true;
      } else {
        this.dialogEduForm = true;
      }
    },
    skipPayPage () {
      setTimeout(function(){
        document.querySelector('body').classList.value = '';
      },300);
      this.dialogSuccess = false;
      this.drawerSuccess = false;
      Cookies.set('setUpgrade', true, {expires: 7});
      if (this.outside.server_env == 'production') {
        window.open('https://creativestore.kdanmobile.com/subscription/markuppro/checkout?default-plan=com.kdanmobile.stripe.PDFReader.deluxe_business_pack_year.001&default-coupon=MKEDU50');
      } else {
        window.open('https://creative-store-client.preparing.kdanmobile.com/subscription/markuppro/checkout?default-plan=com.kdanmobile.stripe.PDFReader.deluxe_business_pack_year.001&default-coupon=MKEDU50');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  /deep/ .dialogFormVisible {
    .el-dialog {
      width: 720px;
      .el-dialog__header {
        padding: 12px 12px 0;
        background-color: #fffaf9;
        svg {
          float: right;
          cursor: pointer;
        }
      }
      .el-dialog__body {
        padding: 0;
        .top {
          padding: 0 90px 28px;
          background-color: #fffaf9;
          text-align: center;
          img {
            margin-top: 24px;
            padding-left: 48px;
          }
          h2 {
            font-size: 36px;
            font-weight: bold;
            color: #071d4c;
            white-space: nowrap;
            line-height: 42px;
            margin-top: 30px;
          }
          h3 {
            font-size: 20px;
            font-weight: normal;
            color: #343434;
            line-height: 23px;
            margin-top: 14px;
          }
          .plan {
            display: flex;
            flex-direction: column;
            text-align: left;
            border-radius: 8px;
            border: solid 2px #fbd3ce;
            background-color: #fff;
            padding: 26px 45px 28px 31px;
            margin-top: 22px;
            padding: 26px 31px;
            font-size: 16px;
            .title {
              font-size: 20px;
              font-weight: bold;
              color: #ff5f4b;
              line-height: 23px;
            }
            .desc {
              font-weight: 500;
              color: #343434;
              margin-top: 7px;
              margin-bottom: 13px;
              line-height: 19px;
            }
            .li {
              position: relative;
              color: #000;
              line-height: 19px;
              margin-top: 10px;
              padding-left: 20px;
              .dot {
                position: absolute;
                top: 8px;
                left: 0;
                width: 6px;
                height: 6px;
                border-radius: 100%;
                background-color: #ff5f4b;
                margin-right: 15px;
              }
            }
          }
        }
        .bottom {
          display: flex;
          flex-direction: column;
          padding: 24px 90px 18px;
          .title {
            font-size: 20px;
            font-weight: bold;
            color: #000;
            line-height: 23px;
          }
          .desc {
            font-size: 16px;
            color: #6f6f6f;
            margin-top: 4px;
            margin-bottom: 15px;
            line-height: 19px;
          }
          input {
            width: 100%;
            height: 48px;
            padding: 14px 13px;
            border-radius: 4px;
            border: 2px solid #DCDFE6;
            &:hover {
              border-color: #C0C4CC;
            }
            &:focus {
              border-color: #92959a;
            }
          }
          input::placeholder {
            font-size: 16px;
            color: #c5c5c5;
          }
          .blue {
            background-color: rgb(232, 240, 254) !important;
          }
          p {
            text-align: right;
            color: rgb(208, 66, 70);
            font-size: 0.75rem;
          }
          #btn {
            font-size: 19px;
            font-weight: bold;
            text-align: center;
            color: #fff;
            max-width: 142px;
            margin: 16px auto 20px;
            padding: 9px 24px;
            border-radius: 5px;
            border: solid 1.6px #ff5f4b;
            background-color: #ff5f4b;
            opacity: 0.5;
            cursor: unset;
          }
          .btnon {
            opacity: 1 !important;
            cursor: pointer !important;
          }
          .terms {
            text-align: center;
            line-height: 16px;
            white-space: nowrap;
            a {
              color: #007aff;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  /deep/ .el-drawer {
    position: relative;
    height: 100vh;
    -webkit-animation: none !important;
    animation: none !important;
    .el-drawer__header {
      position: absolute;
      right: 8px;
      top: 8px;
      padding: 8px 8px 0;
      background-color: #fffaf9;
      margin: 0;
      svg {
        width: 36px;
        height: 36px;
      }
    }
    .el-drawer__body {
      padding: 0;
      .top {
        padding: 18px 25px 20px;
        background-color: #fffaf9;
        img {
          width: 195px;
          height: 44px;
          margin-top: 8px;
        }
        h2 {
          font-size: 24px;
          font-weight: bold;
          color: #071d4c;
          line-height: 28px;
          margin-top: 12px;
        }
        h3 {
          font-weight: normal;
          font-size: 14px;
          color: #343434;
          line-height: 16px;
          margin-top: 8px;
        }
        .plan {
          display: flex;
          flex-direction: column;
          text-align: left;
          border-radius: 8px;
          border: solid 2px #fbd3ce;
          background-color: #fff;
          padding: 18px 17px 21px 22px;
          margin-top: 20px;
          font-size: 16px;
          .title {
            font-size: 18px;
            font-weight: bold;
            color: #ff5f4b;
            line-height: 21px;
          }
          .desc {
            font-size: 14px;
            font-weight: 500;
            color: #343434;
            line-height: 16px;
            margin-top: 7px;
            margin-bottom: 13px;
          }
          .li {
            position: relative;
            color: #000;
            font-size: 14px;
            line-height: 18px;
            margin-top: 12px;
            padding-left: 20px;
            .dot {
              position: absolute;
              top: 8px;
              left: 0;
              width: 6px;
              height: 6px;
              border-radius: 100%;
              background-color: #ff5f4b;
              margin-right: 15px;
            }
          }
        }
      }
      .bottom {
        display: flex;
        flex-direction: column;
        padding: 16px 26px 18px;
        .title {
          font-size: 18px;
          font-weight: bold;
          line-height: 21px;
          color: #000;
        }
        .desc {
          font-size: 16px;
          color: #6f6f6f;
          line-height: 19px;
          margin-top: 4px;
          margin-bottom: 12px;
        }
        input {
          width: 100%;
          height: 40px;
          padding: 12px 13px;
          border-radius: 4px;
          border: solid 1px #e1e1e1;
        }
        input::placeholder {
          font-size: 14px;
          color: #c5c5c5;
        }
        .blue {
          background-color: rgb(232, 240, 254) !important;
        }
        p {
          text-align: right;
          color: rgb(208, 66, 70);
          font-size: 0.75rem;
        }
        #btn {
          font-size: 19px;
          font-weight: bold;
          text-align: center;
          color: #fff;
          max-width: 142px;
          margin: 20px auto 16px;
          padding: 9px 26px;
          border-radius: 5px;
          border: solid 1.6px #ff5f4b;
          background-color: #ff5f4b;
          opacity: 0.5;
          cursor: unset;
        }
        .btnon {
          opacity: 1 !important;
          cursor: pointer !important;
        }
        .terms {
          font-size: 14px;
          text-align: center;
          line-height: 20px;
          a {
            color: #007aff;
            cursor: pointer;
          }
        }
      }
    }
  }
  /deep/ .dialogSuccess, /deep/ .dialogFail, /deep/ .dialogPrompt {
    .el-dialog {
      width: 785px;
      .el-dialog__header {
        padding: 12px 12px 0;
        background-color: #fffaf9;
        svg {
          float: right;
          cursor: pointer;
        }
      }
      .el-dialog__body {
        padding: 0;
        text-align: center;
        .top {
          background-color: #fffaf9;
          padding-bottom: 24px;
          img {
            margin-top: 27px;
            padding-left: 48px;
          }
          h2 {
            font-size: 36px;
            color: #071d4c;
            margin-top: 21px;
            margin-bottom: 12px;
          }
          span {
            font-size: 20px;
            color: #343434;
          }
        }
        .bottom {
          padding: 28px 0;
          .title {
            font-size: 18px;
            color: #000;
            line-height: 26px;
            word-break: break-word;
          }
          .btn {
            font-size: 19px;
            font-weight: bold;
            text-align: center;
            color: #fff;
            max-width: 142px;
            margin: 24px auto 0;
            padding: 9px 26px;
            border-radius: 5px;
            border: solid 1.6px #ff5f4b;
            background-color: #ff5f4b;
            cursor: pointer;
          }
          .terms {
            display: inline-block;
            color: #8c8c8c;
            margin-top: 20px;
            a {
              color: #007aff;
            }
          }
        }
      }
    }
  }
  /deep/ .el-drawerSuccess, /deep/ .el-drawerFail, /deep/.el-drawerPrompt {
    position: relative;
    height: 100vh;
    -webkit-animation: none !important;
    animation: none !important;
    .el-drawer__header {
      position: absolute;
      right: 8px;
      top: 8px;
      padding: 8px 8px 0;
      background-color: #fffaf9;
      margin: 0;
      svg {
        width: 36px;
        height: 36px;
      }
    }
    .el-drawer__body {
      padding: 0;
      text-align: center;
      .top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 40%;
        background-color: #fffaf9;
        margin-bottom: 23px;
        img {
          width: 84px;
          height: 87px;
        }
        h2 {
          font-size: 24px;
          color: #071d4c;
          margin-top: 21px;
          margin-bottom: 12px;
        }
        span {
          color: #343434;
        }
      }
      .bottom {
        .title {
          font-size: 18px;
          font-weight: 300;
          color: #000;
        }
        .down {
          position: absolute;
          left: 0;
          bottom: 136px;
          width: 100%;
          .btn {
            font-size: 19px;
            font-weight: bold;
            text-align: center;
            color: #fff;
            max-width: 142px;
            margin: 20px auto 16px;
            padding: 9px 26px;
            border-radius: 5px;
            border: solid 1.6px #ff5f4b;
            background-color: #ff5f4b;
            cursor: pointer;
          }
          .terms {
            font-size: 14px;
            text-align: center;
            line-height: 20px;
            a {
              color: #007aff;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  /deep/ .dialogPrompt, /deep/ .el-drawerPrompt {
    .top {
      img {
        width: 148px;
      }
    }
  }
  /deep/ .dialogEduForm {
    .el-dialog {
      width: 785px;
      .el-dialog__header {
        padding: 0;
        svg {
          position: absolute;
          top: 20px;
          right: 20px;
          cursor: pointer;
          z-index: 10;
        }
      }
      .el-dialog__body {
        padding: 0;
        .top {
          display: flex;
          justify-content: center;
          height: 230px;
          background: -webkit-linear-gradient(#fff, #eee);
          background: -o-linear-gradient(#fff, #eee);
          background: -moz-linear-gradient(#fff, #eee);
          background: linear-gradient(#fff, #eee);
          .top-left {
            position: absolute;
            left: 0;
          }
          .top-right {
            position: absolute;
            right: 0;
          }
          .top-center {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            max-width: 568px;
            z-index: 9;
            img {
              width: 100px;
              height: 100px;
            }
            span {
              color: #000;
              font-size: 2.25rem;
              font-weight: 500;
              line-height: 1.15;
            }
          }
        }
        .bottom {
          padding: 30px 130px;
          div {
            position: relative;
            margin-bottom: 13px;
            input, textarea {
              width: 100%;
              padding: 14px 24px;
              border: 1px solid rgb(215, 215, 215);
              outline: 1px solid rgb(215, 215, 215);
            }
            .blue {
              background-color: rgb(232, 240, 254) !important;
            }
            p {
              text-align: right;
              color: rgb(208, 66, 70);
              font-size: 0.75rem;
            }
          }
          .check-select {
            padding: 14px 24px;
            background-color: rgb(230, 230, 230);
          }
          .check-select::after {
            content: "";
            position: absolute;
            top: 18px;
            right: 14px;
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 10px 5px 0px;
            border-color: rgb(128, 128, 128) transparent transparent;
          }
          .check-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 20px;
            padding-right: 20px;
            height: 40px;
            border-radius: 6px;
            background-color: #ff5f4b;
            color: #fff;
            font-size: 16px;
            font-weight: bold;
            line-height: 16px;
            text-align: center;
            cursor: pointer;
          }
          .check-btn:hover {
            opacity: 0.8;
          }
          .checkLoad-btn {
            opacity: 0.5;
          }
          .checkLoad-btn:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
  /deep/ .el-drawerEduForm {
    position: relative;
    height: 100vh;
    overflow-y: scroll;
    .el-drawer__header {
      position: absolute;
      right: 8px;
      top: 8px;
      padding: 8px 8px 0;
      background-color: #fffaf9;
      margin: 0;
      svg {
        width: 36px;
        height: 36px;
      }
    }
    .el-drawer__body {
      padding: 0;
      .top {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 190px;
        background: -webkit-linear-gradient(to top, rgb(229, 235, 243), rgb(255, 255, 255));
        background: -o-linear-gradient(to top, rgb(229, 235, 243), rgb(255, 255, 255));
        background: -moz-linear-gradient(to top, rgb(229, 235, 243), rgb(255, 255, 255));
        background: linear-gradient(to top, rgb(229, 235, 243), rgb(255, 255, 255));
        img {
          width: 100px;
          height: 100px;
        }
        span {
          color: #000;
          font-size: 1.5rem;
          font-weight: 500;
          line-height: 1.15;
          text-align: center;
        }
      }
      .bottom {
        padding: 30px;
        div {
          position: relative;
          margin-bottom: 13px;
          input, textarea {
            width: 100%;
            padding: 14px 24px;
            border: 1px solid rgb(215, 215, 215);
            border-radius: 0;
            outline: 0;
          }
          input {
            height: 53.2px;
          }
          input::placeholder, textarea::placeholder {
            font-size: 14px;
            color: #c5c5c5;
          }
          .blue {
            background-color: rgb(232, 240, 254) !important;
          }
          p {
            text-align: right;
            color: rgb(208, 66, 70);
            font-size: 0.75rem;
          }
        }
        .check-select {
          padding: 17px 24px;
          color: rgb(170, 170, 170);
          background-color: rgb(230, 230, 230);
        }
        .check-select::after {
          content: "";
          position: absolute;
          top: 21px;
          right: 14px;
          width: 0px;
          height: 0px;
          border-style: solid;
          border-width: 10px 5px 0px;
          border-color: rgb(128, 128, 128) transparent transparent;
        }
        .check-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-left: 20px;
          padding-right: 20px;
          height: 40px;
          border-radius: 6px;
          background-color: #ff5f4b;
          color: #fff;
          font-size: 16px;
          font-weight: bold;
          line-height: 16px;
          text-align: center;
          cursor: pointer;
        }
        .check-btn:hover {
          opacity: 0.8;
        }
        .checkLoad-btn {
          opacity: 0.5;
        }
        .checkLoad-btn:hover {
          opacity: 0.5;
        }
      }
    }
  }
</style>