export default {
  popupwindow: function(url, title, w, h) {
    var wLeft = window.screenLeft ? window.screenLeft : window.screenX;
    var wTop = window.screenTop ? window.screenTop : window.screenY;

    var left = wLeft + (window.innerWidth / 2) - (w / 2);
    var top = wTop + (window.innerHeight / 2) - (h / 2);
    return window.open(url, title, 'toolbar=0,status=0, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
  },


  fb_share: function(title, subtitle, text, share_url) {
    var appid, caption, description, display, link, name, redirect_uri, url;
    appid = 596469517146307;
    link = encodeURIComponent(share_url);
    name = encodeURIComponent(title);
    caption = encodeURIComponent(subtitle);
    description = encodeURIComponent(text);
    redirect_uri = encodeURIComponent('https://markups.' + 'kdanmobile.com' + '/close-window.html');
    display = 'popup';
    url = "https://www.facebook.com/dialog/share";
    url += "?app_id=" + appid;
    url += "&link=" + redirect_uri;
    url += "&name=" + name;
    url += "&caption=" + caption;
    url += "&description=" + description;
    url += "&href=" + link;
    url += "&display=" + display;
    this.popupwindow(url, "sharer", 626, 436);
  },

  share_twitter: function(text, share_url) {
    var url;
    url = "https://twitter.com/intent/tweet?url=" + (encodeURIComponent(share_url)) + "&text=" + (encodeURIComponent(text));
    this.popupwindow(url, "sharer", 626, 436 );
  },

  share_weibo: function(text, share_url) {
    var url;
    url = "http://service.weibo.com/share/share.php?url=" + (encodeURIComponent(share_url)) + "&title=" + (encodeURIComponent(text));
    this.popupwindow(url, "sharer", 626, 436 );
  }
}