<template>
  <div id="app">
    <p>{{ message }}</p>
  </div>
</template>

<script>
  import VueRouter from 'vue-router';
  import markups from './markups/index';
  import explores from './explores/index';

  const routes = [
    {
      path: '/markups',
      component: markups,
      name: 'markups'
    },
    {
      path: '/explores',
      component: explores,
      name: 'explores'
    }
  ];
  const router = new VueRouter({
    mode: 'history',
    routes
  });

  export default {
    props: ['outside', 'env_ssr'],
    router
  };
</script>

