<template>
  <div class="cookies" v-show="!ifCookie">
    <p>{{ langs.common.gdpr }}</p>
    <div class="btns">
      <a :href="langs.common.footer.a3" target="_blank">{{ langs.common.privacy }}</a>
      <div class="cookies_btn" @click="cookie()">{{ langs.common.agree }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['lang', 'langs'],
  components: {

  },
  data() {
    return {
      ifCookie: true
    }
  },
  mounted () {
    var strCookies = document.cookie;
    var array = strCookies.split(';');
    for (var i = 0; i < array.length; i++) {
      var item = array[i].split("=");
      if (item[0] == ' markup_cookie') {
        this.ifCookie = true;
        break
      } else {
        this.ifCookie = false;
      }
    }
  },
  methods: {
    cookie () {
      if (!this.ifCookie) {
        var exp = new Date();
        exp.setTime(exp.getTime() + 600 * 144000 * 14);
        document.cookie = 'markup_cookie' + "=" + 'true' + ";expires=" + exp.toGMTString() + ";path=/";
      }
      this.ifCookie = true;
      this.$emit('childByValue', this.ifCookie)
    }
  }
}  
</script>

<style lang="scss" scoped>
  .cookies {
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    min-height: 70px;
    background: rgba(7,29,76,0.95);
    font-size: 14px;
    padding: 15px 18px;
    p {
      color: #fff;
      font-weight: 500;
    }
    .btns {
      display: flex;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 118px;
        height: 40px;
        margin-left: 16px;
        cursor: pointer;
        border: solid 1.2px #f1f1f1;
        border-radius: 6px;
        font-weight: bold;
        text-align: center;
        color: #ffffff;
        white-space: nowrap;
        padding: 0 3px;
      }
      .cookies_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 118px;
        height: 40px;
        margin-left: 8px;
        cursor: pointer;
        border-radius: 6px;
        background-color: #ffffff;
        font-weight: bold;
        text-align: center;
        color: #071d4c;
        white-space: nowrap;
      }
    }
  }
  @media screen and (max-width: 575px) {
    .cookies {
      flex-direction: column;
      .btns {
        margin-top: 12px;
      }
    }
  }
</style>