/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import '@babel/polyfill'

console.log('Hello World from Webpacker')
import RailsVueUJS from '../rails_vue_ujs';
var componentRequireContext = require.context("vue_components", true);
RailsVueUJS.initialComponentsContext(componentRequireContext);
self.RailsVueUJS = RailsVueUJS;
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import '../element-variables.scss'
Vue.use(ElementUI);
import axios from 'axios'
Vue.prototype.$axios = axios;
axios.defaults.timeout = 100000;
axios.defaults.baseURL = '/';
axios.defaults.headers['Accept'] = 'application/json'
axios.defaults.headers['AppID'] = 'mk_web'
import '../vue_styles/global.scss';
import common from '../utils/global.js'
Vue.prototype.common = common
import VueLazyload from 'vue-lazyload';
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require('../../assets/images/app_smart_bar/combined-shape-blue.svg'),
  attempt: 1,
  // the default is ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend']
  listenEvents: [ 'scroll' ]
})
