<template>
  <div class="dashboard-container" @click="bread_close()">
    <div class="main-container">
      <header v-show="ifHeader" @mousedown="toolClick">
        <div class="title" @click="go_back_markups()">
          <img src="../../../assets/images/app_smart_bar/ic-back-00.svg">
        </div>
        <div class="btns">
          <div class="btn tag_btn" @click="open_tag()" v-show="enter == 'markups' && currUser && ifMine">
            <img src="../../../assets/images/app_smart_bar/web-ic-tags.svg">
            <span>{{ langs.common.tag }}</span>
          </div>
          <div class="btn" @click="side_open()" v-show="enter == 'markups' && currUser && ifMine">
            <img src="../../../assets/images/app_smart_bar/ic-annotation-00.svg">
            <span>{{ langs.common.annotation }}</span>
          </div>
          <div class="btn" @click="save()" v-if="enter !== 'markups' || !currUser || !ifMine">
            <img src="../../../assets/images/app_smart_bar/ic-savetomymarkups-00.svg" v-show="!ifMine">
            <img src="../../../assets/images/app_smart_bar/ic-savetomymarkups-01.svg" v-show="ifMine">
            <span>{{ langs.common.save }}</span>
          </div>
          <div class="btn" @click="summary_open()" v-show="!isEmpty">
            <img src="../../../assets/images/app_smart_bar/ic-autosummary-00.svg">
            <span>{{ langs.common.auto }}</span>
          </div>
          <a class="btn" :href="outside.webpage.originLink" target="_blank">
            <img src="../../../assets/images/app_smart_bar/ic-openoriginal-00.svg">
            <span>{{ langs.common.open }}</span>
          </a>
          <div class="btn" @click="remove()" v-show="enter == 'markups' && currUser">
            <img src="../../../assets/images/app_smart_bar/ic-remove-00.svg">
            <span>{{ langs.common.remove }}</span>
          </div>
          <div class="btn" @click="share()">
            <img src="../../../assets/images/app_smart_bar/ic-share-00.svg">
            <span>{{ langs.common.share }}</span>
          </div>
        </div>
        <div class="flex-right">
          <a href="javascript:;" class="app" @click="openApp()">{{ langs.common.openapp }}</a>
          <div class="bread" @click.stop="bread_open()"><img src="../../../assets/images/app_smart_bar/ic-more-orange.svg" alt=""></div>
        </div>
        <div class="bread_box" :class="{bread_long_box: lang == 'ja'}" v-show="ifBread">
          <div class="btn tag_btn" @click="open_tag()">
            <img src="../../../assets/images/app_smart_bar/web-ic-tags.svg">
            <span>{{ langs.common.tag }}</span>
          </div>
          <a class="btn" :href="outside.webpage.originLink" target="_blank">
            <img src="../../../assets/images/app_smart_bar/ic-openoriginal-00.svg">
            <span>{{ langs.common.open }}</span>
          </a>
          <div class="btn" @click="share()">
            <img src="../../../assets/images/app_smart_bar/ic-share-00.svg">
            <span>{{ langs.common.share }}</span>
          </div>
          <div class="btn remove_btn" @click="remove()" v-show="enter == 'markups' && currUser">
            <img src="../../../assets/images/app_smart_bar/ic-remove-00.svg">
            <span>{{ langs.common.remove }}</span>
          </div>
        </div>
      </header>
      <div class="bread_bottom" v-show="ifBreadBottom">
        <div class="annotation_btn btn" @click="side_open()" v-show="enter == 'markups' && currUser && ifMine">
          <img src="../../../assets/images/app_smart_bar/ic-annotation-00.svg">
          <span>{{ langs.common.annotation }}</span>
        </div>
        <div class="save_btn btn" @click="save()" v-show="enter !== 'markups' || !currUser || !ifMine">
          <img src="../../../assets/images/app_smart_bar/ic-savetomymarkups-00.svg" v-show="!ifMine">
          <img src="../../../assets/images/app_smart_bar/ic-savetomymarkups-01.svg" v-show="ifMine">
          <span>{{ langs.common.save }}</span>
        </div>
        <div class="btn" @click="summary_open()" v-if="!isEmpty">
          <img src="../../../assets/images/app_smart_bar/ic-autosummary-00.svg">
          <span>{{ langs.common.auto }}</span>
        </div>
      </div>

      <Share :dialogFormVisible1.sync="ifShare" :drawer1.sync="drawer1" :shareUrl="outside.webpage.thumbnailLink" :title="outside.webpage.title" :host="outside.webpage.host" :shareLink="url" :ifBreadBottom.sync="ifBreadBottom" :currUser="currUser" :ifMine="ifMine" :lang="lang" :langs="langs" @changeUrl="changeUrl" />

      <el-drawer custom-class="el-drawer2" :visible.sync="drawer2" :modal="false" size="" :direction="'btt'" :show-close="false">
        <div class="drawer_change" @click="drawer3 = true;drawer2 = false;">
          <img class="drawer_img_pencil" src="../../../assets/images/app_smart_bar/mk-web-ic-highlightcolor-change-mobile.svg" alt="">
          <span>{{ langs.common.change }}</span>
          <img class="drawer_img_next" src="../../../assets/images/app_smart_bar/mk-web-ic-actionsheet-next.svg" alt="">
        </div>
        <div class="drawer_note" @click.stop="noteOpen(toolIndex, null, true)">
          <img src="../../../assets/images/app_smart_bar/mk-note-ic-note.svg" alt="">
          <span>{{ langs.common.note }}</span>
        </div>
        <div class="drawer_del" @click="dialogFormVisible5 = true;drawer2 = false;">
          <img src="../../../assets/images/app_smart_bar/chrome-extension-ic-delete-small.svg" alt="">
          <span>{{ langs.common.deleted }}</span>
        </div>
      </el-drawer>

      <el-drawer custom-class="el-drawer3" :visible.sync="drawer3" :modal="false" size="" :direction="'rtl'" :show-close="false">
        <img src="../../../assets/images/app_smart_bar/mk-web-ic-actionsheet-before.svg" alt="" @click="drawer3 = false;drawer2 = true">
        <p>{{ langs.common.change }}</p>
        <div class="drawer_colors">
          <div class="drawer_color">
            <div class="drawer_yellow" :class="{active: whichColor == 0}" @click="change_color(colorIndex, 'yellow', false, false)"></div>
            <div class="drawer_orange" :class="{active: whichColor == 1}" @click="change_color(colorIndex, 'orange', false, false)"></div>
            <div class="drawer_pink" :class="{active: whichColor == 2, star: currUser && !currUser.purchase_stat}" @click="change_color(colorIndex, 'pink', false, false)"></div>
            <div class="drawer_green" :class="{active: whichColor == 3, star: currUser && !currUser.purchase_stat}" @click="change_color(colorIndex, 'green', false, false)"></div>
            <div class="drawer_blue" :class="{active: whichColor == 4, star: currUser && !currUser.purchase_stat}" @click="change_color(colorIndex, 'blue', false, false)"></div>
            <div class="drawer_gray" :class="{active: whichColor == 5, star: currUser && !currUser.purchase_stat}" @click="change_color(colorIndex, 'gray', false, false)"></div>
          </div>
        </div>
      </el-drawer>

      <div class="shadow" v-show="ifShadow" @click.stop="ifAppStore = false;ifShadow = false" @touchmove.prevent></div>

      <Upgrade :autoSummary="true" :upgrade="true" :ifUpgrade.sync="ifUpgrade" :currUser="currUser" :lang="lang" :langs="langs" @changeCurrUser="changeCurrUser" :ifSideAuto.sync="ifSideAuto" :server_env="server_env" :screenWidth="screenWidth" />

      <el-dialog class="login" :title="langs.common.header.log" :visible.sync="dialogFormVisible3" :show-close="false" width top="25vh">
        <div class="top">{{ langs.common.log }}</div>
        <div class="bottom">
          <el-button class="button button_login" @click="login_login()" :id="saveOrSummary ? 'Btn_LogIn_Navi_SavetoMarkup' : 'Btn_LogIn_Navi_AutoSummary'">{{ langs.common.continue }}</el-button>
        </div>
      </el-dialog>

      <el-dialog class="remove" :title="langs.common.removing" :visible.sync="dialogFormVisible4" :show-close="false" :close-on-click-modal="false" width top="25vh">
        <div class="top">{{ langs.common.deleting }}</div>
        <div class="bottom">
          <el-button class="button button_cancel" @click="dialogFormVisible4 = false; ifBreadBottom = true;">{{ langs.common.cancel }}</el-button>
          <el-button type="primary" class="button button_remove" @click="remove_article()">{{ langs.common.remove }}</el-button>
        </div>
      </el-dialog>

      <el-dialog class="delete" :visible.sync="dialogFormVisible5" :modal="false" :show-close="false" width top="25vh">
        <div class="delete_title">{{ langs.common.deleted }}</div>
        <div class="delete_text">{{ langs.common.are }}</div>
        <div class="delete_btn">
          <el-button class="button btn_cancel" @click="dialogFormVisible5 = false;">{{ langs.common.cancel }}</el-button>
          <el-button type="primary" class="button btn_delete" @click="del(colorIndex);dialogFormVisible5 = false">{{ langs.common.delete }}</el-button>
        </div>
      </el-dialog>

      <el-dialog class="addTag" :class="{addTagMobile: ifMobile}" :title="oldTags.length > 0 ? langs.common.editTag : langs.common.addTag" :visible.sync="dialogFormVisible6" :close-on-press-escape="false" :close-on-click-modal="false" @close="addTag=''" width top="25vh">
        <div class="top addTop">
          <el-tag
            v-for="tag in oldTags"
            :key="tag"
            closable
            type="info"
            @close="handleClose(tag)">
            {{tag}}
          </el-tag>
          <el-input v-model="addTag" @input="suggestSearch" :trigger-on-focus="false" :popper-append-to-body="true" :placeholder="oldTags.length > 0 ? '' : langs.common.type" popper-class="select-option" @focus="focusBorder" @blur="blurBorder">
          </el-input>
        </div>
        <div class="suggestList" v-show="addTag && addTag.trim()">
          <div class="scrollbar" :class="{hasPadding: suggestList.length > 0}">
            <ul>
              <li v-for="suggest in suggestList" :key="suggest" @click="handleSelect(suggest)"><div>{{suggest}}</div></li>
              <li class="create" :class="{hasMargin: suggestList.length > 0}" @click="handleSelect(addTag)" v-show="showSuggest"><div><img src="../../../assets/images/app_smart_bar/web-ic-createtag.svg" alt="">{{langs.common.createTag}}<b> “</b><b>{{addTag}}</b><b>”</b></div></li>
            </ul>
          </div>
        </div>
        <div class="bottom">
          <el-button type="primary" class="button" @click="changeTag()">{{ langs.common.done }}</el-button>
        </div>
      </el-dialog>

      <div class="main">
        <div class="webpage-error not_exist" :class="{empty: !isEmpty}" v-show="error_code == 'not_exist'">
          <div class="error-image">
            <img src="../../../assets/images/sharing/mk-web-img-url-limit.svg" alt="">
          </div>
          <div class="title">
            {{ langs.common.oops }}
          </div>
          <div class="message">
            <span>{{ langs.common.suggest }}</span>
            <br>
            <b>{{outside.webpage.originLink}}</b>
          </div>
          <div class="source">
            <a :href="outside.webpage.originLink"  target="_blank">{{ langs.common.open }}</a>
          </div>
        </div>
        <div class="webpage-error expired" :class="{empty: !isEmpty}" v-show="error_code == 'expired'">
          <div class="error-image">
            <img src="../../../assets/images/sharing/mk-web-img-url-removed.svg" alt="">
          </div>
          <div class="title">
            {{ langs.common.the }}
          </div>
          <div class="message">
            <span>{{ langs.common.find }}</span>
            <br>
            <b>{{outside.webpage.originLink}}</b>
          </div>
          <div class="source">
            <a :href="outside.webpage.originLink"  target="_blank">{{ langs.common.open }}</a>
          </div>
        </div>
        <div class="webpage-error cannot_be_shared" :class="{empty: !isEmpty}" v-show="error_code == 'cannot_be_shared'">
          <div class="error-image">
            <img src="../../../assets/images/sharing/mk-web-img-url-private.svg" alt="">
          </div>
          <div class="title">
            {{ langs.common.access }}
          </div>
          <div class="message">
            <span>{{ langs.common.read }}</span>
            <br>
            <b>{{outside.webpage.originLink}}</b>
          </div>
          <div class="source">
            <a :href="outside.webpage.originLink"  target="_blank">{{ langs.common.open }}</a>
          </div>
        </div>
        <div class="tool" ref="toolDiv" :style="{left: toolLeft + 'px', top: toolTop + 'px'}" v-show="ifTool"><img src="../../../assets/images/app_smart_bar/chrome-extension-btn-highlight.svg" alt="" @click="addHighlight($event)"></div>
        <div class="tool_color" v-show="toolColor" :style="{left: toolLeft + 'px', top: toolTop + 'px'}">
          <div class="tool_yellow" :class="{active: colorBorder == 0}" @click="change_color(toolIndex, 'yellow', false, true)"></div>
          <div class="tool_orange" :class="{active: colorBorder == 1}" @click="change_color(toolIndex, 'orange', false, true)"></div>
          <div class="tool_pink" :class="{active: colorBorder == 2, star: currUser && !currUser.purchase_stat}" @click="change_color(toolIndex, 'pink', false, true)"></div>
          <div class="tool_green" :class="{active: colorBorder == 3, star: currUser && !currUser.purchase_stat}" @click="change_color(toolIndex, 'green', false, true)"></div>
          <div class="tool_blue" :class="{active: colorBorder == 4, star: currUser && !currUser.purchase_stat}" @click="change_color(toolIndex, 'blue', false, true)"></div>
          <div class="tool_gray" :class="{active: colorBorder == 5, star: currUser && !currUser.purchase_stat}" @click="change_color(toolIndex, 'gray', false, true)"></div>
        </div>
        <div class="tool_menu" v-show="toolMenu" :style="{left: toolLeft + 'px', top: toolTop + 'px'}">
          <div class="menu_color" @click="toolMenu = false;toolColor = true;"></div>
          <div class="menu_note" @click="openNote(toolIndex)">
            <img src="../../../assets/images/app_smart_bar/mk-note-ic-note.svg" alt="">
            <div class="tip">{{ langs.common.note }}</div>
          </div>
          <div class="menu_del" @click="del(toolIndex)"><img src="../../../assets/images/app_smart_bar/chrome-extension-ic-delete-small.svg" alt=""></div>
        </div>
        <div class="textarea_note" v-show="toolTextarea" :style="{left: toolLeft + 'px', top: toolTop + 'px'}">
          <textarea @input="showSave(1,$event)" @blur="syncNote(toolIndex)" :class="{textarea_share: isEnterShare}" :placeholder="langs.common.typenote" v-model="noteContent"></textarea>
          <div class="textarea_foot" v-show="noteContent">
            <img @mousedown.stop="syncNote(toolIndex, true)" src="../../../assets/images/app_smart_bar/chrome-extension-ic-delete-small.svg" alt="">
            <span @mousedown.stop="syncNote(toolIndex)">{{ langs.common.saveded }}</span>
          </div>
        </div>
        <div :class="{colorLimitBox: true, colorLimitBoxContainer: ifSideClick, mobileColorLimitBox: ifCross, fixedBox: ifFixed}" :style="{left: toolLeft - 230 + 'px', top: overturn ? overturntop + 'px' : toolTop + 40 + 'px'}" v-show="currUser && limitColor && !currUser.purchase_stat">
          <div class="limitTitle">
            <div class="limitImg"></div>
            <span>{{colorLimitTitle}}</span>
            <div class="fork" @click="closeLimit()"></div>
          </div>
          <div class="limitContent" v-show="!ifCross">{{ langs.common.like }}</div>
          <div class="limit_a">
            <a id="Btn_Upgrade_MoreColor" class="upgrade_a" :href="purchaseUrlColor" target="_blank" @click="setUpgrade()">{{ langs.common.header.upgrade }}</a>
          </div>
        </div>
        <div class="countLimitBox" :style="{left: toolLeft - 230 + 'px', top: overturn ? overturntop + 'px' : toolTop + 8 + 'px'}" v-show="currUser && limitCount && !currUser.purchase_stat">
          <div class="limitTitle">
            <div class="limitImg"></div>
            <span>{{ langs.common.want }}</span>
            <div class="fork" @click="closeLimit()"></div>
          </div>
          <div class="limitContent">{{ langs.common.limit }}</div>
          <div class="limit_a">
            <a id="Btn_Upgrade_Overlimit" class="upgrade_a" :href="purchaseUrlCount" target="_blank" @click="setUpgrade()">{{ langs.common.header.upgrade }}</a>
          </div>
        </div>
        <div class="prompt" :style="{left: toolLeft + 'px', top: toolTop + 'px'}" v-show="ifHighlight">
          <div class="prompt_top">
            <div>
              <img class="prompt_pencil" src="../../../assets/images/app_smart_bar/mk-web-ic-highlightcolor-change-mobile.svg" alt="">
              <span>{{ langs.common.page }}</span>
            </div>
            <img class="prompt_close" src="../../../assets/images/app_smart_bar/ic-close.svg" alt="" @click="ifHighlight = false">
          </div>
          <div class="prompt_middle">
            {{ langs.common.start }}
          </div>
          <div class="prompt_bottom">
            <div @click="skip()">{{ langs.common.ok }}</div>
          </div>
        </div>
        <div class="sharing-content" :class="{empty: isEmpty}" v-html="doc && doc" ref="message" @mousedown="toolClick" @mouseup="pencilShow()" @click="highlightClick($event)" @touchend="ifPop()"></div>
        <div class="arrow" v-show="ifSide || ifSideAuto" @click="side_close()"><img src="../../../assets/images/app_smart_bar/ic-category-arrow-right.svg" alt=""></div>
        <div class="summary" :class="{summary17: !ifCookie}" v-show="ifSummary">
          <div class="summary_left">{{ langs.common.generating }}</div>
          <div class="summary_right" @click="summary_close()">{{ langs.common.cancel }}</div>
        </div>
        <div class="opening" :class="{summary17: !ifCookie}" v-show="ifOpen">
          <div class="opening_left">{{ langs.common.opening }}</div>
        </div>
        <div class="side" v-show="ifSide" @mousedown="toolClick">
          <div class="summary_content" :class="{iOS: ifiOS}" v-show="!summaryEmpty">
            <div class="summary_title">
              <span>{{ langs.common.annotation }}<img v-show="ifCross" src="../../../assets/images/app_smart_bar/ic-close.svg" alt="" @click="side_close()"></span>
              <div>
                <i class="summary_count">{{encoded_highlights.length > 1 ? encoded_highlights.length + ' ' + langs.common.highlights : encoded_highlights.length + ' ' + langs.common.highlight}}</i>
                <i class="summary_date">{{finalDate}}</i>
              </div>
            </div>
            <div class="unit_block" v-for="(item,index) in encoded_highlights" :key="index" :style="{'border-color':item.styleOptions.backgroundColor == 'rgb(255, 255, 137)' ? 'rgb(255, 243, 0)' : item.styleOptions.backgroundColor == 'rgb(255, 229, 188)' ? 'rgb(255, 190, 101)' : item.styleOptions.backgroundColor == 'rgb(255, 218, 246)' ? 'rgb(255, 180, 237)' : item.styleOptions.backgroundColor == 'rgb(214, 253, 189)' ? 'rgb(160, 246, 104)' : item.styleOptions.backgroundColor == 'rgb(198, 230, 255)' ? 'rgb(141, 205, 255)' : item.styleOptions.backgroundColor == 'rgb(216, 214, 255)' ? 'rgb(174, 171, 255)' : item.styleOptions.backgroundColor}" @mouseleave="menuClose()" @click="scrollAnnotation(item, $event)">
              <span class="span">{{item.core}}</span>
              <div class="icon_menu">
                <img src="../../../assets/images/app_smart_bar/mk-annotation-ic-more.svg" alt="" @click.stop="menuOpen(index, $event)">
              </div>
              <div class="note" @click.stop="noteEdit(index, $event)">
                <img class="note_img" src="../../../assets/images/app_smart_bar/mk-note-ic-note20.svg" alt="">
                <span>{{ langs.common.note }}</span>
                <div class="note_arrow arrow_down"></div>
              </div>
              <textarea @input="showSave(2,$event)" @blur="changeNote(index, $event, true)" class="note_textarea" :placeholder= "langs.common.typenote">{{ item.note }}</textarea>
              <div class="note_bottom">
                <img @mousedown.stop="changeNote(index, $event, false, true)" src="../../../assets/images/app_smart_bar/chrome-extension-ic-delete-small.svg" alt="">
                <span @mousedown.stop="changeNote(index, $event, false)">{{ langs.common.saveded }}</span>
              </div>
              <div class="menu" v-show="index == menuIndex">
                <div class="icon_change" @click.stop="colorOpen(index, $event)">
                  <img src="../../../assets/images/app_smart_bar/mk-web-ic-highlightcolor-change-mobile.svg" alt="">
                  <i>{{ langs.common.color }}</i>
                </div>
                <div class="icon_note" @click.stop="noteOpen(index, $event)">
                  <img src="../../../assets/images/app_smart_bar/mk-note-ic-note.svg" alt="">
                  <i>{{ langs.common.note }}</i>
                </div>
                <div class="icon_del" @click.stop="del(index)">
                  <img src="../../../assets/images/app_smart_bar/chrome-extension-ic-delete-small.svg" alt="">
                  <i>{{ langs.common.delete }}</i>
                </div>
              </div>

              <div class="four_color" v-show="index == targetIndex">
                <div class="four_yellow" :class="{active: item.styleOptions.backgroundColor === 'rgb(255, 255, 137)'}" @click.stop="item.styleOptions.backgroundColor = 'rgb(255, 255, 137)';change_color(index, 'yellow', true, false)"></div>
                <div class="four_orange" :class="{active: item.styleOptions.backgroundColor === 'rgb(255, 229, 188)'}" @click.stop="item.styleOptions.backgroundColor = 'rgb(255, 229, 188)';change_color(index, 'orange', true, false)"></div>
                <div class="four_pink" :class="{active: item.styleOptions.backgroundColor === 'rgb(255, 218, 246)', star: currUser && !currUser.purchase_stat}" @click.stop="item.styleOptions.backgroundColor= 'rgb(255, 218, 246)';change_color(index, 'pink', true, false)"></div>
                <div class="four_green" :class="{active: item.styleOptions.backgroundColor === 'rgb(214, 253, 189)', star: currUser && !currUser.purchase_stat}" @click.stop="item.styleOptions.backgroundColor= 'rgb(214, 253, 189)';change_color(index, 'green', true, false)"></div>
                <div class="four_blue" :class="{active: item.styleOptions.backgroundColor === 'rgb(198, 230, 255)', star: currUser && !currUser.purchase_stat}" @click.stop="item.styleOptions.backgroundColor= 'rgb(198, 230, 255)';change_color(index, 'blue', true, false)"></div>
                <div class="four_gray" :class="{active: item.styleOptions.backgroundColor === 'rgb(216, 214, 255)', star: currUser && !currUser.purchase_stat}" @click.stop="item.styleOptions.backgroundColor= 'rgb(216, 214, 255)';change_color(index, 'gray', true, false)"></div>
              </div>
              <img class="more" src="../../../assets/images/app_smart_bar/mk-annotation-ic-more.svg" alt="" @click.stop="clickMore(index, $event)">
            </div>
          </div>
          <div class="summary_content_empty" v-show="summaryEmpty">
            <div class="summary_title">
              <span>{{ langs.common.annotation }}<img v-show="ifCross" src="../../../assets/images/app_smart_bar/ic-close.svg" alt="" @click="side_close()"></span>
              <div>
                <i class="summary_count">{{'0' + ' ' + langs.common.highlight}}</i>
              </div>
            </div>
            <div class="step1">
              <span>{{ langs.common.step1 }}</span>
              <img src="../../../assets/images/app_smart_bar/mk-web-app-img-howto-step-2.svg" alt="">
              <p>{{ langs.common.select }}</p>
            </div>
            <div class="step2">
              <span>{{ langs.common.step2 }}</span>
              <img src="../../../assets/images/app_smart_bar/chrome-extension-img-howto-step-2.svg" alt="">
              <p>{{ langs.common.there }}</p>
            </div>
            <div class="step3">
              <p>{{ langs.common.texts }}</p>
              <a href="javascript:;" @click="openApp()">{{ langs.common.app }}</a>
            </div>
          </div>
          <div class="copyAll" @click="copyAll()" v-show="!summaryEmpty">
            <img src="../../../assets/images/app_smart_bar/mk-ic-highlight-copyall.svg" alt="">
            <span>{{ langs.common.copyall}}</span>
            <div class="copyWords" :class="{copyWords_long: lang=='ja'}" v-show="copyWords">{{ langs.common.copied }}</div>
          </div>
          <div class="side_shadow" v-show="drawer2 || drawer3 || dialogFormVisible5 || limitColor"></div>
        </div>
        <div class="side_two" v-show="ifSide || ifSideAuto"></div>
        <div class="side_auto" v-show="ifSideAuto">
          <div class="summary_content" :class="{iOS: ifiOS}">
            <div class="summary_title">
              <span>{{ langs.common.auto }}<img v-show="ifCross" src="../../../assets/images/app_smart_bar/ic-close.svg" alt="" @click="side_close()"></span>
            </div>
            <template v-if="!currUser || !currUser.purchase_stat">
              <div class="unit_block unit_block1 disabled" v-for="(item1,index1) in summary_data_one" :key="'block1-' + index1" style="border: 0;">
                <span>{{item1}}</span>
                <div class="icon_add" @click="upgradeOpen()">
                  <img src="../../../assets/images/app_smart_bar/ic-addtoannotations-red.png" alt="">
                  <p>{{ langs.common.add }}</p>
                </div>
                <div class="bubble add_bubble" v-show="lang != 'zh-cn' && lang != 'zh-tw'">
                  {{ langs.common.add }}
                  <div class="triangle"></div>
                </div>
              </div>
              <div class="upgrade">
                <div class="line_left"></div>
                <a href="javascript:;" @click.prevent="upgradeOpen()">{{ langs.common.upgraded }}</a>
                <div class="line_right"></div>
              </div>
              <div class="unit_block unit_block2" v-for="(item2,index2) in summary_data_four" :key="'block2-' + index2" style="border: 0;">
                <span>{{item2}}</span>
              </div>
            </template>
            <div v-else class="unit_block" v-for="(item,index) in summary_data" :key="'block-' + index" style="border: 0;">
              <span>{{item}}</span>
              <div class="icon_add" @click="add(index)" v-show="ifMine">
                <img src="../../../assets/images/app_smart_bar/ic-addtoannotations-red.png" alt="">
                <p>{{ langs.common.add }}</p>
              </div>
               <div class="bubble add_bubble" v-show="lang != 'zh-cn' && lang != 'zh-tw'">
                {{ langs.common.add }}
                <div class="triangle"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="appOrStore" v-show="ifAppStore">
          <div class="app_img">
            <img src="../../../assets/images/app_smart_bar/plugin_install_icon72.png" alt="">
          </div>
          <p>{{ langs.common.text }}</p>
          <a href="javascript:;" class="openApp" @click="openApp()">{{ langs.common.app }}</a>
          <a href="javascript:;" class="mobileApp" @click="ifAppStore = false;ifShadow = false">{{ langs.common.mobile }}</a>
        </div>
      </div>
    </div>
    <div id="savesuc" v-show='saveSuccess'><img src="../../../assets/images/app_smart_bar/ic-toast-sucess.svg" alt=""><span>{{ langs.common.success }}</span></div>
    <div id="removesuc" v-show='removeSuccess'><img src="../../../assets/images/app_smart_bar/ic-toast-sucess.svg" alt=""><span>{{ langs.common.removed }}</span></div>
    <div id="fail" v-show='failed'><img src="../../../assets/images/app_smart_bar/ic-toast-fail.svg" alt=""><span>{{ langs.common.failed }}</span></div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import axios from 'axios';
import Clipboard from 'clipboard';
import Share from '../common/Share';
import Upgrade from '../common/upgradeDialog'
export default {
  components: {
    Upgrade,
    Share
  },
  props: ['outside'],
  data() {
    return {
      langues: ['en', 'zh-tw', 'zh-cn', 'ja', 'es', 'ko'],
      currUser: this.outside.currUser,
      server_env: this.outside.server_env,
      api_host: this.outside.api_host,
      error_code: this.outside.error_code,
      encoded_highlights: {},
      encoded_highlights_clone: {},
      ifMine: false,
      ifSide: false,
      doc: null,
      ifSideAuto: false,
      ifSummary: false,
      ifOpen: false,
      ifBread: false,
      ifBreadBottom: true,
      ifCross: false,
      throttle: true,
      crawling: true,
      summary_data: [],
      summary_data_one: [],
      summary_data_four: [],
      ifShare: false,
      drawer1: false,
      drawer2: false,
      drawer3: false,
      ifAppStore: false,
      screenWidth: '',
      ifUpgrade: false,
      targetIndex: -1,
      menuIndex: -1,
      url: null,
      enter: 'web',
      cancelToken: null,
      source: null,
      dialogFormVisible3: false,
      saveOrSummary: true,
      dialogFormVisible4: false,
      dialogFormVisible5: false,
      dialogFormVisible6: false,
      ifHeader: true,
      copied: false,
      saveSuccess: false,
      removeSuccess: false,
      failed: false,
      colorNumber: 1,
      copyWords: false,
      summaryEmpty: false,
      finalDate: '2020/08/24',
      toolLeft: '',
      toolTop: '',
      ifTool: false,
      ifHighlight: false,
      toolColor: false,
      toolMenu: false,
      toolTextarea: false,
      oldToolColor: '',
      oldColorSwitch: false,
      limitColor: false,
      limitCount: false,
      ifFixed: false,
      colorLimitTitle: 'Unlock all highlight colors',
      ifSideClick: false,
      toolIndex: 0,
      oldIndex: '',
      onfocus: '',
      txt: '',
      colorBorder: 0,
      colorIndex: 0,
      whichColor: 0,
      ifCookie: true,
      whatColor: 'rgb(255, 255, 137)',
      overturn: false,
      overturntop: '',
      savePath: '',
      ifShadow: false,
      ifiOS: false,
      purchaseUrlColor: (this.outside.server_env == 'production' ? 'https://creativestore.kdanmobile.com' : 'https://creative-store-client.preparing.kdanmobile.com') + '/subscription/markuppro/checkout?default-plan=com.kdanmobile.stripe.PDFReader.deluxe_business_pack_year.001&utm_source=MyMarkup&utm_campaign=Upgrade&utm_medium=MoreColor',
      purchaseUrlCount: (this.outside.server_env == 'production' ? 'https://creativestore.kdanmobile.com' : 'https://creative-store-client.preparing.kdanmobile.com') + '/subscription/markuppro/checkout?default-plan=com.kdanmobile.stripe.PDFReader.deluxe_business_pack_year.001&utm_source=MyMarkup&utm_campaign=Upgrade&utm_medium=OverLimit',
      lang: 'en',
      addTag: '',
      tags: [],
      oldTags: [],
      tagList: [],
      suggestList: [],
      showSuggest: true,
      ifMobile: false,
      noteContent: '',
      timer: null,
      isTablet: false,
      isAndroid: false,
      isFireFox: false,
      isEnterShare: false
    }
  },
  created() {
    try {
      this.doc = decodeURIComponent(this.outside.html_doc).replace(/<head\b[^<]*(?:(?!<\/head>)<[^<]*)*<\/head>/gi, '')
    } catch (err) {
      this.doc = decodeURIComponent(this.outside.html_doc.replace(/%/g, '%25')).replace(/<head\b[^<]*(?:(?!<\/head>)<[^<]*)*<\/head>/gi, '')
    }
    if (this.outside.webpage.originLink.indexOf('www.youtube.com/watch') != -1) {
      var movieNum = this.outside.webpage.originLink.indexOf('?v=')
      var movieId = this.outside.webpage.originLink.substring(movieNum + 3, this.outside.webpage.originLink.length);
      this.doc = '<div id="body"><div id="bodyContent" style="text-align: -webkit-center;"><div id="box"><did id="box_inner"><div id="text"><div id="pages"><div class="page_content"><div id="articleHeader"><h1 id="articleHeader__title">' + this.outside.webpage.title + '</h1></div><iframe src="https://www.youtube.com/embed/' + movieId + '" width="100%" height="500" frameborder="0"></iframe></div></div></div></div></div></div></div>';
    }
  },
  computed: {
    isEmpty: function () {
      if (this.outside.html_doc == null || this.outside.error_code != null) {
        return true;
      } else {
        return false;
      }
    },
    langs: function(val){
      return JSON.parse(JSON.stringify(this.outside.langs))
    }
  },
  watch: {
    screenWidth(val) {
      this.screenWidth = val;
      if (window.innerWidth <= 768) {
        this.ifShare = false;
        this.ifCross = true;
        this.colorLimitTitle = this.langs.common.unlock2;
        if (this.ifSide || this.ifSideAuto) {
          this.ifHeader = false;
          markups_jQuery("body").css('overflow', 'hidden');
        } else {
          markups_jQuery("body").css('overflow', 'auto');
        }
      } else {
        this.drawer1 = false;
        this.drawer2 = false;
        this.drawer3 = false;
        this.ifAppStore = false;
        this.ifShadow = false;
        this.ifCross = false;
        this.colorLimitTitle = this.langs.common.unlock1;
        if (this.ifSide || this.ifSideAuto) {
          this.ifHeader = true;
        }
        markups_jQuery("body").css('overflow', 'auto');
      }
    },
    ifSide(newValue) {
      if (window.innerWidth <= 768) {
        var scrollHeight = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (newValue) {
          document.querySelector('body').setAttribute('style', 'overflow:hidden;');
        } else {
          document.querySelector('body').setAttribute('style', 'overflow:auto;');
          document.querySelector('body').scrollTop = scrollHeight;
        }
      }
    },
    ifSideAuto(newValue) {
      if (window.innerWidth <= 768) {
        var scrollHeight = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (newValue) {
          document.querySelector('body').setAttribute('style', 'overflow:hidden;');
        } else {
          document.querySelector('body').setAttribute('style', 'overflow:auto;');
          document.querySelector('body').scrollTop = scrollHeight;
        }
      }
    }
  },
  beforeMount() {
    if (this.currUser) {
      if (this.langues.includes(this.currUser.lang.toLowerCase())) {
        this.lang = this.currUser.lang.toLowerCase();
      } else {
        this.lang = 'en';
      }
      Cookies.set('lang', this.lang, {expires: 7});
    } else {
      const lang = Cookies.get('lang');
      if (lang) {
        this.lang = lang;
      } else {
        this.lang = 'en';
      }
    }
    if (this.lang == 'en') {
      if (window.location.search.indexOf('lang=es') != -1 || window.location.search.indexOf('lang=zh-tw') != -1 || window.location.search.indexOf('lang=zh-cn') != -1 || window.location.search.indexOf('lang=ja') != -1 || window.location.search.indexOf('lang=ko') != -1) {
        window.location.search = window.location.search.replace('&' + window.location.search.split("&")[1], '');
      }
    } else {
      if (window.location.search.indexOf('lang=' + this.lang) == -1) {
        if (window.location.search.split("&")[1]) {
          window.location.search = window.location.search.replace(window.location.search.split("&")[1], 'lang=' + this.lang);
        } else {
          window.location.search = window.location.search + '&lang=' + this.lang;
        }
      }
    }
  },
  mounted() {
    if (location.search.indexOf('enter=share') != -1) {
      this.isEnterShare = true;
    }
    if (window.location.pathname.indexOf('webpage_error') > -1) {
      this.error_code = 'not_exist';
    }
    this.fIsMobile();
    this.tags = this.outside.webpage.tags;
    this.getTagList();
    this.syncPurchaseStat();
    let u = navigator.userAgent;
    let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    this.ifiOS = isiOS;
    //判断是否是平板
    this.isAndroid = /(?:Android)/.test(u);
    this.isFireFox = /(?:Firefox)/.test(u);
    this.isTablet = /(?:iPad|PlayBook)/.test(u) || (this.isAndroid && !/(?:Mobile)/.test(u)) || (this.isFireFox && /(?:Tablet)/.test(u));
    this.url = this.api_host + this.outside.webpage.sharing_path + '?enter=share';
    this.enterForm()
    this.getHighlights()
    this.select()
    let that = this;
    window.onresize= () => {
      that.screenWidth = window.innerWidth;
    }
    if (window.innerWidth <= 768) {
      this.ifCross = true;
      this.colorLimitTitle = this.langs.common.unlock2;
    } else {
      this.ifCross = false;
      this.colorLimitTitle = this.langs.common.unlock1;
      if (this.ifMine && location.search.indexOf('enter=markups') != -1) {
        this.ifSide = true;
        if (this.isTablet) {
          if (window.innerWidth <= 1024) {
            markups_jQuery('.GDPR').css('width', 'calc(100vw - 308px)');
          } else {
            markups_jQuery('.GDPR').css('width', 'calc(100vw - 332px)');
          }
        } else {
          if (window.innerWidth <= 1024) {
            markups_jQuery('.GDPR').css('width', 'calc(100vw - 325px)');
          } else {
            markups_jQuery('.GDPR').css('width', 'calc(100vw - 349px)');
          }
        }
      }
    }
    document.body.addEventListener('click',()=>{
      this.ifBread = false;
    },false);
    var strCookies = document.cookie;
    var array = strCookies.split(';');
    for (var i = 0; i < array.length; i++) {
      var item = array[i].split("=");
      if (item[0] == ' markup_cookie') {
        this.ifCookie = true;
        break
      } else {
        this.ifCookie = false;
      }
    }
    this.toolIndex = this.encoded_highlights.length - 1;

    let notes = JSON.parse(decodeURIComponent(escape(window.atob(this.outside.encoded_highlights))));
    for (var i = 0; i < notes.length; i++) {
      if (notes[i].note) {
        for (var j = 0; j < markups_jQuery("markups").length; j++) {
          if (markups_jQuery("markups")[j].dataset.id.slice(3) == notes[i].id) {
            var b = document.createElement("div");
            b.className = "markup_note";
            markups_jQuery("markups")[j].after(b);
            break;
          }
        }
      }
    }
    this.$nextTick(function(){
      for (var i = 0; i < this.encoded_highlights.length; i++) {
        if (this.encoded_highlights[i].note && this.encoded_highlights[i].note != 'undefined') {
          markups_jQuery('.unit_block .note')[i].setAttribute('style','display: block');
        }
      }   
    })
  },
  methods: {
    fIsMobile () {
      if (/Android|iPhone|iPad|iPod|BlackBerry|webOS|Windows Phone|SymbianOS|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.ifMobile = true;
      }
    },
    setUpgrade() {
      Cookies.set('setUpgrade', true, {expires: 7});
    },
    getLastFromArray(arr) {
      return arr[arr.length - 1];
    },
    getStringWithoutSpaces(str) {
      return str.replace(/\s/g, '');
    },
    getBodyTextWithoutSpaces() {
      return this.getStringWithoutSpaces(document.body.textContent);
    },
    getNumberOfSentenceInBodyText(sentence) {
      var number = 0;
      var nextIndex = 0;
      while ((nextIndex = this.getBodyTextWithoutSpaces().indexOf(this.getStringWithoutSpaces(sentence), nextIndex)) >= 0) {
        number++;
        nextIndex++;
      }
      return number;
    },
    getSurroundFromSelection(length) {
      var result = {
        prev: "",
        next: ""
      };
      var walker = document.createTreeWalker(document.body, NodeFilter.SHOW_TEXT, null, false);
      var targetRange = getSelection().getRangeAt(0);
      var startOffset = targetRange.startOffset;
      var endOffset = targetRange.endOffset;

      if ((walker.currentNode = targetRange.startContainer).nodeType != Node.TEXT_NODE) {
        walker.currentNode = targetRange.startContainer.childNodes[startOffset] || targetRange.endContainer.childNodes[0];
        walker.previousNode();
        startOffset = walker.currentNode.textContent.length;
      }
      result['prev'] = walker.currentNode.textContent.substring(0, startOffset);

      while (this.getStringWithoutSpaces(result['prev']).length < length && walker.currentNode != null) {
        result['prev'] = walker.previousNode().textContent + result['prev'];
      }

      if ((walker.currentNode = targetRange.endContainer).nodeType != Node.TEXT_NODE) {

        walker.currentNode = targetRange.endContainer.childNodes[endOffset] || targetRange.endContainer.childNodes[0];

        walker.previousNode();

        walker.nextNode();

        endOffset = 0;
      }
      result['next'] = walker.currentNode.textContent.substring(endOffset, walker.currentNode.textContent.length);

      while (this.getStringWithoutSpaces(result['next']).length < length && walker.currentNode != null) {
        result['next'] = result['next'] + walker.nextNode().textContent;
      }

      result['prev'] = result['prev'].trimLeft();
      result['next'] = result['next'].trimRight();
      if (result['prev'].length >= length)
        result['prev'] = result['prev'].substring(result['prev'].length - length, result['prev'].length);
      if (result['next'].length >= length)
        result['next'] = result['next'].substring(0, length);

      return result;
    },
    getUniqueCoreFromSelection() {
      var LOOP_COUNT_LIMIT = 200; // This value is determined by an experimental test deriving [347, 117, 3, 3, 3, 121, 3, 594, 3, 5, 126, 18, 11, 5, 4, 5, 8, 12, 77, 13, 8, 15, 2, 2, 4, 5, 1, 4, 3, 4, 5, 128, 120, 130, 3, 5, 5, 154, 54, 5, 4, 3, 125, 132, 3, 3, 3, 6, 5, 142, 5, 32, 419, 4, 4, 168, 6, 5, 5, 5, 5, 3, 6, 3, 3, 3, 5, 6, 6, 11, 7, 4, 5, 4, 5, 3, 11, 10, 6, 5, 3, 11, 10, 5, 3, 10, 4, 7, 3, 3, 3, 7, 5, 195, 194, 24, 11, 3, 4, 8, 30]
      var surround;
      var prev = '';
      var next = '';
      var core = getSelection().getRangeAt(0).toString();
      var lengthOfCharactersSurroundingCore = 5;
      var loopCount = 0;

      do {
        if (prev == '' && next == '') {
          surround = this.getSurroundFromSelection(lengthOfCharactersSurroundingCore);
          prev = surround.prev;
          next = surround.next;
        } else {
          if (prev.length <= next.length) {
            lengthOfCharactersSurroundingCore++;
            surround = this.getSurroundFromSelection(lengthOfCharactersSurroundingCore);
            prev = surround.prev;
          } else {
            surround = this.getSurroundFromSelection(lengthOfCharactersSurroundingCore);
            next = surround.next;
          }
        }
        loopCount++;

      } while (this.getNumberOfSentenceInBodyText(prev + core + next) != 1 ||
        this.getStringWithoutSpaces(prev).length < 5 ||
        this.getStringWithoutSpaces(next).length < 5 ||
        (this.getStringWithoutSpaces(prev[0]).length <= 0 && loopCount < LOOP_COUNT_LIMIT) ||
        (this.getStringWithoutSpaces(this.getLastFromArray(next)).length <= 0 && loopCount < LOOP_COUNT_LIMIT));

      return {
        core: core,
        prev: prev.trimLeft(),
        next: next.trimRight()
      };
    },
    changeCurrUser(curr) {
      this.currUser = curr;
    },
    syncPurchaseStat() {
      const refresh = Cookies.get('refreshState');
      const upgrade = Cookies.get('setUpgrade');
      if (this.currUser && (upgrade || !refresh)) {
        axios.get("/api/members/me?refresh=true")
        .then((res)=>{
          if(res.data.results.purchase_stat != this.currUser.purchase_stat){
            this.crawling = true;
            this.ifSideAuto = false;
            if (window.innerWidth > 768) {
              markups_jQuery('.GDPR').css('width', '100vw');
            }
          }
          this.changeCurrUser(res.data.results);
          Cookies.set('refreshState', true);
          Cookies.remove('setUpgrade');
        })
        .catch(function(err){
          console.log(err);
          Cookies.set('refreshState', true);
        });
      }
    },
    enterForm() {
      let url = window.location.search;
      let theRequest = new Object();
      if (url.indexOf("?") != -1) {
        let str = url.substr(1);
        let strs = str.split("&");
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = decodeURI(strs[i].split("=")[1]);
        }
      };
      if (Object.keys(theRequest).length != 0) {
        this.enter = theRequest.enter || 'web';
      }
    },
    login_login() {
      //utm携带到会员系统
      var arrStr = document.cookie.split("; ");
      for (var i = 0; i < arrStr.length; i++) {
        var temp = arrStr[i].split("=");
        if (temp[0] == 'utmutm') {
          window.location = '/auth/kdan_mobile?' + arrStr[i].substr(8) + '&lang=' + this.lang;
          return
        }
      }
      window.location.href = '/auth/kdan_mobile?lang=' + this.lang;
    },
    select() {
      if (this.currUser) {
        var url = window.location.search;
        if (this.outside.webpage.my_web) {
          this.ifMine = true;
        } else if (url.indexOf('markups') > -1) {
          if (this.lang == 'en') {
            window.location.search = '?enter=share';
          } else {
            window.location.search = '?enter=share&lang=' + this.lang;
          }
        }
      }
    },
    convertUtf8toBase64(str) {
      return window.btoa(unescape(encodeURIComponent(str)));
    },
    convertBase64toUtf8(str) {
      return decodeURIComponent(escape(window.atob(str)));
    },
    styleList() {
      var targetItems = this.encoded_highlights.map(function(item) {
        return JSON.stringify({
          id: item.id,
          core: item.core,
          prev: item.prev,
          next: item.next,
          styleOptions: item.styleOptions,
          status: item.status,
          note: item.note
        });
      });
      var ut8_str =  '[' + targetItems.join(',') + ']';
      return this.convertUtf8toBase64(ut8_str);
    },
    getHighlights () {
      if (this.enter == 'markups' || this.enter == 'share') {
        MyMarkup.import(this.outside.encoded_highlights);
      }
      this.encoded_highlights = JSON.parse(decodeURIComponent(escape(window.atob(this.outside.encoded_highlights))));
      if (this.encoded_highlights.length == 0) {
        this.summaryEmpty = true;
      } else {
        this.summaryEmpty = false;
        this.finalDate = this.encoded_highlights[this.encoded_highlights.length - 1].status[0].regTime.substring(0,10);
      }
    },
    setHighlights () {
      this.encoded_highlights_clone = this.styleList();
    },
    async remove_article () {
      let that = this;
      await axios.post("/api/projects/delete_by_id", {"projectId": this.outside.webpage.projectId})
      .then((res)=>{
        if (that.lang == 'en') {
          window.location.href = "/mymarkups";
        } else {
          window.location.href = "/" + that.lang + "/mymarkups";
        }
        that.removeSuccess = true;
        setTimeout(function(){that.removeSuccess = false;},3000);
      })
      .catch(function(err){
      });
    },
    go_back_markups () {
      if (this.enter == 'markups' && this.currUser) {
        var cookieStr = document.cookie;
        if (cookieStr.length > 0) {
          var cookieArr = cookieStr.split(";");
          for (var i = 0; i < cookieArr.length; i++) {
            var cookieVal = cookieArr[i].split("=");
            if (cookieVal[0] == " markup_page") {
              if (this.lang == 'en') {
                window.location.href = "/mymarkups" + '?page=' + cookieVal[1];
              } else {
                window.location.href = "/" + this.lang + "/mymarkups" + '?page=' + cookieVal[1];
              }
              return
            }
          }
        }
        if (this.lang == 'en') {
          window.location.href = "/mymarkups";
        } else {
          window.location.href = "/" + this.lang + "/mymarkups";
        }
      } else {
        if (this.lang == 'en') {
          window.location.href = "/explore";
        } else {
          window.location.href = "/" + this.lang + "/explore";
        }
      }
    },
    side_open () {
      if (this.ifSide) {
        this.ifSide = false;
        this.ifBreadBottom = true;
        if (window.innerWidth > 768) {
          markups_jQuery('.GDPR').css('width', '100vw');
        }
      } else {
        this.ifSide = true;
        this.ifBreadBottom = false;
        if (window.innerWidth > 768) {
          if (this.isTablet) {
            if (window.innerWidth <= 1024) {
              markups_jQuery('.GDPR').css('width', 'calc(100vw - 308px)');
            } else {
              markups_jQuery('.GDPR').css('width', 'calc(100vw - 332px)');
            }
          } else {
            if (window.innerWidth <= 1024) {
              markups_jQuery('.GDPR').css('width', 'calc(100vw - 325px)');
            } else {
              markups_jQuery('.GDPR').css('width', 'calc(100vw - 349px)');
            }
          }
        }
      }
      this.ifSideAuto = false;
    },
    side_close () {
      this.ifHeader = true;
      this.ifSide = false;
      if (window.innerWidth > 768) {
        markups_jQuery('.GDPR').css('width', '100vw');
      }
      this.ifSideAuto = false;
      this.ifBreadBottom = true;
      this.limitColor = false;
    },
    bread_open () {
      this.ifBread = true;
    },
    bread_close () {
      this.ifBread = false;
    },
    async save() {
      if (!this.currUser) {
        this.saveOrSummary = true;
        this.dialogFormVisible3 = true;
        return;
      }
      let that = this;
      if (!this.throttle) {
        return
      }
      this.throttle = !this.throttle;

      if (this.ifMine) {
        let that = this;
        await axios.post("/api/projects/delete_by_id", {projectId: this.outside.webpage.my_web.projectId})
        .then((res)=>{
          this.throttle = !this.throttle;
          this.outside.webpage.my_web = null;
          this.ifMine = false;
          this.removeSuccess = true;
          setTimeout(function(){that.removeSuccess = false;}, 3000);
        })
        .catch(function(err){
          that.failed = true;
          setTimeout(function(){that.failed = false;}, 3000);
        });
        return;
      }
      let projects = {
        projects: [{
          originLink: this.outside.webpage.originLink,
          thumbnailLink: this.outside.webpage.thumbnailLink,
          title: this.outside.webpage.title,
          web_summarys: this.outside.webpage.web_summarys
        }]
      };
      axios.post("/api/projects/save_url",projects)
      .then((res)=>{
        this.outside.webpage.my_web = {projectId: res.data.results[0].projectId};
        this.ifMine = true;
        this.throttle = !this.throttle;
        this.savePath = res.data.results[0].sharing_path;
        this.saveSuccess = true;
        setTimeout(function(){that.saveSuccess = false;}, 3000);
      })
      .catch(function(err){
        that.failed = true;
        setTimeout(function(){that.failed = false;}, 3000);
      });
    },
    summary_open () {
      if (!this.currUser) {
        this.saveOrSummary = false;
        this.dialogFormVisible3 = true;
        return
      }
      if (!this.throttle) {
        return
      }
      this.throttle = !this.throttle;
      this.ifSide = false;
      if (!this.crawling) {
        this.throttle = !this.throttle;
        if (window.innerWidth <= 768) {
          this.ifHeader = false;
          this.ifBreadBottom = false;
        }
        this.ifSideAuto = true;
        if (window.innerWidth > 768) {
          if (this.isTablet) {
            if (window.innerWidth <= 1024) {
              markups_jQuery('.GDPR').css('width', 'calc(100vw - 308px)');
            } else {
              markups_jQuery('.GDPR').css('width', 'calc(100vw - 332px)');
            }
          } else {
            if (window.innerWidth <= 1024) {
              markups_jQuery('.GDPR').css('width', 'calc(100vw - 325px)');
            } else {
              markups_jQuery('.GDPR').css('width', 'calc(100vw - 349px)');
            }
          }
        }
        return
      }
      this.ifSummary = true;
      let summary = {
        "projectId": this.outside.webpage.projectId,
        "file_type": "web",
        "sentence": 5,
        "text": encodeURI(this.$refs.message.innerText)
      };
      this.cancelToken = axios.CancelToken;
      const cancelToken = this.cancelToken;
      this.source = this.cancelToken.source();
      const source = this.source;
      let that = this;
      let summary_api_host = (this.server_env == 'development' ? 'https://markups.preparing.kdanmobile.com' : this.api_host)
      axios.post(summary_api_host + "/api/projects/generate_summary",summary,{
        cancelToken: source.token
      })
      .then((res)=>{
        that.ifSide = false;
        that.crawling = false;
        that.summary_data = res.data.result.web_summarys;
        that.summary_data_one = that.summary_data.splice(0,1);
        that.summary_data_four = that.summary_data.splice(0,4);
        that.summary_data = that.summary_data_one.concat(that.summary_data_four);
        that.summary_close();
        if(window.innerWidth <= 768) {
          that.ifHeader = false;
          that.ifBreadBottom = false;
        }
        that.ifSideAuto = true;
      })
      .catch(function(err){
      });
    },
    add (i) {
      var summary = this.summary_data;
      var highlights = this.encoded_highlights;
      var len = this.encoded_highlights.length;
      for (var j = 0; j < len; j++){ 
        if (highlights[j].core == summary[i]) {
          return
        }
      }
      this.encoded_highlights.push(MyMarkup.autoHighlight(summary[i], 'rgb(255, 255, 137)'));
      this.setHighlights();
      MyMarkup.import(this.encoded_highlights_clone);
      let projects = {
        projects: [{projectId: this.outside.webpage.projectId,highlights: this.encoded_highlights_clone}]
      };
      axios.post("/api/projects/batch_upload", projects)
      .then((res)=>{
        if(this.encoded_highlights.length == 0){
          this.summaryEmpty = true;
        }else {
          this.summaryEmpty = false;
        }
      })
      .catch(function(err){
      });
    },
    summary_close () {
      this.source.cancel('Operation canceled by the user.');
      this.ifHeader = true;
      this.throttle = !this.throttle;
      this.ifSummary = false;
    },
    menuOpen (i,e) {
      this.menuIndex = i;
      if (window.innerWidth > 768) {
        if (markups_jQuery('.main-container .side')[0].offsetHeight + 64 - e.clientY < 120) {
          markups_jQuery('.main-container .side').animate({scrollTop: markups_jQuery('.main-container .side')[0].scrollTop + 100}, 500);
        }
      }
    },
    colorOpen (i,e) {
      this.menuIndex = -1;
      this.targetIndex = i;
      if (e.target.parentNode.parentNode.parentNode.style.borderColor == 'rgb(255, 243, 0)') {
        this.oldToolColor = 'rgb(255, 255, 137)';
      } else if (e.target.parentNode.parentNode.parentNode.style.borderColor == 'rgb(255, 190, 101)') {
        this.oldToolColor = 'rgb(255, 229, 188)';
      } else if (e.target.parentNode.parentNode.parentNode.style.borderColor == 'rgb(255, 180, 237)') {
        this.oldToolColor = 'rgb(255, 218, 246)';
      } else if (e.target.parentNode.parentNode.parentNode.style.borderColor == 'rgb(160, 246, 104)') {
        this.oldToolColor = 'rgb(214, 253, 189)';
      } else if (e.target.parentNode.parentNode.parentNode.style.borderColor == 'rgb(141, 205, 255)') {
        this.oldToolColor = 'rgb(198, 230, 255)';
      } else if (e.target.parentNode.parentNode.parentNode.style.borderColor == 'rgb(174, 171, 255)') {
        this.oldToolColor = 'rgb(216, 214, 255)';
      } else {
        this.oldToolColor = this.encoded_highlights[i].styleOptions.backgroundColor;
      }
      this.ifSideClick = true;
      this.overturn = false;
      this.toolLeft = e.clientX - 460;
      this.toolTop = e.clientY - 180;
      this.toolIndex = i;
    },
    menuClose () {
      this.menuIndex = -1;
    },
    noteOpen (i,e,ifMobile) {
      if (ifMobile) {
        this.drawer2 = false;
        markups_jQuery('.side .unit_block')[i].childNodes[4].setAttribute('style','display: block');
        markups_jQuery('.side .unit_block')[i].childNodes[4].lastElementChild.setAttribute('class', 'note_arrow arrow_up');
        markups_jQuery('.side .unit_block')[i].childNodes[6].setAttribute('style','display: block');
        markups_jQuery('.side .unit_block')[i].childNodes[6].value = this.encoded_highlights[i].note;
        if (!markups_jQuery('.side .unit_block')[i].childNodes[6].value) {
          // markups_jQuery('.side .unit_block')[i].childNodes[6].focus();
          markups_jQuery('.side .unit_block')[i].childNodes[8].lastElementChild.setAttribute('style','display: none');
        }
        markups_jQuery('.side .unit_block')[i].childNodes[8].setAttribute('style','display: block');
        markups_jQuery('.side').animate({scrollTop: markups_jQuery(".unit_block")[i].offsetTop + markups_jQuery(".unit_block .note")[i].offsetTop}, 500);
        return
      }
      this.menuIndex = -1;
      if (e.target.className.indexOf('icon_note') != -1) {
        e.target.parentNode.parentNode.childNodes[4].setAttribute('style','display: block');
        e.target.parentNode.parentNode.childNodes[4].lastElementChild.setAttribute('class', 'note_arrow arrow_up');
        e.target.parentNode.parentNode.childNodes[6].setAttribute('style','display: block');
        e.target.parentNode.parentNode.childNodes[6].value = this.encoded_highlights[i].note;
        if (!e.target.parentNode.parentNode.childNodes[6].value) {
          e.target.parentNode.parentNode.childNodes[6].focus();
          e.target.parentNode.parentNode.childNodes[8].lastElementChild.setAttribute('style','display: none');
        } else {
          if (e.target.parentNode.parentNode.childNodes[6].value == 'undefined') {
            e.target.parentNode.parentNode.childNodes[6].value = '';
          }
        }
        e.target.parentNode.parentNode.childNodes[8].setAttribute('style','display: block');
      } else {
        e.target.parentNode.parentNode.parentNode.childNodes[4].setAttribute('style','display: block');
        e.target.parentNode.parentNode.parentNode.childNodes[4].lastElementChild.setAttribute('class', 'note_arrow arrow_up');
        e.target.parentNode.parentNode.parentNode.childNodes[6].setAttribute('style','display: block');
        e.target.parentNode.parentNode.parentNode.childNodes[6].value = this.encoded_highlights[i].note;
        if (!e.target.parentNode.parentNode.parentNode.childNodes[6].value) {
          e.target.parentNode.parentNode.parentNode.childNodes[6].focus();
          e.target.parentNode.parentNode.parentNode.childNodes[8].lastElementChild.setAttribute('style','display: none');
        } else {
          if (e.target.parentNode.parentNode.parentNode.childNodes[6].value == 'undefined') {
            e.target.parentNode.parentNode.parentNode.childNodes[6].value = '';
          }
        }
        e.target.parentNode.parentNode.parentNode.childNodes[8].setAttribute('style','display: block');
      }
      markups_jQuery('.side').animate({scrollTop: markups_jQuery(".unit_block")[i].offsetTop + markups_jQuery(".unit_block .note")[i].offsetTop}, 500);
    },
    noteEdit (i,e) {
      if (e.target.className == 'note') {
        if (e.target.lastElementChild.className.indexOf('arrow_down') != -1) {
          e.target.lastElementChild.setAttribute('class', 'note_arrow arrow_up');
          e.target.nextElementSibling.setAttribute('style','display: block');
          e.target.nextElementSibling.nextElementSibling.setAttribute('style','display: block');
        } else {
          e.target.lastElementChild.setAttribute('class', 'note_arrow arrow_down');
          e.target.nextElementSibling.setAttribute('style','display: none');
          e.target.nextElementSibling.nextElementSibling.setAttribute('style','display: none');
        }
      } else {
        if (e.target.parentNode.lastElementChild.className.indexOf('arrow_down') != -1) {
          e.target.parentNode.lastElementChild.setAttribute('class', 'note_arrow arrow_up');
          e.target.parentNode.nextElementSibling.setAttribute('style','display: block');
          e.target.parentNode.nextElementSibling.nextElementSibling.setAttribute('style','display: block');
        } else {
          e.target.parentNode.lastElementChild.setAttribute('class', 'note_arrow arrow_down');
          e.target.parentNode.nextElementSibling.setAttribute('style','display: none');
          e.target.parentNode.nextElementSibling.nextElementSibling.setAttribute('style','display: none');
        }
      }
    },
    changeNote (i,e,isBlur,ifDel) {
      if (!isBlur) {
        if (ifDel) {
          this.toolTextarea = false;
          e.target.parentNode.previousElementSibling.value = '';
          let note = {
            'note': ''
          };
          axios.put("/api/projects/highlights/" + this.encoded_highlights[i].id, JSON.stringify(note),{headers:{'Content-Type':'application/json'}})
          .then((res)=>{
            this.encoded_highlights[i].note = '';
            //控制note icon显示隐藏
            let notes = this.encoded_highlights;
            var id = notes[i].id;
            let markups = markups_jQuery("markups");
            if (!notes[i].note) {
              for (var j = 0; j < markups.length; j++) {
                if (markups[j].dataset.id.slice(3) == id) {
                  if (markups_jQuery("markups")[j].nextElementSibling && markups_jQuery("markups")[j].nextElementSibling.className == 'markup_note') {
                    markups_jQuery("markups")[j].nextElementSibling.remove();
                  }
                  return
                }
              }
            }
          })
          .catch(function(err){
          });
          e.target.parentNode.setAttribute('style','display: none');
          e.target.parentNode.parentNode.childNodes[6].setAttribute('style','display: none');
          e.target.parentNode.parentNode.childNodes[4].lastElementChild.setAttribute('class', 'note_arrow arrow_down');
          e.target.parentNode.parentNode.childNodes[4].setAttribute('style','display: none');
        } else {
          let note = {
            'note': e.target.parentNode.previousElementSibling.value
          };
          axios.put("/api/projects/highlights/" + this.encoded_highlights[i].id, JSON.stringify(note),{headers:{'Content-Type':'application/json'}})
          .then((res)=>{
            this.encoded_highlights[i].note = e.target.parentNode.previousElementSibling.value;
            if (e.target.parentNode.previousElementSibling.value) {
              e.target.parentNode.setAttribute('style','display: block');
              e.target.parentNode.previousElementSibling.setAttribute('style','display: block');
              e.target.parentNode.previousElementSibling.previousElementSibling.lastElementChild.setAttribute('class', 'note_arrow arrow_up');
            }
            //控制note icon显示隐藏
            let notes = this.encoded_highlights;
            var id = notes[i].id;
            let markups = markups_jQuery("markups");
            if (notes[i].note) {
              for (var j = 0; j < markups.length; j++) {
                if (markups[j].dataset.id.slice(3) == id) {
                  if (markups_jQuery("markups")[j].nextElementSibling && markups_jQuery("markups")[j].nextElementSibling.className == 'markup_note') {
                    return
                  } else {
                    var b = document.createElement("div");
                    b.className = "markup_note";
                    markups_jQuery("markups")[j].after(b);
                    return
                  }
                }
              }
            }
          })
          .catch(function(err){
          });
        }
      } else {
        let note = {
          'note': e.target.value
        };
        axios.put("/api/projects/highlights/" + this.encoded_highlights[i].id, JSON.stringify(note),{headers:{'Content-Type':'application/json'}})
        .then((res)=>{
          this.encoded_highlights[i].note = e.target.value;
          //控制note icon显示隐藏
          let notes = this.encoded_highlights;
          var id = notes[i].id;
          let markups = markups_jQuery("markups");
          if (e.target.value) {
            if (notes[i].note) {
              for (var j = 0; j < markups.length; j++) {
                if (markups[j].dataset.id.slice(3) == id) {
                  if (markups_jQuery("markups")[j].nextElementSibling && markups_jQuery("markups")[j].nextElementSibling.className == 'markup_note') {
                    return
                  } else {
                    var b = document.createElement("div");
                    b.className = "markup_note";
                    markups_jQuery("markups")[j].after(b);
                    return
                  }
                }
              }
            }
          } else {
            if (!notes[i].note) {
              for (var j = 0; j < markups.length; j++) {
                if (markups[j].dataset.id.slice(3) == id) {
                  if (markups_jQuery("markups")[j].nextElementSibling && markups_jQuery("markups")[j].nextElementSibling.className == 'markup_note') {
                    markups_jQuery("markups")[j].nextElementSibling.remove();
                  }
                  return
                }
              }
            }
          }
        })
        .catch(function(err){
        });
        if (!e.target.value) {
          // e.target.setAttribute('style','display: none');
          // e.target.nextElementSibling.setAttribute('style','display: none');
          e.target.previousElementSibling.lastElementChild.setAttribute('class', 'note_arrow arrow_up');
        }
      }
    },
    syncNote (i,ifDel) {
      if (ifDel) {
        this.noteContent = '';
        markups_jQuery('.unit_block .note')[i].setAttribute('style','display: none');
        markups_jQuery('.unit_block .note_textarea')[i].setAttribute('style','display: none');
        markups_jQuery('.unit_block .note_bottom')[i].setAttribute('style','display: none');
      };
      if (this.noteContent && !this.encoded_highlights[i].note) {
        for (var j = 0; j < markups_jQuery('.side .unit_block').length; j++) {
          markups_jQuery('.side .unit_block')[i].childNodes[4].lastElementChild.setAttribute('class', 'note_arrow arrow_down');
        }
      };
      let note = {
        'note': this.noteContent
      };
      axios.put("/api/projects/highlights/" + this.encoded_highlights[i].id, JSON.stringify(note),{headers:{'Content-Type':'application/json'}})
      .then((res)=>{
        this.encoded_highlights[i].note = this.noteContent;
        this.menuIndex = -1;
        //控制note icon显示隐藏
        let notes = this.encoded_highlights;
        var id = notes[i].id;
        let markups = markups_jQuery("markups");
        if (this.noteContent) {
          //同步侧边栏
          for (var j = 0; j < markups_jQuery('.side .unit_block').length; j++) {
            markups_jQuery('.side .unit_block')[i].childNodes[6].value = this.encoded_highlights[i].note;
            markups_jQuery('.side .unit_block')[i].childNodes[4].setAttribute('style','display: block');
          }
          if (notes[i].note) {
            for (var j = 0; j < markups.length; j++) {
              if (markups[j].dataset.id.slice(3) == id) {
                if (markups_jQuery("markups")[j].nextElementSibling && markups_jQuery("markups")[j].nextElementSibling.className == 'markup_note') {
                  return
                } else {
                  var b = document.createElement("div");
                  b.className = "markup_note";
                  markups_jQuery("markups")[j].after(b);
                  return
                }
              }
            }
          }
        } else {
          if (!notes[i].note) {
            markups_jQuery('.unit_block .note')[i].setAttribute('style','display: none');
            markups_jQuery('.unit_block .note_textarea')[i].setAttribute('style','display: none');
            markups_jQuery('.unit_block .note_bottom')[i].setAttribute('style','display: none');
            for (var j = 0; j < markups.length; j++) {
              if (markups[j].dataset.id.slice(3) == id) {
                if (markups_jQuery("markups")[j].nextElementSibling && markups_jQuery("markups")[j].nextElementSibling.className == 'markup_note') {
                  markups_jQuery("markups")[j].nextElementSibling.remove();
                }
                return
              }
            }
          }
        }
      })
      .catch(function(err){
      });
      this.toolTextarea = false;
    },
    scrollAnnotation (item,e) {
      if (e.target.className == 'note_textarea' || e.target.className == 'note_bottom' || e.target.parentNode.className == 'note_bottom') {
        return
      }
      if (window.innerWidth <= 768) {
        markups_jQuery('html, body').animate({scrollTop: markups_jQuery("markups[data-id=mms"+item.id+"]").offset().top - 44}, 500);
        this.ifSide = false;
        this.ifHeader = true;
        this.ifBreadBottom = true;
        var body = document.getElementsByTagName("body")[0];
        body.style['overflow-y'] = 'visible';
      } else {
        markups_jQuery('html, body').animate({scrollTop: markups_jQuery("markups[data-id=mms"+item.id+"]").offset().top - 64}, 500);
      }
    },
    menuAndTextareaColor (color) {
      if (color == 'yellow' || color == 'rgb(255, 255, 137)') {
        document.getElementsByClassName('menu_color')[0].setAttribute('style','background-color: #fff300');
        document.getElementsByClassName('textarea_note')[0].setAttribute('class','textarea_note yellow_note');
      } else if (color == 'orange' || color == 'rgb(255, 229, 188)') {
        document.getElementsByClassName('menu_color')[0].setAttribute('style','background-color: #ffbe65');
        document.getElementsByClassName('textarea_note')[0].setAttribute('class','textarea_note orange_note');
      } else if (color == 'pink' || color == 'rgb(255, 218, 246)') {
        document.getElementsByClassName('menu_color')[0].setAttribute('style','background-color: #ffb4ed');
        document.getElementsByClassName('textarea_note')[0].setAttribute('class','textarea_note pink_note');
      } else if (color == 'green' || color == 'rgb(214, 253, 189)') {
        document.getElementsByClassName('menu_color')[0].setAttribute('style','background-color: #a0f668');
        document.getElementsByClassName('textarea_note')[0].setAttribute('class','textarea_note green_note');
      } else if (color == 'blue' || color == 'rgb(198, 230, 255)') {
        document.getElementsByClassName('menu_color')[0].setAttribute('style','background-color: #8dcdff');
        document.getElementsByClassName('textarea_note')[0].setAttribute('class','textarea_note blue_note');
      } else if (color == 'gray' || color == 'rgb(216, 214, 255)') {
        document.getElementsByClassName('menu_color')[0].setAttribute('style','background-color: #aeabff');
        document.getElementsByClassName('textarea_note')[0].setAttribute('class','textarea_note gray_note');
      }
    },
    change_color (i,color,ifFixed,ifMenu) {
      var oldWhatColor = this.whatColor;
      var oldWhichColor = this.whichColor;
      if (color == 'yellow') {
        this.encoded_highlights[i].styleOptions.backgroundColor = 'rgb(255, 255, 137)';
        this.whatColor = 'rgb(255, 255, 137)';
      } else if(color == 'orange') {
        this.encoded_highlights[i].styleOptions.backgroundColor = 'rgb(255, 229, 188)';
        this.whatColor = 'rgb(255, 229, 188)';
      } else if(color == 'pink') {
        this.encoded_highlights[i].styleOptions.backgroundColor = 'rgb(255, 218, 246)';
        this.whatColor = 'rgb(255, 218, 246)';
      } else if(color == 'green') {
        this.encoded_highlights[i].styleOptions.backgroundColor = 'rgb(214, 253, 189)';
        this.whatColor = 'rgb(214, 253, 189)';
      } else if(color == 'blue') {
        this.encoded_highlights[i].styleOptions.backgroundColor = 'rgb(198, 230, 255)';
        this.whatColor = 'rgb(198, 230, 255)';
      } else if(color == 'gray') {
        this.encoded_highlights[i].styleOptions.backgroundColor = 'rgb(216, 214, 255)';
        this.whatColor = 'rgb(216, 214, 255)';
      }
      this.borderNumber(this.encoded_highlights[this.colorIndex].styleOptions.backgroundColor, true);
      this.drawer3 = false;
      this.setHighlights();
      MyMarkup.import(this.encoded_highlights_clone);
      this.borderNumber(this.encoded_highlights[this.toolIndex].styleOptions.backgroundColor);
      if (this.currUser && !this.currUser.purchase_stat && (color != 'yellow' && color != 'orange')) {
        if (ifFixed) {this.onfocus = this.encoded_highlights[i].id;}
        this.limitColor = true;
        if (ifFixed) {
          this.ifFixed = true;
        }
        this.whatColor = oldWhatColor;
        this.whichColor = oldWhichColor;
        if (this.oldColorSwitch) {
          this.oldToolColor = oldWhatColor;
        }
        this.oldIndex = i;
        return
      } else if (this.currUser && !this.currUser.purchase_stat && color == 'yellow') {
        this.oldToolColor = 'rgb(255, 255, 137)';
        this.oldIndex = i;
      } else if (this.currUser && !this.currUser.purchase_stat && color == 'orange') {
        this.oldToolColor = 'rgb(255, 229, 188)';
        this.oldIndex = i;
      }
      let projects = {
        projects: [{projectId: this.outside.webpage.projectId,highlights: this.encoded_highlights_clone}]
      };
      axios.post("/api/projects/batch_upload", projects)
      .then((res)=>{
        MyMarkup.import(this.encoded_highlights_clone);
      })
      .catch(function(err){
      });
      this.menuAndTextareaColor(color);
      if (ifMenu) {
        this.toolColor = false;
        this.toolMenu = true;
      }
    },
    clickMore (index,e) {
      this.colorIndex = index;
      this.drawer2 = true;
      this.borderNumber(this.encoded_highlights[index].styleOptions.backgroundColor, true);
      this.ifSideClick = false;
      this.toolLeft = 300;
      this.toolTop = 150;
      this.toolIndex = index;
    },
    openNote (i) {
      this.toolMenu = false;
      markups_jQuery('.textarea_note .textarea_foot span')[0].setAttribute('style','display: none');
      this.toolTextarea = true;
      if (this.encoded_highlights[i].note) {
        this.noteContent = this.encoded_highlights[i].note;
      } else {
        this.noteContent = '';
        setTimeout(function(){
          markups_jQuery('.textarea_note textarea')[0].focus();
        },5);
      }
    },
    del (i) {
      this.menuIndex = -1;
      let statu = {code: 1,regTime: new Date()};
      this.encoded_highlights[i].status.push(statu);
      this.setHighlights();
      //删除note_icon
      let notes = this.encoded_highlights;
      var id = notes[i].id;
      let markups = markups_jQuery("markups");
      for (var j = 0; j < markups.length; j++) {
        if (markups[j].dataset.id.slice(3) == id) {
          if (markups_jQuery("markups")[j].nextElementSibling && markups_jQuery("markups")[j].nextElementSibling.className == 'markup_note') {
            markups_jQuery("markups")[j].nextElementSibling.remove();
          }
          break
        }
      }
      let projects = {
        projects: [{projectId: this.outside.webpage.projectId,highlights: this.encoded_highlights_clone}]
      };
      axios.post("/api/projects/batch_upload", projects)
      .then((res)=>{
        MyMarkup.doRemoveStyle(this.encoded_highlights[i].id);
        this.encoded_highlights.splice(i, 1);
        this.drawer2 = false;
        if (this.encoded_highlights.length == 0) {
          this.summaryEmpty = true;
        } else {
          this.summaryEmpty = false;
        }
      })
      .catch(function(err){
      });
      this.toolMenu = false;
    },
    remove() {
      this.ifBreadBottom = false;
      this.dialogFormVisible4 = true;
    },
    share() {
      if (window.innerWidth < 768) {
        this.drawer1 = true;
        this.ifBreadBottom = false;
      } else {
        this.ifShare = true;
      }
    },
    copy () {
     var clipboard = new Clipboard('.form_btn')
      clipboard.on('success', e => {
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        // 不支持复制
        this.$message('Copy Failed');
        // 释放内存
        clipboard.destroy()
      })
      if(window.innerWidth <= 768) {
        this.$message({
          message: 'Copied Successfully',
          type: 'success',
          offset: 56
        });
      }
      this.ifBreadBottom = true;
      this.drawer1 = false;
      this.copied = true;
      let that = this;
      setTimeout(function(){that.copied = false;},3000);
    },
    upgradeOpen() {
      this.ifUpgrade = true;
      // window.open(window.location.origin + '/pricing');
    },
    handleClose(tag) {
      this.oldTags.splice(this.oldTags.indexOf(tag), 1);
    },
    handleSelect (item) {
      if(this.oldTags.indexOf(item) == -1) {
        this.oldTags.push(item.trim());
      }
      this.addTag = '';
    },
    changeUrl (params) {
      if (params) {
        this.url = this.url + '?enter=share';
      } else {
        this.url = this.url.replace("?enter=share","");
      }
    },
    copyAll () {
      if(this.copyWords) {
        return
      }
      let value = '';
      for (let i = 0; i < this.encoded_highlights.length; i++){
        value += this.encoded_highlights[i].core + '\n';
      }
      //复制到剪切板
      const text = document.createElement('textarea');
      text.value = value;
      document.body.appendChild(text);
      text.select();
      document.execCommand('Copy');
      text.remove();

      this.copyWords = true;
      let that = this;
      setTimeout(function(){that.copyWords = false;},3000);
    },
    borderNumber (bgcolor, ifWhichColor) {
      if (bgcolor == 'rgb(255, 255, 137)') {
        if (ifWhichColor) {
          this.whichColor = 0;
        } else {
          this.colorBorder = 0;
        }
      } else if (bgcolor == 'rgb(255, 229, 188)') {
        if (ifWhichColor) {
          this.whichColor = 1;
        } else {
          this.colorBorder = 1;
        }
      } else if (bgcolor == 'rgb(255, 218, 246)') {
        if (ifWhichColor) {
          this.whichColor = 2;
        } else {
          this.colorBorder = 2;
        }
      } else if (bgcolor == 'rgb(214, 253, 189)') {
        if (ifWhichColor) {
          this.whichColor = 3;
        } else {
          this.colorBorder = 3;
        }
      } else if (bgcolor == 'rgb(198, 230, 255)') {
        if (ifWhichColor) {
          this.whichColor = 4;
        } else {
          this.colorBorder = 4;
        }
      } else if (bgcolor == 'rgb(216, 214, 255)') {
        if (ifWhichColor) {
          this.whichColor = 5;
        } else {
          this.colorBorder = 5;
        }
      }
    },
    toolClick (e) {
      this.ifFixed = false;
      this.ifTool = false;
      this.limitColor = false;
      this.limitCount = false;
      this.toolColor = false;
      this.toolMenu = false;
      this.toolTextarea = false;
      if (e.target.className != 'four_color' && e.target.parentNode.className != 'four_color' ) {
        this.targetIndex = -1;
      }
      if (e.button == 0) {
        window.getSelection().empty();
      }
      if (this.currUser && !this.currUser.purchase_stat && this.oldIndex !== '') {
        this.encoded_highlights[this.oldIndex].styleOptions.backgroundColor = this.oldToolColor;
        this.setHighlights();
        MyMarkup.import(this.encoded_highlights_clone);
        this.borderNumber(this.encoded_highlights[this.toolIndex].styleOptions.backgroundColor);
        this.oldIndex = '';
      }
      const upgrade = Cookies.get('setUpgrade');
      if (upgrade) {
        this.syncPurchaseStat();
      }
    },
    pencilShow () {
      var txt = window.getSelection();
      this.txt = txt.toString();
      if (txt.toString().length > 0) {
        var rect = window.getSelection().getRangeAt(0).getClientRects();
        var coordinate = rect[rect.length - 1];
        var leftPosition = coordinate.x + coordinate.width;
        if (this.ifMine && location.search.indexOf('enter=markups') != -1) {
          var topPosition = parseInt(window.scrollY + coordinate.y + 26);
          this.toolLeft = leftPosition;
          this.toolTop = topPosition - 70;
          this.ifTool = true;
        } else {
          var topPosition = parseInt(window.scrollY + coordinate.y + 26);
          if (window.innerWidth <= 1024) {
            if (window.innerWidth - leftPosition - 311 < 354) {
              if (this.ifside || this.ifSideAuto) {
                this.toolLeft = 100;
              } else {
                this.toolLeft = 200;
              }
            } else {
              this.toolLeft = leftPosition;
            }
          } else {
            if (window.innerWidth - leftPosition - 311 < 376) {
              this.toolLeft = leftPosition - 311;
            } else {
              this.toolLeft = leftPosition;
            }
          }
          if ((window.innerHeight - coordinate.y) < 160) {
            this.toolTop = topPosition - 234;
          } else {
            this.toolTop = topPosition - 70;
          }
          this.ifHighlight = true;
        }
      } else {
        this.ifHighlight = false;
      }
    },
    skip () {
      this.ifOpen = true;
      if (this.currUser) {
        if(this.ifMine){
          window.location = this.api_host + '/sharings/' + this.outside.webpage.shareId + '?enter=markups';
        } else {
          let projects = {
            projects: [{
              originLink: this.outside.webpage.originLink,
              thumbnailLink: this.outside.webpage.thumbnailLink,
              title: this.outside.webpage.title,
              web_summarys: this.outside.webpage.web_summarys
            }]
          };
          axios.post("/api/projects/save_url", projects)
          .then((res)=>{
            this.outside.webpage.my_web = {projectId: res.data.results[0].projectId};
            this.ifMine = true;
            this.throttle = !this.throttle;
            window.location = this.api_host + res.data.results[0].sharing_path + '?enter=markups';
          })
          .catch(function(err){
          });
        }
      } else {
        //utm携带到会员系统
        var arrStr = document.cookie.split("; ");
        for (var i = 0; i < arrStr.length; i++) {
          var temp = arrStr[i].split("=");
          if (temp[0] == 'utmutm') {
            window.location = '/auth/kdan_mobile?' + arrStr[i].substr(8) + '&lang=' + this.lang;
            return
          }
        }
        window.location = '/auth/kdan_mobile?lang=' + this.lang;
      }
    },
    addHighlight (e) {
      this.ifTool = false;
      this.oldColorSwitch = true;
      if((((document.documentElement.clientHeight == 0) ? document.body.clientHeight : document.documentElement.clientHeight) - e.clientY) < 220) {
        this.overturn = true;
        this.overturntop = e.pageY - 290;
      } else {
        this.overturn = false;
      }
      if (this.currUser && this.encoded_highlights.length >= 8 && !this.currUser.purchase_stat) {
        this.limitCount = true;
        return
      } else {
        this.limitCount = false;
      }
      this.menuAndTextareaColor(this.whatColor);
      this.toolMenu = true;
      this.ifSideClick = false;
      var txt = this.txt;
      var highlights = this.encoded_highlights;
      var len = this.encoded_highlights.length;
      for (var j = 0; j < len; j++){ 
        if(highlights[j].core == txt){
          return
        }
      }
      this.encoded_highlights.push(MyMarkup.autoHighlight(txt, this.whatColor));
      this.encoded_highlights[this.encoded_highlights.length - 1].prev = this.getUniqueCoreFromSelection().prev;
      this.encoded_highlights[this.encoded_highlights.length - 1].next = this.getUniqueCoreFromSelection().next;
      this.setHighlights();
      MyMarkup.import(this.encoded_highlights_clone);
      let projects = {
        projects: [{projectId: this.outside.webpage.projectId,highlights: this.encoded_highlights_clone}]
      };
      axios.post("/api/projects/batch_upload", projects)
      .then((res)=>{
        if (this.encoded_highlights.length == 0) {
          this.summaryEmpty = true;
        } else {
          this.summaryEmpty = false;
          this.toolIndex = this.encoded_highlights.length - 1;
        }
      })
      .catch(function(err){
      });

      //取消选中内容
      window.getSelection ? window.getSelection().removeAllRanges() : document.selection.empty();
      this.txt = '';
      this.toolIndex = this.encoded_highlights.length - 1;
      this.borderNumber(this.encoded_highlights[this.toolIndex].styleOptions.backgroundColor);
    },
    childByValue (ifCookie) {
      this.ifCookie = ifCookie;
    },
    highlightClick (e) {
      if (e.target.className == 'markup_note') {
        this.toolLeft = e.pageX - 80;
        this.toolTop = e.pageY - 48;
        var id = e.target.previousElementSibling.dataset.id.slice(3);
        for (var i = 0; i < this.encoded_highlights.length; i++) {
          if (this.encoded_highlights[i].id == id) {
            this.toolIndex = i;
            this.menuAndTextareaColor(this.encoded_highlights[i].styleOptions.backgroundColor);
            this.openNote(i);
          }
        }
        if (location.search.indexOf('enter=share') != -1) {
          markups_jQuery(".textarea_note textarea").attr("readonly", "readonly");
          setTimeout(function(){
            markups_jQuery(".textarea_foot").hide();
          },0);
        }
      }
      if (location.search.indexOf('enter=share') != -1 || window.getSelection().toString().length != 0) {
        return
      }
      if (window.innerWidth <= 768) {
        if (e.target.className == 'markup_note') {
          this.side_open();
        }
        return
      }
      if (e.target.dataset.id) {
        if (this.onfocus != e.target.dataset.id.slice(3)) {
          this.oldToolColor = e.target.style.backgroundColor;
        }
        this.onfocus = '';
        for (var i = 0; i < this.encoded_highlights.length; i++) {
          if (e.target.dataset.id.slice(3) == this.encoded_highlights[i].id) {
            this.toolLeft = e.pageX - 71;
            this.toolTop = e.pageY - 48;
            if ((((document.documentElement.clientHeight == 0) ? document.body.clientHeight : document.documentElement.clientHeight) - e.clientY) < 220) {
              this.overturn = true;
              this.overturntop = e.pageY - 246;
            } else {
              this.overturn = false;
            }
            this.menuAndTextareaColor(e.target.style.backgroundColor);
            this.toolMenu = true;
            this.oldColorSwitch = false;
            this.ifSideClick = false;
            this.toolIndex = i;
            this.borderNumber(this.encoded_highlights[i].styleOptions.backgroundColor);
          }
        }
      }
    },
    openApp () {
      if (this.lang == 'zh-tw') {
        window.location.href = 'https://apps.apple.com/tw/app/apple-store/id936601959?mt=8';
      } else if (this.lang == 'zh-cn') {
        window.location.href = 'https://apps.apple.com/cn/app/apple-store/id936601959?mt=8';
      } else if (this.lang == 'es') {
        window.location.href = 'https://apps.apple.com/es/app/apple-store/id936601959?mt=8';
      } else {
        window.location.href = 'https://apps.apple.com/app/apple-store/id936601959?mt=8';
      }
    },
    ifPop () {
      var strCookies = document.cookie;
      var array = strCookies.split(';');
      for (var i = 0; i < array.length; i++) {
        var item = array[i].split("=");
        if (item[0] == ' markup_app') {
          return
        }
      }
      if(window.getSelection().toString().length > 0) {
        var selection = global.getSelection();
        selection.removeAllRanges();
        this.ifAppStore = true;
        this.ifShadow = true;
        var exp = new Date();
        exp.setTime(exp.getTime() + 600 * 144000);
        document.cookie = 'markup_app' + "=" + 'true' + ";expires=" + exp.toGMTString() + ";path=/";
      }
    },
    closeLimit () {
      this.ifFixed = false;
      this.ifTool = false;
      this.limitColor = false;
      this.limitCount = false;
      this.toolColor = false;
      if(this.currUser && !this.currUser.purchase_stat && this.oldIndex !== '') {
        this.encoded_highlights[this.oldIndex].styleOptions.backgroundColor = this.oldToolColor;
        this.setHighlights();
        MyMarkup.import(this.encoded_highlights_clone);
        this.borderNumber(this.encoded_highlights[this.toolIndex].styleOptions.backgroundColor);
        this.oldIndex = '';
      }
      const upgrade = Cookies.get('setUpgrade');
      if (upgrade) {
        this.syncPurchaseStat();
      }
    },
    async getTagList () {
      let that = this;
      await axios.get('/api/web_tags')
      .then(function(response){
        that.tagList = response.data.results;
      })
      .catch(function(err){
      });
    },
    open_tag () {
      this.oldTags = this.tags.slice();
      this.dialogFormVisible6 = true;
    },
    async changeTag () {
      this.tags = this.oldTags.slice();
      if (this.addTag) {
        this.tags.push(this.addTag.trim());
      }
      this.addTag = '';
      let that = this;
      await axios.post("/api/projects/update_tags", {"projectId": this.outside.webpage.projectId, "tags": this.tags})
      .then((res)=>{
        this.dialogFormVisible6 = false;
        this.getTagList();
      })
      .catch(function(err){
        that.failed = true;
        setTimeout(function(){that.failed = false;},3000);
      });
    },
    tagClose (tag) {
      this.oldTags.splice(this.oldTags.indexOf(tag), 1);
    },
    focusBorder () {
      var length = document.getElementsByClassName('top').length;
      for(var i = 0; i < length; i++){
        document.getElementsByClassName('top')[i].classList.add('focus');
      }
    },
    blurBorder () {
      var length = document.getElementsByClassName('top').length;
      for(var i = 0; i < length; i++){
        document.getElementsByClassName('top')[i].classList.remove('focus');
      }
    },
    suggestSearch() {
      this.suggestList = [];
      var eqNum = 0;
      if(this.addTag && this.addTag.trim()){
        for (var i = 0; i < this.tagList.length; i++) {
          if(this.tagList[i].name.toLowerCase().indexOf(this.addTag.toLowerCase()) > -1) {
            this.suggestList.push(this.tagList[i].name);
          }
          if (this.addTag.toLowerCase() == this.tagList[i].name.toLowerCase()) {
            eqNum++;
          }
        }
        if (eqNum > 0) {
          this.showSuggest = false;
        } else {
          this.showSuggest = true;
        }
        let topDom = document.getElementsByClassName('addTop');
        var width = topDom[topDom.length - 1].offsetWidth;
        var top = topDom[topDom.length - 1].offsetHeight;
        let suggestListDom = document.getElementsByClassName('suggestList');
        suggestListDom[suggestListDom.length - 1].style.width = width + 'px';
        suggestListDom[suggestListDom.length - 1].style.top = top + 'px';
      }
    },
    showSave (pageOrSide,e) {
      if (pageOrSide == 1) {
        markups_jQuery('.textarea_note .textarea_foot span').css('display','none');
        clearTimeout(this.timer);
        this.timer = setTimeout(function(){
          markups_jQuery('.textarea_note .textarea_foot span').css('display','block');
        },500);
      } else if (pageOrSide == 2) {
        e.target.nextElementSibling.lastElementChild.setAttribute('style','display: none');
        clearTimeout(this.timer);
        this.timer = setTimeout(function(){
          if (e.target.value) {
            e.target.nextElementSibling.lastElementChild.setAttribute('style','display: block');
          }
        },500);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .dashboard-container{
    .GDPR {
      z-index: 201;
    }
    .empty {
      display: none;
    }
    textarea::-webkit-input-placeholder {
      color: #c1c1c1;
    }
    textarea:-moz-placeholder {
      color: #c1c1c1;
    }
    textarea::-moz-placeholder {
      color: #c1c1c1;
    }
    textarea:-ms-input-placeholder {
      color: #c1c1c1;
    }
    #removesuc, #savesuc {
      position: fixed;
      left: 36px;
      bottom: 36px;
      display: flex;
      align-items: center;
      padding: 14px 16px;
      border-radius: 5px;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.05);
      background-color: #dff4d2;
      z-index: 999;
      text-align: center;
      white-space: nowrap;
      img {
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }
      span {
        font-size: 14px;
        font-weight: 500;
        color: #28a60e;
      }
    }
    #fail {
      position: fixed;
      left: 36px;
      bottom: 36px;
      display: flex;
      align-items: center;
      padding: 14px 16px;
      border-radius: 5px;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.05);
      background-color: #ffe1dd;
      z-index: 999;
      text-align: center;
      img {
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }
      span {
        font-size: 14px;
        font-weight: 500;
        color: #ff4d36;
      }
    }
    .main-container {
      .webpage-error {
        flex: 3;
        margin: 0 auto;
        margin-top: 216px;
        text-align: center;
        font-size: 16px;
        line-height: 1.428571429;
        color: #343434;
        font-family: Roboto;
        .error-image {
          img {
            width: 240px;
            height: 180px;
            vertical-align: middle;
          }
        }
        .title {
          margin-top: 10px;
          font-size: 21px;
          font-weight: bold;
        }
        .message {
          margin-top: 20px;
          font-size: 16px;
          line-height: 1.5;
        }
        .source {
          margin-top: 20px;
          height: 40px;
          width: 138px;
          margin-left: auto;
          margin-right: auto;
          border: solid 1px #ff5f4b;
          border-radius: 5px;
          box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
          a {
            line-height: 38px;
            font-size: 16px;
            font-weight: bold;
            color: #ff5f4b;
            text-decoration: none;
          }
        }
      }
      /deep/ .el-drawer2, /deep/ .el-drawer3 {
        border-radius: 10px 10px 0 0;
        .el-drawer__header {
          display: none;
        }
      }
      /deep/ .el-drawer2 {
        padding: 13px 0 12px;
        height: 169px;
        .drawer_change {
          padding: 12px 14px 12px 20px;
          .drawer_img_pencil {
            width: 24px;
            height: 24px;
          }
          span {
            vertical-align: middle;
            font-size: 16px;
            color: var(--greyish-brown);
            margin-left: 12px;
          }
          .drawer_img_next {
            width: 30px;
            float: right;
          }
        }
        .drawer_del, .drawer_note {
          padding: 12px 0 12px 20px;
          img {
            width: 24px;
            height: 24px;
          }
          span {
            vertical-align: middle;
            font-size: 16px;
            color: var(--greyish-brown);
            margin-left: 12px;
          }
        }
      }
      /deep/ .el-drawer3 {
        padding: 21px 8px 31px;
        width: 100%;
        position: absolute;
        top: unset;
        bottom: 0;
        height: 148px;
        img {
          float: left;
          position: absolute;
        }
        p {
          padding: 6px 0 5px;
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          color: var(--greyish-brown);
        }
        .drawer_colors {
          margin: 0 auto;
          margin-top: 38px;
          .drawer_color {
            display: flex;
            justify-content: center;
            div {
              width: 28px;
              height: 28px;
              border-radius: 100%;
              margin: auto 13px;
            }
            .drawer_yellow{background-color: rgb(255, 243, 0);}
            .drawer_orange{background-color: rgb(255, 190, 101);}
            .drawer_pink{background-color: rgb(255, 180, 237);}
            .drawer_green{background-color: rgb(160, 246, 104);}
            .drawer_blue{background-color: rgb(141, 205, 255);}
            .drawer_gray{background-color: rgb(174, 171, 255);}
            .star {background-image: url('../../../assets/images/app_smart_bar/ic-pro-star.png');background-size: 16px 16px;background-position: center;background-repeat: no-repeat;}
            .active {border: 3.1px solid #afafaf;}
          }
        }
      }
      .shadow {
        position: fixed;
        top: 0;
        left:0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 2000;
      }
      header {
        position: fixed;
        left: 0;
        top: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 64px;
        padding: 0 38px 0 32px;
        z-index: 300;
        background-color: #fff;
        border-bottom: solid 1px #ececec;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);
        .title {
          display: flex;
          cursor: pointer;
          img {
            width: 36px;
            height: 36px;
          }
        }
        .btns {
          float: left;
          .btn {
            display: inline-block;
            margin-left: 26px;
            cursor: pointer;
            img{
              width: 36px;
              height: 36px;
              margin: auto 0;
            }
            span{
              line-height: 64px;
              font-size: 14px;
              color: #9d9d9d;
            }
          }
          .tag_btn {
            img {
              width: 26px;
              height: 26px;
              margin: 5px;
            }
          }
        }
        .flex-right {
          display: none;
        }
        .app {
          display: none;
          margin-top: 3px;
          margin-right: 16px;
          height: 30px;
          cursor: pointer;
          text-align: center;
          padding: 6px 12px;
          border-radius: 5px;
          background-color: #ff5f4b;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: -0.11px;
          color: #ffffff;
        }
        .bread {
          display: none;
          width: 36px;
          height: 36px;
          cursor: pointer;
        }
        .bread_box {
          position: absolute;
          top: 44px;
          right: 16px;
          border-radius: 2px;
          box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.26);
          background-color: #ffffff;
          .btn {
            display: flex;
            align-items: center;
            width: 136px;
            padding: 2px 6px 2px 4px;
            cursor: pointer;
            border-bottom: 1px solid #e1e1e1;
            img {
              width: 36px;
              height: 36px;
              margin: auto 0;
              vertical-align: top;
            }
            span {
              line-height: 20px;
              font-size: 14px;
              color: #9d9d9d;
              white-space: nowrap;
            }
            &.remove_btn {
              border-bottom: none;
            }
          }
          .tag_btn {
            img {
              width: 26px;
              height: 26px;
              margin: 5px;
            }
          }
        }
        .bread_long_box {
          .btn {
            width: 150px;
          }
        }
      }
      .bread_bottom {
        display: none;
      }
      /deep/ .login {
        .el-dialog {
          width: 420px;
          border-radius: 5px;
          .el-dialog__header{
            .el-dialog__title{
              font-size: 18px;
              font-weight: bold;
              color: #343434;
            }
          }
          .el-dialog__body{
            padding: 0 20px 20px 20px;
            .top{
              font-size: 14px;
              line-height: 1.43;
              color: #4b4b4b;
            }
            .bottom{
              margin-top: 30px;
              display: flex;
              justify-content: flex-end;
              .button {
                height: 40px;
                border-radius: 5px;
                font-size: 16px;
              }
              .button_login {
                background-color: #ff5f4b;
                color: #fff;
              }
              .button_login:hover {
                opacity: 0.8;
              }
              .button_login:active {
                background-color: #e65644;
              }
            }
          }
        }
      }
      /deep/ .remove {
        .el-dialog {
          width: 420px;
          border-radius: 5px;
          .el-dialog__header{
            .el-dialog__title{
              font-size: 18px;
              font-weight: bold;
              color: #343434;
            }
          }
          .el-dialog__body{
            padding: 0 20px 20px 20px;
            .top{
              width: 384px;
              font-size: 14px;
              line-height: 1.43;
              color: #4b4b4b;
            }
            .bottom{
              margin-top: 30px;
              display: flex;
              justify-content: flex-end;
              .button {
                height: 40px;
                border-radius: 5px;
                font-size: 16px;
              }
              .button_cancel {
                min-width: 89px;
                color: #838383;
                padding: 9px 0;
                border: 1px solid #d2d2d2;
                margin-right: 8px;
                background-color: #fff;
                font-weight: normal;
                text-align: center;
              }
              .button_remove {
                min-width: 98px;
                background-color: #ff5f4b;
                color: #fff;
                font-weight: bold;
                margin: 0;
                text-align: center;
              }
              .button_cancel:hover {
                background-color: #f5f5f5;
              }
              .button_remove:hover {
                opacity: 0.8;
              }
              .button_cancel:active {
                background-color: #e5e5e5;
              }
              .button_remove:active {
                background-color: #e65644;
              }
            }
          }
        }
      }
      /deep/ .open {
        .el-dialog {
          border-radius: 5px;
          .el-dialog__header {
            display: none;
          }
          .el-dialog__body{
            padding: 10px 14px;
            .open_a {
              display: flex;
              justify-content: flex-end;
              margin-top: 20px;
              a {
                font-size: 12px;
                color: #007aff;
              }
              .a_open {
                margin-left: 20px;
              }
            }
          }
        }
      }
      /deep/ .delete {
        .el-dialog {
          width: 100%;
          max-width: 343px;
          border-radius: 5px;
        }
        .el-dialog__header {
          display: none;
        }
        .el-dialog__body {
          border-radius: 5px;
          padding: 20px 14px 20px 20px;
          box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
          background-color: #ffffff;
          .delete_title {
            font-size: 18px;
            font-weight: bold;
            color: #343434;
          }
          .delete_text {
            margin-top: 12px;
            font-size: 14px;
            line-height: 1.43;
            color: #4b4b4b;
            word-break: keep-all;
          }
          .delete_btn {
            margin-top: 32px;
            display: flex;
            justify-content: flex-end;
            .button {
              width: 86px;
              height: 40px;
              border-radius: 5px;
              text-align: center;
              font-weight: normal;
            }
            .btn_cancel {
              min-width: 89px;
              padding: 9px 0;
              border: solid 1px #d2d2d2;
              font-size: 16px;
              color: #838383;
              text-align: center;
            }
            .btn_delete {
              min-width: 98px;
              background-color: #ff5f4b;
              font-size: 16px;
              font-weight: bold;
              color: #ffffff;
              margin-left: 8.4px;
              text-align: center;
            }
            .btn_cancel:hover {
              background-color: #f5f5f5;
            }
            .btn_delete:active {
              opacity: 0.8;
            }
            .btn_cancel:active {
              background-color: #e5e5e5;
            }
            .btn_delete:active {
              background-color: #e65644;
            }
          }
        }
      }
      /deep/ .addTag {
        .el-dialog {
          width: 100%;
          max-width: 480px;
          border-radius: 5px;
          .el-dialog__header{
            padding-top: 12px;
            padding: 20px;
            padding-bottom: 24px;
            .el-dialog__title{
              font-size: 18px;
              font-weight: bold;
              color: #343434;
            }
            button {
              font-size: 24px;
              i {
                vertical-align: top;
                color: rgb(68, 68, 68);
              }
            }
          }
          .el-dialog__body{
            position: relative;
            padding: 0 20px 20px 20px;
            .top{
              display: flex;
              flex-wrap: wrap;
              font-size: 14px;
              line-height: 1.43;
              color: #4b4b4b;
              border-radius: 5px;
              overflow: hidden;
              .el-input {
                width: 0;
                height: 26px;
                line-height: 26px;
                min-width: 30px;
                flex-grow: 1;
                text-overflow: ellipsis;
                margin-top: 8px;
                .el-input__inner {
                  height: 26px;
                  line-height: 26px;
                }
              }
            }
            .el-tag {
              position: relative;
              height: 26px;
              max-width: 100%;
              line-height: 18px;
              font-size: 13px;
              color: #717171;
              padding: 4px 21px 4px 8px;
              margin-right: 6px;
              border-radius: 6px;
              background-color: #f0f0f0;
              z-index: 400;
              margin-top: 8px;
              overflow: hidden;
              text-overflow: ellipsis;
              i {
                position: absolute;
                right: 0;
                top: 5px;
              }
              i:hover {
                background-color: #f0f0f0;
                color: #909399;
                font-weight: 700;
              }
            }
            .top {
              border: 2px solid #DCDFE6;
              padding: 3px 13px 11px;
              .el-input {
                display: inline-block;
                .el-input__inner {
                  border: 0;
                  padding: 0;
                }
              }
            }
            .top:hover {
              border: 2px solid #C0C4CC;
            }
            .focus {
              border: 2px solid #92959A !important;
            }
            .suggestList {
              position: absolute;
              overflow: hidden;
              top: 52px;
              left: 20px;
              box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
              border: solid 1px #e3e3e3;
              background-color: #fff;
              z-index: 10000;
              .scrollbar {
                overflow-y: scroll;
                height: 100%;
                margin-right: -17px;
                max-height: 162px;
                ul {
                  li {
                    display: block;
                    margin: 0;
                    cursor: pointer;
                    color: #343434;
                    font-size: 14px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: list-item;
                    text-align: -webkit-match-parent;
                    div {
                      padding: 6px 0 6px 12px;
                      img {
                        margin-right: 8px;
                      }
                    }
                  }
                  li:hover div {
                    background-color: #ededed;
                  }
                  .create {
                    border-top: 1px solid #e7e7e7;
                    padding: 6px 0 6px;
                  }
                  .hasMargin {
                    margin-top: 8px;
                  }
                }
              }
              .hasPadding {
                padding-top: 8px;
              }
            }
            .bottom {
              margin-top: 30px;
              display: flex;
              justify-content: flex-end;
              .button {
                height: 40px;
                min-width: 98px;
                font-size: 16px;
                font-weight: bold;
                text-align: center;
                color: #fff;
                margin: 0;
                border-radius: 5px;
                background-color: #ff5f4b;
              }
              .button:hover {
                opacity: 0.8;
              }
              .button:active {
                background-color: #e65644;
              }
            }
          }
        }
      }
      /deep/ .addTagMobile {
        .el-dialog {
          .el-dialog__body {
            .suggestList {
              .scrollbar {
                margin-bottom: 0;
                margin-right: 0;
              }
            }
          }
        }
      }
      .main {
        display: flex;
        position: relative;
        margin-top: 64px;
        .tool {
          position: absolute;
          cursor: pointer;
          z-index: 1299;
          img {
            width: 26px;
          }
        }
        .tool_color {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          width: 165px;
          height: 32px;
          background-color: #fff;
          padding: 1px 8px 1px 9px;
          border-radius: 3px;
          border: solid 0.5px rgba(0, 0, 0, 0.1);
          box-shadow: 1px 2px 8px 0 rgba(220, 220, 220, 0.55);
          z-index: 1299;
          div {
            float: left;
            width: 18px;
            height: 18px;
            border-radius: 100%;
            margin: auto 3px;
            cursor: pointer;
          }
          .tool_yellow{background-color: rgb(255, 243, 0);}
          .tool_orange{background-color: rgb(255, 190, 101);}
          .tool_pink{background-color: rgb(255, 180, 237);}
          .tool_green{background-color: rgb(160, 246, 104);}
          .tool_blue{background-color: rgb(141, 205, 255);}
          .tool_gray{background-color: rgb(174, 171, 255);}
          .star {background-image: url('../../../assets/images/app_smart_bar/ic-pro-star.png');background-size: 12px 12px;background-position: center;background-repeat: no-repeat;}
          .active {border: 2px solid #ccc;}
        }

        .tool_menu {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: absolute;
          width: 109px;
          height: 32px;
          background-color: #fff;
          padding: 4px 9px 4px 10px;
          border-radius: 3px;
          border: solid 0.5px rgba(0, 0, 0, 0.1);
          box-shadow: 1px 2px 8px 0 rgba(220, 220, 220, 0.55);
          z-index: 1299;
          .menu_color {
            float: left;
            width: 18px;
            height: 18px;
            border-radius: 100%;
            margin: auto 3px;
            cursor: pointer;
          }
          .menu_yellow{background-color: rgb(255, 243, 0);}
          .menu_orange{background-color: rgb(255, 190, 101);}
          .menu_pink{background-color: rgb(255, 180, 237);}
          .menu_green{background-color: rgb(160, 246, 104);}
          .menu_blue{background-color: rgb(141, 205, 255);}
          .menu_gray{background-color: rgb(174, 171, 255);}
          .menu_note {
            cursor: pointer;
            .tip {
              display: none;
              position: absolute;
              top: 36px;
              left: 34px;
              font-size: 11px;
              text-align: center;
              color: #fff;
              padding: 6px 8px;
              border-radius: 1px;
              box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.28);
              background-color: #2c2c2c;
            }
            .tip::before {
              position: absolute;
              content: '';
              top: -3px;
              left: 18px;
              width: 6px;
              height: 6px;
              background-color: #2c2c2c;
              transform: rotate(45deg);
              box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.28);
            }
          }
          .menu_note:hover .tip {
            display: block;
          }
          .menu_del {
            width: 24px;
            height: 24px;
            cursor: pointer;
            img {
              width: 24px;
              height: 24px;
            }
          }
        }

        .textarea_note {
          position: absolute;
          width: 220px;
          min-height: 180px;
          padding: 12px;
          border-radius: 4px;
          border-top: 8px solid #fff300;
          box-shadow: 1px 2px 11px 0 rgba(0, 0, 0, 0.15);
          background-color: #fcfcfc;
          z-index: 1299;
          textarea {
            width: 100%;
            height: 112px;
            font-size: 13px;
            color: #343434;
            border: 0px;
            outline: none;
            background-color: #fcfcfc;
          }
          .textarea_share:hover {
            cursor: default;
          }
          .textarea_foot {
            display: flex;
            justify-content: space-between;
            margin-top: 16px;
            img {
              cursor: pointer;
            }
            span {
              display: none;
              font-size: 14px;
              font-weight: 500;
              color: #bdbdbd;
              cursor: pointer;
            }
          }
        }
        .yellow_note {
          border-color: #fff300;
        }
        .orange_note {
          border-color: #ffbe65;
        }
        .pink_note {
          border-color: #ffb4ed;
        }
        .green_note {
          border-color: #a0f668;
        }
        .blue_note {
          border-color: #8dcdff;
        }
        .gray_note {
          border-color: #aeabff;
        }

        .colorLimitBox, .countLimitBox {
          position: absolute; 
          z-index: 9999;
          flex-direction: column;
          width: 421px;
          padding: 13px;
          box-shadow: 1px 2px 10px 0 rgba(0, 0, 0, 0.2);
          border: solid 1px #f0f0f0;
          background-color: #fff !important;
          border-radius: 5px !important;
          box-sizing: border-box;
          font-family: Roboto !important;
        }

        .fixedBox {
          position: fixed !important;
          margin-top: 40px !important; 
        }

        .mobileColorLimitBox {
          position: fixed !important;
          width: 100% !important;
          max-width: 343px;
          left: 50% !important;
          top: 0 !important;
          margin-left: -171px !important;
          margin-top: 25vh !important;
        }

        .mobileColorLimitBox .limitTitle span {
          margin-left: 16px !important;
          margin-top: 4px !important;
          max-width: 203px;
          white-space: normal !important;
        }

        .mobileColorLimitBox .limit_a {
          padding-top: 20px !important; 
        }

        .colorLimitBoxContainer::before {
          position: absolute;
          top: 70px;
          right: -7px;
          content: '';
          width: 14.142px;
          height: 14.142px;
          background-color: #fff;
          transform: rotate(45deg);
          box-shadow: 0 2px 10px 0 rgba(127, 127, 127, 0.5);
        }

        .colorLimitBoxContainer::after {
          position: absolute;
          top: 62px;
          right: 0px;
          content: '';
          width: 20px;
          height: 32px;
          background-color: #fff;
        }

        .colorLimitBox .fork, .countLimitBox .fork {
          align-items: center;
          width: 31px;
          height: 31px;
          background: url(../../../assets/images/app_smart_bar/ic-close.svg) center no-repeat;
          cursor: pointer;
        }

        .colorLimitBox .limitImg {
          width: 48px;
          height: 48px;
          background: url(../../../assets/images/app_smart_bar/modal-ic-upgrade-colors.svg) center no-repeat;
        }

        .countLimitBox .limitImg {
          width: 48px;
          height: 48px;
          background: url(../../../assets/images/app_smart_bar/modal-ic-upgrade-highlight-limit.svg) center no-repeat;
        }

        .colorLimitBox .limitTitle, .countLimitBox .limitTitle {
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.38;
          color: #0a0a0a;
        }

        .colorLimitBox .limitTitle span, .countLimitBox .limitTitle span {
          width: 300px;
          height: 22px;
          margin-top: 14px;
          float: left;
          white-space: nowrap;
        }

        .colorLimitBox .limitContent, .countLimitBox .limitContent {
          padding-left: 32px;
          text-align: left;
          font-size: 14px;
          line-height: 1.43;
          color: #454545;
          padding-left: 56px;
        }

        .countLimitBox .limitContent {
          padding-left: 58px;
        }

        .colorLimitBox .limit_a, .countLimitBox .limit_a {
          padding-top: 13px;
        }

        .colorLimitBox .upgrade_a, .countLimitBox .upgrade_a {
          display: inline-block;
          line-height: 22px;
          padding: 9px 20px;
          float: right;
          border-radius: 5px;
          background-color: #ff5f4b;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          color: #fff;
          cursor: pointer;
        }
        
        .prompt {
          position: absolute;
          width: 311px;
          padding: 14px 12px 12px 16px;
          border-radius: 5px;
          box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.31);
          background-color: #fff;
          z-index: 1299;
          .prompt_top {
            display: flex;
            justify-content: space-between;
            .prompt_pencil {
              width: 20px;
            }
            span {
              font-size: 16px;
              font-weight: 500;
              color: #282828;
              margin-left: 10px;
            }
            .prompt_close {
              width: 22px;
              position: relative;
              top: -4px;
              cursor: pointer;
            }
          }
          .prompt_middle {
            margin: 2.8px 20px 0 34px;
            font-size: 14px;
            line-height: 1.43;
            color: #282828;
          }
          .prompt_bottom {
            div {
              float: right;
              width: 62px;
              margin-top: 11px;
              margin-right: 4px;
              padding: 10px 0 11px 0;
              border-radius: 5px;
              background-color: #ff5f4b;
              cursor: pointer;
              font-size: 16px;
              font-weight: bold;
              color: #ffffff;
              text-align: center;
              white-space: nowrap;
            }
          }
        }
        /deep/ .sharing-content {
          flex-grow: 1;
          margin-bottom: 96px;
          transition: width 0.3s;
          ::selection {
            background:#3b3b3b; 
            color:#fff;
          }
          ::-moz-selection {
            background:#3b3b3b; 
            color:#fff;
          }
          ::-webkit-selection {
            background:#3b3b3b; 
            color:#fff;
          }
          #bodyContent {
            .page_content {
              line-height: 28px;
              h1 {
                line-height: 39px;
              }
              markups {
                cursor: pointer;
              }
              .markup_note {
                display: inline-block;
                width: 20px;
                height: 20px;
                margin: 0;
                background-image: url('../../../assets/images/app_smart_bar/mk-note-ic-note-red.svg');
                vertical-align: middle;
                cursor: pointer;
              }
              div, p {
                color: #282828;
                font-size: 16px;
                margin-bottom: 44px;
                markups {
                  background-color: pink;
                }
              }
              a {
                word-break: break-all;
                text-decoration: underline;
              }
              #articleHeader {
                #articleHeader__title {
                  text-align: left;
                  font-family: inherit !important;
                  word-break: break-word;
                }
                .separator {
                  height: 44px;
                }
              }
              code {
                word-break: break-word;
                white-space: normal;
              }
              pre {
                display: flex;
                flex-direction: column;
                white-space: normal;
              }
            }
            #text {
              padding-top: 95px;
              padding-bottom: 0;
            }

            img {
              max-width: 100%;
            }

            &>#box {
              @media (min-width: 1281px) {
                width: 708px;
                padding: 0;
              }
              @media (min-width: 1025px) and (max-width: 1280px) {
                width: 80%;
                max-width: 600px;
                padding: 0;
              }
              @media (min-width: 769px) and (max-width: 1024px) {
                width: 80%;
                max-width: 600px;
                padding: 0;
              }
              @media (max-width: 768px) {
                width: 80%;
              }
              @media (max-width: 420px) {
                width: 90%;
              }
            }
            #background {
              display: none;
            }
          }  
        }
        .arrow {
          position: fixed;
          width: 20px;
          height: 41px;
          top: 50%;
          right: 344px;
          z-index: 999;
          border-radius: 6px;
          box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
          cursor: pointer;
          img {
            width: 20px;
            height: 29px;
            margin-top: 6px;
          }
        }
        .summary {
          position: fixed;
          display: flex;
          justify-content: space-between;
          padding: 10px 20px;
          width: 344px;
          height: 48px;
          bottom: 10%;
          left: 4.054%;
          z-index: 1000;
          border-radius: 5px;
          box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.05);
          background-color: #343434;
          .summary_left {
            font-size: 14px;
            line-height: 28px;
            color: #ffffff;
            height: 28px;
          }
          .summary_right {
            font-size: 13px;
            font-weight: bold;
            line-height: 1.23;
            letter-spacing: 1.25px;
            text-align: center;
            color: #ffffff;
            cursor: pointer;
            margin-top: 8px;
          }
        }
        .opening {
          position: fixed;
          display: flex;
          justify-content: space-between;
          padding: 10px 20px;
          width: 300px;
          height: 48px;
          bottom: 10%;
          left: 4.054%;
          z-index: 1000;
          border-radius: 5px;
          box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.05);
          background-color: #343434;
          .opening_left {
            font-size: 14px;
            line-height: 28px;
            color: #ffffff;
            height: 28px;
          }
        }
        .summary17 {
          bottom: 17%;
        }
        .side {
          position: fixed;
          right: 0;
          width: 332px;
          height: calc(100vh - 64px);
          box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.17);
          background-color: #f9f9f9;
          z-index: 999;
          animation: rtl-drawer-in 0.3s;
          overflow-y: scroll;
          scrollbar-width: none;
          -ms-overflow-style: none;
          &::-webkit-scrollbar {
            display: none;
          }
          .summary_content{
            margin: 0 32px;
            padding-bottom: 100px;
            .summary_title {
              width: 100%;
              margin: 33px 0 20px 0;
              border-bottom: 1px solid #eee;
              span{
                line-height: 21px;
                font-size: 16px;
                font-weight: bold;
                color: #343434;
              }
              div {
                margin-top: 8px;
                font-size: 12px;
                letter-spacing: -0.29px;
                color: rgba(0, 0, 0, 0.5);
                padding-bottom: 12px;
                .summary_count {
                  padding-right: 12px;
                  border-right: 1px solid #d8d8d8;
                }
                .summary_date {
                  padding-left: 12px;
                }
              }
            }
            .unit_block {
              position: relative;
              margin-bottom: 12px;
              padding: 13px 12px;
              border-radius: 3px;
              box-shadow: 2px 1px 10px 0 rgba(0, 0, 0, 0.08);
              background-color: #ffffff;
              border-left: 5px solid;
              span {
                font-size: 14px;
                line-height: 1.38;
                color: #484848;
                word-break: break-word;
                margin-left: 4px;
              }
              .icon_menu {
                box-sizing: border-box;
                position: absolute;
                top: 12px;
                right: 12px;
                border-radius: 3px;
                width: 20px;
                height: 20px;
                cursor: pointer;
                img {
                  width: 20px;
                  height: 20px;
                }
              }
              .note {
                display: none;
                position: relative;
                border-top: 1px solid #e2e2e2;
                padding: 12px 4px 0 0;
                margin-top: 12px;
                cursor: pointer;
                .note_img {
                  margin-right: 8px;
                }
                span {
                  font-size: 14px;
                  font-weight: 500;
                  color: #343434;
                  margin-left: 0;
                }
                .note_arrow {
                  position: absolute;
                  top: 12px;
                  right: 0;
                  width: 20px;
                  height: 20px;
                }
                .arrow_down {
                  background-image: url('../../../assets/images/app_smart_bar/mk-note-ic-arrow-down.svg');
                }
                .arrow_up {
                  background-image: url('../../../assets/images/app_smart_bar/mk-note-ic-arrow-up.svg');
                }
              }
              .note_textarea {
                display: none;
                width: 100%;
                min-height: 98px;
                margin-top: 12px;
                font-size: 13px;
                color: #343434;
                border: 0px;
                outline:none;
                padding: 4px;
              }
              .note_bottom {
                display: none;
                img {
                  cursor: pointer;
                }
                span {
                  display: none;
                  position: absolute;
                  right: 16px;
                  bottom: 12px;
                  font-size: 14px;
                  font-weight: 500;
                  color: #bdbdbd;
                  cursor: pointer;
                  margin-left: 0;
                }
              }
              .menu {
                display: flex;
                flex-direction: column;
                position: absolute;
                top: 30px;
                right: 12px;
                min-width: 150px;
                font-size: 14px;
                border-radius: 2px;
                box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2);
                border: solid 0.5px rgba(0, 0, 0, 0.15);
                background-color: #fff;
                z-index: 101;
                .icon_change {
                  width: 100%;
                  padding: 8px 12px;
                  cursor: pointer;
                }
                .icon_change:hover {
                  background-color: #ededed;
                }
                .icon_note {
                  width: 100%;
                  padding: 8px 12px;
                  cursor: pointer;
                }
                .icon_note:hover {
                  background-color: #ededed;
                }
                .icon_del {
                  width: 100%;
                  padding: 8px 12px;
                  cursor: pointer;
                }
                .icon_del:hover {
                  background-color: #ededed;
                }
                img {
                  width: 20px;
                  height: 20px;
                  margin-right: 8px;
                }
              }
              .four_color {
                position: absolute;
                top: 8px;
                right: 8px;
                width: 174px;
                height: 32px;
                background-color: #fff;
                padding: 7px;
                border-radius: 3px;
                border: solid 0.5px #d3d3d3;
                z-index: 99;
                div {
                  float: left;
                  width: 18px;
                  height: 18px;
                  border-radius: 100%;
                  margin: auto 4px;
                  cursor: pointer;
                }
                .four_yellow{background-color: rgb(255, 243, 0);}
                .four_orange{background-color: rgb(255, 190, 101);}
                .four_pink{background-color: rgb(255, 180, 237);}
                .four_green{background-color: rgb(160, 246, 104);}
                .four_blue{background-color: rgb(141, 205, 255);}
                .four_gray{background-color: rgb(174, 171, 255);}
                .star{background-image: url('../../../assets/images/app_smart_bar/ic-pro-star.png');background-size: 12px 12px;background-position: center;background-repeat: no-repeat;}
                .active {border: 2px solid #ccc;}
              }
              .more {
                display: none;
              }
            }
          }
          .summary_content_empty {
            margin: 0 20px;
            .summary_title {
              width: 100%;
              margin: 33px 0 60px 0;
              border-bottom: 1px solid #eee;
              span{
                width: 100%;
                line-height: 21px;
                font-size: 16px;
                font-weight: bold;
                color: #343434;
                img {
                  float: right;
                  cursor: pointer;
                }
              }
              div {
                margin-top: 8px;
                font-size: 12px;
                letter-spacing: -0.29px;
                color: rgba(0, 0, 0, 0.5);
                padding-bottom: 12px;
              }
            }
            .step1, .step2 {
              display: flex;
              flex-direction: column;
              align-items: center;
              span {
                font-size: 14px;
                font-weight: bold;
                line-height: 1.14;
                text-align: center;
                color: #6f6f6f;
              }
              p {
                width: 250px;
                font-size: 14px;
                line-height: 1.43;
                letter-spacing: -0.16px;
                text-align: center;
                color: #6f6f6f;
              }
            }
            .step1 {
              padding: 0 15px;
            }
            .step2 {
              margin-top: 93px;
              padding: 0 10px;
            }
            .step3 {
              display: none;
            }
          }
          .copyAll {
            position: fixed;
            bottom: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            width: 332px;
            height: 50px;
            padding: 0 80px;
            border: solid 1px #e9e9e9;
            background-image: linear-gradient(to bottom, #ffffff 50%, #f7f7f7 98%);
            z-index: 99;
            img {
              width: 30px;
            }
            span {
              font-size: 14px;
              font-weight: 500;
              letter-spacing: -0.2px;
              color: #192f51;
              white-space: nowrap;
            }
            .copyWords {
              position: absolute;
              top: -37px;
              left: 50%;
              margin-left: -30px;
              width: 60px;
              height: 25px;
              padding: 6px 0;
              border-radius: 3px;
              background-color: rgba(48, 48, 48, 0.93);
              font-size: 11px;
              font-weight: 500;
              text-align: center;
              color: #ffffff;
            }
            .copyWords_long {
              width: 70px;
            }
          }
          .copyAll:hover {
            background-image: none;
            background-color: #e7e7e7;
          }
          .copyAll:active {
            background-image: none;
            outline: none;
            background-color: #c6c6c6;
          }
          .side_shadow {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            z-index: 1000;
            background-color: rgba(0, 0, 0, 0.4);
          }
        }
        .side_two {
          width: 332px;
          flex: none;
        }
        .side_auto {
          position: fixed;
          right: 0;
          width: 332px;
          height: calc(100vh - 64px);
          box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.17);
          background-color: #f9f9f9;
          z-index: 999;
          animation: rtl-drawer-in 0.3s;
          overflow-y: scroll;
          scrollbar-width: none;
          -ms-overflow-style: none;
          &::-webkit-scrollbar {
            display: none;
          }
          .summary_content{
            margin: 0 32px;
            padding-bottom: 50px;
            .summary_title{
              width: 100%;
              margin: 33px 0 17px 0;
              span{
                line-height: 21px;
                font-size: 16px;
                font-weight: bold;
                color: #343434;
              }
            }
            .upgrade {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              margin: 15px 0;
              .line_left {
                flex-grow: 1;
                height: 1px;
                background-color: #ccc;
              }
              a {
                display: block;
                margin: 0 15px;
                line-height: 16px;
                font-size: 12px;
                color: #ff5f4b;
                background-color: #f9f9f9;
                text-decoration: underline;
              }
              .line_right {
                flex-grow: 1;
                height: 1px;
                background-color: #ccc;
              }
            }
            .unit_block {
              position: relative;
              margin-bottom: 12px;
              border-radius: 3px;
              box-shadow: 2px 1px 10px 0 rgba(0, 0, 0, 0.08);
              background-color: #ffffff;
              border-left: 5px solid;
              span {
                font-size: 16px;
                line-height: 1.38;
                color: #484848;
                word-break: break-word;
              }
              .icon_add {
                display: none;
                height: 32px;
                padding: 6px;
                border-radius: 3px;
                cursor: pointer;
                img {
                  width: 20px;
                  height: 20px;
                }
                p {
                  display: none;
                }
              }
              .bubble {
                position: absolute;
                padding: 4px 8px;
                border-radius: 4px;
                box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.28);
                background-color: #2c2c2c;
                font-size: 11px;
                text-align: center;
                color: #ffffff;
                z-index: 99999;
                .triangle {
                  position: absolute;
                  width: 5px;
                  height: 5px;
                  border: 5px;
                  border-style: solid;
                  border-color: transparent transparent #2c2c2c transparent;
                  top: -40%;
                  left: 40%;
                }
              }
              .add_bubble {
                display: none;
                top: 45px;
                right: -32px;
                .triangle {
                  left: 50%;
                }
              }
              .icon_add:hover + .add_bubble {
                display: block;
              }
            }
            .unit_block:hover {
              background-color: #ccc;
            }
            .unit_block1.disabled {
              margin-bottom: 0;
            }
            .unit_block2 {
              cursor: pointer;
              background-color: rgba(255, 255, 255, 0.1);
              span {
                -webkit-filter: blur(2px);
                -moz-filter: blur(2px);
                -ms-filter: blur(2px);
                filter: blur(2px);
                border: none;
              }
            }
            .unit_block1:hover {
              background-color: #ccc;
            }
            .unit_block2:hover {
              background-color: rgba(255, 255, 255, 0.1);
            }
          }
        }
        .appOrStore {
          -webkit-touch-callout:none;
          -webkit-user-select:none;
          -khtml-user-select:none;
          -moz-user-select:none;
          -ms-user-select:none;
          user-select:none;
          height: 256px;
          z-index: 9999;
          border-radius: 16px 16px 0 0;
          box-shadow: 0 -6px 6px 0 rgba(0, 0, 0, 0.07);
          background-color: #fff;
          position: fixed;
          bottom: 0;
          width: 100%;
          height: 256px;
          z-index: 2001;
          animation: btt-drawer-in 0.6s;
          .app_img {
            width: 100%;
            text-align: center;
            padding: 10px 0 2px;
            img {
              width: 50px;
              height: 50px;
            }
          }
          p {
            width: 100%;
            padding-top: 10px;
            padding-bottom: 13.2px;
            font-size: 18px;
            font-weight: bold;
            line-height: 1.33;
            letter-spacing: -0.13px;
            text-align: center;
            color: #343434;
          }
          .openApp {
            display: block;
            width: 90%;
            max-width: 366px;
            margin: 0 auto;
            margin-top: 24px;
            padding: 11px 0;
            border-radius: 5px;
            background-color: #ff5f4b;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: -0.11px;
            text-align: center;
            color: #ffffff;
          }
          .openApp:active {
            background-color: #e65644;
          }
          .mobileApp {
            display: block;
            width: 90%;
            max-width: 366px;
            margin: 0 auto;
            margin-top: 8px;
            padding: 13px 0 12px;
            background-color: #fff;
            font-size: 14px;
            letter-spacing: -0.1px;
            text-align: center;
            color: #242424;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .dashboard-container {
      .GDPR {
        bottom: 44px;
      }
      .main-container {
        header {
          top: 0;
          height: 44px;
          padding: 0 8px;
          .flex-right {
            display: flex;
          }
          .app {
            display: block;
          }
          .bread {
            display: block;
          }
          .btns {
            display: none;
          }
        }
        .main {
          margin-top: 44px;
          display: block;
          .tool_menu {
            .menu_note {
              .tip {
                display: none !important;
              }
            }
          }
          /deep/ .sharing-content {
            #bodyContent {
              .page_content {
                div, p {
                  margin-bottom: 30px;
                }
              }
              #box {
                padding-left: 1em;
                padding-right: 1em;
              }
              #text {
                padding-top: 71px;
                .page_content {
                  .separator {
                    height: 30px;
                  }
                }
              }
            }
          }
          .arrow {
            display: none;
          }
          .summary, .opening{
            left: 10%;
          }
          .side {
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            overflow-y: auto;
            padding-bottom: calc(env(safe-area-inset-bottom) + 50px);
            .iOS {
              padding-bottom: 105px;
            }
            .summary_content {
              margin: 0 16px;
              padding-bottom: 50px;
              .summary_title {
                margin: 12px 0 24px;
                span {
                  font-size: 20px;
                  line-height: 36px;
                  img {
                    float: right;
                    cursor: pointer;
                  }
                }
                .summary_count, .summary_date {
                  font-size: 18px;
                }
              }
              .unit_block {
                width: 90%;
                span {
                  font-size: 16px;
                }
                .icon_menu {
                  display: none;
                }
                .note_bottom {
                  span {
                    display: none !important;
                  }
                }
                .more {
                  display: inline-block;
                  position: absolute;
                  top: 6px;
                  right: -35px;
                }
              }
              .unit_block:hover {
                background-color: #fff;
                .icon_change, .icon_note, .icon_del {
                  display: none;
                }
              }
            }
            .summary_content_empty {
              display: flex;
              flex-direction: column;
              height: calc(100vh - 100px);
              .summary_title {
                margin-bottom: 20px;
              }
              .step1, .step2 {
                display: none;
              }
              .step3 {
                display: block;
                margin: auto;
                text-align: center;
                p {
                  font-size: 17px;
                  font-weight: 500;
                  letter-spacing: -0.3px;
                  color: #454545;
                  margin-bottom: 16px;
                }
                a {
                  display: block;
                  width: 90%;
                  margin: 0 auto;
                  padding: 10px;
                  border-radius: 6px;
                  background-color: #ff5f4b;
                  font-size: 16px;
                  font-weight: bold;
                  letter-spacing: -0.11px;
                  text-align: center;
                  color: #ffffff;
                }
                a:active {
                  background-color: #e65644;
                }
              }
            }
            .copyAll {
              width: 100vw;
              padding: 0 70px;
            }
            .side_shadow {
              display: block;
            }
          }
          .side_auto {
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            overflow-y: auto;
            padding-bottom: env(safe-area-inset-bottom);
            .iOS {
              padding-bottom: 105px;
            }
            .summary_content {
              .summary_title {
                margin: 12px 0 24px;
                span {
                  font-size: 20px;
                  line-height: 36px;
                  img {
                    float: right;
                    cursor: pointer;
                  }
                }
              }
              .unit_block {
                padding: 13px 0px 10px;
                text-align: center;
                &:hover .icon_add {
                  background-color: #fff;
                }
                span {
                  display: block;
                  margin-bottom: 10px;
                  padding: 0 16px 13px;
                  border-bottom: 1px solid #ddd;
                  text-align: left;
                }
                .icon_add {
                  display: inline-flex;
                  flex-direction: row;
                  position: relative;
                  p {
                    width: 130px;
                    display:inline-block;
                    height: 22px;
                    font-size: 14px;
                    line-height: 1.57;
                    margin-left: 17px;
                    color: #a3a3a3;
                  }
                }
                .icon_add:hover + .add_bubble {
                  display: none;
                }
              }
              .unit_block:hover {
                background-color: #fff;
              }
              .unit_block1.disabled {
                padding: 13px 0;
                span  {
                  padding: 0 16px 0px;
                  margin-bottom: 0;
                  border: none;
                }
              }
              .unit_block2 {
                padding: 13px 0;
                cursor: default;
                span  {
                  padding: 0 16px 0px;
                  margin-bottom: 0;
                  border: none;
                }
              }
            }
          }
        }
        .bread_bottom {
          width: 100%;
          position: fixed;
          bottom: 0;
          left: 0;
          z-index: 2000;
          display: flex;
          justify-content: space-between;
          border-top: 1px solid #e1e1e1;
          background-color: #ffffff;
          box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.1);
          .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 50%;
            cursor: pointer;
            height: 44px;
            img{
              width: 36px;
              margin-right: 2px;
            }
            span{
              font-size: 14px;
              color: #adadad;
            }
          }
          .annotation_btn, .save_btn {
            border-right: 1px solid #d8d8d8;
          }
        }
        /deep/ .login {
          .el-dialog {
            width: 300px;
            .el-dialog__body {
              .bottom {
                .button {
                  font-size: 12px;
                }
              }
            }
          }
        }
        /deep/ .remove {
          .el-dialog {
            width: 300px;
            .el-dialog__body {
              .top {
                width: 250px;
              }
            }
          }
        }
      }
      #removesuc, #savesuc, #fail {
        bottom: 60px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  @media screen and (max-width: 420px) {
    .dashboard-container {
      .main-container {
        .main {
          .summary, .opening {
            left: 2%;
            width: 96%;
          }
          /deep/ .sharing-content {
            width: 100%;
            #body {
              #bodyContent{
                #box {
                  width: 100%;
                  #text {
                    .page_content {
                      div, p {
                        margin-bottom: 20px;
                      }
                      .separator {
                        height: 20px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 664px) and (max-width:768px) {
    .dashboard-container {
      .main-container {
        .bread_bottom {
          .btn {
            height: 44px;
          }
        }
      }
    }
  }
  @media screen and (min-width: 769px) {
    .dashboard-container .main-container .main .side .summary_content .unit_block {
      .span {
        display: inline-block;
        max-width: 208px;
      }
    }
    .dashboard-container .main-container .main .side_auto .summary_content .unit_block {
      padding: 13px 16px;
      .icon_add {
        position: absolute;
        top: 8px;
        right: 8px;
        background-color: #fff;
      }
      &:hover {
        .icon_add {
          display: flex;
        }
      }
    }
  }
  @media screen and (min-width: 769px) and (max-width:1024px) {
    .dashboard-container .main-container{
      header {
        .btns {
          .btn {
            margin-left: 18px;
          }
        }
        .bread_box {
          display: none;
        }
      }
      .main {
        .summary, .opening {
          left: 30%;
        }
        .side_two, .side_auto, .side {
          width: 308px;
          .summary_content {
            margin-left: 20px;
            margin-right: 20px;
            .unit_block {
              .add_bubble {
                display: none;
                top: 45px;
                right: -20px;
                .triangle {
                  left: 72px;
                }
              }
            }
          }
        }
        .side {
          .copyAll {
            width: 308px;
          }
        }
        .arrow {
          right: 320px;
        }
      }
    }
  }
  @media screen and (min-width: 1025px) and (max-width: 1280px) {
    .dashboard-container{
      .main-container {
        header {
          .btns {
            .btn {
              margin-left: 22px;
            }
          }
          .bread_box {
            display: none;
          }
        }
      }
    }
  }
  @media screen and (min-width:1281px) {
    .dashboard-container {
      .main-container {
        header {
          .bread_box {
            display: none;
          }
        }
      }
    }
  }
  @media screen and (max-width:343px) {
    .dashboard-container {
      .main-container {
        .main {
          .mobileColorLimitBox {
            .limitTitle {
              .fork {
                margin-right: 13px !important;
              }
            }
          }
        }
      }
    }
  }
</style>
