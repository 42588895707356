<template>
  <div class="header_wrapper header">
    <header>
      <div class="bread" type="primary">
        <img class="bread_img1" src="../../../assets/images/app_smart_bar/ic-navigtion-menu.svg" alt="" @click="drawer3 = true">
        <a :href="currUser ? langs.common.header.a2 : '/'"><img class="bread_img2" src="../../../assets/images/app_smart_bar/ic-logo.png" alt="" v-show="lang != 'ja'"></a>
      </div>
      <el-drawer
        custom-class="el-drawer3"
        title=""
        :visible.sync="drawer3"
        :modal-append-to-body="false"
        size=""
        :show-close="false"
        :direction="'ltr'">
        <template slot="title"><inline-svg @click="drawer3 = false" :src="require('../../../assets/images/app_smart_bar/close.svg')"/></template>
        <div class="menu">
          <div class="menu_markups" :class="{menus:isActive}">
            <a :href="langs.common.header.a2" v-show="currUser">{{ langs.common.header.mymarkups }}</a>
            <a :href="utmLoginhref" v-show="!currUser" class="Btn_Login_Navi_MyMarkup">{{ langs.common.header.mymarkups }}</a>
          </div>
          <div class="menu_explore" :class="{menus:!isActive}"><a :href="langs.common.header.a3">{{ langs.common.header.explore }}</a></div>
        </div>
        <div class="menu_list">
          <div><a href="https://www.kdanmobile.com/contact" target="_blank">{{ langs.common.header.contact }}</a></div>
          <div><a href="https://www.kdanmobile.com/terms_of_service" target="_blank">{{ langs.common.header.terms }}</a></div>
          <div><a href="https://www.kdanmobile.com/privacy_policy" target="_blank">{{ langs.common.header.privacy }}</a></div>
          <div><a href="https://support.kdanmobile.com/hc" target="_blank">{{ langs.common.header.faq }}</a></div>
        </div>
        <div class="menu_copyright">
          {{'© 2009-' + fullYear }}
          <a href="https://www.kdanmobile.com">{{ langs.common.header.kdan }}</a>
        </div>
      </el-drawer>
      <el-drawer
        custom-class="el-drawer4"
        :visible.sync="drawer4"
        :show-close="false"
        :modal-append-to-body="false"
        :size="'100%'"
        :direction="'btt'"
        :before-close="edit">
        <template slot="title"><span class="el-dialog__title">{{ langs.common.header.account }}</span><inline-svg @click="edit" :src="require('../../../assets/images/app_smart_bar/close.svg')"/></template>
        <div class="top_profile" :class="{disable: pictures}">
          <div class="dialog_img" :class="{lucency:white}" v-show="!pictures"><div class="dialog_img_img" :style="{backgroundImage: 'url(' + avatar280 + ')'}"></div><div class="dialog_img_change" @click="picturesOpen()" v-show="!(changeName || pictures)"><img src="../../../assets/images/app_smart_bar/ic-profile-edit.svg" alt=""></div></div>
          <div class="click_img" v-show="pictures"><div class="click_img_img" :style="{backgroundImage: 'url(https://s3.amazonaws.com/kdanfile/user-icon/defaults/user_icon_' + targetIndex + '_100.png)'}"></div></div>
          <el-form class="dialog_form" :class="{active: changeName}" v-show="!pictures">
            <el-input v-if="changeName && inputOpen" type="textarea" autosize resize="none" v-model="nickname" v-focus class="textarea"></el-input>
            <div class="nickname" v-else-if="nickname">{{nickname}}</div>
            <div class="name" v-else>{{name}}</div>
            <img src="../../../assets/images/app_smart_bar/ic-profile-edit.svg" alt="" @click="alterName()" v-show="!(changeName || pictures)">
          </el-form>
          <div class="current" v-show="pictures">{{ langs.common.header.current }}</div>
        </div>
        <div class="dialog_bottom1" v-show="!pictures" :class="{lucency:white}">
          <div class="privacy">
            <span>{{ langs.common.header.private }}
              <img class="icon_mark" src="../../../assets/images/app_smart_bar/mk-web-ic-help.svg">
              <div class="bubble">
                {{ langs.common.header.once }}
                <div class="triangle"></div>
              </div>
            </span>
            <div @click="isprivacy()">
              <el-switch
                v-model="setting"
                active-color="#33bbf2"
                inactive-color="#ababab"
                >
              </el-switch>
            </div>
          </div>
          <div class="email">
            <span>{{ langs.common.header.email }}</span>
            <b>{{email}}</b>
          </div>
          <div class="subscription">
            <span :class="{lineHeight40:currUser && !currUser.purchase_stat}">{{ langs.common.header.subscription }}</span>
            <div>
              <b v-if="currUser && currUser.purchase_stat">Markup Pro</b>
              <b v-else :class="{lineHeight40:currUser && !currUser.purchase_stat}">{{ langs.common.header.basic }}</b>
              <div v-if="currUser && !currUser.purchase_stat" class="upgrade_btn Btn_Upgrade_Account" v-show="!(changeName || pictures)" type="primary" @click="setUpgrade()"><a target="_blank" :href="creativeStore">{{ langs.common.header.upgrade }}</a></div>
            </div>
          </div>
        </div>
        <div class="dialog_bottom2_text" v-show="pictures">{{ langs.common.header.select }}</div>
        <div class="dialog_bottom2" v-show="pictures">
          <div class="pictures_container">
            <div class="top_bottom">
              <div class="picture picture0" @click="check(1)"><div v-show="targetIndex == 1" class="picture_bg"></div><img v-show="targetIndex == 1" src="../../../assets/images/app_smart_bar/ic-profile-photo-select.svg" alt=""></div>
              <div class="picture picture1" @click="check(2)"><div v-show="targetIndex == 2" class="picture_bg"></div><img v-show="targetIndex == 2" src="../../../assets/images/app_smart_bar/ic-profile-photo-select.svg" alt=""></div>
              <div class="picture picture2" @click="check(3)"><div v-show="targetIndex == 3" class="picture_bg"></div><img v-show="targetIndex == 3" src="../../../assets/images/app_smart_bar/ic-profile-photo-select.svg" alt=""></div>
              <div class="picture picture3" @click="check(4)"><div v-show="targetIndex == 4" class="picture_bg"></div><img v-show="targetIndex == 4" src="../../../assets/images/app_smart_bar/ic-profile-photo-select.svg" alt=""></div>
            </div>
            <div class="top_bottom">
              <div class="picture picture4" @click="check(5)"><div v-show="targetIndex == 5" class="picture_bg"></div><img v-show="targetIndex == 5" src="../../../assets/images/app_smart_bar/ic-profile-photo-select.svg" alt=""></div>
              <div class="picture picture5" @click="check(6)"><div v-show="targetIndex == 6" class="picture_bg"></div><img v-show="targetIndex == 6" src="../../../assets/images/app_smart_bar/ic-profile-photo-select.svg" alt=""></div>
              <div class="picture picture6" @click="check(7)"><div v-show="targetIndex == 7" class="picture_bg"></div><img v-show="targetIndex == 7" src="../../../assets/images/app_smart_bar/ic-profile-photo-select.svg" alt=""></div>
              <div class="picture picture7" @click="check(8)"><div v-show="targetIndex == 8" class="picture_bg"></div><img v-show="targetIndex == 8" src="../../../assets/images/app_smart_bar/ic-profile-photo-select.svg" alt=""></div>
            </div>
            <div class="top_bottom">
              <div class="picture picture8" @click="check(9)"><div v-show="targetIndex == 9" class="picture_bg"></div><img v-show="targetIndex == 9" src="../../../assets/images/app_smart_bar/ic-profile-photo-select.svg" alt=""></div>
              <div class="picture picture9" @click="check(10)"><div v-show="targetIndex == 10" class="picture_bg"></div><img v-show="targetIndex == 10" src="../../../assets/images/app_smart_bar/ic-profile-photo-select.svg" alt=""></div>
              <div class="picture"></div>
              <div class="picture"></div>
            </div>
          </div>
        </div>
        <div class="dialog-footer" :class="{textAlignLeft:!(changeName || pictures)}">
          <div class="deleteAccount" v-show="!(changeName || pictures)" @click="delAccount()">{{ langs.common.header.deleteAccount }}</div>
          <el-button v-show="changeName" class="cancel_btn" @click="alterNameClose()">{{ langs.common.cancel }}</el-button>
          <el-button class="save_btn" type="primary" v-show="changeName" @click="changeNickname()">{{ langs.common.save }}</el-button>
          <el-button v-show="pictures" class="cancel_btn" @click="picturesClose()">{{ langs.common.cancel }}</el-button>
          <el-button class="save_btn" type="primary" v-show="pictures" @click="changeAvatar()">{{ langs.common.save }}</el-button>
        </div>
      </el-drawer>
      <el-drawer
        custom-class="el-drawer5"
        :visible.sync="drawer5"
        :show-close="false"
        :modal-append-to-body="false"
        :size="'100%'"
        :direction="'btt'"
        :before-close="closeLanguage">
        <template slot="title"><span class="el-dialog__title">{{ langs.common.header.language }}</span><inline-svg @click="closeLanguage" :src="require('../../../assets/images/app_smart_bar/close.svg')"/></template>
        <div class="language_list">
          <div class="language_li"><el-radio v-model="radio" label="en">English</el-radio></div>
          <div class="language_li"><el-radio v-model="radio" label="es">Español</el-radio></div>
          <div class="language_li"><el-radio v-model="radio" label="zh-tw">中文 (繁體)</el-radio></div>
          <div class="language_li"><el-radio v-model="radio" label="zh-cn">中文 (简体)</el-radio></div>
          <div class="language_li"><el-radio v-model="radio" label="ja">日本語</el-radio></div>
          <div class="language_li"><el-radio v-model="radio" label="ko">한국어</el-radio></div>
        </div>
        <div class="dialog-footer">
          <el-button type="primary" @click="changeLanguage()">{{ langs.common.save }}</el-button>
        </div>
      </el-drawer>
      <a class="title" :href="currUser ? langs.common.header.a2 : lang=='en' ? '' : '/'+lang">
        <img src="../../../assets/images/app_smart_bar/img-markup-logotype.png">
      </a>
      <div class="menu">
        <div class="menu_markups" :class="{menus:isActive}">
          <a :href="langs.common.header.a2" v-show="currUser">{{ langs.common.header.mymarkups }}</a>
          <a :href="utmLoginhref" v-show="!currUser" class="Btn_Login_Navi_MyMarkup">{{ langs.common.header.mymarkups }}</a>
        </div>
        <div class="menu_explore" :class="{menus:!isActive}"><a :href="langs.common.header.a3">{{ langs.common.header.explore }}</a></div>
      </div>
      <div class="top_img" v-if="currUser">
        <img v-if="isActive" class="plus" src="../../../assets/images/app_smart_bar/ic-addurl-00.svg" @click="openclean()" />
        <div v-else class="plus place"></div>
        <a id="Btn_Upgrade_Navigation" v-show="!currUser.purchase_stat" class="rectangle" :href="upgradeUrl">{{ langs.common.header.upgrade }}</a>
        <div class="user_icon" @click.stop="openPopper()" :style="{backgroundImage: 'url(' + avatar100 + ')'}"></div>
        <div class="popper" v-show="ifPopper">
          <div class="user_top" @click="webapp()">
            <div class="user_top_img" :style="{backgroundImage: 'url(' + avatar100 + ')'}"></div>
            <div>
              <p v-if="nickname">{{nickname}}</p>
              <p v-else>{{name}}</p>
              <i>{{email}}</i>
            </div>
          </div>
          <div class="line"></div>
          <div class="user_bottom">
            <div @click="openLanguage()">{{ langs.common.header.language }}</div>
            <a href="/logout">{{ langs.common.header.logout }}</a>
          </div>
        </div>
      </div>
      <div class="top_img" v-else>
        <div class="log"><a :href="utmLoginhref" id="Btn_LogIn_Navi">{{ langs.common.header.log }}</a></div>
        <div class="sign"><a :href="utmSignhref" id="Btn_SignUp_Navi">{{ langs.common.header.sign }}</a></div>
      </div>
    </header>
    <div class="path"></div>
    <el-dialog class="save_url" :visible.sync="dialogFormVisible1" :show-close="false" :modal-append-to-body="false" top="30vh">
      <template slot="title"><span>{{ langs.common.saving }}</span><inline-svg @click="dialogFormVisible1 = false" :src="require('../../../assets/images/app_smart_bar/close.svg')"/></template>
      <el-form :model="form1" @submit.native.prevent>
        <el-input resize="none" v-model="form1.url" placeholder="https://en.wikipedia.org/..." autocomplete="off" @keyup.enter.native="saveUrl"></el-input>
      </el-form>
      <template slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveUrl()">{{ langs.common.save }}</el-button>
      </template>
    </el-dialog>
    <el-dialog id="dialog" class="profile-container" :visible.sync="dialogFormVisible2" :show-close="false" :modal-append-to-body="false" top="15vh" :close-on-click-modal="false" :before-close="edit">
      <template slot="title"><span class="el-dialog__title">{{ langs.common.header.account }}</span><inline-svg @click="edit" :src="require('../../../assets/images/app_smart_bar/close.svg')"/></template>
      <div class="top_profile" :class="{disable: pictures}">
        <div class="dialog_img" :class="{lucency:white}" v-show="!pictures"><div class="dialog_img_img" :style="{backgroundImage: 'url(' + avatar280 + ')'}"></div><div class="dialog_img_change" @click="picturesOpen()" v-show="!(changeName || pictures)"><img src="../../../assets/images/app_smart_bar/ic-profile-edit.svg" alt=""></div></div>
        <div class="click_img" v-show="pictures"><div class="click_img_img" :style="{backgroundImage: 'url(https://s3.amazonaws.com/kdanfile/user-icon/defaults/user_icon_' + targetIndex + '_100.png)'}"></div></div>
        <el-form class="dialog_form" :class="{active: changeName}" v-show="!pictures">
          <el-input v-if="changeName && inputOpen" type="textarea" autosize resize="none" v-model="nickname" v-focus class="textarea"></el-input>
          <div class="nickname" v-else-if="nickname">{{nickname}}</div>
          <div class="name" v-else>{{name}}</div>
          <img src="../../../assets/images/app_smart_bar/ic-profile-edit.svg" alt="" @click="alterName()" v-show="!(changeName || pictures)">
        </el-form>
        <div class="current" v-show="pictures">{{ langs.common.header.current }}</div>
      </div>
      <div class="dialog_bottom1" v-show="!pictures" :class="{lucency:white}">
        <div class="privacy">
          <span>{{ langs.common.header.private }}
            <img class="icon_mark" src="../../../assets/images/app_smart_bar/mk-web-ic-help.svg">
            <div class="bubble">
              {{ langs.common.header.once }}
              <div class="triangle"></div>
            </div>
          </span>
          <div @click="isprivacy()">
            <el-switch
              v-model="setting"
              active-color="#33bbf2"
              inactive-color="#ababab"
              >
            </el-switch>
          </div>
        </div>
        <div class="email">
          <span>{{ langs.common.header.email }}</span>
          <b>{{email}}</b>
        </div>
        <div class="subscription">
          <span :class="{lineHeight40:currUser && !currUser.purchase_stat}">{{ langs.common.header.subscription }}</span>
          <div>
            <b v-if="currUser && currUser.purchase_stat">Markup Pro</b>
            <b v-else :class="{lineHeight40:currUser && !currUser.purchase_stat}">{{ langs.common.header.basic }}</b>
            <div class="upgrade_btn Btn_Upgrade_Account" v-if="currUser && !currUser.purchase_stat" v-show="!(changeName || pictures)" @click="setUpgrade()"><a target="_blank" :href="creativeStore">{{ langs.common.header.upgrade }}</a></div>
          </div>
        </div>
      </div>
      <div class="dialog_bottom2_text" v-show="pictures">{{ langs.common.header.select }}</div>
      <div class="dialog_bottom2" v-show="pictures">
        <div class="pictures_container">
          <div class="top_bottom">
            <div class="picture picture0" @click="check(1)"><div v-show="targetIndex == 1" class="picture_bg"></div><img v-show="targetIndex == 1" src="../../../assets/images/app_smart_bar/ic-profile-photo-select.svg" alt=""></div>
            <div class="picture picture1" @click="check(2)"><div v-show="targetIndex == 2" class="picture_bg"></div><img v-show="targetIndex == 2" src="../../../assets/images/app_smart_bar/ic-profile-photo-select.svg" alt=""></div>
            <div class="picture picture2" @click="check(3)"><div v-show="targetIndex == 3" class="picture_bg"></div><img v-show="targetIndex == 3" src="../../../assets/images/app_smart_bar/ic-profile-photo-select.svg" alt=""></div>
            <div class="picture picture3" @click="check(4)"><div v-show="targetIndex == 4" class="picture_bg"></div><img v-show="targetIndex == 4" src="../../../assets/images/app_smart_bar/ic-profile-photo-select.svg" alt=""></div>
            <div class="picture picture4" @click="check(5)"><div v-show="targetIndex == 5" class="picture_bg"></div><img v-show="targetIndex == 5" src="../../../assets/images/app_smart_bar/ic-profile-photo-select.svg" alt=""></div>
          </div>
          <div class="top_bottom">
            <div class="picture picture5" @click="check(6)"><div v-show="targetIndex == 6" class="picture_bg"></div><img v-show="targetIndex == 6" src="../../../assets/images/app_smart_bar/ic-profile-photo-select.svg" alt=""></div>
            <div class="picture picture6" @click="check(7)"><div v-show="targetIndex == 7" class="picture_bg"></div><img v-show="targetIndex == 7" src="../../../assets/images/app_smart_bar/ic-profile-photo-select.svg" alt=""></div>
            <div class="picture picture7" @click="check(8)"><div v-show="targetIndex == 8" class="picture_bg"></div><img v-show="targetIndex == 8" src="../../../assets/images/app_smart_bar/ic-profile-photo-select.svg" alt=""></div>
            <div class="picture picture8" @click="check(9)"><div v-show="targetIndex == 9" class="picture_bg"></div><img v-show="targetIndex == 9" src="../../../assets/images/app_smart_bar/ic-profile-photo-select.svg" alt=""></div>
            <div class="picture picture9" @click="check(10)"><div v-show="targetIndex == 10" class="picture_bg"></div><img v-show="targetIndex == 10" src="../../../assets/images/app_smart_bar/ic-profile-photo-select.svg" alt=""></div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" :class="{textAlignLeft:!(changeName || pictures)}">
        <div class="deleteAccount" v-show="!(changeName || pictures)" @click="delAccount()">{{ langs.common.header.deleteAccount }}</div>
        <el-button v-show="changeName" class="cancel_btn" @click="alterNameClose()">{{ langs.common.cancel }}</el-button>
        <el-button type="primary" v-show="changeName" @click="changeNickname()">{{ langs.common.save }}</el-button>
        <el-button v-show="pictures" class="cancel_btn" @click="picturesClose()">{{ langs.common.cancel }}</el-button>
        <el-button type="primary" v-show="pictures" @click="changeAvatar()">{{ langs.common.save }}</el-button>
      </div>
    </el-dialog>
    <el-dialog class="privacy_hint" :visible.sync="dialogFormVisible3" :show-close="false" :modal-append-to-body="false" top="30vh" :close-on-click-modal="false">
      <template slot="title"><span>{{ langs.common.header.private }}</span></template>
      <p>{{ langs.common.header.please }}</p>
      <template slot="footer" class="dialog-footer">
        <el-button type="button" class="button button_cancel" @click="switchClose()">{{ langs.common.cancel }}</el-button>
        <el-button type="primary" class="button button_confirm" @click="privacy()">{{ langs.common.confirm }}</el-button>
      </template>
    </el-dialog>

    <el-dialog class="language-container" :visible.sync="dialogFormVisible4" :show-close="false" :modal-append-to-body="false" top="15vh" :close-on-click-modal="false" :before-close="closeLanguage">
      <template slot="title"><span class="el-dialog__title">{{ langs.common.header.language }}</span><inline-svg @click="closeLanguage" :src="require('../../../assets/images/app_smart_bar/close.svg')"/></template>
      <div class="language_list">
        <div class="language_li"><el-radio v-model="radio" label="en">English</el-radio></div>
        <div class="language_li"><el-radio v-model="radio" label="es">Español</el-radio></div>
        <div class="language_li"><el-radio v-model="radio" label="zh-tw">中文 (繁體)</el-radio></div>
        <div class="language_li"><el-radio v-model="radio" label="zh-cn">中文 (简体)</el-radio></div>
        <div class="language_li"><el-radio v-model="radio" label="ja">日本語</el-radio></div>
        <div class="language_li"><el-radio v-model="radio" label="ko">한국어</el-radio></div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="changeLanguage()">{{ langs.common.save }}</el-button>
      </div>
    </el-dialog>

    <div id="URLsuc" v-show='URLsuccess'><img src="../../../assets/images/app_smart_bar/ic-toast-sucess.svg" alt=""><span>{{ langs.common.url }}</span></div>
    <div id="Updatedsuc" v-show='Updatedsuccess'><img src="../../../assets/images/app_smart_bar/ic-toast-sucess.svg" alt=""><span>{{ langs.common.profile }}</span></div>
    <div id="Fail" v-show='Failed'>
      <img src="../../../assets/images/app_smart_bar/ic-toast-fail.svg" alt=""><span>{{ langs.common.failed }}</span>
    </div>
    <Upgrade :ifUpgrade.sync="ifUpgrade" @changeCurrUser="changeCurrUser" :currUser.sync="currUser" :lang="lang" :langs="langs" :server_env="server_env" :screenWidth="screenWidth" />
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import axios from 'axios';
import InlineSvg from 'vue-inline-svg';
import Upgrade from '../common/upgradeDialog'
export default {
  components: {
    Upgrade,
    InlineSvg
  },
  props: {
    isActive: {
      type: Boolean,
      default: true
    },
    currUser: {
      default: null
    },
    langs: {
      default: null
    },
    screenWidth: {
      default: ''
    },
    server_env: {
      default: 'production'
    }
  },
  data() {
    return {
      langues: ['en', 'zh-tw', 'zh-cn', 'ja', 'es', 'ko'],
      member: this.currUser && this.currUser.id,
      visible: false,
      dialogFormVisible1: false,
      dialogFormVisible2: false,
      dialogFormVisible3: false,
      dialogFormVisible4: false,
      form1: {
        url: ''
      },
      ifPopper: false,
      nickname: this.currUser && this.currUser.nickname,
      oldname: '',
      name: this.currUser && this.currUser.name,
      email: this.currUser && this.currUser.email,
      changeName: '',
      inputOpen: '',
      targetIndex: 1,
      oldTargetIndex: 1,
      pictures: '',
      formLabelWidth: '120px',
      avatar100: this.currUser && this.currUser.avatar[100],
      avatar280: this.currUser && this.currUser.avatar[280],
      white: '',
      drawer3: false,
      drawer4: false,
      drawer5: false,
      URLsuccess: false,
      Updatedsuccess: false,
      Failed: false,
      ifUpgrade: false,
      setting: false,
      utmLoginhref: '/auth/kdan_mobile',
      utmSignhref: '/auth/kdan_mobile?default_form=sign_up_form',
      radio: 'en',
      lang: 'en',
      fullYear: '',
      isSafari: false
    }
  },
  directives: {
    // 注册一个局部的自定义指令 v-focus
    focus: {
      // 指令的定义
        inserted: function (el) {
          // 聚焦元素
          el.querySelector('textarea').focus()
        }
    }
  },
  computed: {
    upgradeUrl () {
      const token = Cookies.get('access_token')
      if (this.isSafari && token) {
        const host = this.server_env === 'production' ? 'https://markups.preparing.kdanmobile.com/markup/app/mkpro?token=' : 'https://markups.kdanmobile.com/markup/app/mkpro?token='
        return (host + token)
      } else {
        return this.lang == 'en' ? "/pricing" : ('/' + this.lang + "/pricing")
      }
    },
    creativeStore () {
      const isProduction = this.server_env === 'production'
      const token = Cookies.get('access_token')
      if (this.isSafari && token) {
        const host = isProduction ? 'https://markups.preparing.kdanmobile.com/markup/app/mkpro?token=' : 'https://markups.kdanmobile.com/markup/app/mkpro?token='
        return (host + token)
      } else {
        const storeHost = isProduction ? 'https://creativestore.kdanmobile.com' : 'https://creative-store-client.preparing.kdanmobile.com'
        return storeHost + '/subscription/markuppro/checkout?default-plan=com.kdanmobile.stripe.PDFReader.deluxe_business_pack_year.001&utm_source=MyMarkup&utm_campaign=Upgrade&utm_medium=Account'
      }
    }
  },
  watch: {
    '$route':'getPath',
    screenWidth(val) {
      this.screenWidth = val;
      if(this.screenWidth < 768){
        this.dialogFormVisible2 = false;
        this.dialogFormVisible4 = false;
      } else {
        this.drawer3 = false;
        this.drawer4 = false;
        this.drawer5 = false;
      }
    }
  },
  mounted () {
    this.isSafari = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
    this.fullYear = new Date().getFullYear();
    if(window.location.search == '?account'){
      this.webapp();
      window.history.pushState(null,null,'mymarkups');
    }
    document.body.addEventListener('click',() => {
      this.ifPopper = false;
    }, false);
    if (this.currUser) {
      this.ifprivacy();
      if (this.langues.includes(this.currUser.lang.toLowerCase())) {
        this.radio = this.currUser.lang.toLowerCase();
        this.lang = this.currUser.lang.toLowerCase();
      } else {
        this.radio = 'en';
        this.lang = 'en';
      }
      const avatarArr = this.avatar100.split('/');
      const len = avatarArr.length;
      const specialAvatarArr = avatarArr[len - 1].split('_');
      const specialLen = specialAvatarArr.length;
      if (specialAvatarArr[specialLen - 1] === '100.png') {
        this.targetIndex = specialAvatarArr[specialLen - 2] * 1;
      } else {
        this.targetIndex = avatarArr[len - 1].replace(/[^0-9]/ig,"").substr(0,1) * 1;
      }
    } else {
      const lang = Cookies.get('lang');
      if (lang) {
        this.radio = lang;
        this.lang = lang;
      } else {
        this.radio = this.lang = 'en';
      }
    }
    this.utmLoginhref = '/auth/kdan_mobile?lang=' + this.lang;
    this.utmSignhref = '/auth/kdan_mobile?default_form=sign_up_form&lang=' + this.lang;
    //utm携带到会员系统
    var arrStr = document.cookie.split("; ");
    for (var i = 0; i < arrStr.length; i++) {
      var temp = arrStr[i].split("=");
      if (temp[0] == 'utmutm') {
        this.utmLoginhref = '/auth/kdan_mobile?' + arrStr[i].substr(8) + '&lang=' + this.lang;
        this.utmSignhref = '/auth/kdan_mobile?default_form=sign_up_form&' + arrStr[i].substr(8) + '&lang=' + this.lang;
      }
    }
  },
  methods: {
    syncPurchaseStat() {
      const upgrade = Cookies.get('setUpgrade');
      Cookies.remove('refreshState');
      if (upgrade) {
        axios.get("/api/members/me?refresh=true")
        .then((res)=>{
          this.currUser = res.data.results;
          Cookies.remove('setUpgrade');
        })
        .catch(function(err){
          console.log(err)
        });
      }
      this.$emit('update:ifUpgrade', false);
    },
    setUpgrade() {
      Cookies.set('setUpgrade', true, {expires: 7});
    },
    delAccount() {
      //返回主页
      window.location.replace('https://member-center.kdanmobile.com/members/delete_verify');
      //返回mymarkups页
      // window.location.href = this.creativeStore;
    },
    openclean() {
      this.$emit('closeTag');
      this.dialogFormVisible1 = true;
      this.form1.url = '';
    },
    openPopper() {
      this.$emit('closeTag');
      this.ifPopper = true;
    },
    saveUrl() {
      if (!this.form1.url) return;
      let that = this;
      let projects = {
        projects: [{originLink: this.form1.url}]
      };
      axios.post("/api/projects/save_url",projects)
      .then((res)=>{
        this.$parent.getContents(this.$parent.asc);
        window.location.href = window.location.origin + '/sharings/' + res.data.results[0].shareId + '?enter=markups';
        //this.URLsuccess = true;
        //setTimeout(function(){that.URLsuccess = false;},3000);
      })
      .catch(function(err){
        that.Failed = true;
        setTimeout(function(){that.Failed = false;},3000);
      });
      this.dialogFormVisible1 = false;
    },
    getPath() {
      this.isActive = !this.isActive;
    },
    inputClose() {
      this.white = '';
      this.inputOpen = '';
      this.nickname = this.oldname;
    },
    alterName() {
      this.white = true;
      this.changeName = true;
      this.inputOpen = true;
      this.oldname = this.nickname;
    },
    alterNameClose() {
      this.changeName = '';
      this.inputOpen = '';
      this.white = '';
      this.nickname = this.oldname;
    },
    picturesOpen() {
      this.pictures = true;
      this.oldTargetIndex = this.targetIndex;
    },
    picturesClose() {
      this.pictures = '';
      this.targetIndex = this.oldTargetIndex;
    },
    check(i) {
      this.targetIndex = i;
    },
    changeAvatar() {
      if (this.targetIndex === this.oldTargetIndex) {
        this.picturesClose();
        return; 
      }
      let that = this;
      axios.post("/api/members/update_info", {'avatar_num': this.targetIndex})
      .then((res)=>{
        that.avatar100 = res.data.results.avatar[100];
        that.avatar280 = res.data.results.avatar[280];
        this.oldTargetIndex = this.targetIndex;
        // this.Updatedsuccess = true;
        this.picturesClose();
        // setTimeout(function(){that.Updatedsuccess = false;},3000);
      })
      .catch(function(err){
        that.Failed = true;
        setTimeout(function(){that.Failed = false;},3000);
      });
    },
    changeNickname() {
      let that = this;
      this.white = '';
      if (this.nickname === this.oldname) {
        this.changeName = false;
        return;
      }
      axios.post("/api/members/update_info", {'nickname': this.nickname})
      .then((res)=>{
        this.changeName = false;
        this.oldname = res.data.results.nickname
        // this.Updatedsuccess = true;
        // this.dialogFormVisible2 = false;
        // this.drawer4 = false;
        // setTimeout(function(){that.Updatedsuccess = false;},3000);
      })
      .catch(function(err){
        that.Failed = true;
        setTimeout(function(){that.Failed = false;},3000);
      });
    },
    webapp() {
      /* let that = this;
      axios.get("/api/members/me?refresh=true")
      .then((res)=>{
        that.upgrade = res.data.results.purchase_stat;
      })
      .catch(function(err){
      }); */
      if(window.innerWidth < 768){
        this.drawer4 = true;
        this.changeName = '';
        this.pictures = '';
      }else{
        this.dialogFormVisible2 = true;
        this.changeName = '';
        this.pictures = '';
      }
    },
    edit() {
      this.syncPurchaseStat();
      if(this.inputOpen || this.changeName || this.pictures){
        this.inputOpen = false;
        this.changeName = false;
        this.white = '';
        this.pictures = false;
        this.nickname = this.oldname;
        this.targetIndex = this.oldTargetIndex;
        this.drawer4 = false;
        this.dialogFormVisible2 = false;
      }else{
        this.drawer4 = false;
        this.dialogFormVisible2 = false;
      }
    },
    changeCurrUser(curr) {
      this.$emit('update:currUser', curr);
    },
    ifprivacy () {
      this.setting = !this.currUser.share_enabled;
    },
    isprivacy () {
      if(this.setting) {
        this.dialogFormVisible3 = true;
      } else {
        this.privacy();
      }
    },
    privacy () {
      let that = this;
      axios.post("/api/projects/share_web_content", {'shared': !that.setting})
      .then((res)=>{
        that.setting = !res.data.results;
        that.dialogFormVisible3 = false;
      })
      .catch(function(err){
        that.Failed = true;
        setTimeout(function(){that.Failed = false;},3000);
      });
    },
    switchClose () {
      this.setting = false;
      this.dialogFormVisible3 = false;
    },
    openLanguage () {
      if(window.innerWidth < 768){
        this.drawer5 = true;
      }else{
        this.dialogFormVisible4 = true;
      }
    },
    closeLanguage () {
      this.drawer5 = false;
      this.dialogFormVisible4 = false;
      this.radio = this.lang;
    },
    changeLanguage () {
      let that = this;
      axios.post("/api/members/update_info", {'lang': this.radio})
      .then((res)=>{
        Cookies.set('lang', res.data.results.lang, {expires: 7});
        this.Updatedsuccess = true;

        const lang = this.lang
        if (lang === res.data.results.lang) return
        const pathname = window.location.pathname
        const currentLang = this.radio === 'en' ? '' : this.radio + '/'
        if (lang == 'en') {
          window.location.pathname = '/' + this.radio + pathname
        } else {
          window.location.pathname = pathname.replace(/\/([^/]+)\//, `/${currentLang}`)
        }
      })
      .catch(function(err){
        that.Failed = true;
        setTimeout(function(){that.Failed = false;},3000);
      });
      this.drawer5 = false;
      this.dialogFormVisible4 = false;
    }
  }
}
</script>

<style lang="scss" scoped>
  #URLsuc, #Updatedsuc {
    position: fixed;
    left: 36px;
    bottom: 36px;
    display: flex;
    align-items: center;
    padding: 14px 16px;
    border-radius: 5px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.05);
    background-color: #dff4d2;
    z-index: 999;
    text-align: center;
    white-space: nowrap;
    img {
      width: 20px;
      height: 20px;
      margin-right: 12px;
    }
    span {
      font-size: 14px;
      font-weight: 500;
      color: #28a60e;
    }
  }
  #Fail {
    position: fixed;
    left: 36px;
    bottom: 36px;
    display: flex;
    align-items: center;
    padding: 14px 16px;
    border-radius: 5px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.05);
    background-color: #ffe1dd;
    z-index: 999;
    text-align: center;
    img {
      width: 20px;
      height: 20px;
      margin-right: 12px;
    }
    span {
      font-size: 14px;
      font-weight: 500;
      color: #ff4d36;
    }
  }
  .header {
    width: 100%;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 400;
  }
  .popper {
    width: 280px;
    position: absolute;
    top: 63px;
    right: 48px;
    padding: 0;
    border-radius: 3px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    z-index: 999;
    .user_top {
      display: flex;
      align-items: center;
      cursor: pointer;
      width: 100%;
      padding: 15px 18px;
      .user_top_img {
        width: 48px;
        height: 48px;
        margin-right: 16px;
        border-radius: 50%;
        background-image: url(https://s3.amazonaws.com/kdanfile/user-icon/defaults/user_icon_1_100.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
      }
      div {
        color: #636363;
        p{
          width: 180px;
          line-height: 21px;
          font-size: 16px;
          font-weight: bold;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        i{
          margin-bottom: 18px;
          line-height: 16px;
          font-size: 12px;
        }
      }
    }
    .line {
      width: 100%;
      height: 1px;
      &:before {
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        background-color: #ebebeb;
      }
    }
    .user_bottom {
      display: flex;
      flex-direction: column;
      div {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #656565;
        padding: 14px 0 14px 19px;
        cursor: pointer;
      }
      a {
        display: block;
        width: 100%;
        padding: 14px 0 14px 19px;
        line-height: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #656565;
      }
    }
    .user_top:hover {
      background-color: rgba(0,0,0,0.04);
    }
    .user_top {
      img{
        background-color: rgba(0,0,0,0) !important;
      }
      div{
        p {
          background-color: rgba(0,0,0,0) !important;
        }
        i {
          background-color: rgba(0,0,0,0) !important;
        }
      }
    }
    .user_bottom :hover{
      background-color: rgba(0,0,0,0.04);
    }
  }
  header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 64px;
    .bread {
      margin-left: 16px;
      padding: 14px 0;
      font-size: 0;
      .bread_img1 {
        width: 36px;
        margin-right: 8px;
        cursor: pointer;
      }
      .bread_img2 {
        width: 30px;
      }
    }
    .title {
      display: flex;
      align-items: center;
      width: 9.5139%;
      margin: auto 0;
      margin-left: 3.3333%;
      img {
        width: 113.5714px;
        height: 30px;
      }
    }
    .menu {
      display: flex;
      justify-content: space-between;
      width: 256px;
      margin: auto 0;
      text-align: center;
      margin-right: 30%;
      .menu_markups{
        a {
          color: #343434;
        }
      }
      .menu_explore{
        a {
          color: #343434;
        }
      }
      div {
        font-size: 18px;
        height: 64px;
        line-height: 64px;
        letter-spacing: 0.2px;
      }
      .menus {
        font-weight: bold;
        border-bottom: 5px solid  #ff5f4b;
      }
    }
    .top_img {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: auto 0;
      margin-right: 3.135%;
      .plus {
        width: 36px;
        height: 36px;
        margin-right: 8px;
        cursor: pointer;
        &:place {
          cursor: auto;
        }
      }
      .rectangle, .log, .sign{
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 101px;
        padding: 0 4px;
        height: 40px;
        border-radius: 6px;
        background-color: #ff5f4b;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        line-height: 16px;
        text-align: center;
        cursor: pointer;
      }
      .log, .sign {
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 40px;
        }
      }
      .log {
        margin-right: 8px;
        border: 1px solid #ff5f4b;
      }
      .rectangle {
        margin-right: 12px;
      }
      .rectangle:hover,.sign:hover {
        opacity: 0.8;
      }
      .rectangle:active, .sign:active {
        background-color: #e65644;
      }
      .log:hover {
        background-color: #fff1ef;
      }
      .log:active {
        background-color: #fff;
      }
      .user_icon {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-image: url(https://s3.amazonaws.com/kdanfile/user-icon/defaults/user_icon_1_100.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        cursor: pointer;
      }
      .log {
        width: 85px;
        background-color: #fff;
        a {
          color: #ff5f4b;
        }
      }
      .sign {
        width: 96px; 
        a {
          color: #fff;
        }
      }
    }
  }
  .path {
    border-bottom: 1px solid #ececec;
  }
  .lucency {
    -moz-opacity: 0.65;
    opacity:.65;
    filter: alpha(opacity=65);
  }
  .header /deep/ .el-dialog {
    .el-dialog__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 12px 0 20px;
      svg {
        cursor: pointer;
        &:focus {
          outline: none;
        }
      }
      span {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .el-dialog__body {
      word-break: initial;
      .el-textarea {
        .el-textarea__inner {
          padding: 10px 15px;
          color: #343434;
          border: 2px solid #DCDFE6;
          &:hover {
            border-color: #C0C4CC;
          }
          &:focus {
            border-color: #92959a;
          }
        }
      }
      .current {
        text-align: center;
        color: #909090;
        line-height: 19px;
        margin-top: 6px;
      }
    }
    .el-dialog__footer {
      padding: 0 20px 20px 24px;
      .deleteAccount {
        display: inline-block;
        color: #ff5f4b;
        font-weight: 500;
        cursor: pointer;
      }
      .el-button {
        width: 76px;
        height: 40px;
        padding: 0;
        border: none;
        font-weight: bold;
        &.cancel_btn {
          color: #838383;
          border: 1px solid rgb(210, 210, 210);
          background-color: #fff;
          &:hover {
            background-color: #f5f5f5;
          }
          &:active {
            background-color: rgb(229, 229, 229);
          }
        }
        &.cancel_btn {
          width: 90px;
        }
      }
      span {
        font-size: 16px;
      }
    }
    .textAlignLeft {
      text-align: left;
    }
  }
  .header /deep/ {
    .profile-container, .save_url, .privacy_hint, .language-container {
      .el-dialog {
        width: 480px;
        .el-textarea__inner {
          font-size: 16px;
          color: #4b4b4b;
        }
      }
    }
    .save_url {
      .el-dialog {
        border-radius: 5px;
        .el-dialog__body {
          padding: 24px 20px 32px;
          .el-input {
            .el-input__inner {
              height: 48px;
              border: 2px solid #DCDFE6;
              &:hover {
                border-color: #C0C4CC;
              }
              &:focus {
                border-color: #92959a;
              }
            }
          }
        }
      }
    }
    .profile-container {
      .el-dialog__body {
        padding: 0;
        border-radius: 8px;
        .el-textarea {
          .el-textarea__inner {
            text-align: center;
            font-size: 20px;
            font-weight: bold;
          }
        }
      }
    }
    .privacy_hint {
      .el-dialog__header {
        padding: 20px 20px 0 20px;
        span {
          font-size: 18px;
        }
      }
      .el-dialog__body {
        padding: 10px 20px;
        p {
          line-height: 1.43;
          color: #4b4b4b;
        }
      }
      .el-dialog__footer {
        .button_cancel {
          min-width: 89px;
          padding: 9px 0;
          border-radius: 5px;
          border: solid 1px #d2d2d2;
          font-weight: normal;
          color: #838383;
          text-align: center;
        }
        .button_cancel:hover {
          background-color: #f5f5f5;
        }
        .button_cancel:focus {
          background-color: #e5e5e5;
        }
        .button_confirm {
          min-width: 98px;
          padding: 8px 0 10px;
          border-radius: 5px;
          font-weight: bold;
          text-align: center;
        }
      }
    }
    .language-container {
      .el-dialog__body {
        padding: 13px 0 20px;
        .language_list {
          .language_li {
            padding: 14px 0 13px 20px;
            label {
              display: flex;
              align-items: center;
              .el-radio__input {
                width: 20px;
                height: 20px;
                .el-radio__inner {
                  width: 20px;
                  height: 20px;
                }
                .el-radio__inner::after {
                  width: 8px;
                  height: 8px;
                }
              }
              .is-checked {
                width: 20px;
              }
              .el-radio__label {
                font-size: 16px;
                font-weight: 500;
                letter-spacing: -0.39px;
                color: #4b4b4b;
                padding: 0 0 0 20px;
              }
            }
          }
        }
      }
    }
  }
  /deep/ .el-drawer4 {
    .el-drawer__header {
      justify-content: space-between;
      align-items: center;
      margin: 0;
      padding: 12px 12px 0 20px;
      span {
        font-size: 20px;
        line-height: 36px;
        font-weight: bold;
        color: #343434;
      }
    }
    .el-drawer__body {
      .top_profile {
        padding: 40px 20px 0;
        text-align: center;
        border-bottom: 1px solid #e4e4e4;
        &.disable {
          border: none;
        }
      }
      .dialog_form {
        .el-textarea {
          .el-textarea__inner {
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            color: #343434;
            padding: 10px 12px;
            line-height: 26px;
            border: 2px solid #DCDFE6;
            &:hover {
              border-color: #C0C4CC;
            }
            &:focus {
              border-color: #92959A;
            }
          }
        }
      }
      .current {
        margin-top: 6px;
        text-align: center;
        color: #909090;
        line-height: 19px;
      }
    }
  }
  /deep/ .el-drawer5 {
    position: relative;
    .el-drawer__header {
      justify-content: space-between;
      align-items: center;
      margin: 0;
      padding: 12px 12px 0 20px;
      span {
        font-size: 20px;
        line-height: 36px;
        font-weight: bold;
        color: #343434;
      }
    }
    .el-drawer__body {
      .language_list {
        padding: 25px 16px 57px 16px;
        .language_li {
          padding: 14px 0 13px 4px;
          label {
            display: flex;
            align-items: center;
            .el-radio__input {
              width: 20px;
              height: 20px;
              .el-radio__inner {
                width: 20px;
                height: 20px;
              }
              .el-radio__inner::after {
                width: 8px;
                height: 8px;
              }
            }
            .is-checked {
              width: 20px;
            }
            .el-radio__label {
              font-size: 16px;
              font-weight: 500;
              letter-spacing: -0.39px;
              color: #4b4b4b;
              padding: 0 0 0 20px;
            }
          }
        }
      }
      .dialog-footer {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #e4e4e4;
        padding: 20px;
        text-align: center;
        .el-button {
          width: 100%;
          a {
            color: #fff;
            font-weight:bold;
            font-size: 16px;
          }
        }
      }
    }
  }
  .dialog_img {
    position: relative;
    width: 120px;
    margin: 0 auto;
    .dialog_img_img {
      width: 100%;
      padding: 50% 0;
      border-radius: 50%;
      background-image: url(https://s3.amazonaws.com/kdanfile/user-icon/defaults/user_icon_1_100.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
    }
    .dialog_img_change {
      display: none;
      position: absolute;
      right: 10%;
      bottom: 0;
      width: 25%;
      background-color: #fff;
      border-radius: 100%;
      padding: 5px;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
      background-color: #fdfdfd;
      img {
        display: block;
        width: 100%;
        cursor: pointer;
      }
    }
  }
  .dialog_img:hover {
    .dialog_img_change {
      display: block;
    }
  }
  .click_img {
    width: 120px;
    margin: 0 auto;
    .click_img_img {
      width: 100%;
      padding: 50% 0;
      border-radius: 50%;
      background-image: url(https://s3.amazonaws.com/kdanfile/user-icon/defaults/user_icon_1_100.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
    }
  }
  .top_profile {
    padding: 40px 20px 0;
    text-align: center;
    border-bottom: 1px solid rgb(228, 228, 228);
    &.disable {
      border: none;
    }
  }
  .dialog_form {
    position: relative;
    display: inline-block;
    max-width: 80%;
    margin-top: 16px;
    margin-bottom: 42px;
    line-height: 0;
    text-align: center;
    &.active {
      max-width: 100%;
      width: 100%;
      margin-top: 4px;
      margin-bottom: 30px;
    }
    .nickname, .name {
      position: relative;
      display: inline-block;
      font-size: 20px;
      font-weight: bold;
      line-height: 26px;
      color: #343434;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      margin: 0 auto;
    }
    img {
      position: absolute;
      top: 6px;
      right: -27px;
      width: 15px;
      cursor: pointer;
    }
  }
  .header .el-dialog .el-dialog__body .el-textarea{
    /deep/ .el-textarea__inner {
      padding: 10px 12px;
      line-height: 26px;
    }
  }
  .dialog_bottom1 {
    div {
      font-size: 16px;
      color: #818181;
      display: flex;
      justify-content: space-between;
      b {
        display: block;
        font-size: 16px;
        font-weight: 500;
        color: #818181;
      }
    }
    .privacy {
      padding: 0 24px;
      padding-top: 19px;
      span {
        position: relative;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.39px;
        color: #4b4b4b;
        .icon_mark {
          margin-left: 8px;
          cursor: pointer;
        }
        .bubble {
          width: 220px;
          position: absolute;
          top: 30px;
          left: 25px;
          display: none;
          padding: 8px 17px 8px 16px;
          border-radius: 4px;
          box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.1);
          border: solid 0.5px #f4f4f4;
          background-color: #0e0b22;
          font-size: 12px;
          font-weight: normal;
          line-height: 1.5;
          letter-spacing: -0.29px;
          text-align: center;
          color: #ffffff;
          .triangle {
            position: absolute;
            width: 5px;
            height: 5px;
            border: 5px;
            border-style: solid;
            border-color: transparent transparent #2c2c2c transparent;
            top: -10%;
            left: 46%;
          }
        }
        .icon_mark:hover + .bubble{
          display: block;
        }
      }
    }
    .email {
      margin-top: 26px;
      padding: 0 24px;
    }
    .subscription {
      margin-top: 20px;
      margin-bottom: 21px;
      padding: 0 24px 8px;
      border-bottom: 1px solid #e4e4e4;
      .lineHeight40 {
        line-height: 40px;
      }
      div {
        b {
          margin-right: 16px;
        }
      }
      .upgrade_btn a {
        display: inline-block;
        color: #fff;
        background-color: #ff5f4b;
        transition: .1s;
        padding: 12px 20px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        border-radius: 4px;
        &:hover {
          background-color: #ff7f6f;
        }
        &:active {
          background-color: #e65644;
        }
      }
    }
  }
  .dialog_bottom2_text {
    font-size: 14px;
    color: #323232;
    line-height: 19px;
    margin: 36px 20px 4px;
  }
  .dialog_bottom2 {
    margin-bottom: 20px;
    padding: 0 20px;
    .pictures_container {
      padding: 0 30px 20px;
      background-color: rgb(246, 246, 246);
    }
    .top_bottom {
      display: flex;
      justify-content: space-between;
      .picture {
        display: inline-block;
        position: relative;
        background-color: #f6f6f6;
        width: 60px;
        height: 60px;
        margin-top: 20px;
        border-radius: 100%;
        cursor: pointer;
        img {
          position: absolute;
          top: 16px;
          left: 30%;
        }
        .picture_bg {
          width: 100%;
          height: 100%;
          border-radius: 100%;
          background-image: linear-gradient(0deg, rgba(255, 95, 75, 0.55), rgba(255, 95, 75, 0.55));
        }
      }
      .picture0 {background: url("https://s3.amazonaws.com/kdanfile/user-icon/defaults/user_icon_1_100.png") no-repeat center;background-size: 100%}
      .picture1 {background: url("https://s3.amazonaws.com/kdanfile/user-icon/defaults/user_icon_2_100.png") no-repeat center;background-size: 100%}
      .picture2 {background: url("https://s3.amazonaws.com/kdanfile/user-icon/defaults/user_icon_3_100.png") no-repeat center;background-size: 100%}
      .picture3 {background: url("https://s3.amazonaws.com/kdanfile/user-icon/defaults/user_icon_4_100.png") no-repeat center;background-size: 100%}
      .picture4 {background: url("https://s3.amazonaws.com/kdanfile/user-icon/defaults/user_icon_5_100.png") no-repeat center;background-size: 100%}
      .picture5 {background: url("https://s3.amazonaws.com/kdanfile/user-icon/defaults/user_icon_6_100.png") no-repeat center;background-size: 100%}
      .picture6 {background: url("https://s3.amazonaws.com/kdanfile/user-icon/defaults/user_icon_7_100.png") no-repeat center;background-size: 100%}
      .picture7 {background: url("https://s3.amazonaws.com/kdanfile/user-icon/defaults/user_icon_8_100.png") no-repeat center;background-size: 100%}
      .picture8 {background: url("https://s3.amazonaws.com/kdanfile/user-icon/defaults/user_icon_9_100.png") no-repeat center;background-size: 100%}
      .picture9 {background: url("https://s3.amazonaws.com/kdanfile/user-icon/defaults/user_icon_10_100.png") no-repeat center;background-size: 100%}
    }
  }
  /deep/ .el-drawer3 {
    position: relative;
    max-width: 375px;
    .el-drawer__header {
      display: block;
      svg {
        cursor: pointer;
      }
    }
    .menu {
      display: block;
      width: 100%;
      text-align: left;
      div {
        a {
          color: #343434;
        }
      }
      .menus {
        font-weight: bold;
        border-color: #fff #fff #ff5f4b;
      }
    }
    .menu_list {
      margin-top: 84px;
      padding-left: 20px;
      div {
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 28px; 
      }
    }
    .menu_copyright {
      position: absolute;
      width: 100%;
      bottom: 15px;
      text-align: center;
      color: #343434;
      font-size: 11px;
      line-height: 44px;
      a{
        text-decoration: underline;
        color: #343434;
        font-size: 11px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .header {
      header {
        .top_img {
          .popper {
            top: 61px;
          }
        }
      }
    }
    header /deep/ .el-drawer3 {
      .el-drawer__header {
        padding: 10px 0;
        margin: 0 0 0 10px;
      }
      .menu {
        margin-top: 32px;
        padding-left: 20px;
      }
      .menu_copyright {
        bottom: 0;
      }
    }
    .dialog_img {
      .dialog_img_change {
        display: block;
      }
    }
    .dialog_bottom1 {
      margin-bottom: 40px;
      .privacy, .email, .subscription {
        padding-left: 20px;
        padding-right: 20px;
      }
      .subscription {
        .lineHeight40 {
          line-height: 40px;
        }
        div {
          b {
            margin-right: 16px;
          }
        }
      }
    }
    /deep/ .el-drawer4 {
      .el-drawer__body .current {
        font-size: 14px;
      }
      .dialog_bottom2_text {
        margin: 28px 16px 4px;
      }
    }
    .dialog-footer {
      padding: 0 20px 20px;
      text-align: right;
      .deleteAccount {
        display: inline-block;
        color: #ff5f4b;
        font-weight: 500;
        cursor: pointer;
      }
      /deep/ .el-button {
        width: 76px;
        height: 40px;
        font-weight: bold;
        &.cancel_btn {
          width: 90px;
          color: #838383;
          border: 1px solid rgb(210, 210, 210);
          background-color: #fff;
          &:hover {
            background-color: #f5f5f5;
          }
          &:active {
            background-color: rgb(229, 229, 229);
          }
        }
        span {
          font-size: 16px;
        }
      }
      .upgrade_btn {
        width: 100%;
        a {
          color: #fff;
          font-weight:bold;
          font-size: 16px;
        }
      }
    }
    .textAlignLeft {
      text-align: left;
    }
    #URLsuc, #Updatedsuc, #Fail {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  @media screen and (max-width: 575px) {
    .header /deep/ .save_url, .header /deep/ .privacy_hint {
      .el-dialog{
        width: 100%;
        max-width: 343px;
        border-radius: 10px;
        .el-dialog__body {
          padding: 19px 16px 28px;
        }
      }
    }
    .header /deep/ .el-dialog {
      .el-dialog__header {
        padding: 8px 8px 0 16px;
      }
      .el-dialog__footer {
        padding: 0 20px 16px;
      }
    }
    .header /deep/ .privacy_hint {
      .el-dialog{
        .el-dialog__header {
          padding: 20px 0 0 20px;
          span {
            font-size: 18px;
            font-weight: bold;
            color: #343434;
          }
        }
        .el-dialog__body {
          padding: 12px 20px 32px;
          font-size: 14px;
          line-height: 1.43;
          color: #4b4b4b;
        }
        .el-dialog__footer {
          .button_confirm  {
            margin-left: 6px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 420px) {
    .popper {
      right: 24px !important;
    }
    /deep/ .el-drawer4 {
      .dialog_bottom2 {
        padding: 0 !important;
        .top_bottom {
          .picture {
            width: 50px;
            height: 50px;
            margin: 10px 5px !important;
            img {
              top: 10px;
              left: 25%;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width:1025px) {
    header .top_img {
      .plus, .rectangle {
        margin-right: 16px;
      }
    }
  }
  @media screen and (max-width:1024px) {
    header {
      .title {
        display: none;
      }
      .menu {
        display: none;
      }
      /deep/ .el-drawer3 {
        .el-drawer__header {
          padding: 12px 0;
          margin: 0 0 0 10px;
          border-bottom: 1px solid #d0d0d0;
        }
        .menu {
          margin-top: 28px;
          padding-left: 20px;
          div {
            height: 36px;
            line-height: 36px;
            font-size: 22px;
            font-weight: bold;
          }
          .menu_markups {
            white-space: nowrap;
            width: 132px;
          }
          .menu_explore {
            white-space: nowrap;
            width: 78px;
            margin-top: 24px;
          }
        }
      }
    }
  }
  @media screen and (min-width: 1025px) {
    header {
      .bread {
        display: none;
      }
      .menu {
        margin-right: 12%;
        div a {
          padding: 0 10px;
        }
      }
    }
  }
  @media screen and (min-width: 1281px) {
    header {
      .menu {
        margin-right: 24%;
      }
    }
  }
</style>
