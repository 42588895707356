<template>
  <div class="share-wrapper">
    <el-dialog class="share" :title="langs.common.share" :visible.sync="dialogFormVisible1" :show-close="false" :before-close="handleDialogClose" width="480px" top="15vh">
      <template slot="title"><span>{{langs.common.share }}</span><inline-svg @click="handleDialogClose" :src="require('../../../assets/images/app_smart_bar/close.svg')"/></template>
      <div class="share_top">
        <div class="share_top_left img" v-lazy:background-image="shareUrl"></div>
        <div class="share_top_right">
          <p class="share_top_right_top">{{title}}</p>
          <p class="share_top_right_bottom">{{host}}</p>
        </div>
      </div>
      <div class="share_middle" v-show="currUser && ifMine">
        <el-checkbox v-model="checked" @change="ifInclude"></el-checkbox>
        <span>{{ langs.common.include }}</span>
      </div>
      <el-form>
        <el-form-item>
          <div class="el-input">{{shareLink}}</div>
          <el-button type="primary" class="form_btn form_btn1" size="small" :data-clipboard-text="shareLink" @click="copy()" v-show="!copied">{{langs.common.copy }}</el-button>
          <el-button type="primary" class="form_btn form_btn2" size="small" :data-clipboard-text="shareLink" v-show="copied">{{langs.common.copied }}</el-button>
        </el-form-item>
        <div class="form_bottom">
          <div @click="common.fb_share('', '', 'View #MyMarkups at ' + shareLink + ' with @pdfreader', shareLink)"><img src="../../../assets/images/app_smart_bar/chrome-extension-btn-share-facebook.svg" width="36" alt=""><p>Facebook</p></div>
          <div @click="common.share_twitter('View #MyMarkups at ' + shareLink + ' with @KdanMobile', '')"><img src="../../../assets/images/app_smart_bar/chrome-extension-btn-share-twitter.svg" width="36" alt=""><p>Twitter</p></div>
          <div @click="common.share_weibo(text, shareLink)"><img src="../../../assets/images/app_smart_bar/chrome-extension-btn-share-weibo.svg" width="36" alt=""><p>{{ langs.common.weibo }}</p></div>
        </div>
      </el-form>
    </el-dialog>
    <el-drawer
      custom-class="el-drawer1"
      :visible.sync="drawer1"
      size=""
      :before-close="handleClose"
      :direction="'btt'"
      :show-close="false"
      >
      <template slot="title"><span>{{langs.common.share }}</span><inline-svg @click="handleClose" :src="require('../../../assets/images/app_smart_bar/close.svg')"/></template>
      <div class="share_top">
        <div class="share_top_left img" v-lazy:background-image="shareUrl"></div>
        <div class="share_top_right">
          <p class="share_top_right_top">{{title}}</p>
          <p class="share_top_right_bottom">{{host}}</p>
        </div>
      </div>
      <div class="share_middle" v-show="currUser">
        <el-checkbox v-model="checked" @change="ifInclude"></el-checkbox>
        <span>{{ langs.common.include }}</span>
      </div>
      <el-form>
        <div class="form_bottom">
          <div class="form_bottom_box"><div @click="copy()" class="form_btn" :data-clipboard-text="shareLink"><img src="../../../assets/images/app_smart_bar/chrome-extension-btn-share-copylink.svg" alt=""></div><p>{{ langs.common.copylink }}</p></div>
          <div class="form_bottom_box"><div @click="common.fb_share('', '', 'View #MyMarkups at ' + shareLink + ' with @pdfreader', shareLink)"><img src="../../../assets/images/app_smart_bar/chrome-extension-btn-share-facebook.svg" alt=""></div><p>Facebook</p></div>
          <div class="form_bottom_box"><div @click="common.share_twitter('View #MyMarkups at ' + shareLink + ' with @KdanMobile', '')"><img src="../../../assets/images/app_smart_bar/chrome-extension-btn-share-twitter.svg" alt=""></div><p>Twitter</p></div>
          <div class="form_bottom_box"><div @click="common.share_weibo(text, shareLink)"><img src="../../../assets/images/app_smart_bar/chrome-extension-btn-share-weibo.svg" alt=""></div><p>{{ langs.common.weibo }}</p></div>
        </div>
      </el-form>
    </el-drawer>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import Clipboard from 'clipboard';
export default {
  props: ['dialogFormVisible1', 'drawer1', 'shareUrl', 'title', 'host', 'shareLink', 'ifBreadBottom','currUser','ifMine','lang','langs'],
  components: {
    InlineSvg
  },
  data() {
    return {
      copied: false,
      text: "#MyMarkups#我正在使用PDF Markup浏览网页，可实时标注网页重点内容@凯钿软件",
      checked: true
    }
  },
  methods: {
    copy () {
      var clipboard = new Clipboard('.form_btn')
      clipboard.on('success', e => {
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        // 不支持复制
        this.$message('Copy Failed');
        // 释放内存
        clipboard.destroy()
      })
      this.handleClose();
      this.copied = true;
      let that = this;
      setTimeout(function(){that.copied = false;},3000);
    },
    handleDialogClose () {
      this.$emit('update:dialogFormVisible1', false);
    },
    handleClose () {
      this.$emit('update:drawer1', false);
      if (this.ifBreadBottom === false) {
        this.$emit('update:ifBreadBottom', true);
      }
    },
    ifInclude () {
      this.$emit("changeUrl", this.checked);
    }
  }
}  
</script>

<style lang="scss" scoped>
  .share-wrapper /deep/ {
    .el-dialog__header, .el-drawer__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 36px;
      padding: 12px 12px 0 20px;
      svg {
        cursor: pointer;
      }
      span {
        font-size: 20px;
        font-weight: bold;
        line-height: 36px;
        color: #343434;
      }
    }
    .share_top {
      display: flex;
      align-items: center;
      margin-bottom: 19px;
      padding: 0 26px 32px 20px;
      border-bottom: solid 1px rgb(228, 228, 228);
      .img {
        flex: none;
        width: 120px;
        height: 83px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
      .share_top_right {
        padding-left: 16px;
        .share_top_right_top {
          color: #343434;
          font-size: 18px;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          line-height: 1.2;
        }
        .share_top_right_bottom {
          color: #979797;
          font-size: 12px;
          margin: 2px 0;
          word-wrap: break-word;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .share_middle {
      padding: 0 20px 20px;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.39px;
      color: #343434;
      border-bottom: 1px solid #e4e4e4;
      .el-checkbox {
        width: 18px;
        height: 18px;
        .el-checkbox__input {
          margin: 0;
          .el-checkbox__inner {
            width: 18px;
            height: 18px;
            margin: 0;
            border-radius: 3px;
          }
          .el-checkbox__inner::after {
            left: 6px;
            top: 3px;
          }
        }
        .is-focus {
          .el-checkbox__inner {
            border: solid 0.8px #DCDFE6;
          }
        }
      }
      span {
        margin-left: 4px;
      }
    }
  }
  .share /deep/ {
    .el-dialog__body {
      padding: 0;
    }
    .share_top {
      .share_top_right {
        .share_top_right_top {
          -webkit-line-clamp: 3;/*想省略几行就写几*/
        }
      }
    }
    .share_middle {
      span {
        margin-left: 4px;
      }
    }
    .el-form {
      margin-top: 24px;
      padding: 0 20px 0;
      .el-form-item {
        position: relative;
        margin-bottom: 0;
        .el-form-item__content {
          line-height: 0px;
          font-size: 0;
          .el-input {
            height: 48px;
            border-radius: 5px;
            background-color: #f5f5f5;
            font-size: 16px;
            letter-spacing: -0.39px;
            line-height: 40px;
            color: rgba(0, 0, 0, 0.5);
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 4px 82px 4px 15px;
          }
          .el-button {
            position: absolute;
            width: 80px;
            height: 40px;
            font-size: 16px;
            font-weight: bold;
            top: 0;
            right: 0;
            border-radius: 5px;
            background-color: #ff5f4b;
            color: #fff;
            margin: 4px 4px 0 0;
          }
          .form_btn {
            padding: 9px 0;
          }
          .form_btn1:hover {
            background-color: rgb(255,127,111);
          }
          .form_btn1:active {
            background-color: #e65644;
          }
          .form_btn2 {
            background-color: #ffa397;
            border: 0;
          }
        }
      }
      .form_bottom {
        display: flex;
        width: 60%;
        div{
          flex: 1;
          display: flex;
          flex-direction: column;
          padding: 24px 0 32px;
          border-radius: 8px;
          cursor: pointer;
          img{
            width: 36px;
            margin: 0 auto;
          }
          p{
            text-align: center;
            font-size: 14px;
            letter-spacing: -0.34px;
            margin-top: 10px;
            color: #747474;
          }
        }
      }
    }
  }

  /deep/ .el-drawer1 {
    border-radius: 10px 10px 0 0;
    .el-drawer__header {
      margin-bottom: 28px;
      padding: 8px 8px 0 20px;
    }
    .el-drawer__body {
      .share_top {
        display: flex;
        margin-bottom: 15px;
        padding: 0 16px 28px;
        .img {
          width: 100px;
          height: 70px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }
        .share_top_right {
          .share_top_right_top {
            -webkit-line-clamp: 2;/*想省略几行就写几*/
          }
        }
      }
      .share_middle {
        padding: 0 16px 16px;
      }
      .el-form {
        .form_bottom {
          display: flex;
          width: 90%;
          padding: 0 16px 23px;
          .form_bottom_box{
            & + .form_bottom_box {
              margin-left: 16px;
            }
            flex: 1;
            text-align: center;
            img {
              margin: 23px auto 8px;
              width: 70%;
              max-width: 50px;
            }
            p{
              font-size: 12px;
              line-height: 14px;
              text-align: center;
              color: #646464;
              letter-spacing: -0.29px;
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 476px) {
    /deep/ .el-drawer1 .el-drawer__body .el-form .form_bottom {
      margin-top: 23px;
      .form_bottom_box + .form_bottom_box {
        width: 50px;
        margin-left: 30px;
      }
      .form_bottom_box {
        flex: none;
        max-width: 23%;
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 36px;
          height: 36px;
          margin: 0 auto;
          img {
            width: 100%;
            max-with: 36px;
            margin: 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 320px) {
    /deep/ .el-drawer1 .el-drawer__body .el-form .form_bottom {
      padding: 0 10px 23px;
    }
  }
</style>
