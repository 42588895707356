<template>
  <div class="header_wrapper header" :class="{'path': shadow}">
    <header>
      <a :href="langs.common.header.a" class="title">
        <img src="../../../assets/images/app_smart_bar/img-markup-logotype.png">
      </a>
      <div class="top_img" v-if="currUser">
        <a class="goPremium" v-show="!pricingPage && !currUser.purchase_stat" :href="upgradeUrl">{{ langs.common.header.premium }}</a>
        <a class="goMarkup" :href="langs.common.header.a2">{{ langs.common.header.goMarkup }}</a>
      </div>
      <div class="top_img" v-else>
        <a class="goPremium goPremium_nolog" v-show="!pricingPage" :href="langs.common.header.a1">{{ langs.common.header.premium }}</a>
        <a class="log" id="Btn_LogIn_Navi" :href="utmLoginhref">{{ langs.common.header.log }}</a>
        <a class="sign" id="Btn_SignUp_Navi" :href="utmSignhref">{{ langs.common.header.sign }}</a>
      </div>
      <div class="bread">
        <img class="bread_img1" src="../../../assets/images/app_smart_bar/ic-navigtion-menu.svg" alt="" @click="drawer = true" style="width: 36px;height: 36px;">
      </div>
    </header>
    <el-drawer
      custom-class="el-drawer"
      title=""
      :visible.sync="drawer"
      :modal-append-to-body="false"
      size=""
      :show-close="false"
      :direction="'ltr'">
      <template slot="title"><img src="../../../assets/images/app_smart_bar/img-markup-logotype.png" alt=""><img @click="drawer = false" src="../../../assets/images/app_smart_bar/ic-close.svg"/></template>
      <div class="menu">
        <span><a :href="langs.common.header.a1" class="upgrade" v-show="!pricingPage&&(!currUser||(currUser&&!currUser.purchase_stat))">{{ langs.common.header.premium }}</a></span>
        <span><a :href="utmLoginhref" v-show="!currUser">{{ langs.common.header.log }}</a></span>
        <span><a :href="utmSignhref" v-show="!currUser">{{ langs.common.header.sign }}</a></span>
        <span><a :href="langs.common.header.a2" v-show="currUser">{{ langs.common.header.goMarkup }}</a></span>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
export default {
  props: {
    shadow: {
      default: null
    },
    currUser: {
      default: null
    },
    lang: {
      default: null
    },
    langs: {
      default: null
    },
    screenWidth: {
      default: ''
    },
    pricingPage: {
      type: Boolean,
      default: null
    },
    server_env: {
      default: 'production'
    }
  },
  data() {
    return {
      utmLoginhref: '/auth/kdan_mobile',
      utmSignhref: '/auth/kdan_mobile?default_form=sign_up_form',
      drawer: false,
      isSafari: false
    }
  },
  watch: {
    screenWidth(val) {
      this.screenWidth = val;
      if(this.screenWidth < 768){
      } else {
        this.drawer = false;
      }
    }
  },
  computed : {
    upgradeUrl () {
      const token = Cookies.get('access_token')
      if (this.isSafari && token) {
        const host = this.server_env === 'production' ? 'https://markups.preparing.kdanmobile.com/markup/app/mkpro?token=' : 'https://markups.kdanmobile.com/markup/app/mkpro?token='
        return (host + token)
      } else {
        return this.langs.common.header.a1
      }
    }
  },
  mounted () {
    this.isSafari = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
    if(window.location.search.indexOf('utm_source') != '-1' || window.location.search.indexOf('utm_medium') != '-1' || window.location.search.indexOf('utm_campaign') != '-1') {
      this.utmLoginhref = '/auth/kdan_mobile' + window.location.search + '&lang=' + this.lang;
      this.utmSignhref = '/auth/kdan_mobile?default_form=sign_up_form&' + window.location.search.substr(1) + '&lang=' + this.lang;
    } else {
      this.utmLoginhref = '/auth/kdan_mobile?lang=' + this.lang
      this.utmSignhref = '/auth/kdan_mobile?default_form=sign_up_form&lang=' + this.lang
    }
    //utm携带到会员系统
    var arrStr = document.cookie.split("; ");
    for (var i = 0; i < arrStr.length; i++) {
      var temp = arrStr[i].split("=");
      if (temp[0] == 'utmutm') {
        this.utmLoginhref = '/auth/kdan_mobile?' + arrStr[i].substr(8) + '&lang=' + this.lang;
        this.utmSignhref = '/auth/kdan_mobile?default_form=sign_up_form&' + arrStr[i].substr(8) + '&lang=' + this.lang;
      }
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .header {
    width: 100%;
    background-color: #fff;
    position: fixed;
    top: 0px;
    left: 0;
    z-index: 200;
  }
  .path {
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
  }
  header {
    display: flex;
    justify-content: space-between;
    height: 64px;
    margin: 0 auto;
    font-size: Clear Sans,sans-serif;
    .title {
      display: flex;
      align-items: center;
      img {
        width: 135px;
      }
    }
    .top_img {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .log, .sign, .goPremium, .goMarkup {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 20px;
        height: 40px;
        border-radius: 4px;
        background-color: #ff5f4b;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        cursor: pointer;
      }
      .goPremium {
        margin-right: 24px;
      }
      .log {
        margin-right: 12px;
      }
      .sign:hover, .goMarkup:hover {
        opacity: 0.8;
      }
      .sign:active, .goMarkup:active {
        background-color: #e65644;
      }
      .log {
        background-color: #fff;
        color: #ff5f4b;
        border: solid 1.5px #ff5f4b;
        &:hover {
          background-color: #fff1ef;
        }
        &:active {
          background-color: #fff;
        }
      }
      .sign {
        color: #fff;
      }
      .goPremium {
        background-color: #fff;
        font-weight: 500;
        color: #1c1c1c;
      }
      .goPremium_nolog {
        padding: 0 24px 0 0;
        height: 28px;
        font-size: 16px;
        border-radius: 0;
        border-right: 1px solid #e1e1e1;
      }
      .goMarkup {
        color: #fff;
      }
    }
    .bread {
      display: none;
      align-items: center;
      padding: 0 12px;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023.9px) {
    header {
      max-width: 688px;
    }
  }
  @media screen and (max-width: 768px) {
    header {
      padding: 0 16px;
      .top_img {
        display: none;
      }
      .bread {
        display: flex;
      }
    }
    /deep/ .el-drawer {
      position: relative;
      max-width: 320px;
      .el-drawer__header {
        display: flex;
        justify-content: space-between;
        padding: 18px 0 19px;
        margin: 0 12px 0 16px;
        border-bottom: 1px solid #dcdcdc;
        img {
          max-width: 106px;
          height: 28px;
        }
        svg {
          max-width: 36px;
          cursor: pointer;
        }
      }
      .menu {
        display: flex;
        flex-direction: column;
        margin-top: 22px;
        padding-left: 20px;
        span {
          font-size: 18px;
          font-weight: bold;
          line-height: 56px;
          a {
            color: #ff5f4b;
          }
          .upgrade {
            font-weight: 500;
            color: #343434;
          }
        }
      }
    }
  }
  @media screen and (min-width: 1024px) {
    header {
      max-width: 944px;
    }
  }
  @media screen and (min-width: 1280px) {
    header {
      max-width: 1080px;
    }
  }
  @media screen and (min-width: 1440px) {
    header {
      max-width: 1240px;
    }
  }
</style>
