<template>
  <div class="dashboard-container">
    <div id="eventBar" :class="{cms: eventBarData.link}" :style="{backgroundColor: eventBarData.bgColor}"><a :href="eventBarData.link" target="_blank" :style="{color: eventBarData.color}">{{eventBarData.content}}</a></div>
    <Header :class="{barHeader: eventBarData.link, eventHeader: barShadow}" :shadow="shadow" :pricingPage="pricingPage" :currUser.sync="currUser" :lang="value" :langs="langs" :screenWidth="screenWidth" />
    <div class="app-container">
      <div id="plans">
        <h1>{{ langs.pricing.plans.title }}</h1>
        <div class="menu">
          <div class="menu_option" :class="{active: whichPlanup == 0}" @click="annuallyUp()">{{ langs.pricing.menu.annually }}</div>
          <div class="menu_option quarterly" :class="{active: whichPlanup == 1}" :style="{borderLeft: whichPlanup != 2 ? '0px' : '1px solid #d8d8d8', borderRight: whichPlanup != 0 ? '0px' : '1px solid #d8d8d8'}" @click="quarterlyUp()">{{ langs.pricing.menu.quarterly }}</div>
          <div class="menu_option" :class="{active: whichPlanup == 2}" @click="monthlyUp()">{{ langs.pricing.menu.monthly }}</div>
          <div class="slideBlock" :style="{left: whichPlanup == 1 ? (isCross ? '98px' : '126px') : whichPlanup == 2 ? (isCross ? '190px' : '246px') : '4px'}"></div>
        </div>
        <div class="plans_content">
          <div class="plans_content_three">
            <div class="plans_content_left">
              <div class="plans_title">{{ langs.pricing.plans.content.basic.title }}</div>
              <div class="plans_price"><b>{{ langs.pricing.plans.content.basic.free }}</b></div>
              <a id="Btn_SignUp_PricingTable" :href="start_a" v-show="!currUser">{{ langs.pricing.plans.content.basic.link }}</a>
              <a :href="start_a" v-show="currUser">{{ langs.pricing.plans.content.basic.link }}</a>
              <div class="plans_item"><img src="../../../assets/images/app_smart_bar/pricing-ic-plan-check.svg" alt=""><span>{{ langs.pricing.plans.content.basic.span1 }}</span></div>
              <div class="plans_item"><img src="../../../assets/images/app_smart_bar/pricing-ic-plan-check.svg" alt=""><span>{{ langs.pricing.plans.content.basic.span2 }}</span></div>
              <div class="plans_item"><img src="../../../assets/images/app_smart_bar/pricing-ic-plan-check.svg" alt=""><span>{{ langs.pricing.plans.content.basic.span3 }}</span></div>
              <div class="plans_item"><img src="../../../assets/images/app_smart_bar/pricing-ic-plan-check.svg" alt=""><span>{{ langs.pricing.plans.content.basic.span4 }}</span></div>
              <div class="plans_item"><img src="../../../assets/images/app_smart_bar/pricing-ic-plan-check.svg" alt=""><span>{{ langs.pricing.plans.content.basic.span5 }}</span></div>
            </div>
            <div class="plans_content_center">
              <div class="plans_title">{{ langs.pricing.plans.content.pro.title }}</div>
              <div class="plans_price"><span>USD</span><b>{{markupPriceup}}</b>
                <i v-show="markupEveryup==0">{{langs.pricing.year}}</i>
                <i v-show="markupEveryup==1">{{langs.pricing.quarter}}</i>
                <i v-show="markupEveryup==2">{{langs.pricing.month}}</i>
                <div class="save" :style="{marginLeft: whichPlanup == 1 ? '14px' : '20px'}" v-show="!isCross && markupEveryup==0">{{ langs.pricing.plans.content.pro.save }}</div></div>
              <div class="plans_explain" v-show="explain==0">{{langs.pricing.plans.content.pro.annuallyExplain}}</div>
              <div class="plans_explain" v-show="explain==1">{{langs.pricing.plans.content.pro.quarterlyExplain}}</div>
              <div class="plans_explain" v-show="explain==2">{{langs.pricing.plans.content.pro.monthlyExplain}}</div>
              <a v-if="markupEveryup === 0" id="Btn_Upgrade_PricingTable_Pro" :href="markupPro.annuallyLink" @click="setUpgrade()">{{ langs.pricing.plans.content.pro.link }}</a>
              <a v-else-if="markupEveryup === 1" id="Btn_Upgrade_PricingTable_Pro" :href="markupPro.quarterlyLink" @click="setUpgrade()">{{ langs.pricing.plans.content.pro.link }}</a>
              <a v-else="markupEveryup === 2" id="Btn_Upgrade_PricingTable_Pro" :href="markupPro.monthlyLink" @click="setUpgrade()">{{ langs.pricing.plans.content.pro.link }}</a>
              <div class="plans_item"><img src="../../../assets/images/app_smart_bar/pricing-ic-plan-check.svg" alt=""><span>{{ langs.pricing.plans.content.pro.span1 }}</span></div>
              <div class="plans_item"><img src="../../../assets/images/app_smart_bar/pricing-ic-plan-check.svg" alt=""><span>{{ langs.pricing.plans.content.pro.span2 }}</span></div>
              <div class="plans_item"><img src="../../../assets/images/app_smart_bar/pricing-ic-plan-check.svg" alt=""><span>{{ langs.pricing.plans.content.pro.span3 }}</span></div>
              <div class="plans_item"><img src="../../../assets/images/app_smart_bar/pricing-ic-plan-check.svg" alt=""><span>{{ langs.pricing.plans.content.pro.span4 }}</span></div>
              <div class="plans_item"><img src="../../../assets/images/app_smart_bar/pricing-ic-plan-check.svg" alt=""><span>{{ langs.pricing.plans.content.pro.span5 }}</span></div>
              <div class="plans_item"><img src="../../../assets/images/app_smart_bar/pricing-ic-plan-check.svg" alt=""><span>{{ langs.pricing.plans.content.pro.span6 }}</span></div>
            </div>
            <div class="plans_content_right">
              <div class="plans_title">{{ langs.pricing.plans.content.creativity.title }}</div>
              <div class="plans_price"><span>USD</span><b>{{creativityPriceup}}</b>
                <i v-show="creativityEveryup==0">{{ langs.pricing.year }}</i>
                <i v-show="creativityEveryup==1">{{ langs.pricing.quarter }}</i>
                <i v-show="creativityEveryup==2">{{ langs.pricing.month }}</i>
                <div class="save" :style="{marginLeft: whichPlanup == 1 ? '14px' : '20px'}" v-show="!isCross && creativityEveryup === 0">{{ langs.pricing.plans.content.creativity.save }}</div></div>
              <div class="plans_explain" v-show="explain==0">{{ langs.pricing.plans.content.creativity.annuallyExplain }}</div>
              <div class="plans_explain" v-show="explain==1">{{ langs.pricing.plans.content.creativity.quarterlyExplain }}</div>
              <div class="plans_explain" v-show="explain==2">{{ langs.pricing.plans.content.creativity.monthlyExplain }}</div>
              <a v-if="creativityEveryup === 0" id="Btn_Upgrade_PricingTable_C365" :href="creativityStore.annuallyLink" @click="setUpgrade()">
                <span>{{ langs.pricing.plans.content.creativity.link2 }}</span>
              </a>
              <a v-else-if="creativityEveryup==1" id="Btn_Upgrade_PricingTable_C365" :href="creativityStore.quarterlyLink" @click="setUpgrade()">
                <span>{{ langs.pricing.plans.content.creativity.link1 }}</span>
              </a>
              <a v-else-if="creativityEveryup==2" id="Btn_Upgrade_PricingTable_C365" :href="creativityStore.monthlyLink" @click="setUpgrade()">
                <span>{{ langs.pricing.plans.content.creativity.link1 }}</span>
              </a>
              <div class="plans_item"><img src="../../../assets/images/app_smart_bar/pricing-ic-plan-check.svg" alt=""><span>{{ langs.pricing.plans.content.creativity.span1 }}</span></div>
              <div class="plans_item"><img src="../../../assets/images/app_smart_bar/pricing-ic-plan-check.svg" alt=""><span>{{ langs.pricing.plans.content.creativity.span2 }}</span></div>
              <div class="plans_item"><img src="../../../assets/images/app_smart_bar/pricing-ic-plan-check.svg" alt=""><span>{{ langs.pricing.plans.content.creativity.span3 }}</span></div>
              <div class="plans_item"><img src="../../../assets/images/app_smart_bar/pricing-ic-plan-check.svg" alt=""><span>{{ langs.pricing.plans.content.creativity.span4 }}</span></div>
              <div class="plans_item"><img src="../../../assets/images/app_smart_bar/pricing-ic-plan-check.svg" alt=""><span>{{ langs.pricing.plans.content.creativity.span5 }}</span></div>
              <div class="plans_item"><img src="../../../assets/images/app_smart_bar/pricing-ic-plan-check.svg" alt=""><span>{{ langs.pricing.plans.content.creativity.span6 }}</span></div>
              <div class="plans_item"><img src="../../../assets/images/app_smart_bar/pricing-ic-plan-check.svg" alt=""><span>{{ langs.pricing.plans.content.creativity.span7 }}<b>{{ langs.pricing.plans.content.creativity.title }}</b>{{ langs.pricing.plans.content.creativity.span8 }}</span></div>
            </div>
          </div>
          <div class="cancel">
            <img src="../../../assets/images/app_smart_bar/pricing-ic-auto-renew.svg" alt="">
            <span>{{ langs.pricing.plans.content.cancel }}</span>
          </div>
        </div>
      </div>
      <Edu :outside="outside" :langs="langs" :dialogEdu.sync="dialogEdu" :drawerEdu.sync="drawerEdu"/>

      <div id="detail" v-show="!ifCross">
        <h2>{{ langs.pricing.detail.title }}</h2>
        <div class="menu">
          <div class="menu_option" :class="{active: whichPlandown == 0}" @click="annuallyDown()">{{ langs.pricing.menu.annually }}</div>
          <div class="menu_option quarterly" :class="{active: whichPlandown == 1}" :style="{borderLeft: whichPlandown != 2 ? '0px' : '1px solid #d8d8d8', borderRight: whichPlandown != 0 ? '0px' : '1px solid #d8d8d8'}" @click="quarterlyDown()">{{ langs.pricing.menu.quarterly }}</div>
          <div class="menu_option" :class="{active: whichPlandown == 2}" @click="monthlyDown()">{{ langs.pricing.menu.monthly }}</div>
          <div class="slideBlock" :style="{left: whichPlandown == 1 ? '124px' : whichPlandown == 2 ? '244px' : '4px'}"></div>
        </div>
        <div class="detail_content">
          <div class="detail_content_one">
            <div class="detail_item_title"><div class="detail_title" style='color: #fff;'>|||</div><div class="detail_price"><b>{{ langs.pricing.detail.content.features.title }}</b></div></div>
            <div class="detail_item">{{ langs.pricing.detail.content.features.span1 }}</div>
            <div class="detail_item">{{ langs.pricing.detail.content.features.span2 }}</div>
            <div class="detail_item">{{ langs.pricing.detail.content.features.span3 }}</div>
            <div class="detail_item">{{ langs.pricing.detail.content.features.span4 }}</div>
            <div class="detail_item">{{ langs.pricing.detail.content.features.span5 }}<div class="hint" :data-name="langs.pricing.detail.tip"><img src="../../../assets/images/app_smart_bar/pricing-ic-info.svg" alt=""></div></div>
            <div class="detail_item">{{ langs.pricing.detail.content.features.span6 }}<div class="hint" :data-name="langs.pricing.detail.tip"><img src="../../../assets/images/app_smart_bar/pricing-ic-info.svg" alt=""></div></div>
            <div class="detail_item">{{ langs.pricing.detail.content.features.span7 }}<div class="hint" :data-name="langs.pricing.detail.tip"><img src="../../../assets/images/app_smart_bar/pricing-ic-info.svg" alt=""></div></div>
            <div class="detail_item">{{ langs.pricing.detail.content.features.span8 }}<div class="hint" :data-name="langs.pricing.detail.tip"><img src="../../../assets/images/app_smart_bar/pricing-ic-info.svg" alt=""></div></div>
            <div class="detail_item">{{ langs.pricing.detail.content.features.span9 }}<div class="hint" :data-name="langs.pricing.detail.tip"><img src="../../../assets/images/app_smart_bar/pricing-ic-info.svg" alt=""></div></div>
            <div class="detail_item">{{ langs.pricing.detail.content.features.span10 }}<div class="hint" :data-name="langs.pricing.detail.tip"><img src="../../../assets/images/app_smart_bar/pricing-ic-info.svg" alt=""></div></div>
            <div class="detail_item detail_item_last"><div class="detail_item_last_up">{{ langs.pricing.detail.content.features.span11 }}</div><div class="detail_item_last_down">{{ langs.pricing.detail.content.features.span12 }}</div><span class="hint hint1" :data-name="langs.pricing.detail.tips"><img src="../../../assets/images/app_smart_bar/pricing-ic-info.svg" alt=""></span></div>
          </div>
          <div class="detail_content_two">
            <div class="detail_item_title"><div class="detail_title">{{ langs.pricing.detail.content.basic.title }}</div><div class="detail_price"><b>{{ langs.pricing.detail.content.basic.free }}</b></div></div>
            <div class="detail_item">8</div>
            <div class="detail_item">2</div>
            <div class="detail_item">{{ langs.pricing.detail.content.basic.span3 }}</div>
            <div class="detail_item">{{ langs.pricing.detail.content.basic.span4 }}</div>
            <div class="detail_item">{{ langs.pricing.detail.content.basic.span5 }}</div>
            <div class="detail_item"><img src="../../../assets/images/app_smart_bar/ic-close.svg" alt=""></div>
            <div class="detail_item"><img src="../../../assets/images/app_smart_bar/ic-close.svg" alt="" style="width: 28px;"></div>
            <div class="detail_item"><img src="../../../assets/images/app_smart_bar/ic-close.svg" alt="" style="width: 28px;"></div>
            <div class="detail_item"><img src="../../../assets/images/app_smart_bar/ic-close.svg" alt="" style="width: 28px;"></div>
            <div class="detail_item"><img src="../../../assets/images/app_smart_bar/ic-close.svg" alt="" style="width: 28px;"></div>
            <div class="detail_item detail_item_last"><img src="../../../assets/images/app_smart_bar/ic-close.svg" alt="" style="width: 28px;"></div>
            <div class="detail_item_a"><a id="Btn_SignUp_PricingDetail" :href="start_a" v-show="!currUser">{{ langs.pricing.detail.content.basic.link }}</a><a :href="start_a" v-show="currUser">{{ langs.pricing.detail.content.basic.link }}</a></div>
          </div>
          <div class="detail_content_three">
            <div class="detail_item_title" :data-name="langs.pricing.detail.content.pro.span0"><div class="detail_title">{{ langs.pricing.detail.content.pro.title }}</div><div class="detail_price"><span>USD</span><b>{{markupPricedown}}</b>
              <i v-show="markupEverydown==0">{{langs.pricing.year}}</i>
              <i v-show="markupEverydown==1">{{langs.pricing.quarter}}</i>
              <i v-show="markupEverydown==2">{{langs.pricing.month}}</i>
            </div></div>
            <div class="detail_item">{{ langs.pricing.detail.content.pro.span1 }}</div>
            <div class="detail_item">6</div>
            <div class="detail_item">{{ langs.pricing.detail.content.pro.span3 }}</div>
            <div class="detail_item">{{ langs.pricing.detail.content.pro.span4 }}</div>
            <div class="detail_item">{{ langs.pricing.detail.content.pro.span5 }}</div>
            <div class="detail_item"><img src="../../../assets/images/app_smart_bar/pricing-ic-plan-check-28.svg" alt=""></div>
            <div class="detail_item"><img src="../../../assets/images/app_smart_bar/pricing-ic-plan-check-28.svg" alt=""></div>
            <div class="detail_item"><img src="../../../assets/images/app_smart_bar/pricing-ic-plan-check-28.svg" alt=""></div>
            <div class="detail_item"><img src="../../../assets/images/app_smart_bar/pricing-ic-plan-check-28.svg" alt=""></div>
            <div class="detail_item"><img src="../../../assets/images/app_smart_bar/pricing-ic-plan-check-28.svg" alt=""></div>
            <div class="detail_item detail_item_last"><img src="../../../assets/images/app_smart_bar/ic-close.svg" alt="" style="width: 28px;"></div>
            <div v-if="markupEverydown === 0" class="detail_item_a"><a id="Btn_Upgrade_PricingDetail_Pro" :href="markupPro.annuallyLink" @click="setUpgrade()">{{ langs.pricing.detail.content.pro.link }}</a></div>
            <div v-else-if="markupEverydown === 1" class="detail_item_a"><a id="Btn_Upgrade_PricingDetail_Pro" :href="markupPro.quarterlyLink" @click="setUpgrade()">{{ langs.pricing.detail.content.pro.link }}</a></div>
            <div v-else="markupEverydown === 2" class="detail_item_a"><a id="Btn_Upgrade_PricingDetail_Pro" :href="markupPro.monthlyLink" @click="setUpgrade()">{{ langs.pricing.detail.content.pro.link }}</a></div>
          </div>
          <div class="detail_content_four">
            <div class="detail_item_title"><div class="detail_title">{{ langs.pricing.detail.content.creativity.title }}</div><div class="detail_price"><span>USD</span><b>{{creativityPricedown}}</b>
              <i v-show="creativityEverydown==0">{{langs.pricing.year}}</i>
              <i v-show="creativityEverydown==1">{{langs.pricing.quarter}}</i>
              <i v-show="creativityEverydown==2">{{langs.pricing.month}}</i>
            </div></div>
            <div class="detail_item">{{ langs.pricing.detail.content.creativity.span1 }}</div>
            <div class="detail_item">6</div>
            <div class="detail_item">{{ langs.pricing.detail.content.creativity.span3 }}</div>
            <div class="detail_item">{{ langs.pricing.detail.content.creativity.span4 }}</div>
            <div class="detail_item">{{ langs.pricing.detail.content.creativity.span5 }}</div>
            <div class="detail_item"><img src="../../../assets/images/app_smart_bar/pricing-ic-plan-check-28.svg" alt=""></div>
            <div class="detail_item"><img src="../../../assets/images/app_smart_bar/pricing-ic-plan-check-28.svg" alt=""></div>
            <div class="detail_item"><img src="../../../assets/images/app_smart_bar/pricing-ic-plan-check-28.svg" alt=""></div>
            <div class="detail_item"><img src="../../../assets/images/app_smart_bar/pricing-ic-plan-check-28.svg" alt=""></div>
            <div class="detail_item"><img src="../../../assets/images/app_smart_bar/pricing-ic-plan-check-28.svg" alt=""></div>
            <div class="detail_item detail_item_last"><img src="../../../assets/images/app_smart_bar/pricing-ic-plan-check-28.svg" alt=""></div>
            <div class="detail_item_a" v-if="creativityEverydown==0">
              <a :href="creativityStore.annuallyLink" @click="setUpgrade()">{{ langs.pricing.detail.content.creativity.link }}</a>
              <span>{{ langs.pricing.detail.content.creativity.sublink2 }}</span>
            </div>
            <div class="detail_item_a" v-else-if="creativityEverydown==1">
              <a :href="creativityStore.quarterlyLink" @click="setUpgrade()">{{ langs.pricing.detail.content.creativity.link }}</a>
              <span>{{ langs.pricing.detail.content.creativity.sublink1 }}</span>
            </div>
            <div class="detail_item_a" v-else="creativityEverydown==2">
              <a :href="creativityStore.monthlyLink" @click="setUpgrade()">{{ langs.pricing.detail.content.creativity.link }}</a>
              <span>{{ langs.pricing.detail.content.creativity.sublink1 }}</span>
            </div>
          </div>
        </div>
      </div>
      <div id="detail_min" v-show="ifCross">
        <h2>{{ langs.pricing.detail.title }}</h2>
        <div class="menu_min">
          <div class="dropdown" v-for="(item,index) in menu_list_top" :key="item.index">
            <div class="dropdown_title" @click="isShow($event)"><span>{{item.title}}</span><img src="../../../assets/images/app_smart_bar/mk-web-ic-actionsheet-before.svg" alt=""></div>
            <div class="dropdown_content">
              <div class="dropdown_content_item"><span>{{ langs.pricing.detail.content.basic.title }}</span><i>{{item.basic}}</i></div>
              <div class="dropdown_content_item item_pro"><span class="span_pro">{{ langs.pricing.detail.content.pro.title }}</span><i>{{item.pro}}</i></div>
              <div class="dropdown_content_item"><span>{{ langs.pricing.detail.content.creativity.title }}</span><i>{{item.creativity}}</i></div>
            </div>
          </div>
          <div class="dropdown" v-for="(item,index) in menu_list_bottom" :key="item.index"  :class="index == menu_list_bottom.length - 1 ? 'dropdown_last' : ''">
            <div class="dropdown_title" @click="isShow($event)"><span>{{item.title}}</span><img src="../../../assets/images/app_smart_bar/mk-web-ic-actionsheet-next.svg" alt=""></div>
            <div class="active">
              <div class="dropdown_content_item"><span>{{ langs.pricing.detail.content.basic.title }}</span><img :src="item.basic" alt=""></div>
              <div class="dropdown_content_item item_pro"><span class="span_pro">{{ langs.pricing.detail.content.pro.title }}</span><img :src="item.pro" alt=""></div>
              <div class="dropdown_content_item"><span>{{ langs.pricing.detail.content.creativity.title }}</span><img :src="item.creativity" alt=""></div>
            </div>
          </div>
        </div>
      </div>
      <div id="faq">
        <h2>FAQ</h2>
        <div class="faq_content">
          <div class="faq_left">
            <div class="faq_item faq_one">
              <h3>{{ langs.pricing.faq.content.title1 }}</h3>
              <p>{{ langs.pricing.faq.content.content1 }}</p>
            </div>
            <div class="faq_item faq_two">
              <h3>{{ langs.pricing.faq.content.title2 }}</h3>
              <p>{{ langs.pricing.faq.content.content2 }}</p>
            </div>
          </div>
          <div class="faq_right">
            <div class="faq_item faq_three">
              <h3>{{ langs.pricing.faq.content.title3 }}</h3>
              <p>
                <span>{{ langs.pricing.faq.content.content31 }}</span>
                <a :href="langs.pricing.faq.content.a1" target="_blank">{{ langs.pricing.faq.content.link1 }}</a>
                <span>{{ langs.pricing.faq.content.content32 }}</span>
              </p>
            </div>
            <div class="faq_item faq_four">
              <h3>{{ langs.pricing.faq.content.title4 }}</h3>
              <p>{{ langs.pricing.faq.content.content4 }}</p>
            </div>
          </div>
        </div>
        <div class="help">
          <a :href="langs.pricing.faq.content.a" target="_blank">{{ langs.pricing.faq.content.help }}<img src="../../../assets/images/app_smart_bar/ic-openwebpage.svg" alt=""></a>
        </div>
      </div>
    </div>
    <div id="footer">
      <div class="footer_top">
        <span>
          <a :href="langs.common.footer.a2" target="_blank">{{ langs.common.footer.terms }}</a> | <a :href="langs.common.footer.a3" target="_blank">{{ langs.common.footer.privacy }}</a>{{' | © 2009-' + fullYear}}
        </span>
        <span>&nbsp;<a :href="langs.common.footer.a5" target="_blank">Kdan Mobile Software Ltd.</a> All Right Reserved.</span>
      </div>
      <div class="footer_bottom">
        <el-select v-model="value" placeholder="English" @change="home">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            <span>{{ item.label }}</span>
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import axios from 'axios';
import Header from '../common/landingHeader';
import Edu from '../common/Education';
import check from '../../../assets/images/app_smart_bar/pricing-ic-plan-check-28.svg';
import close from '../../../assets/images/app_smart_bar/ic-close.svg';
import InlineSvg from 'vue-inline-svg';

export default {
  name: 'pricing',
  components: {
    Header,
    Edu,
    InlineSvg,
  },
  props: ['outside'],
  data() {
    const creativestoreUrl = this.outside.server_env == 'production' ? 'https://creativestore.kdanmobile.com' : 'https://creative-store-client.preparing.kdanmobile.com'
    return {
      langues: ['en', 'zh-tw', 'zh-cn', 'ja', 'es', 'ko'],
      currUser: this.outside.currUser,
      server_env: this.outside.server_env,
      screenWidth: '',
      screenHeight: '',
      shadow: false,
      ifCross: false,
      isCross: false,
      whichPlanup: 0,
      whichPlandown: 0,
      markupPriceup: ' $39.99 ',
      markupEveryup: 0,
      creativityPriceup: ' $59.99 ',
      creativityEveryup: 0,
      explain: 0,
      markupPricedown: ' $39.99 ',
      markupEverydown: 0,
      creativityPricedown: ' $59.99 ',
      creativityEverydown: 0,
      pricingPage: true,
      menu_list_top: [
      {index: 0, title: 'Highlights per article', basic: '8', pro: 'Unlimited', creativity: 'Unlimited'}, 
      {index: 1, title: 'Highlight colors', basic: '2', pro: '6', creativity: '6'}, 
      {index: 2, title: 'Auto Summary', basic: '1 per article', pro: '5 per article', creativity: '5 per article'}, 
      {index: 3, title: 'Kdan Cloud Storage', basic: '2 GB', pro: '500GB', creativity: '1TB'}, 
      {index: 4, title: 'PDF Editing Tools', basic: 'Basic', pro: 'Advanced', creativity: 'Advanced'}],
      menu_list_bottom: [
      {index: 5, title: 'Flatten PDF', basic: close, pro: check, creativity: check}, 
      {index: 6, title: 'Text to Speech Reader', basic: close, pro: check, creativity: check}, 
      {index: 7, title: 'Audio note-taking', basic: close, pro: check, creativity: check}, 
      {index: 8, title: 'Watermarks', basic: close, pro: check, creativity: check}, 
      {index: 9, title: 'Slide show mode', basic: close, pro: check, creativity: check}, 
      {index: 10, title: 'Access to all Creativity 365 App Series', basic: close, pro: close, creativity: check}],
      start_a: (this.outside.currUser ? '/mymarkups' : '/auth/kdan_mobile'),
      markupPro: {
        annuallyLink: creativestoreUrl + '/subscription/markuppro/checkout?default-plan=com.kdanmobile.stripe.PDFReader.deluxe_business_pack_year.001&utm_source=MyMarkup&utm_campaign=Upgrade&utm_medium=Pricing',
        quarterlyLink: creativestoreUrl + '/subscription/markuppro/checkout?default-plan=com_kdanmobile_stripe_pdfreader_deluxe_business_pack_quarter_001&utm_source=MyMarkup&utm_campaign=Upgrade&utm_medium=Pricing',
        monthlyLink: creativestoreUrl + '/subscription/markuppro/checkout?default-plan=com.kdanmobile.stripe.pdfreader.deluxe_business_pack_month.001&utm_source=MyMarkup&utm_campaign=Upgrade&utm_medium=Pricing'
      },
      creativityStore: {
        annuallyLink: creativestoreUrl + '/subscription/creativity365/checkout?default-plan=com.kdanmobile.stripe.all_access_pack_year.001&utm_source=MyMarkup&utm_campaign=Upgrade&utm_medium=Pricing&default-coupon=7DAYFREE',
        quarterlyLink: creativestoreUrl + '/subscription/creativity365/checkout?default-plan=com.kdanmobile.stripe.all_access_pack.three_month&utm_source=MyMarkup&utm_campaign=Upgrade&utm_medium=Pricing&default-coupon=3DAYFREE',
        monthlyLink: creativestoreUrl + '/subscription/creativity365/checkout?default-plan=com.kdanmobile.stripe.all_access_pack_month.001&utm_source=MyMarkup&utm_campaign=Upgrade&utm_medium=Pricing&default-coupon=3DAYFREE'
      },
      options: [{
        value: 'en',
        label: 'English'
      }, {
        value: 'es',
        label: 'Español'
      }, {
        value: 'zh-tw',
        label: '中文 (繁體)'
      }, {
        value: 'zh-cn',
        label: '中文 (简体)'
      }, {
        value: 'ja',
        label: '日本語'
      }, {
        value: 'ko',
        label: '한국어'
      }],
      value: 'en',
      fullYear: '',
      barShadow: false,
      dialogEdu: false,
      drawerEdu: false,
      eventBarData: {
        color: '',
        bgColor: '',
        content: '',
        link: ''
      }
    }
  },
  watch: {
    screenWidth (val) {
      this.screenWidth = val;
      if (this.screenWidth <= 1024) {
        this.ifCross = true;
        this.isCross = false;
        if (this.screenWidth < 768) {
          this.isCross = true;
        }
        if (this.screenWidth < 930) {
          this.dialogEdu = false;
        } else {
          this.drawerEdu = false;
        }
      } else {
        this.ifCross = false;
        this.isCross = false;
      }
    }
  },
  computed:{
    langs: function(val) {
      return JSON.parse(JSON.stringify(this.outside.langs))
    }
  },
  beforeMount () {
    if (this.currUser) {
      if (this.langues.includes(this.currUser.lang.toLowerCase())) {
        this.value = this.currUser.lang.toLowerCase();
      } else {
        this.value = 'en';
      }
    } else {
      // const lang = Cookies.get('lang');
      // if (lang) {
      //   this.value = lang;
      // } else {
      //   this.value = 'en';
      // }
      if (window.location.pathname.indexOf('/es') != -1 || window.location.pathname.indexOf('/zh-tw') != -1 || window.location.pathname.indexOf('/zh-cn') != -1 || window.location.pathname.indexOf('/ja') != -1 || window.location.pathname.indexOf('/ko') != -1) {
        const lang = window.location.pathname.substr(1);
        this.value =  lang.substring(0,lang.length-8);
      } else {
        this.value = 'en';
      }
      Cookies.set('lang', this.value, {expires: 7});
    }
    if (this.value == 'en') {
      if (window.location.pathname.indexOf('/es/pricing') != -1 || window.location.pathname.indexOf('/zh-tw/pricing') != -1 || window.location.pathname.indexOf('/zh-cn/pricing') != -1 || window.location.pathname.indexOf('/ja/pricing') != -1 || window.location.pathname.indexOf('/ko/pricing') != -1) {
        window.location.pathname = '/pricing'
      }
    } else {
      if (window.location.pathname.indexOf('/' + this.value + '/pricing') == -1) {
        window.location.pathname = '/' + this.value + '/pricing';
      }
    }
    this.menu_list_top = [
      {index: 0, title: 'Highlights per article', basic: '8', pro: this.langs.pricing.detail.content.pro.span1, creativity: this.langs.pricing.detail.content.creativity.span1}, 
      {index: 1, title: 'Highlight colors', basic: '2', pro: '6', creativity: '6'}, 
      {index: 2, title: 'Auto Summary', basic: this.langs.pricing.detail.content.basic.span3, pro: this.langs.pricing.detail.content.pro.span3, creativity: this.langs.pricing.detail.content.creativity.span3}, 
      {index: 3, title: 'Kdan Cloud Storage', basic: '2 GB', pro: '500GB', creativity: '1TB'}, 
      {index: 4, title: 'PDF Editing Tools', basic: this.langs.pricing.detail.content.basic.span5, pro: this.langs.pricing.detail.content.pro.span5, creativity: this.langs.pricing.detail.content.creativity.span5}];
    for (let i = 0; i < this.menu_list_top.length; i++) {
      this.menu_list_top[i].title = eval('this.langs.pricing.detailmin.top.title' + i );
    }
    for (let i = 0; i < this.menu_list_bottom.length; i++) {
      this.menu_list_bottom[i].title = eval('this.langs.pricing.detailmin.bottom.title' + i );
    }
  },
  mounted () {
    this.syncPurchaseStat();
    this.eventBar();
    this.fullYear = new Date().getFullYear();
    document.addEventListener('scroll', this.handleScroll);
    let that = this;
    window.onresize= () => {
      that.screenWidth = window.innerWidth;
    }
    if (window.innerWidth <= 1024) {
      this.ifCross = true;
      if (this.screenWidth < 768) {
        this.isCross = true;
      }
    } else {
      this.ifCross = false;
    }
    if (this.currUser) {
      if (this.value == 'en') {
        this.start_a = '/mymarkups';
      } else {
        this.start_a = '/' + this.value + '/mymarkups';
      }
    } else {
      //utm携带到会员系统
      if(window.location.search.indexOf('utm_source') != '-1' || window.location.search.indexOf('utm_medium') != '-1' || window.location.search.indexOf('utm_campaign') != '-1') {
        this.start_a = '/auth/kdan_mobile?default_form=sign_up_form&' + window.location.search.substr(1) + '&lang=' + this.value;
      } else {
        this.start_a = '/auth/kdan_mobile?default_form=sign_up_form&lang=' + this.value;
      }
      //utm携带到会员系统
      var arrStr = document.cookie.split("; ");
      for (var i = 0; i < arrStr.length; i++) {
        var temp = arrStr[i].split("=");
        if (temp[0] == 'utmutm') {
          this.start_a = '/auth/kdan_mobile?default_form=sign_up_form&' + arrStr[i].substr(8) + '&lang=' + this.value;
        }
      }
    }
  },
  methods: {
    setUpgrade() {
      Cookies.set('setUpgrade', true, {expires: 7});
    },
    async eventBar() {
      let that = this;
      await axios.get("https://cms.kdanmobile.com/items/kdan_event_bar?filter[product_page][_eq]=markups&deep[translations][_filter][status][_eq]=published&deep[translations][_filter][languages_code][_eq]=" + that.value + "&fields=*,translations.*",{transformRequest: [
          function(data,headers){
            if(headers.Accept){
              delete headers.Accept;
            }
            if(headers.AppID){
              delete headers.AppID;
            }
          }
          ]})
      .then((res)=>{
        if(res.data.data[0]){
          // let search = window.location.search;
          // if (search.indexOf('utm_source=PR') == -1 || search.indexOf('utm_campaign=PR_UnJaded_Jade_202202') == -1 || search.indexOf('utm_medium=UnJaded_Jade') == -1) {
          //   that.eventBar_link = '';
          //   return
          // }
          that.eventBarData = {
            color: res.data.data[0].color,
            bgColor: res.data.data[0].bg_color,
            content: res.data.data[0].translations[0].content,
            link: res.data.data[0].translations[0].link
          }
        }
      })
      .catch(function(err){
        console.log(err);
      });
    },
    syncPurchaseStat () {
      const refresh = Cookies.get('refreshState');
      if (this.currUser && !refresh) {
        axios.get("/api/members/me?refresh=true")
        .then((res)=>{
          this.currUser = res.data.results;
          Cookies.set('refreshState', true);
        })
        .catch(function(err){
          console.log(err);
          Cookies.set('refreshState', true);
        });
      }
    },
    home () {
      // setTimeout(function(){
      //   document.body.scrollTop = document.documentElement.scrollTop = 0;
      // },100);
      this.backTop();
      if(this.currUser){
        let that = this;
        axios.post("/api/members/update_info", {lang: this.value})
        .then((res)=>{
          that.currUser = res.data.results;
          that.value = res.data.results.lang;
          Cookies.set('lang', res.data.results.lang, {expires: 7});
          if (window.location.pathname.indexOf('/pricing') != -1 || window.location.pathname.indexOf('/es/pricing') != -1 || window.location.pathname.indexOf('/zh-tw/pricing') != -1 || window.location.pathname.indexOf('/zh-cn/pricing') != -1 || window.location.pathname.indexOf('/ja/pricing') != -1 || window.location.pathname.indexOf('/ko/pricing') != -1) {
            window.location.pathname = (this.value == 'en' ? '' : '/' + this.value) + '/pricing';
          }
        })
        .catch(function(err){
        });
      } else {
        Cookies.set('lang', this.value, {expires: 7});
        if (window.location.pathname.indexOf('/pricing') != -1 || window.location.pathname.indexOf('/es/pricing') != -1 || window.location.pathname.indexOf('/zh-tw/pricing') != -1 || window.location.pathname.indexOf('/zh-cn/pricing') != -1 || window.location.pathname.indexOf('/ja/pricing') != -1 || window.location.pathname.indexOf('/ko/pricing') != -1) {
          window.location.pathname = (this.value == 'en' ? '' : '/' + this.value) + '/pricing';
        }
      }
    },
    handleAnimation: function (anim) {
      this.anim = anim;
    },
    demo () {
      for (i = 1; i < menu_list_bottom.length; i++) {
        menu_list_bottom[i].index1
      }
    },
    handleScroll () {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      this.shadow = scrollTop > 0;
      this.barShadow = scrollTop > 64;
    },
    annuallyUp () {
      this.whichPlanup = 0;
      this.markupPriceup = ' $39.99 ';
      this.markupEveryup = 0;
      this.creativityPriceup = ' $59.99 ';
      this.creativityEveryup = 0;
      this.explain = 0;
    },
    quarterlyUp () {
      this.whichPlanup = 1;
      this.markupPriceup = ' $12.99 ';
      this.markupEveryup = 1;
      this.creativityPriceup = ' $19.99 ';
      this.creativityEveryup = 1;
      this.explain = 1;
    },
    monthlyUp () {
      this.whichPlanup = 2;
      this.markupPriceup = ' $5.99 ';
      this.markupEveryup = 2;
      this.creativityPriceup = ' $9.99 ';
      this.creativityEveryup = 2;
      this.explain = 2;
    },
    annuallyDown () {
      this.whichPlandown = 0;
      this.markupPricedown = ' $39.99 ';
      this.markupEverydown = 0;
      this.creativityPricedown = ' $59.99 ';
      this.creativityEverydown = 0;
    },
    quarterlyDown () {
      this.whichPlandown = 1;
      this.markupPricedown = ' $12.99 ';
      this.markupEverydown = 1;
      this.creativityPricedown = ' $19.99 ';
      this.creativityEverydown = 1;
    },
    monthlyDown () {
      this.whichPlandown = 2;
      this.markupPricedown = ' $5.99 ';
      this.markupEverydown = 2;
      this.creativityPricedown = ' $9.99 ';
      this.creativityEverydown = 2;
    },
    isShow(e) {
      let el = e.currentTarget.lastElementChild;
      //判断当前节点的类名是否存在active_img
      if (el.getAttribute('class') == 'active_img') {
        el.setAttribute('class', '')
      } else {
      //如果不存在添加active属性,翻转180°
        el.setAttribute('class', 'active_img')
      }

      let _el = e.currentTarget.nextElementSibling
      //判断当前节点的类名是否存在active
      if (_el.getAttribute('class') == 'active') {
        _el.setAttribute('class', 'dropdown_content')
      } else {
      //如果不存在添加active属性,隐藏该节点
        _el.setAttribute('class', 'active')
      }
    },
    backTop () {
      let top = document.documentElement.scrollTop || document.body.scrollTop
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50
        if (top <= 0) {
          clearInterval(timeTop)
        }
      }, 3)
    },
    openVerified () {
      this.$children[2].eduEmail = null;
      this.$children[2].eduEmail_error = false;
      this.$children[2].eduFormat_error = false;
      this.$children[2].eduEmail_onblur = false;
      if (window.innerWidth < 930) {
        this.drawerEdu = true;
      } else {
        this.dialogEdu = true;
      }
    }
  },
  destroyed () {
    //离开该页面需要移除这个监听的事件
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>


<style lang="scss" scoped>
@import '../../vue_styles/global.scss';
.dashboard-container {
  #eventBar {
    display: none;
  }
  .barHeader {
    position: static;
  }
  .eventHeader {
    position: fixed;
  }
  #eventBar.cms {
    display: block;
    width: 100%;
    padding: 22px;
    text-align: center;
    a {
      font-size: 16px;
    }
    a:hover {
      text-decoration: underline;
    }
  }
  .app-container {
    margin-top: 64px;
    font-family: Roboto;
    .menu {
      margin: 0 auto;
      display: flex;
      position: relative;
      justify-content: space-around;
      position: relative;
      width: 370px;
      height: 52px;
      padding: 8px;
      border-radius: 6px;
      background-color: #f4f4f4;
      .quarterly {
        border-left: 1px solid #d8d8d8;
        border-right: 1px solid #d8d8d8;
      }
      .menu_option {
        min-width: 120px;
        font-size: 16px;
        text-align: center;
        color: #929292;
        padding: 7px 18px 8px;
        cursor: pointer;
        z-index: 100;
      }
      .active {
        font-weight: 500;
        color: #2b2b2b;
        margin: -4px;
        padding: 11px 18px 12px;
        cursor: default;
      }
      .slideBlock {
        position: absolute;
        width: 120px;
        height: 44px;
        top: 4px;
        left: 4px;
        border-radius: 6px;
        box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.08);
        background-color: #fff;
        z-index: 50;
        transition: left 0.5s;
      }
    }
    .plans_content {
      margin: 0 auto;
      margin-top: 40px;
      overflow: hidden;
      .plans_content_three {
        display: flex;
        justify-content: center;
        .plans_title {
          font-size: 22px;
          font-weight: bold;
          color: #2f2f2f;
        }
        .plans_price {
          position: relative;
          margin-top: 15px;
          white-space: nowrap;
          span {
            font-size: 18px;
            font-weight: bold;
            color: #292929;
          }
          b {
            font-size: 28px;
            font-weight: bold;
            color: #292929;
          }
          i {
            font-size: 16px;
            font-weight: 300;
            color: #7b7b7b;
          }
          .save {
            display: inline-block;
            font-size: 12px;
            font-weight: 500;
            text-align: center;
            color: #0089f1;
            margin-left: 20px;
            padding: 5px 8px;
            border-radius: 13px;
            background-color: #e6f2ff;
          }
        }
        .plans_explain {
          font-size: 12px;
          line-height: 1.5;
          color: #4c4c4c;
          margin-top: 6px;
        }
        a {
          display: block;
          max-width: 300px;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          color: #ff5f4b;
          padding: 11px 10px 10px 10px;
          border-radius: 5px;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
          border: solid 1.6px #ff5f4b;
          background-color: #fff;
          margin-bottom: 31px;
        }
        .plans_item {
          margin-top: 21px;
          display: flex;
          img {
            margin-right: 8px;
            width: 24px;
            height: 24px;
          }
          span {
            font-size: 14px;
            line-height: 24px;
          }
        }
        .plans_content_left {
          width: 380px;
          padding: 32px 40px 136px;
          border: solid 1px #d9d9d9;
          background-color: #fff;
          a {
            margin-top: 41.6px;
          }
          a:hover {
            background-color: #fff1ef;
          }
          a:active {
            background-color: #fff;
          }
          .plans_price {
            b {
              line-height: 33.6px;
            }
          }
        }
        .plans_content_center {
          width: 380px;
          padding: 32px 40px 96px;
          box-shadow: 0 2px 8px 0 rgba(255, 196, 196, 0.5);
          border: solid 1.4px #ff9b81;
          background-color: #fff;
          a {
            color: #fff;
            background-color: #ff5f4b;
            margin-top: 18px;
          }
          a:hover {
            opacity: 0.8;
          }
          a:active {
            background-color: rgb(230, 86, 68);
          }
        }
        .plans_content_right {
          width: 380px;
          padding: 32px 36px 54px 37px;
          border: solid 1px #d9d9d9;
          background-color: #fff;
          a {
            margin-top: 18px;
          }
          a:hover {
            background-color: #fff1ef;
          }
          a:active {
            background-color: #fff;
          }
        }
      }
      .cancel {
        text-align: center;
        margin: 44px 0 48px;
        img {
          width: 36px;
        }
        span {
          font-size: 18px;
          line-height: 1.67;
          color: #5f5f5f;
        }
      }
    }
    #plans {
      background-image: linear-gradient(to bottom, #fff, #fff7f7);
      h1 {
        font-size: 48px;
        text-align: center;
        color: #071d4c;
        margin-top: 128px;
      }
      .menu {
        margin-top: 52px;
      }
    }
    #education {
      display: flex;
      justify-content: center;
      padding: 22px 0 23px;
      background-color: #f0f6fc;;
      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h2 {
          font-size: 28px;
          font-weight: bold;
          color: #071d4c;
        }
        h3 {
          font-size: 22px;
          font-weight: bold;
          color: #071d4c;
          margin-top: 12px;
          white-space: nowrap;
        }
        .education_btn {
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          color: #fff;
          margin-top: 32px;
          padding: 12px 26.1px 11px 27px;
          border-radius: 5px;
          border: solid 1.6px #ff5f4b;
          background-color: #ff5f4b;
          cursor: pointer;
        }
      }
      .img {
        margin-left: 128px;
      }
    }
    #detail {
      padding-bottom: 78px;
      h2 {
        font-size: 36px;
        color: #071d4c;
        text-align: center;
        margin-top: 76px;
      }
      .menu {
        margin-top: 48px;
      }
      .detail_content {
        display: flex;
        justify-content: center;
        margin-top: 76px;
        text-align: center;
        .detail_item_title {
          height: 120px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .detail_item {
          font-size: 14px;
          height: 70px;
          line-height: 70px;
          border: 1px solid #ebebeb;
        }
        .detail_item_last {
          height: 100px;
          line-height: 100px;
        }
        .detail_item_a {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 90px;
          a {
            font-size: 16px;
            font-weight: bold;
            color: #ff5f4b;
            width: 240px;
            height: 42px;
            padding: 10px 0 11px;
            border-radius: 5px;
            border: solid 1.6px #ff5f4b;
            background-color: #fff;
          }
          a:hover {
            background-color: #fff1ef;
          }
          a:active {
            background-color: #fff;
          }
        }
        .detail_title {
          font-size: 18px;
          font-weight: bold;
          color: #484848;
        }
        .detail_price {
          margin-top: 15px;
          span {
            font-size: 16px;
            font-weight: bold;
            color: #292929;
          }
          b {
            font-size: 24px;
            font-weight: bold;
            color: #292929;
          }
          i {
            font-size: 14px;
            font-weight: 300;
            color: #7b7b7b;
          }
        }
        .detail_content_one {
          width: 320px;
          text-align: left;
          .detail_price {
            padding-left: 10px;
          }
          .detail_item {
            font-size: 16px;
            font-weight: 500;
            border-left: 0px;
            border-right: 0px;
            border-bottom: 0px;
            padding-left: 10px;
            .hint {
              position: relative;
              float: right;
              margin-right: 17px;
            }
            .hint:hover.hint:before {
              display: flex;
            }
            .hint:hover.hint:after {
              display: flex;
            }
            .hint:before {
              display: none;
              content: '';
              width: 0;
              border-style:solid;
              border-width: 0 8px 8px;
              border-color: transparent transparent #242424;
              position: absolute;
              top: 50px;
              left: 2.5px;
            }
            .hint:after {
              display: none;
              content: attr(data-name);
              position: absolute;
              justify-content: center;
              top: 55px;
              left: -45px;
              line-height: 30px;
              font-size: 12px;
              text-align: center;
              white-space: nowrap;
              color: #fff;
              box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.28);
              border-radius: 5px;
              background-color: #242424;
              padding: 0 13px 0 14px;
            }
            .hint1:after {
              left: -130px;
            }
          }
          .detail_item_last {
            width: 320px;
            border: 1px solid #ebebeb;
            border-left: 0;
            .detail_item_last_up {
              margin-top: 25px;
              line-height: normal;
            }
            .detail_item_last_down {
              line-height: normal;
              margin-top: 6px;
            }
            .hint {
              margin-top: -30px;
              height: 0;
              line-height: 0;
            }
            .hint:before {
              top: 23px;
            }
            .hint:after {
              top: 28px;
            }
          }
        }
        .detail_content_two {
          width: 280px;
          .detail_item {
            border-right: 0;
            border-bottom: 0; 
          }
          .detail_item_last {
            border-bottom: 1px solid #ebebeb;
          }
        }
        .detail_content_three {
          width: 280px;
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
          .detail_item_title {
            position: relative
          }
          .detail_item_title:before {
            content: attr(data-name);
            position: absolute;
            top: -24px;
            left: 0;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 1.2px;
            color: #fff;
            width: 280px;
            height: 24px;
            line-height: 24px;
            background-color: #ff5f4b;
          }
          .detail_item {
            border-left: 0px;
            border-right: 0px;
            border-bottom: 0px;
          }
          .detail_item_last {
            border-bottom: 1px solid #ebebeb;
          }
          .detail_item_a {
            a {
              color: #fff;
              background-color: #ff5f4b;
            }
            a:hover {
              opacity: 0.8;
            }
            a:active {
              background-color: rgb(230, 86, 68);
            }
          }
        }
        .detail_content_four {
          width: 280px;
          .detail_item {
            border-left: 0;
            border-right: 0;
            border-bottom: 0;
          }
          .detail_item_last {
            border-bottom: 1px solid #ebebeb;
          }
          .detail_item_a {
            position: relative;
          }
          .detail_item_a span {
            position: absolute;
            top: 72px;
            left: 100px;
            font-size: 14px;
            font-weight: 500;
            color: #919191;
          }
        }
      }
    }
    #detail_min {
      h2 {
        font-size: 36px;
        color: #071d4c;
        text-align: center;
        margin-top: 76px;
      }
      .menu_min {
        width: 450px;
        margin: 33px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .dropdown {
          margin-top: 22px;
          padding-bottom: 22px;
          border-bottom: 1px solid #e4e4e4;
          .dropdown_title {
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            span {
              font-size: 16px;
              font-weight: 500;
              color: #000;
              line-height: 28px;
            }
            img {
              width: 28px;
              transition: all 0.5s;
              transform: rotate(90deg);
            }
            .active_img {
              transform: rotate(270deg);
            }
          }
          .dropdown_content {
            padding: 22px 4px 0 11px;
            transition: all 0.5s;
            .dropdown_content_item {
              display: flex;
              justify-content: space-between;
              span {
                font-size: 14px;
                font-weight: 500;
                color: #343434;
                line-height: 22px;
              }
              .span_pro {
                color: #ff5f4b;
              }
              i {
                font-size: 14px;
                color: #000;
              }
              img {
                width: 22px;
              }
            }
            .item_pro {
              margin-top: 12px;
              margin-bottom: 12px;
            }
          }
          .active {
            display: none;
          }
        }
        .dropdown_last {
          border-bottom: 0px;
        }
      }
    }
    #faq {
      overflow: hidden;
      background-color: #f7f8fb;
      h2 {
        font-size: 40px;
        color: #071d4c;
        text-align: center;
        margin-top: 72px;
      }
      .faq_content {
        display: flex;
        justify-content: center;
        margin-top: 64px;
        padding: 0 60px 74px;
        .faq_item {
          width: 560px;
          border-radius: 10px;
          box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.08);
          background-color: #fff;
          transition: margin 0.5s;
          h3 {
            font-size: 22px;
            font-weight: bold;
            color: #343434;
          }
          p {
            font-size: 14px;
            line-height: 1.43;
            color: #515151;
            margin-top: 16px;
          }
        }
        .faq_left {
          .faq_one {
            padding: 33px 35px 82px 45px;
          }
          .faq_two {
            margin-top: 32px;
            padding: 32px 35px 34px 45px;
          }
        }
        .faq_right {
          margin-left: 40px;
          .faq_three {
            padding: 33px 40px 62px;
            a {
              font-weight: bold;
              color: #007aff;
            }
          }
          .faq_four {
            margin-top: 32px;
            padding: 32px 40px 63px;
          }
        }
        .faq_one:hover {
          margin-top: -10px;
        }
        .faq_one:hover + .faq_two {
          margin-top: 42px;
        }
        .faq_three:hover {
          margin-top: -10px;
        }
        .faq_three:hover + .faq_four {
          margin-top: 42px;
        }
        .faq_two:hover {
          margin-top: 22px;
        }
        .faq_four:hover {
          margin-top: 22px;
          margin-bottom: 10px;
        }
      }
      .help {
        margin-bottom: 74px;
        display: flex;
        justify-content: center;
        a {
          font-size: 16px;
          font-weight: 500;
          color: #007aff;
          text-align: center;
        }
      }
    }
  }
  #footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 26px 0;
    height: 50.9px;
    background-color: #4c4c4c;
    .footer_top {
      display: flex;
      color: #aaa;
      text-align: center;
      span {
        font-size: 12px;
        line-height: 16.8px;
        a {
          color: #aaa;
        }
        a:hover {
          color: white;
        }
      }
    }
    .footer_bottom {
      margin-left: 10px;
      /deep/.el-select {
        width: 150px;
        .el-input {
          .el-input__inner:focus {
            border-color: #C0C4CC;
          }
          i::before {
            color: #000;
          }
        }
        .is-focus {
          .el-input__inner {
            border-color: #C0C4CC;
          }
        }
      }
    }
  }
}
@media screen and (min-width:1280px) {
  .dashboard-container {
    .app-container {
      #faq {
        .faq_content {
          .faq_item {
            height: 220px;
          }
        }
      }
    }
  }
}
@media screen and (max-width:1280px) {
  .dashboard-container {
    .app-container {
      #plans {
        .plans_content {
          .plans_content_three {
            flex-direction: column;
            align-items: center;
            .plans_price {
              margin-top: 8px;
            }
            .plans_content_left, .plans_content_center, .plans_content_right {
              width: 520px;
              padding: 30px 40px 36px;
              a {
                max-width: 440px;
                margin-bottom: 27px;
              }
            }
            .plans_content_left {
              a {
                margin-top: 40px;
              }
            }
            .plans_content_center, .plans_content_right {
              margin-top: 32px;
              a {
                margin-top: 16px;
              }
            }
          }
          .cancel {
            margin: 60px 0 70px;
          }
        }
      }
      #detail {
        .detail_content {
          .detail_content_one {
            width: 264px;
            .detail_item_last {
              width: 264px;
            }
          }
          .detail_content_two, .detail_content_three, .detail_content_four {
            width: 220px;
          }
          .detail_content_three {
            .detail_item_title:before {
              width: 220px;
            }
          }
          .detail_content_four {
            .detail_item_a:after {
              left: 80px;
            }
          }
          .detail_item_a {
            a {
              width: 180px;
            }
          }
        }
      }
      #faq {
        .faq_content {
          flex-direction: column;
          align-items: center;
          margin-top: 37px;
          padding-bottom: 66px;
          .faq_item {
            width: 800px;
          }
          .faq_left {
            .faq_one, .faq_two {
              margin-top: 27px;
            }
            .faq_one {
              padding: 33px 40px 47px;
            }
            .faq_two {
              padding: 32px 38px 48px
            }
          }
          .faq_right {
            margin-left: 0;
            .faq_three, .faq_four {
              margin-top: 27px;
            }
            .faq_three {
              padding: 33px 40px 34px
            }
            .faq_four {
              padding: 32px 40px 42px
            }
          }
          .faq_one:hover {
            margin-top: 17px;
            margin-bottom: 37px;
          }
          .faq_two:hover {
            margin-top: 17px;
            margin-bottom: 10px;
          }
          .faq_three:hover {
            margin-top: 17px;
            margin-bottom: 37px;
          }
          .faq_four:hover {
            margin-top: 17px;
            margin-bottom: 10px;
          }
        }
        .help {
          margin-bottom: 66px;
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .dashboard-container {
    .app-container {
      #faq {
        .faq_content {
          padding-bottom: 68px;
          .faq_item {
            width: 600px;
          }
          .faq_one {
            padding: 33px 40px 38px;
          }
          .faq_two {
            padding: 32px 40px 36px;
          }
          .faq_three {
            padding: 33px 40px 38px;
          }
          .faq_four {
            padding: 32px 40px 39px;
          }
        }
        .help {
          margin-bottom: 60px;
        }
      }
    }
    #footer {
      .footer_top {
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .dashboard-container {
    .app-container {
      #education {
        .img {
          margin-left: 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 930px) {
  .dashboard-container {
    .app-container {
      #education {
        flex-direction: column;
        padding: 52px 0 20px;
        .text {
          h3 {
            text-align: center;
            white-space: normal;
          }
        }
        .img {
          margin: 32px auto;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .dashboard-container {
    .app-container {
      #plans {
        h1 {
          font-size: 32px;
          line-height: 1.25;
        }
        .menu {
          margin-top: 34px;
          height: 40px;
          width: 287px;
          .menu_option {
            min-width: 92px;
            font-size: 12px;
            padding: 4px 16px 5px;
          }
          .active {
            padding: 8px 16px 9px;
          }
          .slideBlock {
            width: 92px;
            height: 32px;
          }
        }
        .plans_content {
          padding-left: 16px;
          padding-right: 16px;
          .plans_content_three {
            .plans_content_left, .plans_content_center, .plans_content_right {
              width: 100%;
              max-width: 520px;
              padding: 16px 13px 34px 20px;
            }
            .plans_explain {
              margin-top: 4px;
            }
            a {
              margin-bottom: 23px;
            }
            .plans_item {
              margin-top: 13px;
            }
          }
          .cancel {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 44px 0 53px;
            span {
              font-size: 14px;
            }
          }
        }
      }
      #education {
        padding-left: 24px;
        padding-right: 24px; 
        .img {
          img {
            width: 288px;
            height: 224px;
          }
        }
      }
      #detail_min {
        padding-left: 16px;
        padding-right: 16px;  
        .menu_min {
          width: 100%;
          max-width: 450px;
        }
      }
      #faq {
        .faq_content {
          padding: 0 16px 48px;
          .faq_item {
            width: 100%;
          }
        }
      }
    }
    #footer {
      flex-direction: column;
      height: auto;
      padding: 26px 18px;
      .footer_top {
        flex-direction: column;
        font-size: 12px;
      }
      .footer_bottom {
        margin-top: 10px;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .dashboard-container {
    .app-container {
      #faq {
        .faq_content {
          .faq_item {
            height: auto;
          }
        }
        .help {
          padding: 0 30px;
        }
      }
    }
  }
}
@media screen and (max-width: 375px) {
  .dashboard-container {

  }
}
</style>
