<template>
  <div class="footer_wrapper footer">
    <footer>
      <div class="footer_bottom">
        <img class="kdan_logo" src="../../../assets/images/home/ic-kdan.png" alt="logo">
        <div class="pa">
          <p>{{ langs.landing.footer.download.title }}</p>
          <div class="as">
            <a href="https://apps.apple.com/app/id936601959?pt=264933&ct=Markup_Website_DownloadApp&mt=8" target="_blank">{{ langs.landing.footer.download.mobile }}</a>
            <a href="https://chrome.google.com/webstore/detail/markup-web-markups-highli/cbjngbfjehiofmihfpodinolkcokdojp" target="_blank">{{ langs.landing.footer.download.chrome }}</a>
            <a href="https://microsoftedge.microsoft.com/addons/detail/markup-my-web-markups-/bkaddcdohjngdgldhhdmghjdohcnncjj" target="_blank">{{ langs.landing.footer.download.edge }}</a>
          </div>
        </div>
        <div class="pa">
          <p>{{ langs.landing.footer.about.title }}</p>
          <div class="as">
            <a :href="langs.landing.footer.a1" target="_blank">{{ langs.landing.footer.link1 }}</a>
            <a :href="langs.landing.footer.a2" target="_blank">{{ langs.landing.footer.link2 }}</a>
            <a :href="langs.landing.footer.a3" target="_blank">{{ langs.landing.footer.link3 }}</a>
          </div>
        </div>
        <div class="pa">
          <p>{{ langs.landing.footer.help.title }}</p>
          <div class="as">
            <a :href="langs.landing.footer.kdanMobile.a3" target="_blank">{{ langs.landing.footer.help.contact }}</a>
            <a :href="langs.landing.footer.resources.a3" target="_blank">{{ langs.landing.footer.help.faq }}</a>
          </div>
        </div>
      </div>
      <div class="reserved">
        <p class="copyright">{{'© 2009-' + fullYear }} <a :href="langs.landing.footer.a4" target="_blank">Kdan Mobile Software Ltd.</a> All Rights Reserved.</p>
        <div class="langbar_bottom">
          <img src="../../../assets/images/home/language.svg" alt="icon">
          <el-select v-model="value" placeholder="English" @change="home">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
              <span>{{ item.label }}</span>
            </el-option>
          </el-select>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import axios from 'axios';
export default {
  props: {
    currUser: {
      default: null
    },
    lang: {
      default: null
    },
    langs: {
      default: null
    },
    server_env: {
      default: null
    }
  },
  data() {
    return {
      langues: ['en', 'zh-tw', 'zh-cn', 'ja', 'es', 'ko'],
      options: [{
        value: 'en',
        label: 'English'
      }, {
        value: 'es',
        label: 'Español'
      }, {
        value: 'zh-tw',
        label: '中文 (繁體)'
      }, {
        value: 'zh-cn',
        label: '中文 (简体)'
      }, {
        value: 'ja',
        label: '日本語'
      }, {
        value: 'ko',
        label: '한국어'
      }],
      value: 'en',
      fullYear: ''
    }
  },
  mounted () {
    this.fullYear = new Date().getFullYear();
    if (this.currUser) {
      if (this.langues.includes(this.currUser.lang.toLowerCase())) {
        this.value = this.currUser.lang.toLowerCase();
      } else {
        this.value = 'en';
      }
    } else {
      const lang = Cookies.get('lang');
      if (lang) {
        this.value = lang;
      } else {
        this.value = 'en';
      }
    }
  },
  methods: {
    home () {
      this.backTop();
      if(this.currUser){
        let that = this;
        axios.post("/api/members/update_info", {'lang': this.value})
        .then((res)=>{
          that.value = res.data.results.lang;
          Cookies.set('lang', res.data.results.lang, {expires: 7});
          this.$emit('changeCurrUser', res.data.results, res.data.results.lang);
        })
        .catch(function(err){
        });
      } else {
        Cookies.set('lang', this.value, {expires: 7});
        this.$emit('changeCurrUser', null, this.value);
      }
    },
    backTop () {
      let top = document.documentElement.scrollTop || document.body.scrollTop
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50
        if (top <= 0) {
          clearInterval(timeTop)
        }
      }, 3)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../vue_styles/font.scss';
  .footer_wrapper {
    width: 100%;
    padding: 60px 0 40px;
    background-color: #202020;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    font-family: Clear Sans,sans-serif;
  }
  footer {
    margin: 0 auto;
    .footer_bottom {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 100px;
      .pa {
        p {
          width: 100%;
          font-size: 18px;
          line-height: 24px;
          font-weight: bold;
          text-align: left;
        }
        .as{
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          overflow: hidden;
          a {
            margin-top: 16px;
            font-size: 14px;
            line-height: 20px;
            color: #fff;
          }
        }
      }
    }
    .reserved {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .langbar_bottom {
      display: flex;
      align-items: center;
    }
    /deep/.el-select {
      .el-input {
        .el-input__inner {
          width: 120px;
          margin-left: 5px;
          padding-left: 0px;
          padding-right: 20px;
          color: #fff;
          font-size: 16px;
          height: 28px;
          border: none;
          background-color: transparent;
        }
        i::before {
          color: #fff;
        }
        .el-input__suffix {
          right: 0;
        }
        .el-input__icon {
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
      }
      .is-focus {
        .el-input__inner {
          border-color: #C0C4CC;
        }
      }
    }
    .copyright {
      font-size: 14px;
      color: #fff;
      line-height: 20px;
      a {
        color: #fff;
        text-decoration: underline;
      }
    }
  }
  .kdan_logo {
    height: 40px;
  }
  @media screen and (max-width: 1023.9px) {
    .footer_wrapper {
      padding: 60px 0 40px;
    }
    footer {
      width: 100%;
      max-width: 768px;
      padding: 0 40px;
      .footer_bottom {
        display: block;
        margin-bottom: 0;
        img {
          margin-bottom: 60px;
        }
        .pa {
          margin-bottom: 48px;
        }
      }
      .reserved {
        flex-direction: column-reverse;
        align-items: flex-start;
      }
      .copyright {
        margin-top: 48px;
      }
    }
  }
  @media screen and (max-width: 767.9px) {
    footer {
      width: 100%;
      padding: 0 16px;
    }
  }
  @media screen and (min-width: 1024px) {
    footer {
      width: 944px;
    }
  }
  @media screen and (min-width: 1280px) {
    footer {
      width: 1000px;
    }
  }
</style>
