<template>
  <el-dialog class="upgrade_box" :visible.sync="ifUpgrade" :show-close="false" width top :before-close="syncPurchaseStat" :modal-append-to-body="false">
    <template slot="title">
      <inline-svg @click="syncPurchaseStat" :src="require('../../../assets/images/app_smart_bar/ic-web-popup-close.svg')" v-show="screenWidth >= 575" />
      <inline-svg @click="syncPurchaseStat" :src="require('../../../assets/images/app_smart_bar/ic-web-popup-close24.svg')" v-show="screenWidth < 575" />
    </template>
    <div class="upgrade_title">{{ langs.common.upgrade.level }}</div>
    <div class="upgrade_img"><img src="../../../assets/images/app_smart_bar/img-popup-upgrade-levelup.svg" alt=""></div>
    <div class="upgrade_text">{{ langs.common.upgrade.upgrade }}</div>
    <ul class="upgrade_ul">
      <li class="upgrade_li">
        <img class="upgrade_icon" src="../../../assets/images/app_smart_bar/ic-web-upgrade-check.svg" alt="">
        <span class="upgrade_span">{{ langs.common.upgrade.unlimited }}</span>
      </li>
      <li class="upgrade_li">
        <img class="upgrade_icon" src="../../../assets/images/app_smart_bar/ic-web-upgrade-check.svg" alt="">
        <span class="upgrade_span">{{ langs.common.upgrade.full }}</span>
      </li>
      <li class="upgrade_li">
        <img class="upgrade_icon" src="../../../assets/images/app_smart_bar/ic-web-upgrade-check.svg" alt="">
        <span class="upgrade_span">{{ langs.common.upgrade.all }}</span>
      </li>
      <li class="upgrade_li">
        <img class="upgrade_icon" src="../../../assets/images/app_smart_bar/ic-web-upgrade-check.svg" alt="">
        <span class="upgrade_span">{{ langs.common.upgrade.kdan }}</span>
      </li>
    </ul>
    <button class="upgrade_btn" @click="gotoUpgradePage()">{{ langs.common.upgrade.a }}</button>
  </el-dialog>
</template>

<script>
import Cookies from 'js-cookie';
import axios from 'axios';
import InlineSvg from 'vue-inline-svg';
export default {
  props: ['ifUpgrade', 'currUser', 'lang', 'langs', 'ifSideAuto', 'server_env', 'upgrade', 'screenWidth', 'autoSummary'],
  components: {
    InlineSvg
  },
  data() {
    return {
      ifMicro: true
    }
  },
  methods: {
    syncPurchaseStat() {
      const upgrade = Cookies.get('setUpgrade');
      if (upgrade) {
        axios.get("/api/members/me?refresh=true")
        .then((res)=>{
          if(res.data.results.purchase_stat != this.currUser.purchase_stat){
            this.$emit('update:crawling', true);
            this.$emit('update:ifSideAuto', false);
          }
          this.$emit('changeCurrUser', res.data.results);
          Cookies.remove('setUpgrade');
        })
        .catch(function(err){
          console.log(err)
        });
      }
      this.$emit('update:ifUpgrade', false);
    },
    gotoUpgradePage() {
      Cookies.set('setUpgrade', true, {expires: 7});
      let host = '';
      if(this.server_env == 'production') {
        host = 'https://creativestore.kdanmobile.com';
      }else{
        host = 'https://creative-store-client.preparing.kdanmobile.com';
      }
      let upgradeUrl = host + '/subscription/markuppro/checkout?default-plan=com.kdanmobile.stripe.PDFReader.deluxe_business_pack_year.001&utm_source=MyMarkup&utm_campaign=Upgrade&utm_medium='
      if(this.autoSummary){
        upgradeUrl += 'AutoSummary';
      } else {
        upgradeUrl += 'Navigation';
      }
      window.open(upgradeUrl)
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-dialog__wrapper.upgrade_box {
    /deep/ .el-dialog .el-dialog__header {
      display: flex;
      justify-content: flex-end;
      padding: 16px 16px 0 16px;
      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
  .upgrade_box {
    text-align: center;
    /deep/ .el-dialog {
       width: 440px;
       margin-top: 10vh;
       border-radius: 10px;
      .el-dialog__header {
        display: flex;
        svg {
          cursor: pointer;
        }
      }
      .el-dialog__body {
        padding: 0;
        padding-bottom: 26px;
        word-break: initial;
      }
      .upgrade_title {
        margin-top: -22px;
        margin-bottom: 6px;
        font-size: 24px;
        font-weight: bold;
        color: #343434;
      }
      .upgrade_text {
        margin-top: 11px;
        margin-left: 4px;
        padding-left: 7.5%;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.24px;
        color: #1c1c1c;
        text-align: left;
      }
      .upgrade_ul {
        margin-top: 20px;
        padding-left: 7.5%;
        padding-right: 7.5%;
        .upgrade_li {
          display: flex;
          align-items: flex-start;
          text-align: left;
          & + .upgrade_li {
            margin-top: 12px;
          }
          .upgrade_span {
            padding-left: 6px;
            line-height: 18px;
          }
        }
      }
      .upgrade_btn {
        width: 86%;
        height: 44px;
        margin: 30px;
        margin-bottom: 0;
        padding: 10px 19px 9px 21px;
        border-radius: 5px;
        background-color: #ff5f4b;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        color: #ffffff;
      }
      .upgrade_btn:hover {
        opacity: 0.8;
      }
      .upgrade_btn:active {
        background-color: #e65644;
      }
    }
  }
  @media screen and (max-width: 575px) {
    .el-dialog__wrapper.upgrade_box {
      /deep/ .el-dialog .el-dialog__header {
        padding: 11px 11px 0 11px;
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
    .upgrade_box {
      /deep/ .el-dialog {
        width: 100%;
        max-width: 320px;
        margin-top: 6vh;
        .el-dialog__body {
          .upgrade_img {
            img {
              width: 160px;
            }
          }
          .upgrade_title {
            font-size: 18px;
            margin-bottom: 0;
          }
          .upgrade_text {
            margin-top: 16px;
            font-size: 15px;
            letter-spacing: -0.23px;
          }
          .upgrade_ul {
            margin-top: 18px;
            .upgrade_li {
              & + .upgrade_li {
                margin-top: 12px;
              }
              .upgrade_span {
                padding-left: 7px;
              }
            }
          }
        }
        .upgrade_btn {
          width: 81.25%;
          height: 40px;
          padding: 8px 0 7px;
          margin-top: 30px;
          line-height: 21px;
        }
      }
    }
  }
</style>