<template>
  <div class="dashboard-container" @click="omit_close()">
    <div id="eventBar" :class="{cms: eventBarData.link}" :style="{backgroundColor: eventBarData.bgColor}"><a :href="eventBarData.link" target="_blank" :style="{color: eventBarData.color}">{{eventBarData.content}}</a></div>
    <Header :class="{barHeader: eventBarData.link, eventHeader: barShadow}" ref="header" :isActive="true" :currUser.sync="currUser" :lang.sync="lang" :langs="langs" :screenWidth="screenWidth" :server_env="server_env" @changeCurrUser="changeCurrUser" @closeTag="closeTag" />
    <Edu :outside="outside" :langs="langs" :dialogEdu.sync="dialogEdu" :drawerEdu.sync="drawerEdu"/>
    <div v-if="load" class="skeleton_wrapper">
      <div class="skeleton_container">
        <div class="skeleton_item">
          <div class="skeleton_left"></div>
          <div class="skeleton_right">
            <div class="skeleton_top"></div>
            <div class="skeleton_top"></div>
            <div class="skeleton_bottom"></div>
          </div>
        </div>
        <div class="skeleton_item">
          <div class="skeleton_left"></div>
          <div class="skeleton_right">
            <div class="skeleton_top"></div>
            <div class="skeleton_top"></div>
            <div class="skeleton_bottom"></div>
          </div>
        </div>
        <div class="skeleton_item">
          <div class="skeleton_left"></div>
          <div class="skeleton_right">
            <div class="skeleton_top"></div>
            <div class="skeleton_top"></div>
            <div class="skeleton_bottom"></div>
          </div>
        </div>
        <div class="skeleton_item">
          <div class="skeleton_left"></div>
          <div class="skeleton_right">
            <div class="skeleton_top"></div>
            <div class="skeleton_top"></div>
            <div class="skeleton_bottom"></div>
          </div>
        </div>
        <div class="skeleton_item">
          <div class="skeleton_left"></div>
          <div class="skeleton_right">
            <div class="skeleton_top"></div>
            <div class="skeleton_top"></div>
            <div class="skeleton_bottom"></div>
          </div>
        </div>
      </div>
    </div>
    <template v-else>
      <div class="app-container container" :class="{empty: !isEmpty}">
        <div class="zeroTitle">{{ langs.common.header.mymarkups }}</div>
        <div class="zero">{{ langs.markups.zeroArticle }}</div>
        <div class="add">
          <img class="add_img" src="../../../assets/images/app_smart_bar/img-mymarkups-empty.svg">
          <div class="add_text1">{{ langs.markups.no }}</div>
          <div class="add_text2">{{ langs.markups.you }}</div>
          <div class="add_plus" @click="add()"><img src= "../../../assets/images/app_smart_bar/ic-addurl-00.svg">&nbsp;&nbsp;{{ langs.common.saving }}</div>
          <div class="add_or">{{ langs.markups.or }}</div>
          <a class="add_a" href="/explore">{{ langs.markups.explore }}</a>
        </div>
        <div class="steps">
          <h2>{{ langs.markups.title }}</h2>
          <div class="step_one">
            <div class="step_text">
              <h3>1</h3>
              <p>{{ langs.markups.span1 }}</p>
              <div class="step_btns">
                <a class="step_btn_chrome" id="Btn_Extension_Chrome" target="_blank" href="https://chrome.google.com/webstore/detail/pdf-markup-my-web-markups/cbjngbfjehiofmihfpodinolkcokdojp">
                  <img src="../../../assets/images/app_smart_bar/ic-logo-chrome.svg" alt="">
                  <span>{{ langs.markups.a1 }}</span>
                </a>
                <a class="step_btn_edge" id="Btn_Extension_Edge" target="_blank" href="https://microsoftedge.microsoft.com/addons/detail/markup-my-web-markups-/bkaddcdohjngdgldhhdmghjdohcnncjj">
                  <img src="../../../assets/images/app_smart_bar/ic-logo-edge.png" alt="">
                  <span>{{ langs.markups.a2 }}</span>
                </a>
              </div>
            </div>
            <img class="step_img" src="../../../assets/images/app_smart_bar/emptystate_guide_1.png" alt="">
          </div>
          <div class="step_two">
            <div class="step_text">
              <h3>2</h3>
              <p>{{ langs.markups.span2 }}</p>
            </div>
            <img class="step_img" src="../../../assets/images/app_smart_bar/emptystate_guide_2.png" alt="">
          </div>
          <div class="step_three">
            <div class="step_text">
              <h3>3</h3>
              <p>{{ langs.markups.span3 }}</p>
            </div>
            <img class="step_img" src="../../../assets/images/app_smart_bar/emptystate_guide_3.png" alt="">
          </div>
        </div>
      </div>
      <div class="app-container container" :class="{empty: isEmpty}">
        <div class="myTitle" :class="{noBottom: ifSearchTag}">
          <div class="countBox">
            <div class="mymarkups" v-show="!ifSearch">{{ langs.common.header.mymarkups }}</div>
            <div class="count" v-show="!ifSearch">
              <span>{{count}}</span>
              <span v-show="count <= 1">{{ langs.markups.article }}</span>
              <span v-show="count > 1">{{ langs.markups.articles }}</span>
            </div>
            <div class="mymarkups" v-show="ifSearch">{{ langs.markups.searchResult }}</div>
            <div class="count" v-show="ifSearch">
              <span>{{count}}</span>
              <span v-show="count <= 1">{{ langs.markups.articleFound }}</span>
              <span v-show="count > 1">{{ langs.markups.articlesFound }}</span>
            </div>
          </div>
          <div class="searchBox">
            <div class="search">
              <el-input ref="mark" v-model="searchContent" :placeholder="langs.markups.search" @keyup.enter.native="changePager(1)" @focus="focusSearchBorder" @blur="blurSearchBorder"></el-input>
              <div class="close" v-show="searchContent!=''" @click="cleanSearchContent()"><img class="close_img" src="../../../assets/images/app_smart_bar/ic-close.svg" alt=""></div>
              <img class="search_img" src="../../../assets/images/app_smart_bar/ic-web-mymarkups-search.svg" alt="" @click="changePager(1)">
            </div>
            <div class="sort" @click="openSort()">
              <div class="sort_img"></div>
              <span>{{ langs.markups.sorts }}</span>
              <div class="by" v-show="by">
                <div class="title">{{ langs.markups.sortBy }}</div>
                <div class="by_item by_item1"><el-radio @change="changeSort('1',searchContent)" v-model="asc" label="1">{{ langs.markups.newtoold }}</el-radio></div>
                <div class="by_item by_item2"><el-radio @change="changeSort('2',searchContent)" v-model="asc" label="2">{{ langs.markups.oldtonew }}</el-radio></div>
              </div>
            </div>
            <div class="tag" @click="openTag()" v-show="screenWidth > 768">
              <div class="tag_img"></div>
              <span>{{ langs.markups.tags }}</span>
            </div>
          </div>
          <div class="tag_min" @click="openTag()" v-show="screenWidth <= 768">
            <div class="tag_img"></div>
            <span>{{ langs.markups.tags }}</span>
          </div>
        </div>
        <div class="searchTag" v-show="ifSearchTag">
          <span class="block"><i>{{ searchNameTag == '_no_tag' ? langs.markups.notTagged : searchNameTag }}</i><img src="../../../assets/images/app_smart_bar/tag-close.svg" alt="" @click="closeSearchTag()"></span>
        </div>
        <div class="content" v-for="(item,index) in contentAll" :key="index" v-show="count != 0">
          <a class="content_left" :href="'/sharings/'+item.shareId+'?enter=markups&lang='+lang">
            <div class="img" v-lazy:background-image="item.thumbnailLink"></div>
          </a>
          <div class="content_middle">
            <a class="title" :href="'/sharings/'+item.shareId+'?enter=markups&lang='+lang">{{ item.title }}</a>
            <a class="img_a" :href="'/sharings/'+item.shareId+'?enter=markups&lang='+lang">
              <div class="host">
                <img src="../../../assets/images/app_smart_bar/ic-web-globe.svg">
                <a :href="item.originLink" target="_blank">{{ item.host }}</a>
              </div>
              <i>|</i>
              <span>{{item.highlightsCount}} {{item.highlightsCount > 1 ? langs.markups.annotations : langs.markups.annotation }}</span>
            </a> 
            <a class="slide" :href="'/sharings/'+item.shareId+'?enter=markups&lang='+lang">
              <div v-for="(item2,index2) in item.highlights" :key="index2" class="article" :style="{'border-color': item2.styleOptions.backgroundColor}">{{item2.core}}</div>
            </a>
            <div class="tagsBox">
              <div class="tags">
                <span class="tag" v-for="(item1,index1) in contentAll[index].tags" :key="index1">{{item1}}</span>
              </div>
            </div>
          </div>
          <div class="content_right">
            <img class="omit_img" src="../../../assets/images/app_smart_bar/ic-more-gray-small.svg" @click.prevent.stop="omit_open(index)">
            <div class="omit_box" v-show="targetIndex == index">
              <div class="add_btn" @click.prevent="omitAdd(index)" v-show="!addOrEdit"><img src="../../../assets/images/app_smart_bar/web-ic-tags.svg" alt=""><span>{{ langs.markups.add }}</span></div>
              <div class="edit_btn" @click.prevent="omitAdd(index)" v-show="addOrEdit"><img src="../../../assets/images/app_smart_bar/web-ic-tags.svg" alt=""><span>{{ langs.markups.edit }}</span></div>
              <div @click.prevent="omitShare(index)"><img src="../../../assets/images/app_smart_bar/ic-share-00.svg" alt=""><span>{{ langs.common.share }}</span></div>
              <div @click.prevent="omitRemove(index)"><img src="../../../assets/images/app_smart_bar/ic-remove-00.svg" alt=""><span>{{ langs.common.remove }}</span></div>
            </div>
          </div>
          <el-divider></el-divider>
        </div>
        <div class="noSearchResult" v-show="count == 0">
          <img src="../../../assets/images/app_smart_bar/img-mk-web-search-empty.svg" alt="">
          <p>{{ langs.markups.noResult }}</p>
        </div>

        <Share :dialogFormVisible1.sync="dialogFormVisible1" :drawer1.sync="drawer1" :shareUrl="shareUrl" :title="contentAll[share_index] && contentAll[share_index].title" :host="contentAll[share_index] && contentAll[share_index].host" :shareLink="url" :currUser="currUser" :ifMine="ifMine" :lang="lang" :langs="langs" @changeUrl="changeUrl" />

        <el-dialog class="remove" :title="langs.common.removing" :visible.sync="dialogFormVisible3" :show-close="false" width top="25vh">
          <div class="top">{{ langs.common.deleting }}</div>
          <div class="bottom">
            <el-button class="button button_cancel" @click="dialogFormVisible3 = false">{{ langs.common.cancel }}</el-button>
            <el-button type="primary" class="button button_remove" @click="remove_article(article_index)">{{ langs.common.remove }}</el-button>
          </div>
        </el-dialog>

        <el-drawer
          custom-class="el-drawer2"
          :visible.sync="drawer2"
          size=""
          direction="btt"
          :show-close="false"
          >
          <template slot="title"><inline-svg @click="drawer2 = false" :src="require('../../../assets/images/app_smart_bar/close.svg')"/></template>
          <div class="add_btn" @click.prevent="omitAdd(index_index)" v-show="!addOrEdit">
            <img src="../../../assets/images/app_smart_bar/web-ic-tags.svg" alt="">
            {{ langs.markups.add }}
          </div>
          <div class="edit_btn" @click.prevent="omitAdd(index_index)" v-show="addOrEdit">
            <img src="../../../assets/images/app_smart_bar/web-ic-tags.svg" alt="">
            {{ langs.markups.edit }}
          </div>
          <div class="share_btn" @click.prevent="omitShare(index_index)">
            <img src="../../../assets/images/app_smart_bar/ic-share-00.svg" alt="">
            {{ langs.common.share }}
          </div>
          <div class="remove_btn" @click.prevent="omitRemove(index_index)">
            <img src="../../../assets/images/app_smart_bar/ic-remove-00.svg" alt="">
            {{ langs.common.remove }}
          </div>
        </el-drawer>

        <el-dialog class="addTag" :class="{addTagMobile: ifMobile}" :title="oldTags.length > 0 ? langs.markups.edit : langs.markups.add" :visible.sync="dialogFormVisible5" :close-on-press-escape="false" @close="addTag=''" width top="25vh">
          <div class="top addTop">
            <el-tag
              v-for="tag in oldTags"
              :key="tag"
              closable
              type="info"
              @close="handleClose(tag)">
              {{tag}}
            </el-tag>
            <el-input v-model="addTag" @input="suggestSearch" :trigger-on-focus="false" :popper-append-to-body="true" :placeholder="oldTags.length > 0 ? '' : langs.markups.type" popper-class="select-option" @focus="focusBorder" @blur="blurBorder">
            </el-input>
          </div>
          <div class="suggestList" v-show="addTag && addTag.trim()">
            <div class="scrollbar" :class="{hasPadding: suggestList.length > 0}">
              <ul>
                <li v-for="suggest in suggestList" :key="suggest" @click="handleSelect(suggest)"><div>{{suggest}}</div></li>
                <li class="create" :class="{hasMargin: suggestList.length > 0}" @click="handleSelect(addTag)" v-show="showSuggest"><div><img src="../../../assets/images/app_smart_bar/web-ic-createtag.svg" alt="">{{langs.markups.create}}<b> “</b><b>{{addTag}}</b><b>”</b></div></li>
              </ul>
            </div>
          </div>
          <div class="bottom">
            <el-button type="primary" class="button" @click="changeTag(article_index)">{{ langs.markups.done }}</el-button>
          </div>
        </el-dialog>

        <el-dialog class="createTag" :title="langs.markups.create" :visible.sync="dialogFormVisible6" :close-on-click-modal="false" @close="newTag=''" width top="25vh">
          <div class="top"><el-input v-model="newTag" :placeholder="langs.markups.type" @focus="focusBorder" @blur="blurBorder"></el-input></div>
          <div class="bottom">
            <el-button type="primary" class="button" @click="createTag">{{ langs.markups.done }}</el-button>
          </div>
        </el-dialog>

        <el-dialog class="renameTag" :title="langs.markups.rename" :visible.sync="dialogFormVisible7" :close-on-click-modal="false" width top="25vh">
          <div class="top"><el-input v-model="newnameTag" @focus="focusBorder" @blur="blurBorder"></el-input></div>
          <div class="bottom">
            <el-button type="primary" class="button" @click="renameTag">{{ langs.markups.done }}</el-button>
          </div>
        </el-dialog>

        <el-dialog class="deleteTag" :title="langs.markups.delete + '?'" :visible.sync="dialogFormVisible8" :close-on-click-modal="false" :show-close="false" width top="25vh">
          <div class="top">{{langs.markups.deleting1}}{{oldnameTag}}{{langs.markups.deleting2}}</div>
          <div class="bottom">
            <el-button class="button button_cancel" @click="dialogFormVisible8 = false;">{{ langs.common.cancel }}</el-button>
            <el-button type="primary" class="button button_remove" @click="deleteTag">{{ langs.common.delete }}</el-button>
          </div>
        </el-dialog>

        <el-drawer
          custom-class="el-drawer6"
          :visible.sync="drawer6"
          size=""
          direction="rtl"
          :show-close="false"
          :wrapperClosable="true"
          :modal-append-to-body="false"
          >
          <template slot="title"><h4>{{ langs.markups.tags }}</h4><inline-svg @click="closeTag()" :src="require('../../../assets/images/app_smart_bar/close.svg')" />
          </template>
          <div class="tags" :class="{iOS: ifiOS}">
            <div class="tag" v-for="(item,index) in tagList" :key="index" @click.prevent.stop="searchTag(index)" @mouseleave="menu_close()">
              <div class="tag_left">
                <img src="../../../assets/images/app_smart_bar/web-ic-tag.svg" alt="">
                <span>{{item.name}}&nbsp;</span><i>({{item.webpages_count}})</i>
              </div>
              <img class="more" src="../../../assets/images/app_smart_bar/ic-tag-more.svg" alt="" @click.prevent.stop="menu_open(index)">
              <div class="options" v-show="tagIndex == index">
                <div class="options_rename" @click.prevent.stop="dialogFormVisible7 = true;oldnameTag = tagList[index].name;newnameTag = oldnameTag">{{ langs.markups.rename }}</div>
                <div class="hr"></div>
                <div class="options_delete" @click.prevent.stop="dialogFormVisible8 = true;oldnameTag = tagList[index].name;">{{ langs.markups.delete }}</div>
              </div>
            </div>
            <div class="tag tagZero" @click.prevent.stop="searchTag()">
              <img src="../../../assets/images/app_smart_bar/web-ic-tag.svg" alt="">
              <span>{{ langs.markups.notTagged }}&nbsp;(<i>{{ notTagged }}</i>)</span>
            </div>
          </div>
          <div class="create_btn" @click="dialogFormVisible6 = true;">
            <img src="../../../assets/images/app_smart_bar/web-ic-createtag.svg" alt=""><span>{{ langs.markups.create }}</span>
          </div>
        </el-drawer>

        <el-drawer
          custom-class="el-drawer7"
          :visible.sync="drawer7"
          size=""
          direction="btt"
          :show-close="false"
          >
          <template slot="title"><div>{{ langs.markups.sortBy }}</div></template>
          <div class="by_item by_item1">
            <el-radio @change="changeSort('1',searchContent)" v-model="asc" label="1">{{ langs.markups.newtoold }}</el-radio>
          </div>
          <div class="by_item by_item2">
            <el-radio @change="changeSort('2',searchContent)" v-model="asc" label="2">{{ langs.markups.oldtonew }}</el-radio>
          </div>
        </el-drawer>
        
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="changePager"
          :current-page="page"
          :page-size="pageSize"
          :prev-text="langs.markups.prev"
          :next-text="langs.markups.next"
          :total="count"
          :pager-count="pagerCount"
          :class="{disabled: count < 9}"
          v-show="count != 0"
          >
        </el-pagination>

        <footer v-show="count != 0">
          <div class="footer_top">
            <div class="cu"><a href="https://www.kdanmobile.com/contact" target="_blank">{{ langs.common.footer.contact }}</a></div>
            <div class="oval"></div>
            <div class="tos"><a href="https://www.kdanmobile.com/terms_of_service" target="_blank">{{ langs.common.footer.terms }}</a></div>
            <div class="oval"></div>
            <div class="pp"><a href="https://www.kdanmobile.com/privacy_policy" target="_blank">{{ langs.common.footer.privacy }}</a></div>
            <div class="oval"></div>
            <div class="faq"><a href="https://support.kdanmobile.com/hc" target="_blank">FAQ</a></div>
          </div>
          <div class="footer_bottom">
            {{'© 2009-' + fullYear + '&nbsp;'}}<a href="https://www.kdanmobile.com" target="_blank">Kdan Mobile Software Ltd.</a>
          </div>
        </footer>
      </div>
    </template>
    <div id="removesuc" v-show='removeSuccess'><img src="../../../assets/images/app_smart_bar/ic-toast-sucess.svg" alt=""><span>{{ langs.common.removed }}</span></div>
    <div id="addsuc" v-show='addSuccess'><img src="../../../assets/images/app_smart_bar/ic-toast-sucess.svg" alt=""><span>{{ langs.markups.changeSaved }}</span></div>
    <div id="fail" v-show='failed'><img src="../../../assets/images/app_smart_bar/ic-toast-fail.svg" alt=""><span>{{ langs.common.failed }}</span></div>
  </div>
</template>

<script>
import Header from '../common/Header';
import Share from '../common/Share';
import Edu from '../common/Education';
import Cookies from 'js-cookie';
import axios from 'axios';
import InlineSvg from 'vue-inline-svg';
export default {
  name: 'markups',
  components: {
    Header,
    InlineSvg,
    Share,
    Edu
  },
  props: ['outside'],
  data() {
    return {
      langues: ['en', 'zh-tw', 'zh-cn', 'ja', 'es', 'ko'],
      currUser: this.outside.currUser,
      api_host: this.outside.api_host,
      server_env: this.outside.server_env,
      ifMine: true,
      contentAll: [],
      contentAllOld: [],
      load: true,
      page: 1,
      pageSize: 8,
      count: 0, //总个数
      pagerCount: 7,//按钮个数
      targetIndex: -1,
      share_index: '',
      shareUrl: '',
      dialogFormVisible1: false,
      dialogFormVisible3: false,
      article_index: null,
      drawer7: false,
      drawer6: false,
      drawer2: false,
      drawer1: false,
      screenWidth: '',
      url: null,
      index_index: null,
      removeSuccess: false,
      addSuccess: false,
      failed: false,
      lang: 'en',
      fullYear: '',
      searchContent: '',
      by: false,
      asc: '1',
      ifSearch: false,
      dialogFormVisible5: false,
      dialogFormVisible6: false,
      dialogFormVisible7: false,
      dialogFormVisible8: false,
      addTag: '',
      newTag: '',
      addOrEdit: false,
      tags: [],
      oldTags: [],
      tagList: [],
      suggestList: [],
      tagIndex: -1,
      oldnameTag: '',
      newnameTag: '',
      ifSearchTag: false,
      searchNameTag: '',
      notTagged: 0,
      showSuggest: true,
      ifMobile: false,
      ifiOS: false,
      barShadow: false,
      dialogEdu: false,
      drawerEdu: false,
      eventBarData: {
        color: '',
        bgColor: '',
        content: '',
        link: ''
      }
    }
  },
  computed: {
    isEmpty: function () {
      if(this.ifSearch){
        return false;
      }
      if(this.count == 0){
        return true;
      } else {
        return false;
      }
    },
    langs: function(val){
      return JSON.parse(JSON.stringify(this.outside.langs))
    }
  },
  watch: {
    screenWidth(val) {
      this.screenWidth = val;
      if (this.screenWidth <= 768) {
        this.dialogFormVisible1 = false;
        this.pagerCount = 5;
      } else {
        this.drawer2 = false;
        this.drawer1 = false;
        this.pagerCount = 7;
      }
      if (this.screenWidth < 930) {
        this.dialogEdu = false;
      } else {
        this.drawerEdu = false;
      }
    }
  },
  beforeMount() {
    if (this.currUser) {
      if (this.langues.includes(this.currUser.lang.toLowerCase())) {
        this.lang = this.currUser.lang.toLowerCase();
      } else {
        this.lang = 'en';
      }
      Cookies.set('lang', this.lang, {expires: 7});
    } else {
      window.location.pathname = '/explore'
      // const lang = Cookies.get('lang');
      // if (lang) {
      //   this.lang = lang;
      // } else {
      //   this.lang = 'en';
      // }
    }
    if (this.lang == 'en') {
      if (window.location.pathname.indexOf('/es/mymarkups') != -1 || window.location.pathname.indexOf('/zh-tw/mymarkups') != -1 || window.location.pathname.indexOf('/zh-cn/mymarkups') != -1 || window.location.pathname.indexOf('/ja/mymarkups') != -1 || window.location.pathname.indexOf('/ko/mymarkups') != -1) {
        window.location.pathname = '/mymarkups'
      }
    } else {
      if (window.location.pathname.indexOf('/' + this.lang + '/mymarkups') == -1) {
        window.location.pathname = '/' + this.lang + '/mymarkups';
      }
    }
  },
  mounted() {
    this.fIsMobile();
    var u = navigator.userAgent;
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    this.ifiOS = isiOS;
    this.fullYear = new Date().getFullYear();
    this.syncPurchaseStat();
    !this.currUser.purchase_stat && this.eventBar();
    document.addEventListener('scroll', this.handleScroll);
    document.body.addEventListener('click',(e) => {
      if (e.target.parentNode.className != 'sort' && e.target.parentNode.className != 'searchBox') {
        this.by = false;
      }
    }, false);
    if (window.location.search == '' || window.location.search.substring(6) == '') {
      var exp = new Date();
      exp.setTime(exp.getTime() + 600 * 144000);
      document.cookie = 'markup_page' + "=1" + ";expires=" + exp.toGMTString() + ";path=/";
    } else {
      var exp = new Date();
      exp.setTime(exp.getTime() + 600 * 144000);
      document.cookie = 'markup_page' + "=" + window.location.search.substring(6) + ";expires=" + exp.toGMTString() + ";path=/";
    }
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0;i < vars.length;i++) {
      var pair = vars[i].split("=");
      if(pair[0] == "page"){
        this.page = pair[1]*1;
      }
    }
    this.getTagList();
    if (this.currUser) {
      this.getContents(this.asc);
    }
    let that = this;
    that.screenWidth = window.innerWidth;
    window.onresize= () => {
      that.screenWidth = window.innerWidth;
    }
  },
  methods: {
    async eventBar() {
      let that = this;
      await axios.get("https://cms.kdanmobile.com/items/kdan_event_bar?filter[product_page][_eq]=markups&deep[translations][_filter][status][_eq]=published&deep[translations][_filter][languages_code][_eq]=" + that.lang + "&fields=*,translations.*",{transformRequest: [
          function(data,headers){
            if(headers.Accept){
              delete headers.Accept;
            }
            if(headers.AppID){
              delete headers.AppID;
            }
          }
          ]})
      .then((res)=>{
        if(res.data.data[0]){
          // let search = window.location.search;
          // if (search.indexOf('utm_source=PR') == -1 || search.indexOf('utm_campaign=PR_UnJaded_Jade_202202') == -1 || search.indexOf('utm_medium=UnJaded_Jade') == -1) {
          //   that.eventBar_link = '';
          //   return
          // }
          that.eventBarData = {
            color: res.data.data[0].color,
            bgColor: res.data.data[0].bg_color,
            content: res.data.data[0].translations[0].content,
            link: res.data.data[0].translations[0].link
          }
        }
      })
      .catch(function(err){
        console.log(err);
      });
    },
    fIsMobile () {
      if (/Android|iPhone|iPad|iPod|BlackBerry|webOS|Windows Phone|SymbianOS|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.ifMobile = true;
      }
    },
    syncPurchaseStat() {
      const refresh = Cookies.get('refreshState');
      const upgrade = Cookies.get('setUpgrade');
      if (this.currUser && (upgrade || !refresh)) {
        axios.get("/api/members/me?refresh=true")
        .then((res)=>{
          this.currUser = res.data.results;
          Cookies.set('refreshState', true);
          Cookies.remove('setUpgrade');
        })
        .catch(function(err){
          console.log(err);
          Cookies.set('refreshState', true);
        });
      }
    },
    // 获取列表,排序,关键字,失去焦点，是否查询，根据tag名查询
    async getContents (asc, keyWord, ifSearch, tagName) {
      let that = this;
      await axios.get('/api/projects/my_weblist', {params: {"index": (this.page - 1) * 8, "length": this.pageSize, "sort_order": (asc == '2' ? 'ASC' : 'DESC'), "keyword": (keyWord ? keyWord : ''), "tag_name": (tagName ? tagName.trim() : '')}})
        .then(function(response){
          that.load = false;
          that.count = response.data.paging.total_count;
          that.contentAllOld = response.data.results;
          if (ifSearch) {
            that.ifSearch = true;
          } else {
            that.ifSearch = false;
          }
        })
        .catch(function(err){
        });
      this.contentAll = this.contentAllOld;
      const len = this.contentAll.length
      if (len == 0) {
        if (this.page != 1) {
          this.page--;
          window.location.search = '?page=' + this.page;
          return
        }
      }
      for (var i = 0; i < len; i++) {
        this.contentAll[i].highlights = JSON.parse(decodeURIComponent(escape(window.atob(this.contentAll[i].highlights))));
      };
      window.pageYOffset = document.documentElement.scrollTop = document.body.scrollTop = 0;
    },
    cleanSearchContent () {
      this.searchContent = '';
      this.$refs['mark'].focus();
      this.changePager(1);
    },
    changeSort(asc,searchContent){
      this.drawer7 = false;
      this.asc = asc;
      this.getContents(this.asc,this.searchContent,(this.searchContent ? true : this.searchNameTag ? true : false),this.searchNameTag);
    },
    async getTagList () {
      let that = this;
      await axios.get('/api/web_tags?with_no_tag=1')
        .then(function(response){
          that.tagList = response.data.results;
          for(var i = 0; i < that.tagList.length; i++){
            if (that.tagList[i].name == '_no_tag') {
              that.notTagged = that.tagList[i].webpages_count;
              that.tagList.pop(1)
            }
          }
        })
        .catch(function(err){
        });
    },
    async createTag () {
      this.dialogFormVisible6 = false;
      if (!this.newTag) {
        return
      }
      let that = this;
      let oldTagList = that.tagList;
      for (let i = 0; i < oldTagList.length; i++) {
        if (oldTagList[i].name == that.newTag.trim()) {
          that.failed = true;
          setTimeout(function(){that.failed = false;},3000);
          return
        }
      }
      await axios.post("/api/web_tags", {"name": that.newTag.trim()})
        .then((res)=>{
          that.getTagList();
          if (res.data.results[0].id) {
            that.addSuccess = true;
            setTimeout(function(){that.addSuccess = false;},3000);
          } else {
            that.failed = true;
            setTimeout(function(){that.failed = false;},3000);
          }
        })
        .catch(function(err){
          that.failed = true;
          setTimeout(function(){that.failed = false;},3000);
        });
    },
    async renameTag () {
      this.dialogFormVisible7 = false;
      if (!this.oldnameTag) {
        return
      }
      let that = this;
      await axios.put("/api/web_tags/0", {"old_name": that.oldnameTag.trim(), "name": that.newnameTag.trim()})
        .then((res)=>{
          that.getTagList();
          if (that.searchNameTag == that.oldnameTag && that.newnameTag) {
            that.searchNameTag = that.newnameTag;
          }
          if (res.data.results.name != that.oldnameTag) {
            that.getContents(that.asc,that.searchContent,that.searchContent ? true : that.searchNameTag ? true : false,that.searchNameTag);
            that.addSuccess = true;
            setTimeout(function(){that.addSuccess = false;},3000);
          } else {
            that.failed = true;
            setTimeout(function(){that.failed = false;},3000);
          }
        })
        .catch(function(err){
          that.failed = true;
          setTimeout(function(){that.failed = false;},3000);
        });
    },
    async deleteTag () {
      this.dialogFormVisible8 = false;
      let that = this;
      await axios.delete("/api/web_tags/0", {params: {"name": that.oldnameTag.trim()}})
        .then((res)=>{
          that.getTagList();
          if (that.searchNameTag == that.oldnameTag) {
            if (that.searchNameTag == that.oldnameTag) {
              that.ifSearchTag = false;
              that.searchNameTag = '';
            }
            that.changePager(1);
          } else {
            that.getContents(that.asc,that.searchContent,that.searchContent ? true : that.searchNameTag ? true : false,that.searchNameTag);
          }
          that.removeSuccess = true;
          setTimeout(function(){that.removeSuccess = false;},3000);
        })
        .catch(function(err){
          that.failed = true;
          setTimeout(function(){that.failed = false;},3000);
        });
    },
    omit_open(i){
      if(this.contentAll[i].tags.length > 0){
        this.addOrEdit = true;
      } else {
        this.addOrEdit = false;
      }
      this.targetIndex = i;
      if(window.innerWidth < 768) {
        this.index_index = i;
        this.drawer2 = true;
      }
    },
    omit_close(){
      this.targetIndex = -1;
      this.drawer2 = false;
    },
    omitShare(i) {
      this.share_index = i;
      this.shareUrl = this.contentAll[this.share_index].thumbnailLink;
      if(window.innerWidth < 768){
        this.drawer1 = true;
      }else{
        this.dialogFormVisible1 = true;
      }
      this.url = this.api_host + this.contentAll[i].sharing_path + '?enter=share';
    },
    async remove_article (i) {
      let that = this;
      await axios.post("/api/projects/delete_by_id", {"projectId": this.contentAll[i].projectId})
        .then((res)=>{
          this.dialogFormVisible3 = false;
          this.getTagList();
          that.getContents(that.asc,that.searchContent,that.searchContent ? true : that.searchNameTag ? true : false,that.searchNameTag);
          this.removeSuccess = true;
          setTimeout(function(){that.removeSuccess = false;},3000);
        })
        .catch(function(err){
          that.failed = true;
          setTimeout(function(){that.failed = false;},3000);
        });
    },
    omitRemove(i) {
      this.dialogFormVisible3 = true;
      this.article_index = i;
    },
    changePager(newPage){
      this.page = newPage;
      this.getContents(this.asc,this.searchContent,this.searchContent ? true : this.searchNameTag ? true : false,this.searchNameTag);
      var pageSearch = '?page=' + newPage;
      window.history.pushState({status: 0} ,'',pageSearch);
      var exp = new Date();
      exp.setTime(exp.getTime() + 600 * 144000);
      document.cookie = 'markup_page' + "=" + newPage + ";expires=" + exp.toGMTString() + ";path=/";
    },
    changeUrl (params) {
      if (params) {
        this.url = this.url + '?enter=share';
      }else {
        this.url = this.url.replace("?enter=share","");
      }
    },
    add () {
      this.$refs.header.openclean();
    },
    changeCurrUser (curr,lang) {
      this.$emit('update:currUser', curr, lang);
      if (window.location.pathname.indexOf('/mymarkups') != -1 || window.location.pathname.indexOf('/es/mymarkups') != -1 || window.location.pathname.indexOf('/zh-tw/mymarkups') != -1 || window.location.pathname.indexOf('/zh-cn/mymarkups') != -1 || window.location.pathname.indexOf('/ja/mymarkups') != -1 || window.location.pathname.indexOf('/ko/mymarkups') != -1) {
        if(lang == 'en'){
          window.location.pathname = '/mymarkups'
        } else {
          window.location.pathname = '/' + lang + '/mymarkups';
        }
      }
    },
    openSort () {
      if (window.innerWidth > 768) {
        this.by = !this.by;
      } else {
        this.by = false;
        this.drawer7 = true;
      }
    },
    openTag () {
      this.drawer6 = true;
      document.getElementsByClassName('header_wrapper')[0].setAttribute('style', 'z-index: 3000');
      document.getElementsByTagName("body")[0].className = "clearScroll";
    },
    closeTag () {
      this.drawer6 = false;
      this.dialogFormVisible6 = false;
      this.dialogFormVisible7 = false;
      this.dialogFormVisible8 = false;
      document.getElementsByClassName('header_wrapper')[0].setAttribute('style', 'z-index: 400');
      document.body.removeAttribute("class", "clearScroll");
    },
    omitAdd (i) {
      this.tags = this.contentAll[i].tags;
      this.oldTags = this.tags.slice();
      this.dialogFormVisible5 = true;
      this.article_index = i;
    },
    async changeTag (i) {
      this.tags = this.oldTags.slice();
      if (this.addTag) {
        this.tags.push(this.addTag.trim());
      }
      this.addTag = '';
      let that = this;
      await axios.post("/api/projects/update_tags", {"projectId": this.contentAll[i].projectId, "tags": this.tags})
        .then((res)=>{
          this.dialogFormVisible5 = false;
          this.getTagList();
          that.getContents(that.asc,that.searchContent,that.searchContent ? true : that.searchNameTag ? true : false,that.searchNameTag);
        })
        .catch(function(err){
          that.failed = true;
          setTimeout(function(){that.failed = false;},3000);
        });
    },
    handleClose(tag) {
      this.oldTags.splice(this.oldTags.indexOf(tag), 1);
    },
    focusBorder () {
      var length = document.getElementsByClassName('top').length;
      for(var i = 0; i < length; i++){
        document.getElementsByClassName('top')[i].classList.add('focus');
      }
    },
    blurBorder () {
      var length = document.getElementsByClassName('top').length;
      for(var i = 0; i < length; i++){
        document.getElementsByClassName('top')[i].classList.remove('focus');
      }
    },
    focusSearchBorder () {
      var length = document.getElementsByClassName('search').length;
      for(var i = 0; i < length; i++){
        document.getElementsByClassName('search')[i].classList.add('focusSearch');
      }
    },
    blurSearchBorder () {
      var length = document.getElementsByClassName('search').length;
      for(var i = 0; i < length; i++){
        document.getElementsByClassName('search')[i].classList.remove('focusSearch');
      }
    },
    handleSelect (item) {
      if(this.oldTags.indexOf(item) == -1) {
        this.oldTags.push(item.trim());
      }
      this.addTag = '';
    },
    menu_open (i) {
      this.tagIndex = i;
    },
    menu_close () {
      this.tagIndex = -1;
    },
    searchTag (i) {
      this.ifSearchTag = true;
      if (i >= 0) {
        this.searchNameTag = this.tagList[i].name;
      } else {
        this.searchNameTag = '_no_tag';
      }
      this.changePager(1);
      this.drawer6 = false;
    },
    closeSearchTag () {
      this.ifSearchTag = false;
      this.searchNameTag = '';
      this.changePager(1);
    },
    suggestSearch() {
      this.suggestList = [];
      var eqNum = 0;
      if (this.addTag && this.addTag.trim()) {
        for (var i = 0; i < this.tagList.length; i++) {
          if (this.tagList[i].name.toLowerCase().indexOf(this.addTag.toLowerCase()) > -1) {
            this.suggestList.push(this.tagList[i].name);
          }
          if (this.addTag.toLowerCase() == this.tagList[i].name.toLowerCase()) {
            eqNum++;
          }
        }
        if (eqNum > 0) {
          this.showSuggest = false;
        } else {
          this.showSuggest = true;
        }
        let topDom = document.getElementsByClassName('addTop');
        var width = topDom[topDom.length - 1].offsetWidth;
        var top = topDom[topDom.length - 1].offsetHeight;
        let suggestListDom = document.getElementsByClassName('suggestList');
        suggestListDom[suggestListDom.length - 1].style.width = width + 'px';
        suggestListDom[suggestListDom.length - 1].style.top = top + 'px';
      }
    },
    handleScroll () {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      this.shadow = scrollTop > 0;
      this.barShadow = scrollTop > 64;
    },
    openVerified () {
      this.$children[2].eduEmail = null;
      this.$children[2].eduEmail_error = false;
      this.$children[2].eduFormat_error = false;
      this.$children[2].eduEmail_onblur = false;
      if (window.innerWidth < 930) {
        this.drawerEdu = true;
      } else {
        this.dialogEdu = true;
      }
    }
  },
  destroyed () {
    //离开该页面需要移除这个监听的事件
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style lang="scss" scoped>
@import '../../vue_styles/global.scss';
.clearScroll {
  overflow: hidden;
}
.dashboard-container{
  #eventBar {
    display: none;
  }
  #eventBar.cms {
    display: block;
    width: 100%;
    padding: 22px;
    text-align: center;
    a {
      font-size: 16px;
    }
    a:hover {
      text-decoration: underline;
    }
  }
  .barHeader {
    position: static;
  }
  .eventHeader {
    position: fixed;
  }
  /deep/ .path {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);
  }
  #removesuc, #addsuc {
    position: fixed;
    left: 36px;
    bottom: 36px;
    display: flex;
    align-items: center;
    padding: 14px 16px;
    border-radius: 5px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.05);
    background-color: #dff4d2;
    z-index: 999;
    text-align: center;
    white-space: nowrap;
    img {
      width: 20px;
      height: 20px;
      margin-right: 12px;
    }
    span {
      font-size: 14px;
      font-weight: 500;
      color: #28a60e;
    }
  }
  #fail {
    position: fixed;
    left: 36px;
    bottom: 36px;
    display: flex;
    align-items: center;
    padding: 14px 16px;
    border-radius: 5px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.05);
    background-color: #ffe1dd;
    z-index: 999;
    text-align: center;
    img {
      width: 20px;
      height: 20px;
      margin-right: 12px;
    }
    span {
      font-size: 14px;
      font-weight: 500;
      color: #ff4d36;
    }
  }
  .skeleton_wrapper {
    margin-top: 286.4px;
    .skeleton_container {
      width: 1024px;
      margin: 0 auto;
      .skeleton_item {
        display: flex;
        .skeleton_left {
          width: 260px;
          height: 180px;
          background-color: rgb(232, 232, 232);
        }
        .skeleton_right {
          flex-grow: 1;
          margin-left: 32px;
          .skeleton_top {
            width: 100%;
            height: 16px;
            margin-bottom: 12px;
            background-color: rgb(232, 232, 232);
          }
          .skeleton_bottom {
            width: 33.3333%;
            height: 10px;
            background-color: rgb(232, 232, 232);
          }
        }
      }
      .skeleton_item + .skeleton_item {
        margin-top: 80px;
      }
    }
  }
  .empty {
    display: none;
  }
  .app-container {
    .zeroTitle {
      display: none;
      height: 40px;
      margin-top: 16px;
      font-size: 32px;
      font-weight: bold;
      letter-spacing: -0.3px;
    }
    .eventZeroTitle {
      margin-top: 80px;
    }
    .zero {
      display: none;
      margin-top: 20px;
      font-size: 28px;
      font-weight: 500;
      letter-spacing: -0.3px;
    }
    .add {
      display: none;
      flex-direction: column;
      width: 28.0405%;
      margin: 40px auto;
      .add_img {
        width: 100%;
      }
      .add_text1 {
        margin-top: 12px;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: -0.13px;
        color: #343434;
      }
      .add_text2 {
        font-size: 14px;
        line-height: 1.71;
        letter-spacing: -0.1px;
        text-align: center;
        color: #7a7a7a;
      }
      .add_plus {
        display: inline-block;
        text-align: center;
        margin: 0 auto;
        margin-top: 20px;
        width: 155px;
        height: 40px;
        padding: 10px 19px 9px 8px;
        border-radius: 5px;
        border: solid 1px #ff5f4b;
        color: #ff5f4b;
        font-size: 14px;
        font-weight: bold;
        img {
          margin-top: -2px;
        }
      }
      .add_plus:hover {
        background-color: #fff1ef;
      }
      .add_plus:active {
        background-color: #e65644;
      }
      .add_or {
        width: 100%;
        text-align: center;
        margin-top: 10px;
      }
      .add_a {
        display: inline-block;
        text-align: center;
        margin: 0 auto;
        margin-top: 10px;
        width: 155px;
        height: 40px;
        color: #ff5f4b;
        font-size: 14px;
        font-weight: bold;
        text-decoration: underline;
      }
    }
    .steps {
      margin: 0 auto;
      margin-top: 100px;
      width: 549px;
      h2 {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: -0.11px;
        color: #505050;
        margin-bottom: 20px;
      }
      .step_one, .step_two, .step_three {
        display: flex;
        justify-content: space-between;
        margin-top: 14px;
        .step_text {
          display: flex;
          flex-direction: column;
          h3 {
            font-size: 36px;
            font-weight: bold;
            line-height: 0.72;
            letter-spacing: -0.26px;
            color: #dedede;
            margin-bottom: 10px;
          }
          p {
            font-size: 14px;
            line-height: 1.5;
            letter-spacing: -0.1px;
            color: var(--greyish-brown);
          }
        }
        .step_img {
          width: 200px;
          height: 160px;
        }
      }
      .step_one {
        .step_text {
          margin-top: 28px;
          .step_btns {
            display: flex;
            margin-top: 10px;
            .step_btn_chrome, .step_btn_edge {
              display: flex;
              align-items: center;
              padding: 3px 10px 3px 7px;
              border-radius: 5px;
              border: solid 1px #cecece;
              img {
                width: 30px;
              }
              span {
                margin-left: 5px;
                font-size: 14px;
                font-weight: 500;
                text-align: center;
                color: #363636;
              }
            }
            .step_btn_edge {
              margin-left: 12px;
            }
          }
        }
      }
      .step_two {
        .step_text {
          margin-top: 30px;
        }
      }
      .step_three {
        .step_text {
          margin-top: 18px;
        }
      }
    }
    .eventSteps {
      margin-top: 164px;
    }
    .myTitle {
      display: flex;
      justify-content: space-between;
      padding-top: 96px;
      margin-bottom: 72px;
      .countBox {
        .mymarkups {
          font-size: 28px;
          font-weight: bold;
          letter-spacing: -0.26px;
          color: #343434;
        }
        .count {
          font-size: 16px;
          letter-spacing: -0.15px;
          color: #929292;
        }
      }
      .searchBox {
        display: flex;
        justify-content: space-around;
        padding-top: 13.6px;
        .search {
          display: flex;
          align-items: center;
          position: relative;
          border: 1px solid #e1e1e1;
          border-radius: 4px;
          max-height: 44px;
          &:hover {
            border-color: #C0C4CC;
          }
          /deep/ .el-input {
            width: 360px;
            .el-input__inner {
              height: 26px;
              line-height: 26px;
              border: 0;
            }
          }
          .close {
            position: absolute;
            display: flex;
            align-items: center;
            height: 30px;
            top: 7px;
            right: 37px;
            border-right: 1px solid #e0e0e0;
            padding-right: 7.5px;
            margin-right: 6.5px;
            .close_img {
              width: 20px;
              height: 20px;
              cursor: pointer;
              border-radius: 100%;
            }
            .close_img:hover {
              background-color: #f4f4f4;
            }
          }
          .search_img {
            position: absolute;
            top: 7px;
            right: 7px;
            cursor: pointer;
            border-radius: 4px;
          }
          .search_img:hover {
            background-color: #f4f4f4;
          }
        }
        .focusSearch {
          border: 1px solid #92959A !important;
        }
        .sort, .tag {
          display: flex;
          align-items: center;
          height: 44px;
          position: relative;
          padding: 7px 14px 7px 7px;
          border-radius: 4px;
          border: solid 1px #e1e1e1;
          font-weight: 500;
          letter-spacing: -0.15px;
          color: #868686;
          margin-left: 12px;
          cursor: pointer;
        }
        .sort {
          .sort_img {
            width: 30px;
            height: 30px;
            background-image: url(../../../assets/images/app_smart_bar/ic-web-mymarkups-sort.svg);
            margin-right: 6px;
          }
          .by {
            position: absolute;
            top: 43px;
            right: 0;
            min-width: 188px;
            padding: 9px 9px 20px 15px;
            border-radius: 4px;
            box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.14);
            border: 1px solid #e0e0e0;
            font-size: 14px;
            background-color: #fff;
            .title {
              color: #959595;
            }
            .by_item {
              /deep/ label {
                color: #646464;
                .el-radio__input {
                  .el-radio__inner {
                    width: 16px;
                    height: 16px;
                  }
                  .el-radio__inner:after {
                    width: 5px;
                    height: 5px;
                  }
                }
              }
              /deep/ .is-checked {
                .el-radio__label {
                  color: #646464;
                }
              }
            }
            .by_item1 {
              margin-top: 21px;
            }
            .by_item2 {
              margin-top: 25px;
            }
          }
        }
        .sort:hover {
          background-color: #eee;
        }
        .tag {
          .tag_img {
            width: 30px;
            height: 30px;
            background-image: url(../../../assets/images/app_smart_bar/ic-web-mymarkups-tag.svg);
            margin-right: 6px;
          }
        }
        .tag:hover {
          background-color: #eee;
        }
      }
    }
    .eventTitle {
      padding-top: 160px;
    }
    .noBottom {
      margin-bottom: 0;
    }
    .searchTag {
      margin-top: 32px;
      margin-bottom: 48px;
      .block {
        display: inline-block;
        padding: 6px 6px 6px 12px;
        border-radius: 6px;
        background-color: #343434;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.15px;
        color: #fff;
        img {
          cursor: pointer;
        }
      }
    }
    .content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      .content_left {
        width: 25.3906%;
        font-size: 0;
        .img {
          width: 260px;
          height: 180px;
          display: inline-block;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
      .content_middle {
        display: flex;
        flex-direction: column;
        width: 61.0352%;
        height: 180px;
        .title {
          flex: none;
          font-size: 20px;
          line-height: 1.3;
          max-height: 50px;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;/*想省略几行就写几*/
          -webkit-box-orient: vertical;
          color: #343434;
        }
        .img_a{
          padding: 8px 0 14px;
          img, a {
            vertical-align: middle;
          }
          img {
            width: 20px;
          }
          a {
            overflow: hidden;
            color: rgb(141, 141, 141);
          }
          i {
            color: #d8d8d8;
          }
          a, span {
            display: inline-block;
            font-size: 12px;
            line-height: 16px;
            color: rgb(151, 151, 151);
          }
        }
        .slide {
          padding-right: 8px;
          flex-grow: 1;
          overflow-y: scroll;
          .article {
            & + .article {
              margin-top: 5px;
            }
            padding-left: 8px;
            color: #727272;
            font-size: 14px;
            border-left: 5px solid;
          }
        }
        .slide::-webkit-scrollbar {
          display: none;
        }
        .tagsBox {
          display: flex;
          .tags {
            display: inline-block;
            margin: 7px 0 0;
            flex: none;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            .tag {
              display: inline-block;
              vertical-align: middle;
              font-size: 13px;
              color: #6f6f6f;
              padding: 5.5px 8px;
              border-radius: 6px;
              background-color: #f0f0f0;
              margin-right: 1%;
            }
          }
          .tags::-webkit-scrollbar {
            display: none;
          }
        }
      }
      .content_right {
        position: relative;
        width: 9.1797%;
        text-align: right;
        .omit_img {
          width: 20px;
          cursor: pointer;
        }
        .omit_box {
          position: absolute;
          right: 0;
          text-align: left;
          border-radius: 2px;
          box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.26);
          background-color: #fff;
          z-index: 9;
          div {
            display: flex;
            align-items: center;
            min-width: 103px;
            height: 40px;
            padding: 2px;
            font-size: 14px;
            color: #9d9d9d;
            border-bottom: 1px solid #e1e1e1;
            img {
              width: 36px;
              margin-right: 2px;
            }
            span {
              white-space: nowrap;
            }
          }
          .add_btn, .edit_btn {
            img {
              width: 22px;
              margin: 7px;
            }
          }
        }
      }
      .el-divider {
        flex-basis: 100%;
        margin: 40px 0;
      }
    }
    .content:focus {
      outline: none;
    }
    .noSearchResult {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 240px;
        height: 180px;
      }
      p {
        margin-top: 12px;
        font-size: 21px;
        font-weight: bold;
        color: #343434;
        text-align: center;
      }
    }
    /deep/ .el-pagination {
      &.disabled {
        .btn-prev, .btn-next {
          display: none;
        }
      }
      .btn-prev {
        margin-right: 4px;
        color: #ff5f4b;
      }
      .btn-next {
        margin-left: 4px;
        color: #ff5f4b;
      }
      margin: 15px auto;
      padding: 0;
      text-align: center;
      button {
        height: 40px;
        margin: 0;
        background-color: #fff;
        span {
          padding: 0 4px;
          min-width: 60px;
          height: 100%;
          line-height: 40px;
          border-radius: 3px;
          box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
          font-size: 14px;
          font-weight: 500;
        }
      }
      .el-pager {
        li {
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-size: 16px;
          font-weight: normal;
          background-color: #fff;
        }
        .number {
          margin: 0 4px;
          border-radius: 3px;
          box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
          letter-spacing: -0.6px;
          color: #9b9b9b;
        }
        .active {
          color: #fff;
        }
      }
    }
    footer {
      margin: 0 auto;
      margin-top: 85px;
      width: 60%;
      .footer_top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        div {
          margin: auto 0;
          font-weight: 500;
          font-size: 14px;
          color: #343434;
          white-space: nowrap;
        }
        .oval {
          width: 5px;
          height: 5px;
          background-color: #444444;
          margin-left: 20px;
          margin-right: 20px;
          border-radius: 4px;
        }
      }
      .footer_bottom {
        display: flex;
        justify-content: center;
        margin-top: 32px;
        margin-bottom: 25px;
        font-size: 14px;
        color: #343434;
        text-align: center;
        a {
          text-decoration: underline;
          color: #343434;
        }
      }
    }
  }
  /deep/ .remove, /deep/ .deleteTag {
    .el-dialog {
      width: 100%;
      max-width: 420px;
      border-radius: 5px;
      .el-dialog__header{
        .el-dialog__title{
          font-size: 18px;
          font-weight: bold;
          color: #343434;
        }
      }
      .el-dialog__body{
        padding: 0 20px 20px 20px;
        .top {
          width: 100%;
          max-width: 384px;
          font-size: 14px;
          line-height: 1.43;
          color: #4b4b4b;
        }
        .bottom {
          margin-top: 30px;
          display: flex;
          justify-content: flex-end;
          .button {
            height: 40px;
            border-radius: 5px;
            font-size: 16px;
          }
          .button_cancel {
            min-width: 89px;
            color: #838383;
            padding: 9px 0;
            border: 1px solid #d2d2d2;
            margin-right: 8px;
            background-color: #fff;
            font-weight: normal;
            text-align: center;
          }
          .button_remove {
            min-width: 98px;
            background-color: #ff5f4b;
            color: #fff;
            font-weight: bold;
            margin: 0;
            text-align: center;
          }
          .button_cancel:hover {
            background-color: #f5f5f5;
          }
          .button_remove:hover {
            opacity: 0.8;
          }
          .button_cancel:active {
            background-color: #e5e5e5;
          }
          .button_remove:active {
            background-color: #e65644;
          }
        }
      }
    }
  }
  /deep/ .el-drawer2 {
    display: flex;
    flex-direction: column;
    .el-drawer__header {
      justify-content: flex-end;
      margin: 0;
      padding: 8px 8px 0 0;
      svg {
        flex: none;
        width: 36px;
        height: 36px;
      }
    }
    .el-drawer__body {
      padding: 8px 0 24px 24px;
    }
    .add_btn, .edit_btn, .share_btn {
      margin-bottom: 16px;
    }
    div {
      width: 100%;
      font-size: 16px;
      color: #9d9d9d;
      cursor: pointer;
      img {
        width: 36px;
        margin-right: 5px;
      }
    }
    .add_btn, .edit_btn {
      img {
        width: 22px;
        margin: 7px;
      }
    }
  }
  /deep/ .el-drawer6 {
    position: fixed;
    right: 0;
    width: 320px;
    margin-top: 64px;
    height: calc(100vh - 50px);
    box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.17);
    background-color: #fff;
    z-index: 999;
    animation: rtl-drawer-out 0.3s;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .el-drawer__header {
      position: fixed;
      width: 320px;
      height: 56px;
      justify-content: space-between;
      margin: 0;
      padding: 16px 12px 16px 20px;
      border-bottom: 1px solid #ececec;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: -0.19px;
      color: #383838;
      background-color: #fff;
      z-index: 100;
      svg {
        flex: none;
        width: 36px;
        height: 36px;
        cursor: pointer;
      }
    }
    .el-drawer__body {
      padding-top: 64px;
    }
    .tags {
      width: 100%;
      font-size: 14px;
      line-height: 1.29;
      color: #343434;
      margin-bottom: calc(env(safe-area-inset-bottom) + 64px);
      .tag {
        display: flex;
        justify-content: space-between;
        position: relative;
        padding: 12px 19px 12px 20px;
        margin: 4px 0;
        .tag_left {
          display: flex;
          align-items: center;
          img {
            width: 20px;
            margin-right: 9px;
          }
          span {
            display: inline-block;
            max-width: 210px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .more {
          display: none;
          cursor: pointer;
          float: right;
        }
        .options {
          position: absolute;
          top: 32px;
          right: 28px;
          border-radius: 4px;
          box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.15);
          border: solid 1px #e0e0e0;
          background-color: #fff;
          padding: 4px 0;
          z-index: 100;
          .options_rename, .options_delete {
            width: 160px;
            padding: 4px 0 4px 12px;
            font-size: 14px;
            color: #343434;
            cursor: pointer;
          }
          .options_rename:hover, .options_delete:hover {
            background-color: #ededed;
          }
          .hr {
            border-bottom: 1px solid #e1e1e1;
            margin: 4px 0;
          }
        }
      }
      .tag:hover {
        background-color: #f7f7f7;
        .more {
          display: block;
        }
      }
      .tagZero {
        justify-content: flex-start;
        img {
          margin-right: 9px;
        }
        span {
          line-height: 20px;
        }
      }
    }
    .iOS {
      padding-bottom: 90px;
    }
    .create_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      bottom: 0;
      right: 0;
      cursor: pointer;
      width: 320px;
      height: 50px;
      padding: 0 80px;
      border: solid 1px #e9e9e9;
      background-color: #fff;
      z-index: 99;
      img {
        width: 20px;
        margin-right: 8px;
      }
      span {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.2px;
        color: #192f51;
        white-space: nowrap;
      }
    }
    .create_btn:hover {
      background-color: #e7e7e7;
    }
    .create_btn:active {
      background-image: none;
      outline: none;
      background-color: #c6c6c6;
    }
  }
  /deep/ .el-drawer7 {
    display: flex;
    flex-direction: column;
    border-radius: 10px 10px 0 0;
    .el-drawer__header {
      justify-content: flex-end;
      margin: 0;
      padding: 12px 0 14px 16px;
    }
    .el-drawer__body {
      padding: 0 0 15px 16px;
      .by_item {
        font-size: 14px;
        color: #646464;
        label {
          .el-radio__input {
            .el-radio__inner {
              width: 16px;
              height: 16px;
            }
            .el-radio__inner:after {
              width: 5px;
              height: 5px;
            }
          }
        }
        .is-checked {
          .el-radio__label {
            color: #646464;
          }
        }
      }
      .by_item2 {
        margin-top: 14px;
      }
    }
  }
  /deep/ .addTag, /deep/ .createTag, /deep/ .renameTag {
    .el-dialog {
      width: 100%;
      max-width: 480px;
      border-radius: 5px;
      .el-dialog__header {
        padding-top: 12px;
        padding: 20px;
        padding-bottom: 24px;
        .el-dialog__title {
          font-size: 18px;
          font-weight: bold;
          color: #343434;
        }
        button {
          font-size: 24px;
          i {
            vertical-align: top;
            color: rgb(68, 68, 68);
          }
        }
      }
      .el-dialog__body {
        position: relative;
        padding: 0 20px 20px 20px;
        .top {
          display: flex;
          flex-wrap: wrap;
          font-size: 14px;
          line-height: 1.43;
          color: #4b4b4b;
          border-radius: 5px;
          overflow: hidden;
          .el-input {
            width: 0;
            height: 26px;
            line-height: 26px;
            min-width: 30px;
            flex-grow: 1;
            text-overflow: ellipsis;
            margin-top: 8px;  
            .el-input__inner {
              height: 26px;
              line-height: 26px;
            }
          }
        }
        .el-tag {
          position: relative;
          height: 26px;
          max-width: 100%;
          line-height: 18px;
          font-size: 13px;
          color: #717171;
          padding: 4px 21px 4px 8px;
          margin-right: 6px;
          border-radius: 6px;
          background-color: #f0f0f0;
          z-index: 400;
          margin-top: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          i {
            position: absolute;
            right: 0;
            top: 5px;
          }
          i:hover {
            background-color: #f0f0f0;
            color: #909399;
            font-weight: 700;
          }
        }
        .top {
          border: 2px solid #DCDFE6;
          padding: 3px 13px 11px;
          .el-input {
            display: inline-block;
            .el-input__inner {
              border: 0;
              padding: 0;
            }
          }
        }
        .top:hover {
          border: 2px solid #C0C4CC;
        }
        .focus {
          border: 2px solid #92959A !important;
        }
        .suggestList {
          position: absolute;
          overflow: hidden;
          top: 52px;
          left: 20px;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
          border: solid 1px #e3e3e3;
          background-color: #fff;
          z-index: 10000;
          .scrollbar {
            overflow-y: scroll;
            height: 100%;
            margin-right: -17px;
            max-height: 162px;
            ul {
              li {
                display: block;
                margin: 0;
                cursor: pointer;
                color: #343434;
                font-size: 14px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: list-item;
                text-align: -webkit-match-parent;
                cursor: pointer;
                div {
                  padding: 6px 0 6px 12px;
                  img {
                    margin-right: 8px;
                  }
                }
              }
              li:hover div {
                background-color: #ededed;
              }
              .create {
                border-top: 1px solid #e7e7e7;
                padding: 6px 0 6px;
              }
              .hasMargin {
                margin-top: 8px;
              }
            }
          }
          .hasPadding {
            padding-top: 8px;
          }
        }
        .bottom{
          margin-top: 30px;
          display: flex;
          justify-content: flex-end;
          .button {
            height: 40px;
            border-radius: 5px;
            font-size: 16px;
          }
          .button {
            min-width: 98px;
            background-color: #ff5f4b;
            color: #fff;
            font-weight: bold;
            margin: 0;
            text-align: center;
          }
          .button:hover {
            opacity: 0.8;
          }
          .button:active {
            background-color: #e65644;
          }
        }
      }
    }
  }
  /deep/ .addTagMobile {
    .el-dialog {
      .el-dialog__body {
        .suggestList {
          .scrollbar {
            margin-bottom: 0;
            margin-right: 0;
          }
        }
      }
    }
  }
}
  @media screen and (max-width: 768px) {
    .dashboard-container {
      .app-container {
        .zeroTitle {
          display: block;
        }
        .zero {
          display: block;
        }
        .myTitle {
          flex-direction: column;
          padding-top: 32px;
          margin-bottom: 46px;
          .searchBox {
            justify-content: space-between;
            .search {
              width: 100%;
              /deep/ .el-input {
                width: 100%;
              }
            }
            .sort {
              span {
                white-space:nowrap;
              }
            }
          }
          .tag_min {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 44px;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: -0.15px;
            color: #868686;
            text-align: center;
            border-radius: 4px;
            border: solid 1px #e1e1e1;
            background-color: #fff;
            cursor: pointer;
            margin-top: 10px;
            .tag_img {
              width: 30px;
              height: 30px;
              background-image: url(../../../assets/images/app_smart_bar/ic-web-mymarkups-tag.svg);
              margin-right: 6px;
            }
          }
          .tag_min:hover {
            background-color: #eee;
          }
        }
        .eventTitle {
          padding-top: 96px;
        }
        .noBottom {
          margin-bottom: 0;
        }
        .searchTag {
          margin-top: 24px;
          margin-bottom: 28px;
          .block {
            border-radius: 4px;
          }
        }
        .add {
          display: flex;
          width: 70%;
          .add_img {
            max-width: 400px;
            margin: 0 auto;
          }
        }
        .steps {
          display: none;
        }
      }
      .container {
        .countBox {
          .mymarkups {
            font-size: 22px;
            letter-spacing: -0.21px;
          }
          .count {
            font-size: 12px;
            letter-spacing: -0.11px;
          }
        }
        .content {
          .content_left {
            width: 100px;
            .img {
              width: 100px;
              height: 100px;
            }
          }
          .content_middle {
            display: flex;
            flex-direction: column;
            width: 64.0352%;
            height: 100px;
            .title {
              font-size: 15px;
              line-height: 1.3;
              max-height: 36px;
            }
            .img_a {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              flex-grow: 1;
              padding: 0;
              img {
                display: none;
              }
              a {
                display: block;
                margin-top: 8px;
                font-size: 12px;
                line-height: 16px;
                word-break: break-all;
              }
              i{
                display: none;
              }
              span{
                display: none;
                line-height: 20px;
              }
            }
            .slide {
              display: none;
            }
            .tagsBox  {
              .tags {
                .tag {
                  font-size: 10.4px;
                  padding: 3.9px 6.6px;
                  border-radius: 4.8px;
                  margin-right: 1.33333%;
                }
              }
            }
          }
          .content_right {
            position: relative;
            width: 4.1797%;
            .omit_img {
              position: absolute;
              top: 0px;
              right: 0px;
            }
          }
          .el-divider {
            margin: 15px 0;
          }
        }
        /deep/ .el-drawer6 {
          top: 0;
          left: 0;
          width: 100%;
          height: calc(100vh - 50px);
          overflow-y: auto;
          .el-drawer__header {
            width: 100%;
          }
          .tags {
            .tag {
              .tag_left {
                span {
                  max-width: 192px;
                }
              }
              .more {
                display: block;
              }
            }
            .tag:hover {
              background-color: #fff;
            }
          }
          .create_btn {
            width: 100%;
          }
        }
        footer {
          display: none;
        }
      }
      .app-container /deep/ .el-pagination {
        button {
          height: 30px;
          span {
            min-width: 40px;
            height: 100%;
            line-height: 30px;
          }
        }
        .el-pager li {
          width: 30px;
          height: 30px;
          line-height: 30px;
        }
      }
      /deep/ .remove {
        .el-dialog {
          width: 300px;
          .el-dialog__body {
            .top {
              width: 250px;
            }
          }
        }
      }
      #removesuc, #addsuc, #fail {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  @media screen and (max-width: 767.5px) {
    .dashboard-container {
      .container {
        .content {
          .content_right {
            .omit_box {
              display: none;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 420px) {
    .dashboard-container {
      .app-container {
        .myTitle {
          padding-top: 16px;
          margin-bottom: 28px;
        }
        .eventTitle {
          padding-top: 80px;
        }
        .noBottom {
          margin-bottom: 0;
        }
        .add {
          width: 90%;
          .add_img {
            width: 80%;
            margin: 0 auto;
          }
        }
      }
      .container {
        .content {
          .content_middle {
            width: 55.0352%;
          }
        }
        /deep/ .el-pagination {
          margin: 15px auto;
          padding: 0;
        }
      }
    }
  }
  @media screen and (min-width: 769px) {
    .dashboard-container .app-container .content .content_middle .img_a {
      display: flex;
      align-items: center;
      i {
        margin: 0 16px;
      }
    }
  }
  @media screen and (min-width: 769px) and (max-width:1024px) {
    .dashboard-container {
      .container {
        padding: 0 20px 16px;
        .myTitle {
          .searchBox {
            .search {
              width: 300px;
              /deep/ .el-input {
                width: 300px;
              }
            }
          }
        }
        .content {
          .content_left {
            width: 29.3906%;
            .img {
              width: 220px;
              height: 152px;
            }
          }
          .content_middle {
            width: 55.0352%;
            height: 152px;
            .title {
              font-size: 17px;
              line-height: 1.4;
            }
            .slide {
              .article {
                font-size: 12px;
              }
            }
            .tags {
              margin: 5px 0;
              .tag {
                font-size: 11px;
                padding: 3px 7px 6px;
                border-radius: 5.4px;
                margin-right: 4px;
              }
            }
          }
          .content_right {
            width: 7.1797%;
            .omit_img {
              margin-left: 60%;
            }
          }
        }
        footer {
          width: 86% !important;
        }
      }
    }
  }
  @media screen and (min-width: 1025px) and (max-width: 1280px) {
    .dashboard-container {
      .container {
        padding: 0 48px;
        .content {
          .content_left {
            width: 25.3906%;
            .img {
              width: 259px;
              height: 180px;
            }
          }
          .content_middle {
            width: 55.0352%;
            height: 180px;
          }
          .content_right {
            width: 3.1797%;
            .omit_img {
              margin-left: 0;
            }
          }
        }
      }
      footer {
        width: 80% !important;
      }
    }
  }
  @media screen and (max-width: 1280px) {
    .dashboard-container .skeleton_wrapper .skeleton_container {
      width: 832px;
      .skeleton_item .skeleton_left {
        width: 259px;
        height: 180px;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .dashboard-container .skeleton_wrapper .skeleton_container {
      width: 728px;
    }
  }
  @media screen and (max-width: 768px) {
    .dashboard-container .skeleton_wrapper {
      margin-top: 100px;
      .skeleton_container {
        width: 100%;
        padding: 0 16px;
        padding-top: 46px;
        .skeleton_item .skeleton_left {
          width: 100px;
          height: 100px;
        }
        .skeleton_item + .skeleton_item {
          margin-top: 34px;
        }
      }
    }
  }
  @media screen and (min-width:1281px) {
    .dashboard-container {
      .container {
        padding: 0 80px;
      }
    }
  }
</style>
